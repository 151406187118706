import React from "react";
import { withRouter, useLocation, useHistory } from "react-router-dom";
import { Tabs, Button } from "antd";

const { TabPane } = Tabs;

function TabHeader(props) {
  console.log(props);
  let history = useHistory();
  const navigate = (screen) => {
    let location = screen === "1" ? "/auth/Collections" : "/auth/Boxes";
    history.push(location);
  };
  return (
    <div className="TabHeader-box1">
      <div className="card-container">
        <Tabs defaultActiveKey="1" onChange={(val) => navigate(val)}>
          <TabPane tab="Collection" key="1"></TabPane>
          <TabPane tab="Boxes" key="2"></TabPane>
        </Tabs>
      </div>
      {props.selectedRow?.length ? (
        <div>
          <Button
            onClick={() => props.setPayModal()}
            size="small"
            style={{
              borderRadius: 6,
              background: "rgb(191, 255, 209)",
            }}
          >
            Pay{" "}
            {props.selectedRow[0].total_amount
              ? props.selectedRow[0].total_amount
              : props.selectedRow[0]?.boxes.reduce(function (tot, arr) {
                  return Number(tot) + Number(arr.total_charge);
                }, 0)}
          </Button>
        </div>
      ) : null}
    </div>
  );
}

export default TabHeader;

import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import MontserratMedium from "../../../Assets/Fonts/Montserrat-Medium.ttf";
import MontserratBold from "../../../Assets/Fonts/Montserrat-Bold.ttf";
import MontserratRegular from "../../../Assets/Fonts/Montserrat-Regular.ttf";
function Table(props) {
  return (
    <View>
      <View style={{ marginTop: 5 }} />
      <View style={styles.Table}>
        <View style={styles.Row}>
          <View style={styles.Col1}>
            <View>
              <Text style={styles.Text1}>S No</Text>
            </View>
          </View>
          <View style={styles.Col}>
            <View>
              <Text style={styles.Text1}>Items Name</Text>
            </View>
          </View>
          <View style={styles.Col1}>
            <View>
              <Text style={styles.Text1}>Qty</Text>
            </View>
          </View>
          <View style={styles.Col1}>
            <View>
              <Text style={styles.Text1}>S No</Text>
            </View>
          </View>
          <View style={styles.Col}>
            <View>
              <Text style={styles.Text1}>Items Name</Text>
            </View>
          </View>
          <View style={styles.Col1}>
            <View>
              <Text style={styles.Text1}>Qty</Text>
            </View>
          </View>
        </View>
        {props.item &&
          props.item.boxitems
            .slice(props.init, props.length)
            .map((item, index) => {
              return (
                <View style={styles.Row} key={index}>
                  <View style={styles.Col1}>
                    <View>
                      <Text style={styles.Text1}>{props.init + index}</Text>
                    </View>
                  </View>
                  <View style={styles.Col}>
                    <View>
                      <Text style={styles.Text1}>{item.item}</Text>
                    </View>
                  </View>
                  <View style={styles.Col1}>
                    <View>
                      <Text style={styles.Text1}>{item.quantity}</Text>
                    </View>
                  </View>
                  <View style={styles.Col1}>
                    <View>
                      <Text style={styles.Text1}></Text>
                    </View>
                  </View>
                  <View style={styles.Col}>
                    <View>
                      <Text style={styles.Text1}></Text>
                    </View>
                  </View>
                  <View style={styles.Col1}>
                    <View>
                      <Text style={styles.Text1}></Text>
                    </View>
                  </View>
                </View>
              );
            })}
      </View>
    </View>
  );
}

export default Table;

const styles = StyleSheet.create({
  Table: {
    display: "table",
    tableLayout: "fixed",
    width: "100%",
  },
  Row: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  Col: {
    flex: 1,
    border: "1px solid black",
  },
   Col1: {
    border: "1px solid black",
    width:"30px",
    textAlign:"center"
  },
  Col2: {
    flex: 1,
  },
  Text1: {
    fontFamily: "Montserrat",
    fontSize: "10px",
    textTransform: "uppercase",
  },
});

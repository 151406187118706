import { useState } from "react";
import { Table, Pagination, Button, Popconfirm, Popover, Tag } from "antd";
import moment from "moment";
import { MdOutlineDone, MdPersonOutline } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import { FaStore } from "react-icons/fa";
import styles from "./styles";
function DataTable(props) {
  const [details, setDetails] = useState(false);

  const content = (record) => (
    <div className="PickupScreen-DataTable-box1">
      <div>
        <Button
          size="small"
          type="primary"
          onClick={() => props.assign(record)}
          block
        >
          Assign
        </Button>
      </div>
      <div style={{ margin: 1 }} />
      <div>
        <Button
          size="small"
          block
          onClick={() => setDetails(!details)}
          disabled={record.assigned_to ? false : true}
        >
          Details {"   "}
          <IoIosArrowForward size={10} />
        </Button>
      </div>
      {details ? (
        <div className="PickupScreen-DataTable-txt1">
          <br />
          <div>
            <MdPersonOutline /> {"  "}
            Driver :{" "}
            {record.assigned_users && record.assigned_users.name
              ? record.assigned_users.name
              : "N/A"}
          </div>
          <div>
            <FaStore size={10} /> {"  "}
            Branch :{" "}
            {record.assigned_users &&
            record.assigned_users.branch &&
            record.assigned_users.branch.name
              ? record.assigned_users.branch.name
              : "N/A"}
          </div>
        </div>
      ) : null}
    </div>
  );

  const getColumnSearchProps = (dataIndex) => ({});

  const columns = [
    {
      title: <span className="tableHeading">ID</span>,
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => (
        <div className={record.active ? "" : "PickupScreen-DataTable-pickedup"}>
          {index + 1}
        </div>
      ),
    },
    {
      title: <span className="tableHeading">Sender Name</span>,
      dataIndex: "sender_name",
      key: "sender_name",
      render: (text, record) => (
        <div className={record.active ? "" : "PickupScreen-DataTable-pickedup"}>
          {text}
        </div>
      ),
    },
    {
      title: <span className="tableHeading">Sender Phone</span>,
      dataIndex: "sender_phone",
      key: "sender_phone",
      render: (text, record) => (
        <div className={record.active ? "" : "PickupScreen-DataTable-pickedup"}>
          {text}
        </div>
      ),
    },
    {
      title: <span className="tableHeading">Pickup Place</span>,
      dataIndex: "pickup_place",
      key: "pickup_place",
      render: (text, record) => (
        <div className={record.active ? "" : "PickupScreen-DataTable-pickedup"}>
          {record.pickup_branchs && record.pickup_branchs.name}
        </div>
      ),
    },
    {
      title: <span className="tableHeading">Assign to Driver</span>,
      dataIndex: "pickup_place",
      key: "pickup_place",
      render: (text, record) => (
        <div className="PickupScreen-DataTable-box2">
          <Popover
            content={content(record)}
            title="Details"
            placement="rightTop"
          >
            <Button
              size="small"
              style={record.assigned_to ? styles.AssignedBtn : styles.Button}
              icon={
                record.assigned_to ? (
                  <MdOutlineDone size={10} color="#000" />
                ) : (
                  ""
                )
              }
            >
              {record.assigned_to ? "Assigned" : "Assign"}
            </Button>
          </Popover>
        </div>
      ),
    },
    {
      title: <span className="tableHeading">Convenient date</span>,
      dataIndex: "convenient_datetime",
      key: "convenient_datetime",
      render: (text, record) => (
        <div className={record.active ? "" : "PickupScreen-DataTable-pickedup"}>
          {moment(text).format("DD-M-YYYY HH:MM")}
        </div>
      ),
    },
    {
      title: "status",
      dataIndex: "active",
      key: "active",
      filters: [
        {
          text: "picked",
          value: true,
        },
        {
          text: "pending",
          value: false,
        },
      ],

      onFilter: (value, record) => (
        console.log("record===>", record.active), value ? value : false
      ),

      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ["descend"],
      render: (text, record) => (
        <div className="PickupScreen-DataTable-box2">
          {" "}
          <Tag color={record.active ? "red" : "green"}>
            {record.active ? "pending" : "picked"}
          </Tag>
        </div>
      ),
    },
    {
      title: "",
      render: (item) => (
        <div className="tableAction">
          <Popconfirm
            placement="bottomRight"
            title={"Are you sure to delete?"}
            okText="Yes"
            cancelText="No"
            onConfirm={() => props.delete(item)}
          >
            <Button size="small">Delete</Button>
          </Popconfirm>
          <Button
            size="small"
            onClick={() => props.update(item)}
            type="primary"
          >
            Edit
          </Button>
        </div>
      ),
    },
  ];
  return (
    <div className="tableBox">
      <Table
        columns={columns}
        dataSource={props.data}
        pagination={false}
        size="small"
        style={{ textTransform: "uppercase" }}
      />
      <br />
      <div align="right">
        <Pagination
          defaultCurrent={props.meta && props.meta.page}
          total={props.meta && props.meta.itemCount}
          pageSize={props.meta && props.meta.take ? props.meta.take : 0}
          onChange={props.PageOnchange}
          pageSizeOptions={[10, 20, 50]}
        />
      </div>
    </div>
  );
}
export default DataTable;

import { Table, Pagination, Button, Popconfirm } from "antd";
import moment from "moment";

function DataTable(props) {
  console.log("props.columnsVisible.accounts", props.columnsVisible);
  const columns = [
    {
      title: "#",
      key: "index",
      width: "20px",
      render: (text, record, index) =>
        (props.meta.page - 1) * props.meta.take + index + 1,
    },
    {
      title: "Date",
      dataIndex: "voucher_date",
      key: "voucher_date",
      render: (text, record, index) => (
        <span>{moment(record.voucher_date).format("DD-MM-YYYY")}</span>
      ),
    },
    props.columnsVisible.accounts && {
      title: "Account No",
      dataIndex: "account_no",
      key: "account_no",
      render: (text, record, index) => (
        <span>
          <b>
            {record.account &&
              record.account.account_no + " - " + record.account.account_name}
          </b>
        </span>
      ),
    },
    props.columnsVisible.amount_cr && {
      title: "Credit Amt.",
      dataIndex: "cr_total",
      key: "cr_total",
      render: (text, record, index) => (
        <span>{props.columnsVisible.amount_cr && text}</span>
      ),
    },
    props.columnsVisible.amount_dr && {
      title: "Debit Amt.",
      dataIndex: "dr_total",
      key: "dr_total",
      render: (text, record, index) => (
        <span>{props.columnsVisible.amount_dr && text}</span>
      ),
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      render: (text, record, index) => (
        <span>{record.user && record.user.name}</span>
      ),
    },
    {
      title: "Created",
      dataIndex: "createdat",
      key: "createdat",
      align: "center",
      render: (text, record, index) => (
        <span>
          <b>{record.created_user && record.created_user.name} </b>
          {moment(record.createdat).fromNow()}
        </span>
      ),
    },
    {
      title: "",
      render: (item) => (
        <div className="tableAction">
          <Popconfirm
            placement="bottomRight"
            title={"Are you sure to delete?"}
            okText="Yes"
            cancelText="No"
            onConfirm={() => props.delete(item)}
          >
            <Button size="small">Delete</Button>
          </Popconfirm>
          <Button
            size="small"
            onClick={() => props.update(item)}
            type="primary"
          >
            Edit
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div style={{ minHeight: "60vh" }}>
      <Table
        columns={columns.filter((column) => column)}
        dataSource={props.data}
        pagination={false}
        size="small"
      />
      <br />
      <div align="right">
        <Pagination
          defaultCurrent={props.meta && props.meta.page}
          total={props.meta && props.meta.itemCount}
          pageSize={props.meta && props.meta.take ? props.meta.take : 0}
          onChange={props.PageOnchange}
          pageSizeOptions={[10, 20, 50]}
        />
      </div>
    </div>
  );
}

export default DataTable;

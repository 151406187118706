import React, { useCallback, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import { Button, Modal } from "antd";

import Divider from "@mui/material/Divider";

import { useSelector } from "react-redux";

import ProtectRouter from "./ProtectRouter";
import SideBar from "./SideBar";
import HomeScreen from "../Containers/HomeScreen";
import PickupScreen from "../Containers/PickupScreen";
import CollectionScreen from "../Containers/CollectionScreen";
import StaffScreen from "../Containers/StaffScreen";
import DriverScreen from "../Containers/DriverScreen";
import AgentScreen from "../Containers/AgentScreen";
import BranchScreen from "../Containers/BranchScreen";
import CustomerScreen from "../Containers/CustomerScreen";
import AirlineScreen from "../Containers/AirlineScreen";
import AirwayBillScreen from "../Containers/AirwayBillScreen";
import PortScreen from "../Containers/PortScreen";
import ExpenseScreen from "../Containers/ExpenseScreen";
import CountrieScreen from "../Containers/CountrieScreen";
import ItemScreen from "../Containers/ItemScreen";
import StatuScreen from "../Containers/StatuScreen";
import ExportBrokerScreen from "../Containers/ExportBrokerScreen";
import DeliveryAgentScreen from "../Containers/DeliveryAgentScreen";
import AirwayBillGenScreen from "../Containers/AirwayBillGenScreen";
import TransactionScreen from "../Containers/TransactionScreen";
import SenderScreen from "../Containers/SenderScreen";
import RecieverScreen from "../Containers/RecieverNew";
import BoxesScreen from "../Containers/Boxes";
import AwbSupplierScreen from "../Containers/AwbSupplierScreen";
import AwbXrayAgentScreen from "../Containers/AwbXrayAgent";
import ClearingAgentScreen from "../Containers/ClearingAgent";
import ServiceTypeScreen from "../Containers/ServiceType";
import BoxesItemsScreen from "../Containers/BoxeItemScreen";
import GroupsScreen from "../Containers/Groups";
import UsersScreen from "../Containers/Users";
import RoleScreen from "../Containers/Role";
import BoxTypeScreen from "../Containers/BoxTypeScreen";
import AssignBoxesToAwbBill from "../Containers/AssignBoxesToAwbBill";
import TripScreen from "../Containers/Trip";
import AssignBoxesToTrip from "../Containers/AssignBoxesToTrip";
import AirwayBillProScreen from "../Containers/AirwayBillProScreen";
import Pincode from "../Containers/Pincode";
import ThirtPartySenders from "../Containers/ThirtPartySenders";
import ReferenceNoGenScreen from "../Containers/ReferenceNoGenScreen";
import AwbtoXrayTransactions from "../Containers/AwbtoXrayTransactions";
import Reports from "../Containers/Reports";
import AwbReport from "../Containers/AwbReport";
import TripReport from "../Containers/TripReport";
import DetailedTracking from "../Containers/DetailedTracking";
import VerifyUserScreen from "../Containers/VerifyUserScreen";
import KeyBoardShortCut from "../Components/KeyBoardShortCut";
import ThirdPartyTransactions from "../Containers/ThirdPartyTransactions";
import BoxItem from "../Containers/BoxItem";
import Stock from "../Containers/Stock";
import "./styles.css";
import { useHistory } from "react-router-dom";
import Header from "./Header";
import Expense from "../Containers/Expense";
import ExpenceReport from "../Containers/ExpenceReport";
import ChartOfAccounts from "../Containers/ChartOfAccounts/ChartOfAccounts";
import Voucher from "../Containers/VoucherScreen/Voucher";
import StatementPage from "../Containers/Statement/StatementPage";
import TrialBalanceTable from "../Containers/TrialBalance/TrialBalanceTable";
import AirwayBillPayment from "../Containers/AirwayBillPayment/AirwayBillPayment";
import AwbReports from "../Containers/AwbReports/AwbReports";
import PackingReport from "../Containers/Reports/PackingReport/PackingReport";
import ExchangeRates from "../Containers/ExchangeRates";
import UserAccess from "../Containers/UserAccess";
import PhoneNumbers from "../Containers/PhoneNumbers";
import Resetpassword from "../../src/Containers/Restpassword";
import { useSessionTimeout } from "../Config/useSessionTimeout";
import { IoMdClose } from "react-icons/io";
import { CiWarning } from "react-icons/ci";
import Delivery from "../Containers/Delivery";
import DeliveryStatusUpdate from "../Containers/DeliveryStatus";

const drawerWidth = 280;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 9px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 16px)`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    // marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  background: "#1e88e5",
  marginBottom: "8px",

  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function Routes() {
  const Auth = useSelector((state) => state.Auth);

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  let history = useHistory();
  const handleKeyPress = useCallback((event) => {
    if (event.key === "k" && event.altKey) {
      history.push("/auth/keyboardshortcuts");
    }
    if (event.key === "c" && event.altKey) {
      history.push("/auth/Collections");
    }
    if (event.key === "b" && event.altKey) {
      history.push("/auth/Boxes");
    }
    if (event.key === "a" && event.altKey) {
      history.push("/auth/airwaybillpro");
    }
    if (event.key === "p" && event.altKey) {
      history.push("/auth/Pickups");
    }
    if (event.key === "t" && event.altKey) {
      history.push("/auth/Trip");
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  const { isModalOpen, handleClose, handleStayLoggedIn } = useSessionTimeout();

  return (
    <Box>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          open={open}
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer - 850,
            // width: open
            //   ? { sm: `calc(100% - ${drawerWidth}px)` }
            //   : { sm: `calc(100%)` },
            // ml: { sm: `${drawerWidth}px` },
            backgroundColor: "#1e88e5",
            color: "#000",
            boxShadow: 0.01,
            minHeight: "70px",
          }}
        >
          <Header open={() => handleDrawerOpen()} status={open} />
        </AppBar>
        <Drawer
          variant="permanent"
          open={open}
          sx={{
            "& .MuiDrawer-paper": {
              zIndex: (theme) => theme.zIndex.drawer - 900,
            },
          }}
        >
          <DrawerHeader>
            <div>Header</div>
            <br />
          </DrawerHeader>

          <Divider />

          <SideBar status={open} open={() => handleDrawerOpen()} />
          <Divider />
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            minHeight: "100vh",
          }}
        >
          <Toolbar />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/home"
            component={HomeScreen}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/Pickups"
            component={PickupScreen}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/Collections"
            component={CollectionScreen}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/Staffs"
            component={StaffScreen}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/Drivers"
            component={DriverScreen}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/Branches"
            component={BranchScreen}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/Customers"
            component={CustomerScreen}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/Airlines"
            component={AirlineScreen}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/AirwayBills"
            component={AirwayBillScreen}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/AirBillGen"
            component={AirwayBillGenScreen}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/Ports"
            component={PortScreen}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/ExportBroker"
            component={ExportBrokerScreen}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/Expenses"
            component={ExpenseScreen}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/Countries"
            component={CountrieScreen}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/Items"
            component={ItemScreen}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/BoxItem"
            component={BoxItem}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/Statuses"
            component={StatuScreen}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/Transactions"
            component={TransactionScreen}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/Senders"
            component={SenderScreen}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/Recievers"
            component={RecieverScreen}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/Boxes"
            component={BoxesScreen}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/AwbSupplier"
            component={AwbSupplierScreen}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/Agents"
            component={AgentScreen}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/DeliveryAgent"
            component={DeliveryAgentScreen}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/AwbXrayAgent"
            component={AwbXrayAgentScreen}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/ClearingAgent"
            component={ClearingAgentScreen}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/ServiceType"
            component={ServiceTypeScreen}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/BoxeItems"
            component={BoxesItemsScreen}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/Trip"
            component={TripScreen}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/Groups"
            component={GroupsScreen}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/Users"
            component={UsersScreen}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/Role"
            component={RoleScreen}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/boxtype"
            component={BoxTypeScreen}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/assignboxawb"
            component={AssignBoxesToAwbBill}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/assignboxtrip"
            component={AssignBoxesToTrip}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/airwaybillpro"
            component={AirwayBillProScreen}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/ThirtPartySenders"
            component={ThirtPartySenders}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/AwbtoXrayTransactions"
            component={AwbtoXrayTransactions}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/Pincode"
            component={Pincode}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/reference-number"
            component={ReferenceNoGenScreen}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/reports"
            component={Reports}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/AwbReport"
            component={AwbReport}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/TripReport"
            component={TripReport}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/ExpenceReport"
            component={ExpenceReport}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/detailedtracking"
            component={DetailedTracking}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/user-verification"
            component={VerifyUserScreen}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/keyboardshortcuts"
            component={KeyBoardShortCut}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/ThirdPartyTransactions"
            component={ThirdPartyTransactions}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/Stock"
            component={Stock}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/Expense"
            component={Expense}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/StatementPage"
            component={StatementPage}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/TrialBalanceTable"
            component={TrialBalanceTable}
          />

          <ProtectRouter
            authed={Auth.auth}
            path="/auth/ChartOfAccounts"
            component={ChartOfAccounts}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/VoucherPv/:page_mode"
            component={Voucher}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/VoucherRv/:page_mode"
            component={Voucher}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/VoucherJv/:page_mode"
            component={Voucher}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/AirwayBillPayment"
            component={AirwayBillPayment}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/AwbReports"
            component={AwbReports}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/PackingReport"
            component={PackingReport}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/ExchangeRates"
            component={ExchangeRates}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/UserAccess"
            component={UserAccess}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/restpassword"
            component={Resetpassword}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/PhoneNumbers"
            component={PhoneNumbers}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/Delivery"
            component={Delivery}
          />
          <ProtectRouter
            authed={Auth.auth}
            path="/auth/DeliveryStatusUpdate"
            component={DeliveryStatusUpdate}
          />
        </Box>
      </Box>

      <Modal
        open={isModalOpen}
        width={400}
        footer={false}
        closeIcon={<IoMdClose color="#fff" />}
        icon={false}
        centered={true}
      >
        <div className="modalBody">
          <CiWarning color="red" size={70} />
          <div className="modalTxt1">Session Expired</div>
          <div className="modalTxt2">Continue to Login</div>
          <Button type="primary" style={{ width: 200 }} onClick={handleClose}>
            Login
          </Button>
        </div>
      </Modal>
    </Box>
  );
}

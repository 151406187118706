import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import moment from "moment";
import logo from "../../Assets/Images/logoNew.jpg";
import MontserratMedium from "../../Assets/Fonts/Montserrat-Medium.ttf";
import MontserratBold from "../../Assets/Fonts/Montserrat-Bold.ttf";

var JsBarcode = require("jsbarcode");

function PdfTripSheet(props) {
  let data = props.selected.id;
  let canvas;
  canvas = document.createElement("canvas");
  JsBarcode(canvas, data, {
    width: 3,
    height: 30,
  });

  const barcode = canvas.toDataURL();

  return (
    <Document>
      <Page size="A4" wrap style={styles.Page}>
        <View style={styles.Table}>
          <View style={styles.Row}>
            <View style={styles.Col}>
              <View style={styles.LogoBox}>
                <Image src={logo} style={styles.Logo} />
              </View>
            </View>
            <View style={styles.Col2}>
              {/* <View>
                <Image src={barcode} />
              </View> */}
              <Text style={styles.Text1}> VAT NO: 300602378400003</Text>
            </View>
          </View>
          <View style={styles.Row}>
            <View style={styles.Col}>
              <View>
                <Text style={styles.Text1}>
                  DATE : {moment(props.selected.createdAt).format("l")}{" "}
                </Text>
              </View>
            </View>
            <View style={styles.Col2}>
              <View>
                <Text style={styles.Text1}>
                  FROM :{" "}
                  {props.selected.loggin_branch.name &&
                    props.selected.loggin_branch.name}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.Row}>
            <View style={styles.Col}>
              <View>
                <Text style={styles.Text1}>TRIP NO :{props.selected.id}</Text>
              </View>
            </View>
            <View style={styles.Col2}>
              <View>
                <Text style={styles.Text1}>
                  TO :{" "}
                  {props.selected.to_branch.name &&
                    props.selected.to_branch.name}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.Row}>
            <View style={styles.Col}>
              <View>
                <Text style={styles.Text1}>
                  VEHICLE NO : {props.selected.truck_no}
                </Text>
              </View>
            </View>
            <View style={styles.Col2}>
              <View>
                <Text style={styles.Text1}>TOTAL PCS</Text>
              </View>
            </View>
          </View>
          <View style={styles.Row}>
            <View style={styles.Col}>
              <View>
                <Text style={styles.Text1}>
                  DRIVER NAME : {props.selected.driver_name}
                </Text>
              </View>
            </View>
            <View style={styles.Col2}>
              <View>
                <Text style={styles.Text1}>TOTAL WEIGHT :</Text>
              </View>
            </View>
          </View>
          <View style={styles.Table2}>
            <View style={styles.Thead}>
              <View style={styles.Row}>
                <View style={styles.Col}>
                  <Text style={styles.Text2}>BOX NO</Text>
                </View>
                <View style={styles.Col}>
                  <Text style={styles.Text2}>WEIGHT</Text>
                </View>
                <View style={styles.Col}>
                  <Text style={styles.Text2}>SERVICE</Text>
                </View>
              </View>
            </View>
            <View style={styles.Tbody}>
              {props.data &&
                props.data.map((item, index) => {
                  console.log("PdfTripSheet===>", item);
                  return (
                    <View style={styles.Row}>
                      <View style={styles.BorderedCol}>
                        <Text style={styles.Text2}>{item.box_number}</Text>
                      </View>
                      <View style={styles.BorderedCol}>
                        <Text style={styles.Text2}> {item.weight}</Text>
                      </View>
                      <View style={styles.BorderedCol}>
                        <Text style={styles.Text2}>
                          {" "}
                          {item.service && item.service.name}
                        </Text>
                      </View>
                    </View>
                  );
                })}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}

export default PdfTripSheet;

const styles = StyleSheet.create({
  Page: {
    padding: 20,
  },
  Table: {
    display: "table",
    tableLayout: "fixed",
    width: "100%",
  },
  Table2: {
    marginTop: 5,
    display: "table",
    tableLayout: "fixed",
    width: "100%",
  },
  Row: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  Col: {
    flex: 1,
  },
  Col2: {
    flex: 1,
    textAlign: "right",
  },
  BorderedCol: {
    flex: 1,
    border: "1px solid black",
  },
  Text1: {
    fontSize: "13px",
    fontFamily: "Montserrat",
    textTransform: "uppercase",
    margin: 5,
  },
  Text2: {
    fontSize: "13px",
    fontFamily: "Montserrat",
    textTransform: "uppercase",
  },
  Thead: {
    backgroundColor: "rgb(173, 173, 173)",
  },
  Tbody: {},
  LogoBox: {
    width: "100%",
    height: "90px",
    objectFit: "contain",
  },
  Logo: {
    width: "190px",
    height: "50px",
  },
});

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratMedium,
    },
    { src: MontserratBold, fontWeight: "bold" },
  ],
});

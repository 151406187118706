import { Table, Pagination, Button, Popconfirm, Tag } from "antd";
import moment from "moment";

function DataTable(props) {
  console.log("data", props);
  const columns = [
    // {
    //   title: "#",
    //   key: "index",
    //   width: "20px",
    //   render: (text, record, index) =>
    //     (props.meta.page - 1) * props.meta.take + index + 1,
    // },
    {
      title: "From",
      dataIndex: "from_date",
      key: "from_date",
      render: (_, record) => (
        <span>{moment(record.from_date).format("ll")}</span>
      ),
    },
    {
      title: "To",
      dataIndex: "to_date",
      key: "to_date",
      render: (_, record) => <span>{moment(record.to_date).format("ll")}</span>,
    },

    {
      title: "SAR - USD",
      dataIndex: "sar_usd",
      key: "sar_usd",
      render: (_, record) => <span>{Number(record.sar_usd).toFixed(3)}</span>,
    },

    {
      title: "SAR - INR",
      dataIndex: "sar_inr",
      key: "sar_inr",
      render: (_, record) => <span>{Number(record.sar_inr).toFixed(3)}</span>,
    },

    {
      title: "INR - SAR",
      dataIndex: "inr_sar",
      key: "inr_sar",
      render: (_, record) => <span>{Number(record.inr_sar).toFixed(3)}</span>,
    },
    {
      title: "",
      dataIndex: "",
      key: "x",
      render: (item) => (
        <div>
          <Button size="small" onClick={() => props.update(item)}>
            Edit
          </Button>
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "x",
      render: (item) => (
        <div>
          <Button
            size="small"
            onClick={() => props.delete(item)}
            type="primary"
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];
  return (
    <div style={{ minHeight: "60vh" }}>
      <Table
        columns={columns}
        dataSource={props?.data}
        pagination={false}
        size="small"
      />
      <br />

      <Pagination
        total={props?.meta?.total_count}
        showTotal={(total) => `Total ${props?.meta?.total_count}`}
        onChange={(page, pageSize) => props?.onPageChange(page, pageSize)}
        // onChange={(page, pageSize) => {
        //   setPage(props?.page);
        //   setTake(props?.pageSize);
        // }}
      />
      {/* <div align="right">
        <Pagination
          responsive
          defaultCurrent={props.meta && props.meta.page}
          total={props.meta && props.meta.itemCount}
          pageSize={props.meta && props.meta.take ? props.meta.take : 0}
          onChange={props.PageOnchange}
          pageSizeOptions={[10, 20, 50]}
        />
      </div> */}
    </div>
  );
}
export default DataTable;

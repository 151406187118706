import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { sessionOut } from "../Redux/Slices/AuthSlice";
import { useDispatch } from "react-redux";

export const useSessionTimeout = (timeout = 3600000) => {
  const dispatch = useDispatch();
  // 3600000 milliseconds = 60 minutes
  const history = useHistory();
  const [isModalOpen, setModalOpen] = useState(false);
  const timerRef = useRef(null); // Using useRef to hold the timer ID

  const handleActivity = () => {
    if (!isModalOpen) {
      // Only reset the timer if the modal is not open
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        // Open modal instead of logging out immediately
        dispatch(sessionOut());

        setModalOpen(true);
      }, timeout);
    }
  };

  useEffect(() => {
    // Listen for mouse movements in the window
    window.addEventListener("mousemove", handleActivity);

    // Set initial timer
    handleActivity();

    return () => {
      // Cleanup event listener and timer on component unmount
      window.removeEventListener("mousemove", handleActivity);
      clearTimeout(timerRef.current);
    };
  }, [timeout]); // Removed history from dependency array as it's not directly used in the effect

  const handleClose = () => {
    setModalOpen(false);
    history.push("/"); // redirect to login page after confirming logout
  };

  const handleStayLoggedIn = () => {
    setModalOpen(false);
    // Resume the timer and the mouse movement listener
    handleActivity(); // Reset and start the timer anew
  };

  return { isModalOpen, handleClose, handleStayLoggedIn };
};

import React, { useEffect, useState } from "react";
import axios from "axios";
import { message } from "antd";
import { API, Headers } from "../../Config";
import PageHeader from "../../Routes/PageHeader";
import LoadingBox from "../../Components/LoadingBox";
import ReportTable from "./ReportTable";
import MenuBar from "./MenuBar";

function AwbReport() {
  const [isLoading, setIsLoading] = useState(false);
  const [indicator, setIndicator] = useState(false);
  const [data, setData] = useState([]);
  const [user, setUser] = useState([]);
  const [branch, setBranch] = useState([]);
  const [obj, setObj] = useState([]);

  useEffect(() => {
    loadUsers();
    loadBranches();
  }, []);

  const loadUsers = () => {
    axios
      .get(API.BASR_URL + API.USERS_LIST + `/list`, {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setUser(data);
        }
      });
  };

  const loadBranches = () => {
    axios
      .get(API.BASR_URL + API.BRANCHES_LIST + `/list`, {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setBranch(data);
          console.log(branch);
        }
      });
  };

  const onFinish = (val) => {
    setIsLoading(true);
    let requestObj = val;
    setObj(requestObj);

    axios({
      method: "post",
      url: API.BASR_URL + API.AIRWAY_BILLS_GEN_ACTION + `report`,
      data: requestObj,
      headers: Headers(),
    })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          console.log(data);
          setData(data);
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <div>
      <PageHeader PageName={"Report"} breadScrum={"Admin / Report/ Awb"} />
      <MenuBar user={user} branches={branch} onFinish={onFinish} />

      {isLoading ? <LoadingBox /> : <ReportTable data={data} obj={obj} />}
    </div>
  );
}

export default AwbReport;

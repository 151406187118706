import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { Form, Input, Modal, Button, message } from "antd";
import { Row, Col } from "react-bootstrap";
import axios from "axios";
import { API, Headers } from "../../../../Config";
import moment from "moment";
import styles from "../../styles";

const { TextArea } = Input;
function FormModal(props) {
  const Auth = useSelector((state) => state.Auth.user);
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    let requestObj = {
      name: values.name,
      address: values.address,
      account_number: parseInt(values.account_number),
      userid: parseInt(Auth.id),
    };

    console.log(requestObj);

    const RowID = props.selected && props.selected[0]?.id;

    let URL =
      props.mode === "post"
        ? API.BASR_URL + API.SENDERS_ACTION
        : API.BASR_URL + API.SENDERS_ACTION + RowID;

    axios({
      method: props.mode,
      url: URL,
      headers: Headers(),
      data: requestObj,
    })
      .then(function (response) {
        setLoading(false);
        if ([200, 201].includes(response.status)) {
          message.success("Success");
          props.reload();
        }
      })
      .catch(function (error) {
        setLoading(false);
        message.error("Oops.Something went wrong");
        console.log(error);
      });
  };
  return (
    <Modal visible={props.visible} onCancel={props.onClose} footer={false}>
      <h5 className="PrintLabelModal-txt1">
        {props.mode === "post" ? "Add" : "Modify"} Contact
      </h5>
      <br />
      <Form
        initialValues={{
          name: props.selected && props.selected[0]?.name,
          address: props.selected && props.selected[0]?.address,
          account_number: props.selected && props.selected[0]?.account_number,
        }}
        onFinish={onFinish}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item name="name" label="Display Name" style={{ margin: 4 }}>
          <Input placeholder="" style={styles.FormInput} />
        </Form.Item>
        <Form.Item
          name="account_number"
          label="Account Number"
          style={{ margin: 4 }}
        >
          <Input placeholder="" />
        </Form.Item>
        <Form.Item name="address" label="Address" style={{ margin: 4 }}>
          <TextArea placeholder="" style={styles.FormInput} />
        </Form.Item>
        <br />
        <Row>
          <Col sm="6"></Col>
          <Col sm="3">
            <Button type="primary" htmlType="submit" block>
              Add
            </Button>
          </Col>
          <Col sm="3">
            <Button onClick={props.onClose} block>
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default FormModal;

// import React, { useState } from "react";
// import { Tree, Input } from "antd";
// const { Search } = Input;
// const SearchableTree = (props) => {
//   const data = props.treeData;
//   const [expandedKeys, setExpandedKeys] = useState([]);
//   const [searchValue, setSearchValue] = useState("");
//   const [autoExpandParent, setAutoExpandParent] = useState(true);

//   const onExpand = (expandedKeys) => {
//     setExpandedKeys(expandedKeys);
//     setAutoExpandParent(false);
//   };

//   const onChange = (e) => {
//     const { value } = e.target;
//     setSearchValue(value);
//     setAutoExpandParent(true);
//   };

//   const loop = (data) =>
//     data.map((item) => {
//       const index = item.account_name.toLowerCase().indexOf(searchValue.toLowerCase());
//       const title =
//         index > -1 ? (
//           <span key={item.id}>
//             <b> {item.account_no}</b> - {item.account_name.substr(0, index)}
//             <span style={{ color: "#f50" }}>
//               {item.account_name.substr(index, searchValue.length)}
//             </span>
//             {item.account_name.substr(index + searchValue.length)}
//           </span>
//         ) : (
//           <span>{item.title}</span>
//         );
//       if (item.children) {
//         return { ...item, title, children: loop(item.children) };
//       }

//       return { ...item, title };
//     });
//   const handleData = (e) => {
//     console.log(e);
//   };
//   return (
//     <>
//       <Search
//        style={{ marginBottom: 16,width:'300px' }}
//         placeholder="Search"
//         onChange={onChange}
//       />
//       <Tree

//         onExpand={onExpand}
//         expandedKeys={expandedKeys}
//         autoExpandParent={autoExpandParent}
//         treeData={loop(data)}
//         onClick={handleData}
//         defaultExpandAll
//         onSelect={(selectedKeys, info) => {
//           console.log('selected', selectedKeys, info);
//         }}
//       />
//     </>
//   );
// };

// export default SearchableTree;
import React, { useState, useEffect } from "react";
import { Tree, Input } from "antd";

const { Search } = Input;

const SearchableTree = (props) => {
  const data = props.treeData;
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  useEffect(() => {
    // Get all the keys of the tree nodes
    const allKeys = getAllKeys(data);
    setExpandedKeys(allKeys);
  }, [data]);

  const getAllKeys = (data) => {
    const keys = [];
    data.forEach((item) => {
      keys.push(item.key);
      if (item.children) {
        keys.push(...getAllKeys(item.children));
      }
    });
    return keys;
  };

  const onExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  };

  const onChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    setAutoExpandParent(true);
  };

  const loop = (data) =>
    data.map((item) => {
      const index = item.account_name
        .toLowerCase()
        .indexOf(searchValue.toLowerCase());
      const title =
        index > -1 ? (
          <span key={item.id}>
            <b> {item.account_no}</b> - {item.account_name.substr(0, index)}
            <span style={{ color: "#f50" }}>
              {item.account_name.substr(index, searchValue.length)}
            </span>
            {item.account_name.substr(index + searchValue.length)}
          </span>
        ) : (
          <span>{item.title}</span>
        );
      if (item.children) {
        return { ...item, title, children: loop(item.children) };
      }

      return { ...item, title };
    });

  const handleData = (e) => {
    console.log(e);
  };

  return (
    <>
      <Search
        style={{ marginBottom: 16, width: "300px" }}
        placeholder="Search"
        onChange={onChange}
      />
      <Tree
        onExpand={onExpand}
        expandedKeys={expandedKeys}
        autoExpandParent={autoExpandParent}
        treeData={loop(data)}
        onClick={handleData}
        onSelect={(selectedKeys, info) => {
          console.log("selected", selectedKeys, info);
        }}
      />
    </>
  );
};

export default SearchableTree;

import React, { useState, useEffect, useRef } from "react";
import { Form, DatePicker, Select, Button, Table, message } from "antd";
import moment from "moment";
import { API, Headers } from "../../Config";
import axios from "axios";
import ReactToPrint from "react-to-print";
import { PrinterOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;
const { Option } = Select;

const StatementPage = () => {
  const [loading, setLoading] = useState(false);
  const componentRef = useRef();
  const [form] = Form.useForm();
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [account_no, setAccountNo] = useState("");
  useEffect(() => {
    AccountsDropdown();
  }, []);
  const handleSearch = () => {
    form.validateFields().then((values) => {
      const { dateRange, account_no } = values;

      // Make an API call to the backend to fetch filtered data based on date range and selected account
      fetchFilteredData(dateRange, account_no);
    });
  };
  const fetchFilteredData = async (dateRange, account) => {
    const [startDate, endDate] = dateRange;
    const formattedStartDate = startDate
      ? startDate.startOf("day").toISOString()
      : null;
    const formattedEndDate = endDate
      ? endDate.endOf("day").toISOString()
      : null;
    setDateRange(dateRange);
    setAccountNo(account);
    setLoading(true);
    let obj = {
      date_from: formattedStartDate,
      date_to: formattedEndDate,
      account_no: account,
    };
    console.log(obj);

    axios({
      method: "post",
      url: API.BASR_URL + "ledgerTransactions/stmt",
      headers: Headers(),
      data: obj,
    })
      .then(function (response) {
        setLoading(false);
        if ([200, 201].includes(response.status)) {
          message.success("Success");
          setFilteredDataSource(response.data);
        }
      })
      .catch(function (error) {
        setLoading(false);
        message.error("Oops.Something went wrong");
        console.log(error);
      });
  };
  const AccountsDropdown = () => {
    axios
      .get(API.BASR_URL + API.CHART_OF_ACCOUNTS_LIST + `/child_accounts`, {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) setAccounts(res.data);
      })
      .catch(function (error) {
        message.error("Customers dropdown faild");
      });
  };

  const columns = [
    {
      title: "#",
      key: "index",
      width: "20px",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Date",
      dataIndex: "trans_date",
      key: "trans_date",
      render: (text, record, index) => (
        <span>{moment(record.trans_date).format("DD-MM-YYYY")}</span>
      ),
    },
    {
      title: "Reference No",
      dataIndex: "reference_no",
      key: "reference_no",
    },

    {
      title: "Description",
      dataIndex: "note",
      key: "note",
    },
    {
      title: "Transaction Type",
      dataIndex: "trans_type",
      key: "trans_type",
    },
    {
      title: "Amount (Dr)",
      dataIndex: "amount_dr",
      key: "amount_dr",
      render: (text, record) =>
        record.dr_or_cr === "Dr"
          ? Math.abs(Number(record.amount)).toFixed(2)
          : 0,
    },
    {
      title: "Amount (Cr)",
      dataIndex: "amount_cr",
      key: "amount_cr",
      render: (text, record) =>
        record.dr_or_cr === "Cr"
          ? Math.abs(Number(record.amount)).toFixed(2)
          : 0,
    },
    {
      title: "Balance",
      key: "balance",
      render: (text, record, index) => {
        const previousRecords = filteredDataSource.slice(0, index + 1);
        const balance = previousRecords.reduce((total, r) => {
          if (r.dr_or_cr === "Cr") {
            return total + r.amount;
          } else {
            return total - r.amount;
          }
        }, 0);
        const Dr_or_Cr = balance <= 0 ? "Dr" : "Cr";
        const formattedNumber = `${Math.abs(Number(balance)).toFixed(
          2
        )} ${Dr_or_Cr}`;
        return formattedNumber;
      },
    },
  ];

  const renderSummary = () => {
    let balance = 0;
    const totalDrAmount = filteredDataSource.reduce((acc, item) => {
      if (item.dr_or_cr === "Dr") {
        return acc + item.amount;
      }
      return acc;
    }, 0);
    const totalCrAmount = filteredDataSource.reduce((acc, item) => {
      if (item.dr_or_cr === "Cr") {
        return acc + item.amount;
      }
      return acc;
    }, 0);
    balance = (Number(totalDrAmount) - Number(totalCrAmount)).toFixed(2);
    let Dr_or_Cr = balance >= 0 ? "Dr" : "Cr";
    // const totalTaxAmount = data.reduce((acc, item) => acc + item.taxAmount, 0);
    // const totalDiscount = data.reduce((acc, item) => acc + item.discount, 0);
    return (
      <>
        <Table.Summary.Row className="my-summary-row">
          <Table.Summary.Cell className="cell"></Table.Summary.Cell>
          <Table.Summary.Cell className="cell"></Table.Summary.Cell>
          <Table.Summary.Cell className="cell"></Table.Summary.Cell>
          <Table.Summary.Cell className="cell">
            {" "}
            <b>Total</b>
          </Table.Summary.Cell>

          <Table.Summary.Cell className="cell">
            {" "}
            <b style={{ paddingLeft: "9px" }}>
              {Math.abs(Number(totalDrAmount)).toFixed(2)}
            </b>
          </Table.Summary.Cell>
          <Table.Summary.Cell className="cell">
            {" "}
            <b style={{ paddingLeft: "9px" }}>
              {Math.abs(Number(totalCrAmount)).toFixed(2)}
            </b>
          </Table.Summary.Cell>
          <Table.Summary.Cell className="cell">
            {" "}
            <b style={{ paddingLeft: "9px" }}>
              {Math.abs(Number(balance)).toFixed(2)} {Dr_or_Cr}
            </b>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  };
  const handlePrint = (record) => {
    // Handle printing logic here
    // You can use a library like react-to-print to facilitate the printing process
    // Example: https://www.npmjs.com/package/react-to-print
    componentRef.current.handlePrint();
  };
  return (
    <div>
      <Form
        form={form}
        layout="inline"
        style={{
          marginBottom: 16,
          display: "flex",
          justifyContent: "center",
          border: "1px solid #ccc",
          padding: "16px",
        }}
      >
        <Form.Item name="dateRange">
          <RangePicker size="small" style={{ width: 240 }} />
        </Form.Item>
        <Form.Item name="account_no">
          <Select
            size="small"
            placeholder="Select account"
            showSearch
            allowClear
            style={{ borderRadius: "0", width: "300px" }}
            filterOption={(input, option) => {
              const accountNo = option.value.toLowerCase();
              const accountName = option.children.toString().toLowerCase();
              return (
                accountNo.includes(input.toLowerCase()) ||
                accountName.includes(input.toLowerCase())
              );
            }}
          >
            {accounts.map((item, index) => (
              <Select.Option key={item.account_no} value={item.account_no}>
                {item.account_no} - {item.account_name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button
            size="small"
            type="primary"
            onClick={handleSearch}
            loading={loading}
          >
            Search
          </Button>
        </Form.Item>
        <Form.Item>
          <ReactToPrint
            trigger={() => (
              <Button
                size="small"
                type="primary"
                icon={<PrinterOutlined />}
                style={{ marginLeft: 8 }}
              >
                Print Statement
              </Button>
            )}
            content={() => componentRef.current}
          />
        </Form.Item>
      </Form>
      <div ref={componentRef} style={{ margin: "20px 20px" }}>
        <div style={{ marginBottom: "10px" }}>
          <b>Date Range:</b> {moment(dateRange[0]).format("DD-MM-YYYY")} to{" "}
          {moment(dateRange[1]).format("DD-MM-YYYY")}
        </div>
        <div style={{ marginBottom: "10px" }}>
          <b>Account:</b>{" "}
          {
            accounts.find((item) => item.account_no === account_no)
              ?.account_name
          }
        </div>

        <Table
          size="small"
          columns={columns}
          dataSource={filteredDataSource}
          pagination={false}
          bordered
          summary={renderSummary}
        />
      </div>
    </div>
  );
};

export default StatementPage;

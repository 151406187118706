import React, { useEffect, useState } from "react";
import { Button, Modal, message } from "antd";
import axios from "axios";
import moment from "moment";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { VscFilePdf } from "react-icons/vsc";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { API, Headers } from "../../../Config";
import DeliveryListgif from "../../.././Assets/Images/deliverylist.gif";
import styles from "./style";

function PdfTaxInvoice(props) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadBoxesViaAwb();
  }, []);

  const loadBoxesViaAwb = () => {
    const AWBID = props.selected && props.selected.id;
    let URL = API.BASR_URL + API.BOXES_VIA_AWB + AWBID;
    axios
      .get(URL, {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setData(data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          message.error("Oops.Something went");
        }
      });
  };

  const PdfTaxInvoices = () => {
    return (
      <Document>
        {data.map((item, index) => {
          let Charge = (
            Number(item.chargable_weight) * Number(props.formdata.rate)
          ).toFixed(2);
          let GST = (Number(Charge) * 0.18).toFixed(2);
          let TotalCharge = (Number(Charge) + Number(GST)).toFixed(2);
          let RoundOff = (Number(TotalCharge) % 1).toFixed(2);
          let TOTAL = TotalCharge - RoundOff;

          return (
            <Page size="A4" wrap orientation="landscape">
              <View style={styles.Page}>
                <View style={styles.Heading}>
                  <Text style={styles.Tx1}>Tax Invoice</Text>
                </View>
                <View style={styles.Heading2}>
                  <Text style={styles.Txt2}>
                    SAHARI INTERNATIONAL COURIER SERVICE
                  </Text>
                  <Text style={styles.Txt2}>
                    16/356 A,VALAMANGALAM,PULPATTA PO,
                  </Text>
                  <Text style={styles.Txt2}>MALAPPURAM ,KERALA,INDIA</Text>
                  <Text style={styles.Txt2}>GSTIN: 32CCKPM5380H1ZH</Text>
                </View>
                <View style={{ margin: 20 }}>{` `}</View>
                <View style={styles.Row}>
                  <View style={styles.Col}>
                    <View>
                      <Text style={styles.Txt3}>BUYER ADDRESS</Text>
                      <Text style={styles.Txt4}>
                        {item.adhar_name && item.adhar_name}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.Col}></View>
                  <View style={styles.Col}></View>
                  <View style={styles.Col}>
                    <View>
                      <Text>
                        <Text style={styles.Txt3}> DATE:</Text>
                        <Text style={styles.Txt5}>
                          {moment(props.formdata.date).format("l")}
                        </Text>
                      </Text>
                      <Text>
                        <Text style={styles.Txt3}>INVOCE NO </Text>
                        <Text style={styles.Txt5}>
                          {" "}
                          {item.box_number && item.box_number}
                        </Text>
                      </Text>
                      <Text>
                        <Text style={styles.Txt3}>REF NO :</Text>
                        <Text style={styles.Txt5}>
                          {item.box_ref_no && item.box_ref_no}
                        </Text>
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={styles.Row}>
                  <View>
                    <Text style={styles.Txt5}>
                      {item.adhar_address && item.adhar_address}
                    </Text>
                    <Text style={styles.Txt5}>
                      {item.service && item.service.name}
                    </Text>
                    <Text style={styles.Txt5}>101</Text>
                    <Text style={styles.Txt5}>{item.pin && item.pin}</Text>
                    <Text style={styles.Txt5}>
                      {item.reciver_mobile1 && item.reciver_mobile1}
                    </Text>
                  </View>
                </View>
                <View style={{ margin: 10 }}>{` `}</View>
                <View style={styles.Table}>
                  <View style={styles.Row}>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}>ITEMS</Text>
                    </View>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}>PCS</Text>
                    </View>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}>KILO</Text>
                    </View>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}>RATE/KILO</Text>
                    </View>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}>CHARGE</Text>
                    </View>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}>GST18</Text>
                    </View>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}> KFC 0%</Text>
                    </View>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}> TOTAL</Text>
                    </View>
                  </View>
                  <View style={{ margin: 5 }}>{` `}</View>
                  <View style={styles.Row}>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}> COURIER BOX</Text>
                    </View>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}> 1</Text>
                    </View>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}> --</Text>
                    </View>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}> --</Text>
                    </View>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}> --</Text>
                    </View>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}> --</Text>
                    </View>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}> --</Text>
                    </View>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}> 0</Text>
                    </View>
                  </View>
                  <View
                    style={{
                      borderBottom: 1,
                      borderBottomColor: "#000",
                      margin: 5,
                    }}
                  ></View>
                  <View style={styles.Row}>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}> BOX</Text>
                    </View>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}> 1</Text>
                    </View>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}>
                        {item.chargable_weight && item.chargable_weight}
                      </Text>
                    </View>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}>
                        {props.formdata.rate && props.formdata.rate}
                      </Text>
                    </View>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}>
                        {/* {item.chargable_weight * item.rate} */}
                        {Charge}
                      </Text>
                    </View>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}>
                        {GST}
                        {/* {item.chargable_weight * item.rate * 0.18} */}
                      </Text>
                    </View>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}> 0.00</Text>
                    </View>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}> {TotalCharge}</Text>
                    </View>
                  </View>
                  <View
                    style={{
                      borderBottom: 1,
                      borderBottomColor: "#000",
                      margin: 5,
                    }}
                  ></View>
                  <View style={styles.Row}>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}> ROUND OFF</Text>
                    </View>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}> </Text>
                    </View>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}> </Text>
                    </View>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}> </Text>
                    </View>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}> </Text>
                    </View>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}> </Text>
                    </View>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}> </Text>
                    </View>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}> {RoundOff}</Text>
                    </View>
                  </View>
                  <View
                    style={{
                      borderBottom: 1,
                      borderBottomColor: "#000",
                      margin: 5,
                    }}
                  ></View>

                  <View style={styles.Row}>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}> TOTAL</Text>
                    </View>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}> </Text>
                    </View>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}> </Text>
                    </View>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}> </Text>
                    </View>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}> </Text>
                    </View>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}> </Text>
                    </View>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}> </Text>
                    </View>
                    <View style={styles.Col}>
                      <Text style={styles.Txt5}> {TOTAL}</Text>
                    </View>
                  </View>
                </View>
                <View style={{ margin: 10 }}></View>
                <View style={styles.Row}>
                  <View style={styles.Col}></View>
                  <View style={styles.Col2}>
                    <Text style={styles.Txt3}> SIGNATURE AND SEAL</Text>
                  </View>
                </View>
              </View>
            </Page>
          );
        })}
      </Document>
    );
  };

  return (
    <div>
      <Modal
        visible={props.visible}
        onCancel={props.onCancel}
        width={300}
        footer={false}
      >
        {isLoading ? (
          <div className="print-manifest-box1">
            <img src={DeliveryListgif} className="print-document-loader" />
            <div>Downloading Details....</div>
          </div>
        ) : data && data.length === 0 ? (
          <div className="print-manifest-box1">
            <br />
            <AiOutlineCloseCircle size={50} color={"red"} />
            <div style={{ margin: 20 }} />
            <div>Sorry No document Find.</div>
            <div style={{ margin: 30 }} />
            <Button type="primary" onClick={props.onClose}>
              Close
            </Button>
          </div>
        ) : (
          <>
            <div className="print-manifest-box1">
              <br />
              <VscFilePdf size={50} color="#1e88e5" />
              <div style={{ margin: 20 }} />
              <div> Pdf is Ready For download</div>
              <div style={{ margin: 20 }} />
              <PDFDownloadLink
                document={<PdfTaxInvoices />}
                fileName="taxInvoice.pdf"
              >
                {({ blob, url, loading, error }) =>
                  loading ? null : <Button>Download</Button>
                }
              </PDFDownloadLink>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
}

export default PdfTaxInvoice;

import { Popover, Menu, Dropdown } from "antd";
import { useHistory } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import { BsPersonCircle } from "react-icons/bs";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import ProfilePopup from "./ProfilePopup";
import { MdVerifiedUser } from "react-icons/md";
import { AiOutlineMessage } from "react-icons/ai";
import Logo from "../Assets/Images/logoWbg2.png";

import React, { useEffect, useState } from "react";
import axios from "axios";
import { API, Headers } from "../Config";

function Header(props) {
  let history = useHistory();
  const navigate = () => {
    history.push("/auth/user-verification");
    console.log(props);
  };

  const [length, setLength] = useState(0);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    axios
      .get(API.BASR_URL + API.USERS_LIST + `/false/list`, {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setLength(data.length);
        }
      });
  };

  return (
    <div className="navbar-container">
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onMouseEnter={props.open}
        ></IconButton>
        <div onMouseEnter={props.open}>
          <img src={Logo} className="route-side-bar-logo" alt="sahari" />
        </div>
      </Toolbar>
      <div className="navbar-container-box">
        <div className="navbar-container-mail-icon">
          <Dropdown
            trigger="click"
            overlay={
              <Menu>
                <Menu.ItemGroup
                  title={
                    <div className="navbar-container-mail-txt1">Actions</div>
                  }
                >
                  <Menu.Item
                    icon={<MdVerifiedUser />}
                    onClick={() => navigate()}
                  >
                    <div className="navbar-container-mail-txt2">
                      {" "}
                      Verify Users
                    </div>
                  </Menu.Item>
                </Menu.ItemGroup>

                <Menu.ItemGroup
                  title={
                    <div className="navbar-container-mail-txt1">
                      Notifications
                    </div>
                  }
                >
                  <Menu.Item icon={<AiOutlineMessage />}>
                    <div className="navbar-container-mail-txt2">
                      Notifications
                    </div>
                  </Menu.Item>
                </Menu.ItemGroup>
              </Menu>
            }
          >
            <Badge badgeContent={length} color="error">
              <MailIcon color="info" style={{ color: "#fff" }} />
            </Badge>
          </Dropdown>
        </div>
        <Popover
          placement="bottomLeft"
          content={
            <div style={{ width: 300, minHeight: 300 }}>
              <ProfilePopup />
            </div>
          }
        >
          <div className="navbar-container-icon">
            <BsPersonCircle size={35} color={"#fff"} />
          </div>
        </Popover>
      </div>
    </div>
  );
}
export default Header;

import React, { useState } from "react";
import { IoPersonCircle } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Form, Input, message, Spin } from "antd";
import { withRouter } from "react-router-dom";
import { logout } from "../Redux/Slices/AuthSlice";
import API from "../Config/API";
import axios from "axios";
import { Headers } from "../Config";
import { Col, Row } from "react-bootstrap";
import { LoadingOutlined } from "@ant-design/icons";

function ProfilePopup(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const Auth = useSelector((state) => state.Auth.user);
  const [isLoading, setIsLoading] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
    form.resetFields();
  };

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      let obj = {
        id: parseInt(Auth?.id),
        password: values?.oldpassword,
        new_password: values?.cpassword,
      };
      const url = API.BASR_URL + API.UPDATEPASSWORD;
      const response = await axios.put(url, obj, { headers: Headers() });
      if (response.data.status) {
        message.success("Password updated successfully");
        form.resetFields();
        setIsLoading(false);
        handleCancel();
      } else {
        message.error(`Failed to update password(${response.data.message})`);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      message.error("Failed to update password");
    }
  };

  const content = (
    <Form
      form={form}
      name="update-password"
      labelCol={{ flex: "110px" }}
      labelAlign="left"
      labelWrap
      wrapperCol={{ flex: 1 }}
      colon={false}
      style={{ maxWidth: 600 }}
      onFinish={handleSubmit}
    >
      <span>
        Current Password <span style={{ color: "red" }}>*</span>
      </span>
      <Form.Item
        name="oldpassword"
        rules={[{ required: true, message: "Please enter your old password" }]}
      >
        <Input.Password
          size="large"
          onPaste={(e) => {
            e.preventDefault();
            return false;
          }}
          onCopy={(e) => {
            e.preventDefault();
            return false;
          }}
          placeholder="Current Password"
        />
      </Form.Item>

      <span>
        New password <span style={{ color: "red" }}>*</span>
      </span>

      <Form.Item
        name="password"
        rules={[{ required: true, message: "Please enter your new password" }]}
      >
        <Input.Password
          size="large"
          onPaste={(e) => {
            e.preventDefault();
            return false;
          }}
          onCopy={(e) => {
            e.preventDefault();
            return false;
          }}
          placeholder="New Password"
        />
      </Form.Item>

      <span>
        Confirm new password <span style={{ color: "red" }}>*</span>
      </span>

      <Form.Item
        name="cpassword"
        rules={[
          { required: true, message: "Please confirm your new password" },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error("The two passwords do not match!")
              );
            },
          }),
        ]}
      >
        <Input.Password
          size="large"
          onPaste={(e) => {
            e.preventDefault();
            return false;
          }}
          onCopy={(e) => {
            e.preventDefault();
            return false;
          }}
          placeholder="Confirm Password"
        />
      </Form.Item>
      <br />
      <Row>
        <Col md="6">
          <Button size="large" onClick={handleCancel} style={{ width: "100%" }}>
            Cancel
          </Button>
        </Col>

        <Col md="6">
          <Form.Item>
            <Button
              size="large"
              style={{ width: "100%" }}
              type="primary"
              htmlType="submit"
            >
              {isLoading ? (
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 24, color: "#fff" }}
                      spin
                    />
                  }
                />
              ) : (
                "Submit"
              )}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );

  const logouts = () => {
    dispatch(logout());
    props.history.push("/");
  };

  return (
    <div className="route-header-popup">
      <IoPersonCircle style={{ fontSize: 90, color: "#e3e3e3" }} />
      <br />
      <h5 className="route-header-popup-txt1">{Auth.user_name}</h5>
      <h6 className="route-header-popup-txt2">{Auth.email}</h6>
      <h6 className="route-header-popup-txt3">
        {Auth.branch && Auth.branch.name}
      </h6>
      <div className="route-header-popup-btnbox">
        <Button block onClick={showModal}>
          Update Password
        </Button>
        {/* Modal component */}
        <Modal
          title="Update Password"
          visible={modalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          {content}
        </Modal>
      </div>
      <Button
        size="large"
        style={{ width: "100%" }}
        type="primary"
        onClick={logouts}
      >
        Logout
      </Button>
    </div>
  );
}

export default withRouter(ProfilePopup);

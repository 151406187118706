import React, { useState } from "react";
import { Button, Popconfirm, Table, Pagination, message } from "antd";
import { BsFillPrinterFill } from "react-icons/bs";
import { MdOutlineDelete } from "react-icons/md";
import { AiOutlinePrinter } from "react-icons/ai";
import axios from "axios";
import moment from "moment";
import { API, Headers } from "../../Config";
import PdfRefNumModal from "./PdfRefNumModal";

function DataTable(props) {
  const [list, setList] = useState([]);
  const [download, setDownload] = useState(false);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => (
        <div className="data-table-items">{index + 1}</div>
      ),
    },
    {
      title: "Created On",
      dataIndex: "createdat",
      key: "createdat",
      render: (text) => (
        <div className="data-table-items">{moment(text).format("ll")}</div>
      ),
    },
    {
      title: "User",
      dataIndex: "users",
      key: "users",
      render: (text, record, index) => (
        <div className="data-table-items">
          {record.users.name && record.users.name}
        </div>
      ),
    },

    {
      title: "Total",
      dataIndex: "numbers",
      key: "numbers",
      render: (text) => <div className="data-table-items">{text?.length}</div>,
    },

    {
      title: <div>Actions </div>,
      dataIndex: "Actions",
      key: "Actions",
      width: 150,
      render: (text, record) => (
        <div className="data-table-items">
          {record?.is_print ? null : (
            <div>
              <Button
                onClick={() => props.print(record)}
                size="small"
                type="primary"
                icon={<BsFillPrinterFill style={{ marginRight: 10 }} />}
              >
                Print
              </Button>
              <Popconfirm
                placement="bottomRight"
                title={"Are you sure to delete?"}
                okText="Yes"
                cancelText="No"
                onConfirm={() => props.delete(record)}
              >
                <Button size="small" type="link">
                  <MdOutlineDelete size={25} />
                </Button>
              </Popconfirm>
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <div>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={props.data}
        size="small"
        pagination={false}
      />
      <br />
      <div align="right">
        <Pagination
          size="small"
          responsive
          defaultCurrent={props.meta && props.meta.page}
          total={props.meta && props.meta.itemCount}
          pageSize={props.meta && props.meta.take ? props.meta.take : 0}
          onChange={props.pageOnChange}
          pageSizeOptions={[10, 20, 50]}
        />
      </div>
      {download && list.length ? (
        <PdfRefNumModal
          visible={download}
          onCancel={() => setDownload(false)}
          selected={list}
        />
      ) : null}
    </div>
  );
}

export default DataTable;

import React, { useState, useEffect, useRef } from "react";
import { Modal, message, Button } from "antd";
import LoadingBox from "../../Components/LoadingBox";
import { Table, Row, Col, Container } from "react-bootstrap";
import { API, Headers } from "../../Config";
import axios from "axios";
import moment from "moment";
import "./style.css";
import ReactToPrint from "react-to-print";
import Barcode from "react-barcode";

function PrintBoxNumModal(props) {
  const componentRef = useRef();
  const [loading, setLoading] = useState(false);

  let requestData = {
    is_box_bill_number: true,
  };
  const isPrinted = () => {
    axios({
      method: "put",
      url:
        API.BASR_URL +
        API.BOXES_ACTIONS +
        "print/" +
        props.item.box_number +
        "/is_box_bill_number",
      headers: Headers(),
      data: requestData,
    })
      .then(function (response) {
        setLoading(false);
        if ([200, 201].includes(response.status)) {
          message.success("Success");
          props.onClose();
          props.reload();
        }
      })
      .catch(function (error) {
        setLoading(false);
        message.error("Oops.Something went wrong");
        console.log(error);
      });
  };

  return (
    <Modal
      visible={props.visible}
      footer={null}
      onCancel={() => props.onClose()}
      width={600}
    >
      {loading ? (
        <LoadingBox />
      ) : (
        <div>
          <div ref={componentRef} className="print-box-num-container">
            <table className="print-box-num-table">
              <tr>
                <td colSpan={5}>
                  <div style={{ textAlign: "center" }}>
                    <div className="print-box-num-txt1">
                      {props.item.service && props.item.service.name}
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={5}>
                  <Barcode
                    value={props.item.box_number}
                    height="140"
                    width="4"
                    fontSize="50"
                  />
                </td>
              </tr>
            </table>
          </div>
          <br />
          <br />
          <br />

          <Row>
            <Col sm="6"></Col>
            <Col sm="3">
              <Button onClick={() => props.onClose()} block>
                Cancel
              </Button>
            </Col>
            <Col sm="3">
              <ReactToPrint
                trigger={() => (
                  <Button
                    type="primary"
                    block
                    onClickCapture={() => {
                      isPrinted();
                    }}
                  >
                    Print
                  </Button>
                )}
                content={() => componentRef.current}
              />
            </Col>
          </Row>
        </div>
      )}
    </Modal>
  );
}
export default PrintBoxNumModal;

import React, { useState, useEffect } from "react";
import PageHeader from "../../Routes/PageHeader";
import FormModal from "./FormModal";
import DataTable from "./DataTable";
import DetailsMoal from "./DetailsMoal";
import axios from "axios";
import { message } from "antd";
import { API, Headers } from "../../Config";
import LoadingBox from "../../Components/LoadingBox";
function AirwayBillGenScreen() {
  const [loading, setLoading] = useState(true);
  const [indicator, setIndicator] = useState(false);
  const [details, setdetails] = useState(false);
  const [form, setform] = useState(false);
  const [mode, setMode] = useState("post");
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({});
  const [item, setItem] = useState({});

  const [bills, setbills] = useState([]);
  const [ports, setports] = useState([]);
  const [airline, setairline] = useState([]);
  const [awbsuppliers, setawbsuppliers] = useState([]);
  const [agents, setagents] = useState([]);
  const [exportbroker, setexportbroker] = useState([]);
  const [clearingagents, setclearingagents] = useState([]);
  const [deliveryagents, setdeliveryagents] = useState([]);

  useEffect(() => {
    loaddata(page, take);
    airbwaybillsDropdown();
    portsDropdown();
    airlineDropdown();
    AwbsuplliersDropdown();
    awbAgentsDropdown();
    ExportbrokerDropdown();
    ClearingAgentDropdown();
    DeliveryAgentDropdown();
  }, []);

  const loaddata = (p, t) => {
    axios
      .get(
        API.BASR_URL + API.AIRWAY_BILLS_GEN + `?order=ASC&page=${p}&take=${t}`,
        {
          headers: Headers(),
        }
      )
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setData(data.data);
          setMeta(data.meta);
          message.success("Success");
        } else {
          message.error("Oops.Something went wrong");
        }
        setLoading(false);
        setIndicator(false);
      })
      .catch(function (error) {
        message.error("Oops.Something went wrong");
        setIndicator(false);
        setLoading(false);
      });
  };

  const airbwaybillsDropdown = () => {
    axios
      .get(API.BASR_URL + API.AIRWAY_BILLS + `/list`, {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) setbills(res.data);
      })
      .catch(function (error) {
        message.error("Airway bills dropdown faild");
      });
  };

  const portsDropdown = () => {
    axios
      .get(API.BASR_URL + API.PORTS_LIST + `/list`, {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) setports(res.data);
      })
      .catch(function (error) {
        message.error("porst dropdown faild");
      });
  };

  const airlineDropdown = () => {
    axios
      .get(API.BASR_URL + API.AIRLINE_LIST + `/list`, {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) setairline(res.data);
      })
      .catch(function (error) {
        message.error("airline dropdown faild");
      });
  };

  const AwbsuplliersDropdown = () => {
    axios
      .get(API.BASR_URL + API.AWB_SUPPLIER_LIST + `/list`, {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) setawbsuppliers(res.data);
      })
      .catch(function (error) {
        message.error("awb suppliers dropdown faild");
      });
  };

  const awbAgentsDropdown = () => {
    axios
      .get(API.BASR_URL + API.AWB_XRAY_AGENT_LIST + `/list`, {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) setagents(res.data);
      })
      .catch(function (error) {
        message.error("agents dropdown faild");
      });
  };

  const ExportbrokerDropdown = () => {
    axios
      .get(API.BASR_URL + API.EXPORT_BROKER_LIST + `/list`, {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) setexportbroker(res.data);
      })
      .catch(function (error) {
        message.error("Export broker dropdown faild");
      });
  };

  const ClearingAgentDropdown = () => {
    axios
      .get(API.BASR_URL + API.CLEARING_AGENT_LIST + `/list`, {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) setclearingagents(res.data);
      })
      .catch(function (error) {
        message.error("Clearing agent dropdown faild");
      });
  };

  const DeliveryAgentDropdown = () => {
    axios
      .get(API.BASR_URL + API.DELIVERY_AGENT_LIST + `/list`, {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) setdeliveryagents(res.data);
      })
      .catch(function (error) {
        message.error("Clearing agent dropdown faild");
      });
  };

  const pagination = (page, take) => {
    window.scrollTo(0, 0);
    setIndicator(true);
    setPage(page);
    setTake(take);
    loaddata(page, take);
  };

  const relaod = () => {
    loaddata(page, take);
    setMode("post");
    setItem({});
    setform(false);
  };

  const AddItem = () => {
    setMode("post");
    setItem({});
    setform(true);
  };

  const UpdateItem = (item) => {
    setMode("put");
    setItem(item);
    setform(true);
  };

  const showDetails = (item) => {
    setItem(item);
    setdetails(!details);
  };

  const DeleteItem = (item) => {
    setIndicator(true);
    axios
      .delete(API.BASR_URL + API.AIRWAY_BILLS_GEN_ACTION + item.id, {
        headers: Headers(),
      })
      .then(function (response) {
        setIndicator(false);
        message.success("Deleted Successfully");
        relaod();
      })
      .catch(function (error) {
        setIndicator(false);
        message.error("Oops.Something went wrong");
        console.log(error);
      });
  };

  return (
    <div>
      <PageHeader
        PageName={"Airway Bills"}
        breadScrum={"Admin / Airway Bills"}
        RightButton={"Generate Airway Bill"}
        RightClcik={() => AddItem()}
        loader={indicator}
      />
      {loading ? (
        <LoadingBox />
      ) : (
        <div>
          <DataTable
            meta={meta}
            data={data}
            update={(item) => UpdateItem(item)}
            delete={(item) => DeleteItem(item)}
            details={(item) => showDetails(item)}
            PageOnchange={(page, take) => pagination(page, take)}
          />
          {form ? (
            <FormModal
              item={item}
              mode={mode}
              visible={form}
              bills={bills}
              ports={ports}
              airline={airline}
              agents={agents}
              a
              exportbroker={exportbroker}
              clearingagents={clearingagents}
              deliveryagents={deliveryagents}
              awbsuppliers={awbsuppliers}
              onchange={() => relaod()}
              onClose={() => setform(false)}
            />
          ) : null}
          {details ? (
            <DetailsMoal
              item={item}
              visible={details}
              onClose={() => showDetails({})}
            />
          ) : null}
        </div>
      )}
    </div>
  );
}
export default AirwayBillGenScreen;

import React, { useState } from "react";
import { Form, InputNumber, Input, Popconfirm } from "antd";
import styles from "./styles";

import { MdRemoveCircle } from "react-icons/md";
import { IoMdAddCircle } from "react-icons/io";
import { Table } from "react-bootstrap";
import axios from "axios";
import { API, Headers } from "../../Config";

const BoxItemsForm = (props) => {
  const [items, setItems] = useState([{ item: "", quantity: "", value: 0 }]);

  const addNewBoxItemAndNewRow = (add, e) => {
    e.preventDefault();
    add();
  };

  const DeleteBoxItem = (val, boxitems, remove, item, index2, fieldKey) => {
    // boxitems.length === 1 ? null :
    // remove(item.name);
    //  console.log("BoxItemID ========>", BoxItemID);
    let BoxItemID =
      props.items.boxes &&
      props.items.boxes[fieldKey] &&
      props.items.boxes[fieldKey].boxitems[index2] &&
      props.items.boxes[fieldKey].boxitems[index2].id;

    axios({
      method: "delete",
      url: API.BASR_URL + API.BOXE_ITEMS_ACTIONS + BoxItemID,
      headers: Headers(),
    })
      .then(function (response) {
        if (response.status === 200) {
          console.log(response);
          remove(item.name);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <Table
      bordered
      size="sm"
      className="formtable"
      style={{
        width: "100%",
        tableLayout: "fixed",
        display: "block",
        overflowX: "auto",
        whiteSpace: "nowrap",
        maxHeight: "150px",
      }}
    >
      <Form.List name={[props.fieldKey, "boxitems"]} initialValue={items}>
        {(boxitems, { add, remove }) => {
          return (
            <>
              <thead
                style={{
                  width: "100%",
                  display: "table",
                  tableLayout: "fixed",
                }}
              >
                <tr>
                  <td colSpan={1}>Sl.No</td>
                  <td colSpan={5}>Item</td>
                  <td colSpan={1}>Qty</td>
                  <td colSpan={1}>Value</td>
                  <td colSpan={1}>Brand</td>
                  <td colSpan={1}>
                    <div className="tableAction" onClick={() => add()}>
                      <IoMdAddCircle color="#00d73c" size={20} />
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody
                style={{
                  width: "100%",
                  display: "table",
                  tableLayout: "fixed",
                }}
              >
                {boxitems.map((item, index2) => (
                  <tr key={index2}>
                    <td colSpan={1}>{index2 + 1}</td>
                    <td colSpan={5}>
                      <Form.Item
                        {...item}
                        name={[item.name, "item"]}
                        fieldKey={[item.fieldKey, "item"]}
                        style={styles.FormItem}
                      >
                        <Input
                          placeholder=""
                          size="small"
                          style={styles.FormInput}
                          onPressEnter={(e) =>
                            boxitems && boxitems.length === index2 + 1
                              ? addNewBoxItemAndNewRow(add, e)
                              : null
                          }
                          autoFocus={
                            boxitems && boxitems.length === index2 + 1
                              ? true
                              : false
                          }
                        />
                      </Form.Item>
                    </td>
                    <td colSpan={1}>
                      <Form.Item
                        {...item}
                        name={[item.name, "quantity"]}
                        fieldKey={[item.fieldKey, "quantity"]}
                        style={styles.FormItem}
                      >
                        <InputNumber
                          size="small"
                          style={styles.FormInput}
                          onPressEnter={(e) =>
                            boxitems && boxitems.length === index2 + 1
                              ? addNewBoxItemAndNewRow(add, e)
                              : null
                          }
                        />
                      </Form.Item>
                    </td>
                    <td colSpan={1}>
                      <Form.Item
                        {...item}
                        name={[item.name, "value"]}
                        fieldKey={[item.fieldKey, "value"]}
                        style={styles.FormItem}
                      >
                        <InputNumber
                          size="small"
                          style={styles.FormInput}
                          onPressEnter={(e) =>
                            boxitems && boxitems.length === index2 + 1
                              ? addNewBoxItemAndNewRow(add, e)
                              : null
                          }
                        />
                      </Form.Item>
                    </td>
                    <td colSpan={1}>
                      <Form.Item
                        {...item}
                        name={[item.name, "brand"]}
                        fieldKey={[item.fieldKey, "brand"]}
                        style={styles.FormItem}
                      >
                        <Input
                          size="small"
                          style={styles.FormInput}
                          onPressEnter={(e) =>
                            boxitems && boxitems.length === index2 + 1
                              ? add()
                              : null
                          }
                        />
                      </Form.Item>
                    </td>
                    <td colSpan={1}>
                      <div className="tableAction">
                        {props.mode === "put" ? (
                          <Popconfirm
                            title="Delete the Box Item"
                            description="Are you sure to delete this Box?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={(val) =>
                              DeleteBoxItem(
                                val,
                                boxitems,
                                remove,
                                item,
                                index2,
                                props.fieldKey
                              )
                            }
                          >
                            <MdRemoveCircle size={20} color="#1e88e5" />
                          </Popconfirm>
                        ) : (
                          <MdRemoveCircle
                            size={20}
                            color="#1e88e5"
                            onClick={() =>
                              boxitems.length === 1 ? null : remove(item.name)
                            }
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </>
          );
        }}
      </Form.List>
    </Table>
  );
};

export default BoxItemsForm;

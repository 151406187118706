import React from "react";
import { Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import moment from "moment";
import logo from "../../Assets/Images/logoNew.jpg";
import Table from "./Components/Table";
import Companyseal from "../../Assets/Images/seal.JPG";
import TermsAndCondition from "./Components/termsAndCondition";

var QRCodes = require("qrcode");
var JsBarcode = require("jsbarcode");

const PdfCustomerInvoice = (props) => {
  return (
    <>
      {props.data.boxes &&
        props.data.boxes.map((item, index) => {
          let data = item.box_number && item.box_number;
          let canvas;
          canvas = document.createElement("canvas");
          QRCodes.toCanvas(canvas, data);
          const qrcode = canvas.toDataURL();

          canvas = document.createElement("canvas");
          JsBarcode(canvas, data, {
            width: 10,
            height: 200,
            fontSize: 100,
          });
          const barcode = canvas.toDataURL();
          return (
            <>
              {index === 0 && (
                <TermsAndCondition
                  data={item}
                  name={props?.data?.sender_name}
                  collectionDate={props?.data?.collected_datetime}
                />
              )}
              <Page size="A4" wrap style={styles.Page}>
                <View style={styles.Table}>
                  <View style={styles.Row}>
                    <View style={styles.Col}>
                      <View style={styles.LogoBox}>
                        <Image src={logo} style={styles.Logo} />
                      </View>
                    </View>
                    <View style={styles.Col4}>
                      <Text style={styles.Text1}>
                        HO:{" "}
                        {item.created_users && item.created_users.branch
                          ? `${item.created_users.branch?.name}`
                          : null}
                      </Text>
                      <Text style={styles.Text1}>
                        PH:{" "}
                        {item.created_users && item.created_users.branch
                          ? `${item.created_users.branch?.mobile}`
                          : null}
                      </Text>
                      <Text style={styles.Text1}> VAT NO: 300602378400003</Text>
                    </View>
                  </View>
                  <View style={styles.Row}>
                    <View style={styles.Col3}>
                      <Text style={styles.Text2}>CUSTOMER INVOICE</Text>
                    </View>
                  </View>
                  <View style={styles.Row}>
                    <View style={styles.Col}>
                      <View>
                        <Text style={styles.Text3}> SENDER INFO</Text>
                        <Text style={styles.Text4}>
                          {props.data && props.data.sender_name}
                        </Text>
                        <Text style={styles.Text4}>
                          {props.data && props.data.sender_address}
                        </Text>
                        <Text style={styles.Text4}>
                          {props.data && props.data.sender_phone}
                        </Text>
                      </View>
                      <View>
                        <Text style={styles.Text3}> RECIEVER INFO</Text>
                        <Text style={styles.Text4}>
                          {props.data && props.data.reciver_name}
                        </Text>
                        <Text style={styles.Text4}>
                          {props.data && props.data.reciver_address}
                        </Text>
                        <Text style={styles.Text4}>
                          {props.data && props.data.reciver_post}
                        </Text>
                        <Text style={styles.Text4}>
                          {props.data && props.data.reciver_phone},
                          {props.data && props.data.reciver_altphone}
                        </Text>
                        <Text style={styles.Text4}>
                          {props.data && props.data.reciver_pincode},{""}
                          {props.data && props.data.reciver_city}
                        </Text>
                        <Text style={styles.Text4}>
                          {props.data && props.data.reciver_distrcit},{""}
                          {props.data && props.data.reciver_state}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.Col}>
                      <View style={styles.QrBOX}>
                        <Image src={qrcode} style={styles.QR} />
                      </View>
                    </View>
                    <View style={styles.Col2}>
                      <Text style={styles.Text5}>
                        <Text style={styles.Text7}> INVOICE NO:</Text>
                        {"  "}
                        INV{moment().unix()}
                      </Text>
                      <Text style={styles.Text5}>
                        <Text style={styles.Text7}> INVOICE DATE:</Text>{" "}
                        {item && moment().format("DD-MM-YYYY")}
                      </Text>
                      <Text style={styles.Text5}>
                        <Text style={styles.Text7}> BOOKED STAFF: </Text>{" "}
                        {item.created_users && item.created_users.name}
                      </Text>
                      <Text style={styles.Text5}>
                        <Text style={styles.Text7}> BOOKED BRANCH: </Text>{" "}
                        {item.created_users &&
                          item.created_users.branch &&
                          item.created_users.branch.name}
                      </Text>
                      <Text style={styles.Text5}>
                        <Text style={styles.Text7}> BOOKED DATE: </Text>{" "}
                        {item &&
                          moment(props.data.createdat).format("DD-MM-YYYY ")}
                      </Text>
                      <Text style={styles.Text5}>
                        <Text style={styles.Text7}> REFRENCE: </Text>{" "}
                        {item.box_ref_no
                          ? item.box_ref_no
                          : item.referance_details
                          ? item.referance_details
                          : ""}
                      </Text>
                      <Text style={styles.Text5}>
                        <Text style={styles.Text7}> BOX SIZE: </Text>{" "}
                        {item.boxType && item.boxType.name}{" "}
                        {item &&
                          `${item.length} x ${item.breadth} x ${item.height}`}
                      </Text>
                      <Text style={styles.Text5}>
                        <Text style={styles.Text7}> CHARGABLE WEIGHT: </Text>{" "}
                        {item && item.chargable_weight}
                      </Text>
                      <Text style={styles.Text5}>
                        <Text style={styles.Text7}> RATE PER KILO: </Text>{" "}
                        {item && item.rate}
                      </Text>
                      <Text style={styles.Text5}>
                        <Text style={styles.Text7}> PACKING CHARGE: </Text>{" "}
                        {item && item.packing_charge}
                      </Text>
                      <Text style={styles.Text5}>
                        <Text style={styles.Text7}> DUTY: </Text>{" "}
                        {item && item.duty}
                      </Text>
                      <Text style={styles.Text5}>
                        <Text style={styles.Text7}> OTHER: </Text>{" "}
                        {item && item.other_charges}
                      </Text>
                      <Text style={styles.Text5}>
                        <Text style={styles.Text7}> AMOUNT: </Text>{" "}
                        {item && item.total_charge}
                      </Text>
                      <Text style={styles.Text5}>
                        <Text style={styles.Text7}> VAT: </Text> 0
                      </Text>
                      <Text style={styles.Text5}>
                        <Text style={styles.Text7}> TOTAL:</Text>{" "}
                        {item && item.total_charge}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.HR}></View>
                  <View style={styles.Row}>
                    <View style={styles.Col}>
                      <View>
                        <Image src={barcode} />
                      </View>
                    </View>
                    <View style={styles.Col}></View>
                    <View style={styles.Col}>
                      <View>
                        <Text style={styles.Text11}>
                          {item.service && item.service.name}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.Row}>
                    <View style={styles.Col3}>
                      <Text style={styles.Text8}>PACKING LIST</Text>
                    </View>
                  </View>
                  <View>
                    <Table item={item} init={0} length={16} />
                  </View>
                  <View>
                    <View>
                      <Text style={styles.Text9}>
                        1. {props.data && props.data.sender_name}for my
                        family/friends, personal use as gift.not for sale.
                        Shipper also declare that he is liable of any customs
                        duty/fine / penalites against this shipment at origin
                        and destination
                      </Text>
                    </View>
                    <View>
                      <Text style={styles.Text9}>
                        2. Shipper certifies that the particulars on the face
                        here of are correct and that insofar as any part of the
                        consignemt contains Dangerous Goods if any surch part is
                        property by name and is in proper condition for carriage
                        by air according to the Internation Transport
                        Association's applicable dangerous goods regulations and
                        civil Aviation Regulation of the United Arab Emirates.
                      </Text>
                    </View>
                    <View>
                      <Text style={styles.Text9}>
                        3. I/We agree that standard terms and conditions apply
                        for this shipment and liability to terms and conditions.
                        Warsaw Convention amy apply. I/We Service Liability
                        shall not exceed US$100 for any shipment.
                      </Text>
                    </View>
                    <View>
                      <Text style={styles.Text9}>
                        4. /The Shipper also here by authorize forward the
                        shipment in full or part their convenience
                      </Text>
                    </View>
                    <View>
                      <Text style={styles.Text9}>
                        5. The Shipper also here by authorize and their
                        Counterpart India to clear the parcel on behalf of me
                        and same should be delivered to the Consignee address.
                      </Text>
                    </View>
                    <View style={{ marginBottom: "5px" }}>x</View>
                    <View style={styles.Row}>
                      <View style={styles.Col}>
                        <Text style={styles.Text10}>Sender Signature</Text>
                      </View>
                      <View style={styles.Col}>
                        <Text style={styles.Text10}>
                          For Sahari International courier
                        </Text>
                      </View>
                    </View>
                    <View style={styles.Row}>
                      <View style={styles.Col}>
                        {props.data && props.data.signature ? (
                          <View style={{ width: "50%" }}>
                            <Image
                              source={{
                                uri: props.data && props.data.signature,
                                method: "GET",
                                headers: { "Cache-Control": "no-cache" },
                              }}
                              style={{ width: "50%" }}
                            />
                          </View>
                        ) : null}
                      </View>
                      <View style={styles.Col}>x</View>
                      <View style={styles.Col}>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "50%",
                          }}
                        >
                          <Image
                            src={Companyseal}
                            style={{ flex: 2, width: "50%" }}
                          />
                          <Image
                            source={{
                              uri: props.data.driver_signature,
                              method: "GET",
                              headers: { "Cache-Control": "no-cache" },
                            }}
                            style={{ flex: 1, width: "50%" }}
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </Page>
              {item.boxitems && item.boxitems.length > 16 ? (
                <Page size="A4" wrap style={styles.Page}>
                  <Table item={item} init={16} length={item.boxitems.length} />
                </Page>
              ) : null}
            </>
          );
        })}
    </>
  );
};

export default PdfCustomerInvoice;

const styles = StyleSheet.create({
  Page: {
    padding: 20,
  },
  Table: {
    display: "table",
    tableLayout: "fixed",
    width: "100%",
  },
  Row: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  Col: {
    flex: 1,
  },
  Col2: {
    flex: 1,
    justifyContent: "flex-end",
    alignContent: "flex-end",
    alignItems: "flex-end",
    alignSelf: "flex-end",
  },
  Col3: {
    flex: 1,
    textAlign: "center",
  },
  Col4: {
    flex: 1,
    textAlign: "right",
  },
  LogoBox: {
    width: "100%",
    height: "90px",
    objectFit: "contain",
  },
  Logo: {
    width: "190px",
    height: "50px",
  },
  Text1: {
    fontFamily: "Montserrat",
    fontSize: "10px",
    textTransform: "uppercase",
  },
  Text2: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  Text3: {
    fontFamily: "Montserrat",
    fontSize: "11px",
    textAlign: "left",
    marginBottom: 3,
    textTransform: "uppercase",
  },
  Text4: {
    fontFamily: "Montserrat",
    fontSize: "10px",
    fontWeight: 200,
    textAlign: "left",
    marginBottom: 2,
    textTransform: "uppercase",
  },
  Text5: {
    fontFamily: "Montserrat",
    fontSize: "10px",
    textTransform: "uppercase",
  },
  Text7: {
    fontFamily: "Montserrat",
    fontSize: "10px",
    fontWeight: 200,
    textTransform: "uppercase",
  },
  Text8: {
    fontFamily: "Montserrat",
    fontSize: "10px",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  Text9: {
    fontFamily: "Montserrat",
    fontSize: "6px",
    textTransform: "uppercase",
  },
  Text10: {
    fontFamily: "Montserrat",
    fontSize: "10px",
    textTransform: "uppercase",
  },
  Text11: {
    fontFamily: "Montserrat",
    fontSize: "40px",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  HR: {
    marginTop: "5px",
    borderBottom: "1px solid black",
    marginBottom: "5px",
  },
  QrBOX: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    alignSelf: "center",
    textAlign: "center",
  },
  QR: {
    width: "80px",
    height: "80px",
  },
});

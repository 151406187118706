import { Table, Pagination, Button, Popconfirm, Form, Space } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { DatePicker, Input, Row, Col } from "antd";
function DataTable(props) {
  console.log("props.columnsVisible.accounts", props.columnsVisible);
  const [boundFilter, setBoundFilter] = useState(null);
  const filteredData = boundFilter
    ? props.data.filter((record) => record.bound === boundFilter)
    : props.data;

  const columns = [
    {
      title: "#",
      key: "index",
      width: "20px",
      render: (text, record, index) =>
        (props.meta.page - 1) * props.meta.take + index + 1,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text, record, index) => (
        <span>{moment(record.date).format("DD-MM-YYYY")}</span>
      ),
    },
    {
      title: "Awb No",
      dataIndex: "awb_no",
      key: "awb_no",
      render: (text, record, index) => (
        <span>
          <b>{record?.awb?.awb_no}</b>
        </span>
      ),
    },
    {
      title: "Bound",
      dataIndex: "bound",
      key: "bound",
      filters: [
        { text: "Inbound", value: "inbound" },
        { text: "Outbound", value: "outbound" },
      ],
      filteredValue: boundFilter ? [boundFilter] : null,
      onFilter: (value, record) => record.bound === value,
      render: (text, record, index) => (
        <span>{record.bound === "inbound" ? "inbound" : "outbound"}</span>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => {
        return Number(text).toFixed(2);
      },
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      render: (text, record, index) => (
        <span>{record.user && record.user.name}</span>
      ),
    },
    {
      title: "Created",
      dataIndex: "createdat",
      key: "createdat",
      align: "center",
      render: (text, record, index) => (
        <span>
          <b>{record.created_user && record.created_user.name} </b>
          {moment(record.createdat).fromNow()}
        </span>
      ),
    },
    {
      title: "",
      render: (item) => (
        <div className="tableAction">
          <Popconfirm
            placement="bottomRight"
            title={"Are you sure to delete?"}
            okText="Yes"
            cancelText="No"
            onConfirm={() => props.delete(item)}
          >
            <Button size="small">Delete</Button>
          </Popconfirm>
          <Button
            size="small"
            onClick={() => props.update(item)}
            type="primary"
          >
            Edit
          </Button>
        </div>
      ),
    },
  ];
  const onSearch = (values) => {
    let obj = {};
    const dateRange = values.dateRange;
    const startDate = dateRange ? dateRange[0] : null;
    const endDate = dateRange ? dateRange[1] : null;

    const formattedStartDate = startDate
      ? startDate.startOf("day").toISOString()
      : null;
    const formattedEndDate = endDate
      ? endDate.endOf("day").toISOString()
      : null;

    console.log("Formatted Start Date:", formattedStartDate);
    console.log("Formatted End Date:", formattedEndDate);

    if (formattedStartDate !== null && formattedEndDate !== null) {
      obj.date_from = formattedStartDate;
      obj.date_to = formattedEndDate;
    }
    if (values.search) {
      obj.awb_no = values.search;
    }

    props.Search(obj);
  };
  return (
    <div style={{ minHeight: "60vh" }}>
      <Form onFinish={onSearch}>
        <Space align="center">
          <Form.Item label="Dates" name="dateRangePicker">
            <DatePicker.RangePicker style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item label="search" name="search">
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
          </Form.Item>
        </Space>
      </Form>

      <Table
        columns={columns.filter((column) => column)}
        dataSource={filteredData}
        pagination={false}
        size="small"
      />
      <br />
      <div align="right">
        <Pagination
          defaultCurrent={props.meta && props.meta.page}
          total={props.meta && props.meta.itemCount}
          pageSize={props.meta && props.meta.take ? props.meta.take : 0}
          onChange={props.PageOnchange}
          pageSizeOptions={[10, 20, 50]}
        />
      </div>
    </div>
  );
}

export default DataTable;

import moment from "moment";
import React from "react";

import { CSVDownload } from "react-csv";

function ExportReport(props) {
  var arr = [];

  props.data &&
    props.data.map((item, index) => {
      let obj = {
        "Created on": item.createdAt,
        "Expence id": item.id && item.id,
        "User Id": item.id && item.userid,
        "Expense Typ": item.id && item.head,
        Amount: item.amount,
      };
      arr.push(obj);
    });

  return (
    <div>
      <CSVDownload data={arr} />
    </div>
  );
}

export default ExportReport;

import { createSlice } from "@reduxjs/toolkit";
const CollectionSlice = createSlice({
  name: "Collection",
  initialState: {
    data: {},
  },
  reducers: {
    save: (state, action) => {
      state.data = action.payload;
    },
    clear: (state, action) => {
      state.data = {};
    },
  },
});

export default CollectionSlice;
export const { save, clear } = CollectionSlice.actions;

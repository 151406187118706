import React, { useState } from "react";
import { Drawer, Form, Button, Input, message, Select } from "antd";
import { Container, Row, Col } from "react-bootstrap";
import { API, Headers } from "../../Config";
import axios from "axios";
var width = window.innerWidth;
const { Option } = Select;
function FormModal(props) {
  const [loading, setLoading] = useState(false);
  const onFinish = (values) => {
    setLoading(true);
    let requestObj = {
      pincode: Number(values.pincode),
      office: values.office,
      district: values.district,
      state: values.state,
      country_id: Number(values.country_id),
      active: true,
    };

    let URL =
      props.mode === "post"
        ? API.PINCODE_ACTIONS
        : API.PINCODE_ACTIONS + props.item.id;
    axios({
      method: props.mode,
      url: API.BASR_URL + URL,
      headers: Headers(),
      data: requestObj,
    })
      .then(function (response) {
        setLoading(false);
        if ([200, 201].includes(response.status)) {
          message.success("Success");
          props.onchange();
        }
      })
      .catch(function (error) {
        setLoading(false);
        message.error("Oops.Something went wrong");
        console.log(error);
      });
  };
  return (
    <div>
      <Drawer
        placement={props.mode === "post" ? "right" : "left"}
        title={
          <span className="txt4">
            {props.mode === "post" ? "New" : "Update"} Pincode
          </span>
        }
        width={width / 2.7}
        onClose={() => props.onClose()}
        visible={props.visible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form
          layout="vertical"
          hideRequiredMark
          onFinish={onFinish}
          initialValues={{
            pincode: props.item && props.item.pincode,
            office: props.item && props.item.office,
            district: props.item && props.item.district,
            state: props.item && props.item.state,
            country_id: props.item && props.item.country_id,
          }}
        >
          <Container>
            <Row>
              <Col sm="6" xs="12">
                <Form.Item
                  name="pincode"
                  label={<div className="form-item-label">Pincode</div>}
                  rules={[{ required: true, message: "required" }]}
                >
                  <Input placeholder="" />
                </Form.Item>
              </Col>
              <Col sm="6" xs="12">
                <Form.Item
                  name="office"
                  label={<div className="form-item-label">Office</div>}
                  rules={[{ required: true, message: "required" }]}
                >
                  <Input placeholder="" />
                </Form.Item>
              </Col>
              <Col sm="6" xs="12">
                <Form.Item
                  name="district"
                  label={<div className="form-item-label">District</div>}
                  rules={[{ required: true, message: "required" }]}
                >
                  <Input placeholder="" />
                </Form.Item>
              </Col>
              <Col sm="6" xs="12">
                <Form.Item
                  name="state"
                  label={<div className="form-item-label">State</div>}
                  rules={[{ required: true, message: "required" }]}
                >
                  <Input placeholder="" />
                </Form.Item>
              </Col>
              <Col sm="6" xs="12">
                <Form.Item
                  name="country_id"
                  label={<div className="form-item-label">Country</div>}
                  rules={[{ required: true, message: "required" }]}
                >
                  <Select
                    placeholder="Select Country"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLocaleLowerCase()) >= 0
                    }
                  >
                    {props.countries.map((option) => (
                      <Option key={option.code}>{option.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Container>
          <Row>
            <Col sm="6" xs="12"></Col>
            <Col sm="3" xs="12">
              <Button block onClick={() => props.onClose()}>
                Cancel
              </Button>
            </Col>
            <Col sm="3" xs="12">
              <Button block type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </div>
  );
}

export default FormModal;

import React, { useRef } from "react";
import { Table } from "react-bootstrap";
import { Button, Popover, Empty } from "antd";
import ReactToPrint from "react-to-print";
import { AiOutlineFileExcel } from "react-icons/ai";
import moment from "moment";
import ExportReport from "./ExportReport";

function ReportTable(props) {
  const ref = useRef();
  return (
    <div>
      <div ref={ref} className="p-2">
        <Table className="formTable" bordered>
          <thead style={{ backgroundColor: "#e2e2e2" }}>
            <tr>
              <td colSpan={6}>
                <div className="Report-table-txt1">
                  Report{" "}
                  {`from ${moment(props.obj.date_from).format("l")} to ${moment(
                    props.obj.date_to
                  ).format("l")}`}
                </div>
              </td>
              <td colSpan={3}>
                <div className="Reports-Datatable-box1">
                  <ReactToPrint
                    trigger={() => <Button type="primary">Print</Button>}
                    content={() => ref.current}
                  />
                  <Popover
                    trigger="click"
                    placement="bottom"
                    content={
                      <div>
                        <ExportReport data={props.data} />
                      </div>
                    }
                  >
                    <Button type="link">
                      <AiOutlineFileExcel size={25} color="#fff" />
                    </Button>
                  </Popover>
                </div>
              </td>
            </tr>
          </thead>
          <tbody className="Reports-Datatable-items">
            <tr>
              <th>Created on</th>
              <th>AWB Num</th>
              <th>Owner</th>
              <th>Pcs K/L</th>
              <th>Origin</th>
              <th>Destination</th>
              <th>Income</th>
              <th>Expense</th>
              <th>Profit</th>
            </tr>
            {props.data && props.data.length ? (
              props.data.map((item, index) => {
                console.log(item);
                let TotalIncome = 0;
                let TotalExpense = 0;
                let TotalProfit = 0;

                TotalIncome =
                  item.boxes &&
                  item.boxes.reduce(function (tot, arr) {
                    return Number(tot) + Number(arr.total_charge);
                  }, 0);
                console.log("TotalIncome===>", TotalIncome);

                TotalExpense =
                  Number(item.awb_supplier_rate && item.awb_supplier_rate) +
                  Number(
                    item.forwarding_agent_rate && item.forwarding_agent_rate
                  ) +
                  Number(item.export_broker_rate && item.export_broker_rate) +
                  Number(
                    item.clearing_agent_destination_rate &&
                      item.clearing_agent_destination_rate
                  ) +
                  Number(
                    item.delivery_agent_destination_rate &&
                      item.delivery_agent_destination_rate
                  );

                TotalProfit = (TotalIncome - TotalExpense).toFixed(2);

                console.log("TotalExpense ====>", TotalExpense);

                return (
                  <tr key={index}>
                    <td>{moment(item.createdAt).format("l")}</td>
                    <td>{`${item.airline} - ${item.awb_no}`}</td>
                    <td>
                      <div className="Reports-Datatable-col">
                        <div className="Reports-Datatable-txt1">
                          {item.user.name && item.user.name}
                        </div>
                      </div>
                    </td>
                    <td>{`${item.psc} / ${item.chargable_weight}`}</td>

                    <td>
                      <div className="Reports-Datatable-txt1">
                        {`${item.orijin_airport_name}`}
                      </div>
                    </td>
                    <td>
                      <div className="Reports-Datatable-txt1">
                        {item.destination_airport_name}
                      </div>
                    </td>
                    <td>
                      {TotalIncome === 0 ? "N/A" : TotalIncome.toFixed(2)}
                    </td>
                    <td>
                      {TotalExpense === 0 ? "N/A" : TotalExpense.toFixed(2)}
                    </td>
                    <td>{TotalProfit === 0 ? "N/A" : TotalProfit}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={9}>
                  <Empty />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default ReportTable;

import React from "react";
import "./styles.css";
import { Collapse, Table } from "antd";

function CustomTimeline({ items }) {
	const columns = [
		{
			title: "City",
			dataIndex: "city",
		},
		{
			title: "Status",
			dataIndex: "status",
		},
	];
	const data = [
		{
			key: "1",
			city: "14 Oct 2023, Morning",
			status: "Hojai	Our executive is out for delivery",
		},
	];
	return (
		<div className="custom-timeline">
			{items.map((item, index) => (
				<div className="custom-timeline-item" key={index}>
					<div className="custom-timeline-marker" />
					<div className="custom-timeline-content">{item.children}</div>
					<br />
					<Collapse>
						<Collapse.Panel header="1 update available" key="1">
							<Table columns={columns} dataSource={data} bordered={false} />{" "}
						</Collapse.Panel>
					</Collapse>
				</div>
			))}
		</div>
	);
}

export default CustomTimeline;

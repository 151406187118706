import React from "react";
import { Container } from "react-bootstrap";
import { List } from "antd";
import { BsPlusLg } from "react-icons/bs";
import PageHeader from "../Routes/PageHeader";
import ALT from "../Assets/Images/altkey.png";
import K from "../Assets/Images/Kkey.png";
import plusKey from "../Assets/Images/plusKey.png";
import aKey from "../Assets/Images/aKey.png";
import bKey from "../Assets/Images/bKey.png";
import cKey from "../Assets/Images/cKey.png";
import pKey from "../Assets/Images/pKey.png";
import tKey from "../Assets/Images/tKey.png";

function KeyBoardShortCut() {
  const data = [
    {
      title: "ALT + K",
      description: "Try Pressing Alt + K to View ShortCuts",
      controlKey: ALT,
      subKey: K,
    },
    {
      title: "ALT + C",
      description: "Alt + K to Create a Collection",
      controlKey: ALT,
      subKey: cKey,
    },
    {
      title: "ALT + B",
      description: "Alt + K to View Boxes",
      controlKey: ALT,
      subKey: bKey,
    },
    {
      title: "ALT + A",
      description: "Alt + A to Create a AirwayBill",
      controlKey: ALT,
      subKey: aKey,
    },
    {
      title: "ALT + P",
      description: "Alt + P to Create a Pickup",
      controlKey: ALT,
      subKey: pKey,
    },
    {
      title: "ALT + T",
      description: "Alt + T to Create a Trip",
      controlKey: ALT,
      subKey: tKey,
    },
  ];
  return (
    <>
      <div>
        <PageHeader
          PageName={"KeyBoard ShortCuts"}
          breadScrum={"Admin / ShortCuts"}
        />
        <div className="keyboard-shortcuts-box">
          <Container>
            <List
              itemLayout="horizontal"
              dataSource={data}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      <div>
                        <img src={ALT} />
                        <BsPlusLg style={{ margin: 5 }} />
                        <img src={item.subKey} />
                      </div>
                    }
                    title={item.title}
                    description={item.description}
                  />
                </List.Item>
              )}
            />
          </Container>
        </div>
      </div>
    </>
  );
}

export default KeyBoardShortCut;

import { Table, Tooltip } from "antd";
import React, { useState } from "react";

const OtherTable = (props) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys, rowDetails) => {
    props.onSelect(rowDetails);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const columns = [
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text, record, index) => (
        <Tooltip title={text}>
          <div
            className="airwaybill-ratedesc-table-colomn"
            onClick={() => onSelectChange([record.id], [record])}
          >
            <div className="airwaybill-ratedesc-table-item">{text}</div>
          </div>
        </Tooltip>
      ),
      width: 150,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, record, index) => (
        <Tooltip title={text}>
          <div
            className="airwaybill-ratedesc-table-colomn"
            onClick={() => onSelectChange([record.id], [record])}
          >
            <div className="airwaybill-ratedesc-table-item">{text}</div>
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Entitlement",
      dataIndex: "entitlement",
      key: "entitlement",
      render: (text, record, index) => (
        <div
          className="airwaybill-ratedesc-table-item"
          onClick={() => onSelectChange([record.id], [record])}
        >
          <div className="airwaybill-ratedesc-table-item">{text}</div>
        </div>
      ),
    },
  ];
  return (
    <div>
      <Table
        rowKey="id"
        size="small"
        rowSelection={{ type: "radio", ...rowSelection }}
        columns={columns}
        dataSource={props.data}
        pagination={false}
        rowClassName={(record, index) =>
          record.id === selectedRowKeys[0]
            ? "DataTable-row-light"
            : "DataTable-row-dark"
        }
      />
      <br />
    </div>
  );
};

export default OtherTable;

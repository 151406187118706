import React from "react";
import { Steps, Card } from "antd";
import moment from "moment";
import { Container } from "react-bootstrap";
import { IoIosArrowUp } from "react-icons/io";
const { Step } = Steps;
function BoxHistory(props) {
  return (
    <div>
      <Card>
        <Container>
          <div className="trackBox">
            {props?.data[0]?.boxStatus &&
              props?.data[0]?.boxStatus?.map((item) => {
                return (
                  <div>
                    <div className="trackBox-dot-active">
                      <div>
                        <IoIosArrowUp size={25} color="#fff" />
                      </div>
                    </div>
                    <div className="trackBox-box-active">
                      <div className="trackBox-box-content">
                        <div className="Formtxt6">
                          {item?.box_statuss?.status_head}
                        </div>
                        <div className="Formtxt5">
                          {item?.box_statuss?.status_label}
                        </div>
                        <div className="Formtxt5">{`${moment(
                          item?.created_at
                        ).format("DD /MM/YYYY")}    ${moment(
                          item?.created_at
                        ).format("HH:MM")}`}</div>
                      </div>
                    </div>
                  </div>
                );
              })}

            <div>
              <div className="trackBox-dot-active"></div>
              <div className="trackBox-box">
                <div className="trackBox-box-content">
                  <div className="Formtxt6">Origin Statement</div>
                  <div className="Formtxt5">Lorem ipsum dolor sit</div>
                  <div className="Formtxt5">12 Dec 2022</div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Card>
    </div>
  );
}

export default BoxHistory;

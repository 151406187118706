import React, { useState } from "react";
import {
  Drawer,
  Form,
  Button,
  Input,
  Switch,
  message,
  InputNumber,
} from "antd";
import { Container, Row, Col } from "react-bootstrap";
import { API, Headers } from "../../Config";
import axios from "axios";

var width = window.innerWidth;
var innerwidth = window.innerWidth <= 760;
function FormModal(props) {
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    let obj = {
      pickup_collections_request_id: values.pickup_collections_request_id,
      customer_id: values.customer_id,
      consignment_id: values.consignment_id,
      box_type: values.box_type,
      box_length: values.box_length,
      box_breadth: values.box_breadth,
      box_height: values.box_height,
      volume_weight: values.volume_weight,
      chargeable_weight: values.chargeable_weight,
      sender: values.sender,
      receiver: values.receiver,
      airwaybill_number: values.airwaybill_number,
      delivery_driver: values.delivery_driver,
      pickup_driver: values.pickup_driver,
      status: values.status,
      active: values.active,
    };
    //console.log ('data array '+obj)
    let URL =
      props.mode === "post"
        ? API.PICKUP_COLLECTION_REQUEST_ACTIONS
        : API.PICKUP_COLLECTION_REQUEST_ACTIONS + props.item.id;
    axios({
      method: props.mode,
      url: API.BASR_URL + URL,
      headers: Headers(),
      data: obj,
    })
      .then(function (response) {
        setLoading(false);
        if ([200, 201].includes(response.status)) {
          message.success("Success");
          props.onchange();
        }
      })
      .catch(function (error) {
        setLoading(false);
        message.error("Oops.Something went wrong");
        console.log(error);
      });
  };

  return (
    <Drawer
      placement={props.mode === "post" ? "right" : "left"}
      title={
        <span className="txt4">
          {props.mode === "post" ? "New" : "Update"} Boxes
        </span>
      }
      width={innerwidth ? width : width / 2.7}
      onClose={() => props.onClose()}
      visible={props.visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form
        layout="vertical"
        hideRequiredMark
        onFinish={onFinish}
        initialValues={{
          pickup_collections_request_id:
            props.item && props.item.pickup_collections_request_id,
          customer_id: props.item && props.item.customer_id,
          consignment_id: props.item && props.item.consignment_id,
          box_type: props.item && props.item.box_type,
          box_length: props.item && props.item.length,
          box_breadth: props.item && props.item.breadth,
          box_height: props.item && props.item.height,
          volume_weight: props.item && props.item.volume_weight,
          chargeable_weight: props.item && props.item.chargable_weight,
          sender: props.item && props.item.iqama_name,
          receiver: props.item && props.item.adhar_name,
          airwaybill_number: props.item && props.item.airway_bill_no,
          delivery_driver: props.item && props.item.delivery_driver,
          pickup_driver: props.item && props.item.pickup_driver,
          status: props.item && props.item.status,
          active: props.item && props.item.active ? props.item.active : false,
        }}
      >
        <Container>
          <Row>
            <Col sm="6" xs="12">
              <Form.Item
                name="pickup_collections_request_id"
                label="Pickup Colln ID"
                rules={[{ required: true, message: "required" }]}
              >
                <Input placeholder="Select  Pickup Colln Request ID  " />
              </Form.Item>
              <Form.Item
                name="consignment_id"
                label="Consignment Id"
                rules={[{ required: true, message: "required" }]}
              >
                <Input placeholder="Enter Consignment Place" />
              </Form.Item>
              <Form.Item
                name="box_length"
                label="Box Length"
                rules={[{ required: true, message: "required" }]}
              >
                <InputNumber placeholder="Enter box length" />
              </Form.Item>
              <Form.Item
                name="box_height"
                label="Box Height"
                rules={[{ required: false, message: "required" }]}
              >
                <InputNumber placeholder="Select box height" />
              </Form.Item>
              <Form.Item
                name="chargeable_weight"
                label="Chargeable. Weight (Kg)"
              >
                <InputNumber placeholder="Enter chargeable weight" />
              </Form.Item>
              <Form.Item
                name="receiver"
                label="Receiver"
                rules={[{ required: false, message: "required" }]}
              >
                <Input placeholder="Select receiver" />
              </Form.Item>
              <Form.Item
                name="delivery_driver"
                label="Delivery Driver"
                rules={[{ required: false, message: "required" }]}
              >
                <Input placeholder="Select delivery driver" />
              </Form.Item>
              <Form.Item
                name="status"
                label="Status"
                rules={[{ required: false, message: "required" }]}
              >
                <Input placeholder="Select Status" />
              </Form.Item>
              <Form.Item
                label="Active"
                name="active"
                rules={[{ required: true, message: "required" }]}
                valuePropName={props.item.active ? "checked" : "uncheked"}
              >
                <Switch />
              </Form.Item>
            </Col>

            <Col sm="6" xs="12">
              <Form.Item
                name="customer_id"
                label="customer ID"
                rules={[{ required: true, message: "required" }]}
              >
                <Input placeholder="Enter Contact Number" />
              </Form.Item>
              <Form.Item
                name="box_type"
                label="Box Type  "
                rules={[{ required: true, message: "required" }]}
              >
                <Input placeholder="Enter box type Landmark" />
              </Form.Item>

              <Form.Item
                name="box_breadth"
                label="Box Breadth"
                rules={[{ required: true, message: "required" }]}
              >
                <InputNumber placeholder="Select box breadth  " />
              </Form.Item>
              <Form.Item name="volume_weight" label="volume. Weight (Kg)">
                <InputNumber placeholder="Enter volume_weight" />
              </Form.Item>
              <Form.Item
                name="sender"
                label="Sender"
                rules={[{ required: true, message: "required" }]}
              >
                <Input placeholder="Enter sender" />
              </Form.Item>

              <Form.Item
                name="airwaybill_number"
                label="Airwaybill Number"
                rules={[{ required: true, message: "required" }]}
              >
                <InputNumber placeholder="Enter airwaybill number" />
              </Form.Item>
              <Form.Item
                name="pickup_driver"
                label="Pickup Driver"
                rules={[{ required: true, message: "required" }]}
              >
                <Input placeholder="Enter pickup driver  " />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            {props.mode === "post" ? null : <Col sm="6" xs="12"></Col>}
            <Col sm="6" xs="12">
              <Row>
                <Col sm="6" xs="12">
                  <Button onClick={() => props.onClose()} block>
                    Cancel
                  </Button>
                </Col>
                <Col sm="6" xs="12">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    block
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Form>
    </Drawer>
  );
}
export default FormModal;

import React, { useState, useRef } from "react";
import { Form, InputNumber, Input, Button, Select } from "antd";
import { Row, Col } from "react-bootstrap";
function PaymentForm(props) {
  const formRef = useRef();
  const [data, setData] = useState(props.data);

  var cargo_charge = props.data && props.data.cargo_charge;
  var packing_charge = props.data && props.data.packing_charge;
  var other_charge = props.data && props.data.other_charge;
  var duty = props.data && props.data.duty;
  var total_pay =
    Number(cargo_charge) +
    Number(packing_charge) +
    Number(other_charge) +
    Number(duty);

  const onFinish = (values) => {
    let next = Number(props.count) + 1;
    setData(values);
    props.next(next, values);
  };

  const OnBack = () => {
    let back = Number(props.count) - 1;
    props.next(back, data);
  };

  const handleTotal = (_, values) => {
    let amount = Number(total_pay) - Number(values.discount);
    let balance = Number(amount) - Number(values.paid_amount);
    formRef.current.setFieldsValue({
      total_pay: amount,
      balance: balance,
    });
    props.onchange(props.count, values);
  };

  return (
    <div>
      <br />
      <div className="FormSubHeading">Payment Details</div>
      <Form
        layout="vertical"
        ref={formRef}
        hideRequiredMark
        onValuesChange={handleTotal}
        onFinish={onFinish}
        initialValues={{
          payment_mode:
            props.data && props.data.payment_mode
              ? props.data.payment_mode
              : "cash",
          cargo_charge: cargo_charge,
          packing_charge: packing_charge,
          other_charge: other_charge,
          duty: duty,
          discount: props.data && props.data.discount ? props.data.discount : 0,
          paid_amount: props.data && props.data.paid_amount,
          total_pay: props.data && props.datatotal_pay,
          balance: props.data && props.data.total_pay,
          paymentdetails: props.data && props.data.paymentdetails,
        }}
      >
        <Row>
          <Col sm="6" xs="12">
            <Form.Item name="cargo_charge" label="Cargo Charge">
              <InputNumber
                placeholder="Enter Amount"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col sm="6" xs="12">
            <Form.Item name="packing_charge" label="Packing Charge">
              <InputNumber
                placeholder="Enter Amount"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col sm="6" xs="12">
            <Form.Item name="other_charge" label="Other Charges">
              <InputNumber
                placeholder="Enter Amount"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col sm="6" xs="12">
            <Form.Item name="duty" label="Duty">
              <InputNumber
                placeholder="Enter Amount"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col sm="6" xs="12">
            <Form.Item name="discount" label="Discount Amount">
              <InputNumber
                placeholder="Enter Amount"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col sm="6" xs="12">
            <Form.Item name="total_pay" label="Total Payment">
              <InputNumber
                placeholder="Enter Payment"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>

          <Col sm="6" xs="12">
            <Form.Item name="paid_amount" label="Paid Amount">
              <InputNumber
                placeholder="Enter Amount"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col sm="6" xs="12">
            <Form.Item name="balance" label="Balance Amount">
              <InputNumber
                placeholder="Enter Amount"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col sm="6" xs="12">
            <Form.Item
              name="payment_mode"
              label="Payment Mode"
              rules={[{ required: true, message: "required" }]}
            >
              <Select placeholder="Select Payment Mode">
                <Select.Option value={"card"}>card</Select.Option>
                <Select.Option value={"bank"}>bank</Select.Option>
                <Select.Option value={"cash"}>cash</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col sm="6" xs="12">
            <Form.Item name="paymentdetails" label="Payment details">
              <Input.TextArea
                placeholder="Enter details"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col sm="3" xs="6">
            <Button onClick={() => OnBack()} block size="large">
              Back
            </Button>
          </Col>
          <Col sm="3" xs="6">
            <Button type="primary" block htmlType="submit" size="large">
              Next
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
export default PaymentForm;

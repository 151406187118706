import React, { useState } from "react";
import {
  Drawer,
  Form,
  Button,
  Input,
  message,
  InputNumber,
  notification,
} from "antd";
import { Container, Row, Col } from "react-bootstrap";
import { API, Headers } from "../../Config";
import axios from "axios";
var width = window.innerWidth;
var innerwidth = window.innerWidth <= 760;
function FormModal(props) {
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    let obj = values;
    let URL =
      props.mode === "post"
        ? API.BOXTYPE_ACTIONS
        : API.BOXTYPE_ACTIONS + props.item.id;
    axios({
      method: props.mode,
      url: API.BASR_URL + URL,
      headers: Headers(),
      data: obj,
    })
      .then(function (response) {
        setLoading(false);
        if ([200, 201].includes(response.status)) {
          message.success("Success");
          props.onchange();
        }
      })
      .catch(function (error) {
        setLoading(false);
        message.error("Oops.Something went wrong");
        console.log(error);
        notification.open({
          message: "ERROR",
          description: `${error.response.data.message}`,
        });
      });
  };

  return (
    <Drawer
      placement={props.mode === "post" ? "right" : "left"}
      title={
        <span className="txt4">
          {props.mode === "post" ? "New" : "Update"} Box Type
        </span>
      }
      width={innerwidth ? width : width / 2.7}
      onClose={() => props.onClose()}
      visible={props.visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form
        layout="vertical"
        hideRequiredMark
        onFinish={onFinish}
        initialValues={{
          breadth: props.item.breadth,
          height: props.item.height,
          length: props.item.length,
          name: props.item.name,
          short_name: props.item.short_name,
        }}
      >
        <Container>
          <Row>
            <Col sm="6" xs="12">
              <Form.Item
                name="name"
                label={<div className="form-item-label">Name</div>}
                rules={[{ required: true, message: "required" }]}
              >
                <Input placeholder="" style={{ textTransform: "uppercase" }} />
              </Form.Item>
            </Col>
            <Col sm="6" xs="12">
              <Form.Item
                name="short_name"
                label={<div className="form-item-label">Short Name</div>}
                rules={[{ required: true, message: "required" }]}
              >
                <Input placeholder="" style={{ textTransform: "uppercase" }} />
              </Form.Item>
            </Col>
            <Col sm="4" xs="12">
              <Form.Item
                name="length"
                label={<div className="form-item-label">Length</div>}
                rules={[{ required: true, message: "required" }]}
              >
                <InputNumber placeholder="" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col sm="4" xs="12">
              <Form.Item
                name="breadth"
                label={<div className="form-item-label">Breadth</div>}
                rules={[{ required: true, message: "required" }]}
              >
                <InputNumber placeholder="" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col sm="4" xs="12">
              <Form.Item
                name="height"
                label={<div className="form-item-label">Height</div>}
                rules={[{ required: true, message: "required" }]}
              >
                <InputNumber placeholder="" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            {props.mode === "post" ? null : <Col sm="6" xs="12"></Col>}
            <Col sm="6" xs="12">
              <Row>
                <Col sm="6" xs="12">
                  <Button onClick={() => props.onClose()} block>
                    Cancel
                  </Button>
                </Col>
                <Col sm="6" xs="12">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    block
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Form>
    </Drawer>
  );
}
export default FormModal;

import React, { useState } from "react";
import {
  Drawer,
  Form,
  Button,
  Input,
  Switch,
  message,
  DatePicker,
  Upload,
  Select,
} from "antd";
import { Container, Row, Col } from "react-bootstrap";
import { API, Headers } from "../../Config";
import axios from "axios";
import moment from "moment";
var width = window.innerWidth;
var innerwidth = window.innerWidth <= 760;
function FormModal(props) {
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    let reqobj={
      userid:Number(values.userid),
      landmark: values.landmark,
      alt_mobile: values.alt_mobile,
      city: values.city,
      pincode: values.pincode,
      adhar_id_no: values.adhar_id_no,
      id_image: "string_id_image",
      address: values.address,
      type: values.type,
      iqama: values.iqama,
      iqama_exp_date: values.iqama_exp_date,
      iqama_image: "string_iqm_image",
      active: values.active,
    }

    let URL =
      props.mode === "post"
        ? API.RECIVERS_ACTION
        : API.RECIVERS_ACTION + props.item.id;
    axios({
      method: props.mode,
      url: API.BASR_URL + URL,
      headers: Headers(),
      data: reqobj,
    })
      .then(function (response) {
        setLoading(false);
        if ([200, 201].includes(response.status)) {
          message.success("Success");
          props.onchange();
        }
      })
      .catch(function (error) {
        setLoading(false);
        message.error("Oops.Something went wrong");
        console.log(error);
      });
  };

  return (
    <Drawer
      placement={props.mode === "post" ? "right" : "left"}
      title={
        <span className="txt4">
          {props.mode === "post" ? "New" : "Update"} Recievers
        </span>
      }
      width={innerwidth ? width : width / 2.7}
      onClose={() => props.onClose()}
      visible={props.visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form
        layout="vertical"
        hideRequiredMark
        onFinish={onFinish}
        initialValues={{
          userid: props.item && props.item.userid,
          iqama: props.item && props.item.iqama,
          iqama_exp_date: props.item && moment(props.item.iqama_exp_date),
          iqama_image: props.item && props.item.iqama_image,
          active: props.item && props.item.active ? props.item.active : false,
        }}
      >
        <Container>
          <Row>
            <Col sm="6" xs="12">
            <Form.Item
                name="userid"
                label="Name"
                rules={[{ required: true, message: "required" }]}
              >
                <Select placeholder="Select User">
                  {props.users.map((option) => (
                    <Select.Option key={option.id}>{option.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="iqama_exp_date"
                label="Iqama exp date"
                rules={[{ required: true, message: "required" }]}
              >
                <DatePicker
                  placeholder="Pick a date"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              
              <Form.Item
                name="adhar_id_no"
                label="adhar_id_no"
                rules={[{ required: true, message: "required" }]}
              >
                <Input placeholder="Enter adhar_id_no" />
              </Form.Item>
              <Form.Item
                name="alt_mobile"
                label="Mobile"
                rules={[{ required: true, message: "required" }]}
              >
                <Input placeholder="Enter mobile" />
              </Form.Item>
              <Form.Item
                name="city"
                label="city"
                rules={[{ required: true, message: "required" }]}
              >
                <Input placeholder="Enter city" />
              </Form.Item>
              <Form.Item
                name="address"
                label="address"
                rules={[{ required: true, message: "required" }]}
              >
                <Input placeholder="Enter address" />
              </Form.Item>
              
              
              <Form.Item
                label="Active"
                name="active"
                rules={[{ required: true, message: "required" }]}
                valuePropName={props.item.active ? "checked" : "uncheked"}
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col sm="6" xs="12">
              <Form.Item
                name="iqama"
                label="Iqama"
                rules={[{ required: true, message: "required" }]}
              >
                <Input placeholder="Enter Iqama" />
              </Form.Item>
              <Form.Item
                name="iqama_image"
                label="Upload Iqama"
                style={{ width: "100%" }}
              >
                <Upload
                  name="logo"
                  action="/upload.do"
                  listType="picture"
                  style={{ width: "100%" }}
                >
                  <Button block>Click to upload</Button>
                </Upload>
              </Form.Item>

              <Form.Item
                name="id_image"
                label="Upload id"
                style={{ width: "100%" }}
              >
                <Upload
                  name="logo"
                  action="/upload.do"
                  listType="picture"
                  style={{ width: "100%" }}
                >
                  <Button block>Click to upload</Button>
                </Upload>
              </Form.Item>
              
              <Form.Item
                name="landmark"
                label="landmark"
                rules={[{ required: true, message: "required" }]}
              >
                <Input placeholder="Enter Any landmark" />
              </Form.Item>
              <Form.Item
                name="pincode"
                label="pincode"
                rules={[{ required: true, message: "required" }]}
              >
                <Input placeholder="Enter  pincode" />
              </Form.Item>

              <Form.Item
                name="type"
                label="type"
                rules={[{ required: true, message: "required" }]}
              >
                <Input placeholder=" type" />
              </Form.Item>
              
            </Col>
          </Row>
          <Row>
            {props.mode === "post" ? null : <Col sm="6" xs="12"></Col>}
            <Col sm="6" xs="12">
              <Row>
                <Col sm="6" xs="12">
                  <Button onClick={() => props.onClose()} block>
                    Cancel
                  </Button>
                </Col>
                <Col sm="6" xs="12">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    block
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Form>
    </Drawer>
  );
}
export default FormModal;

import React, { useState } from "react";
import { Table, Pagination, Button, Input } from "antd";
import { AiOutlineFilter } from "react-icons/ai";
function DataTable(props) {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys, rowDetails) => {
    props.onSelect(rowDetails);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          size="middle"
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => props.onSearch(selectedKeys, confirm, dataIndex)}
        />
        <div style={{ margin: 5 }} />
        <Button onClick={() => props.onSearchReset(clearFilters)} size="small">
          Reset
        </Button>
        <Button
          onClick={() => props.onSearch(selectedKeys, confirm, dataIndex)}
          type="primary"
          size="small"
        >
          Search
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <div>
        <AiOutlineFilter size={10} />
      </div>
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().includes(value.toLowerCase()),
  });

  const columns = [
    {
      title: <div style={{ fontSize: "9px" }}>Account No.</div>,
      dataIndex: "account_number",
      key: "account_number",
      render: (text, record, index) => (
        <div
          className="airwaybill-contactfinder-table-column"
          onClick={() => onSelectChange([record.id], [record])}
        >
          <div>{text}</div>
        </div>
      ),
      ...getColumnSearchProps("account_number"),
      sorter: (a, b) => a.account_number.length - b.account_number.length,
      sortDirections: ["descend", "ascend"],
      width: 70,
    },
    {
      title: "IATA code",
      dataIndex: "code",
      key: "code",
      render: (text, record, index) => (
        <div
          className="airwaybill-contactfinder-table-column"
          onClick={() => onSelectChange([record.id], [record])}
        >
          <div>{text}</div>
        </div>
      ),
      ...getColumnSearchProps("code"),
      sorter: (a, b) => a.code.length - b.code.length,
      sortDirections: ["descend,ascend"],
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => (
        <div
          className="airwaybill-contactfinder-table-column"
          onClick={() => onSelectChange([record.id], [record])}
        >
          <div>{text}</div>
        </div>
      ),
      ...getColumnSearchProps("name"),
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ["descend", "ascend"],
    },
  ];
  return (
    <div>
      <Table
        rowKey="id"
        size="small"
        rowSelection={{ type: "radio", ...rowSelection }}
        loading={props.loading}
        columns={columns}
        dataSource={props.data}
        pagination={false}
      />
      <div align="right">
        <Pagination size="small" onChange={props.pageOnChange} />
      </div>
    </div>
  );
}

export default DataTable;

import React, { useState } from "react";
import {
  Form,
  Button,
  Input,
  message,
  Select,
  DatePicker,
  Modal,
  Slider,
  InputNumber,
  notification,
} from "antd";
import { Row, Col, Table } from "react-bootstrap";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { API, Headers } from "../../Config";
import axios from "axios";
import moment from "moment";
import styles from "./styles";
import "./styles.css";
import { IoIosClose } from "react-icons/io";
const { Option } = Select;
var width = window.innerWidth;

function FormModal(props) {
  const [expanded, setExpanded] = useState("panel1");
  const [loading, setLoading] = useState(false);
  const [formdata, setFormdata] = useState([]);
  const [start, setStart] = useState(props.item && props.item.from_time);
  const [end, setEnd] = useState(props.item && props.item.to_time);

  const marks = {
    9: {
      label: (
        <InputNumber
          value={start}
          style={{
            margin: 0,
            border: 0,
          }}
          readOnly
        />
      ),
    },
    23: {
      label: (
        <InputNumber
          value={end}
          style={{
            margin: 0,
            border: 0,
          }}
          readOnly
        />
      ),
    },
  };

  const onPickTime = (val) => {
    const timestring1 = val[0] * 60;
    let newDate1 = moment
      .utc()
      .startOf("day")
      .add(timestring1, "minutes")
      .format("hh:mm A");
    setStart(newDate1);

    const timestring2 = val[1] * 60;
    let newDate2 = moment
      .utc()
      .startOf("day")
      .add(timestring2, "minutes")
      .format("hh:mm A");
    setEnd(newDate2);
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const onValuesChange = (val, action) => {
    console.log("action =====>", action);

    // let country = props.countries.filter(
    //   (i) => (i.code = action.reciver_cnty_id)
    // );

    // let branch = props.branches.filter((i) => (i.id = action.pickup_branch));
    debugger;
    setFormdata(action);
  };

  const onFinish = (values) => {
    setLoading(true);
    let obj = {
      sender_name: values.sender_name,
      sender_phone: values.sender_phone,
      sender_phone_alt: values.sender_phone_alt,
      sender_address: values.sender_address,
      reciver_name: values.reciver_name,
      reciver_phone: values.reciver_phone,
      reciver_alt_phone: values.reciver_alt_phone,
      reciver_address: values.reciver_address,
      reciver_cnty_id: Number(values.reciver_cnty_id),
      reciver_state: values.reciver_state,
      reciver_distrcit: values.reciver_distrcit,
      reciver_pincode: values.reciver_pincode,
      reciver_post: values.reciver_post,
      pickup_branch: Number(values.pickup_branch),
      pickup_landmark: "string",
      pickup_address: "string",
      no_of_box: Number(values.no_of_box),
      convenient_datetime: moment(values.convenient_datetime),
      approximate_weight: Number(values.approximate_weight),
      status: "string",
      active: true,
      from_time: start,
      to_time: end,
    };
    console.log("obj=====>", obj);
    let URL =
      props.mode === "post"
        ? API.PICKUP_COLLECTION_REQUEST_ACTIONS
        : API.PICKUP_COLLECTION_REQUEST_ACTIONS + props.item.id;
    axios({
      method: props.mode,
      url: API.BASR_URL + URL,
      headers: Headers(),
      data: obj,
    })
      .then(function (response) {
        setLoading(false);
        if ([200, 201].includes(response.status)) {
          message.success("Success");
          props.onchange();
        }
      })
      .catch(function (error) {
        setLoading(false);
        message.error("Oops.Something went wrong");
        console.log(error);
        notification.open({
          message: "ERROR",
          description: `${error.response.data.message}`,
        });
      });
  };

  return (
    <div>
      <Modal
        title={
          <span className="txt4">
            {props.mode === "post" ? "New" : "Update"} Pickup Request
          </span>
        }
        visible={props.visible}
        onCancel={() => props.onClose()}
        maskClosable={false}
        footer={false}
        width={width / 1.5}
        closeIcon={<IoIosClose color="#fff" size={30} />}
      >
        <Form
          layout="vertical"
          hideRequiredMark
          onValuesChange={onValuesChange}
          onFinish={onFinish}
          initialValues={{
            sender_name: props.item && props.item.sender_name,
            sender_phone: props.item && props.item.sender_phone,
            sender_phone_alt: props.item && props.item.sender_phone_alt,
            sender_address: props.item && props.item.sender_address,
            reciver_name: props.item && props.item.reciver_name,
            reciver_phone: props.item && props.item.reciver_phone,
            reciver_alt_phone: props.item && props.item.reciver_alt_phone,
            reciver_address: props.item && props.item.reciver_address,
            reciver_cnty_id: props.item && props.item.reciver_cnty_id,
            reciver_state: props.item && props.item.reciver_state,
            reciver_distrcit: props.item && props.item.reciver_distrcit,
            reciver_pincode: props.item && props.item.reciver_pincode,
            reciver_post: props.item && props.item.reciver_post,
            no_of_box: props.item && props.item.no_of_box,
            pickup_branch: props.item && props.item.pickup_branch,
            approximate_weight: props.item && props.item.approximate_weight,
            convenient_datetime:
              props.item && moment(props.item.convenient_datetime),
          }}
        >
          <div>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <div className="PickupScreen-FormModal-txt1">Sender</div>
              </AccordionSummary>
              <AccordionDetails>
                <Row>
                  <Col sm="6" xs="12">
                    <Form.Item
                      name="sender_name"
                      label="Sender Name"
                      rules={[{ required: true, message: "required" }]}
                      style={styles.FormItem}
                    >
                      <Input placeholder="Enter Name" style={styles.Input} />
                    </Form.Item>
                  </Col>
                  <Col sm="6" xs="12">
                    <Form.Item
                      name="sender_address"
                      label="Sender Address"
                      rules={[{ required: true, message: "required" }]}
                      style={styles.FormItem}
                    >
                      <Input.TextArea
                        placeholder="Enter Address"
                        style={styles.Input}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm="6" xs="12">
                    <Row>
                      <Col sm="6" xs="12">
                        <Form.Item
                          name="sender_phone"
                          label="  Phone Number"
                          rules={[{ required: true, message: "required" }]}
                          style={styles.FormItem}
                        >
                          <Input
                            placeholder="Enter Phone Number"
                            style={styles.Input}
                          />
                        </Form.Item>
                      </Col>
                      <Col sm="6" xs="12">
                        <Form.Item
                          name="sender_phone_alt"
                          label="Alt. Phone Number"
                          style={styles.FormItem}
                        >
                          <Input
                            placeholder="Enter Alt. Phone Number"
                            style={styles.Input}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <div className="PickupScreen-FormModal-txt1">Reciever</div>
              </AccordionSummary>
              <AccordionDetails>
                <Row>
                  <Col sm="6" xs="6">
                    <Form.Item
                      name="reciver_name"
                      label="Reciever Name"
                      style={styles.FormItem}
                    >
                      <Input placeholder="Enter Name" style={styles.Input} />
                    </Form.Item>
                  </Col>
                  <Col sm="6" xs="6">
                    <Form.Item
                      name="reciver_address"
                      label="Address"
                      style={styles.FormItem}
                    >
                      <Input.TextArea
                        placeholder="Enter Address"
                        style={styles.Input}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm="6" xs="12">
                    <Form.Item
                      name="reciver_cnty_id"
                      label="Country"
                      style={styles.FormItem}
                      rules={[{ required: false, message: "required" }]}
                    >
                      <Select
                        bordered={false}
                        className="PickupScreen-FormModal-Select"
                        placeholder="Select Country"
                        showSearch
                        allowClear
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {props.countries.map((option) => (
                          <Select.Option key={option.code} value={option.id}>
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col sm="6" xs="12">
                    <Form.Item
                      name="reciver_pincode"
                      label="Pincode"
                      rules={[{ required: false, message: "required" }]}
                      style={styles.FormItem}
                    >
                      <Input placeholder="Enter Pincode" style={styles.Input} />
                    </Form.Item>
                  </Col>

                  <Col sm="6" xs="12">
                    <Form.Item
                      name="reciver_post"
                      label="Post"
                      rules={[{ required: false, message: "required" }]}
                      style={styles.FormItem}
                    >
                      <Input placeholder="Enter Post" style={styles.Input} />
                    </Form.Item>
                  </Col>
                  <Col sm="6" xs="12">
                    <Form.Item
                      name="reciver_distrcit"
                      label="District"
                      rules={[{ required: false, message: "required" }]}
                      style={styles.FormItem}
                    >
                      <Input
                        placeholder="Enter District"
                        style={styles.Input}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm="6" xs="12">
                    <Form.Item
                      name="reciver_state"
                      label="State"
                      rules={[{ required: false, message: "required" }]}
                      style={styles.FormItem}
                    >
                      <Input placeholder="Enter State" style={styles.Input} />
                    </Form.Item>
                  </Col>
                  <Col sm="6" xs="12">
                    <Form.Item
                      name="reciver_phone"
                      label="Reciever Phone"
                      rules={[{ required: false, message: "required" }]}
                      style={styles.FormItem}
                    >
                      <Input placeholder="Enter Phone" style={styles.Input} />
                    </Form.Item>
                  </Col>
                  <Col sm="6" xs="12">
                    <Form.Item
                      name="reciver_alt_phone"
                      label=" Alernative phone"
                      rules={[{ required: false, message: "required" }]}
                      style={styles.FormItem}
                    >
                      <Input placeholder="Enter Phone" style={styles.Input} />
                    </Form.Item>
                  </Col>
                </Row>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <div className="PickupScreen-FormModal-txt1">Other Details</div>
              </AccordionSummary>
              <AccordionDetails>
                <Row>
                  <Col sm="6" xs="12">
                    <Form.Item
                      name="no_of_box"
                      label="Box Count"
                      style={styles.FormItem}
                    >
                      <Input style={styles.Input} />
                    </Form.Item>
                  </Col>
                  <Col sm="6" xs="12">
                    <Form.Item
                      name="approximate_weight"
                      label="Approx Weight"
                      style={styles.FormItem}
                    >
                      <Input style={styles.Input} />
                    </Form.Item>
                  </Col>
                  <Col sm="6" xs="12">
                    <Form.Item
                      name="convenient_datetime"
                      label="Convenient Pickup Time"
                      style={styles.FormItem}
                    >
                      <DatePicker style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                  <Col sm="6" xs="12">
                    <Form.Item
                      name="pickup_branch"
                      label="Location"
                      style={styles.FormItem}
                    >
                      <Select
                        bordered={false}
                        className="PickupScreen-FormModal-Select"
                        placeholder="Select Branch"
                        showSearch
                        allowClear
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {props.branches &&
                          props.branches.map((option) => (
                            <Option key={option.id} value={option.id}>
                              {option.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col sm="1" xs="1">
                    TIME
                  </Col>
                  <Col sm="10" xs="10">
                    <br />
                    <Form.Item name="time">
                      <Slider
                        range
                        marks={marks}
                        onChange={onPickTime}
                        max={23}
                        min={9}
                        step={0.25}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm="1" xs="1"></Col>
                </Row>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <div className="PickupScreen-FormModal-txt1">Overview</div>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <Table borderless>
                    <tbody>
                      <tr>
                        <td colSpan={2}>
                          <div className="PickupScreen-FormModal-txt2">
                            Sender Name
                          </div>
                        </td>
                        <td colSpan={2}>
                          <div className="PickupScreen-FormModal-txt3">
                            {formdata.sender_name && formdata.sender_name}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div className="PickupScreen-FormModal-txt2">
                            Sender Phone
                          </div>
                        </td>
                        <td colSpan={2}>
                          <div className="PickupScreen-FormModal-txt3">
                            {formdata.sender_phone && formdata.sender_phone}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div className="PickupScreen-FormModal-txt2">
                            Alt Sender Phone
                          </div>
                        </td>
                        <td colSpan={2}>
                          <div className="PickupScreen-FormModal-txt3">
                            {formdata.sender_phone_alt &&
                              formdata.sender_phone_alt}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div className="PickupScreen-FormModal-txt2">
                            Sender Address
                          </div>
                        </td>
                        <td colSpan={2}>
                          <div className="PickupScreen-FormModal-txt3">
                            {formdata.sender_address && formdata.sender_address}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={4}>
                          <hr />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div className="PickupScreen-FormModal-txt2">
                            Reciever Name
                          </div>
                        </td>
                        <td colSpan={2}>
                          <div className="PickupScreen-FormModal-txt3">
                            {formdata.reciver_name && formdata.reciver_name}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div className="PickupScreen-FormModal-txt2">
                            Reciever Phone
                          </div>
                        </td>
                        <td colSpan={2}>
                          <div className="PickupScreen-FormModal-txt3">
                            {formdata.reciver_phone && formdata.reciver_phone}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div className="PickupScreen-FormModal-txt2">
                            Reciever Alt. Phone
                          </div>
                        </td>
                        <td colSpan={2}>
                          <div className="PickupScreen-FormModal-txt3">
                            {formdata.reciver_alt_phone &&
                              formdata.reciver_alt_phone}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div className="PickupScreen-FormModal-txt2">
                            Reciever Address
                          </div>
                        </td>
                        <td colSpan={2}>
                          <div className="PickupScreen-FormModal-txt3">
                            {formdata.reciver_address &&
                              formdata.reciver_address}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div className="PickupScreen-FormModal-txt2">
                            Reciever Country
                          </div>
                        </td>
                        <td colSpan={2}>
                          <div className="PickupScreen-FormModal-txt3">
                            {formdata.reciver_cnty_id &&
                              formdata.reciver_cnty_id}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div className="PickupScreen-FormModal-txt2">
                            Reciever State
                          </div>
                        </td>
                        <td colSpan={2}>
                          <div className="PickupScreen-FormModal-txt3">
                            {formdata.reciver_state && formdata.reciver_state}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div className="PickupScreen-FormModal-txt2">
                            Reciever District
                          </div>
                        </td>
                        <td colSpan={2}>
                          <div className="PickupScreen-FormModal-txt3">
                            {formdata.reciver_distrcit &&
                              formdata.reciver_distrcit}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div className="PickupScreen-FormModal-txt2">
                            Reciver Pincode
                          </div>
                        </td>
                        <td colSpan={2}>
                          <div className="PickupScreen-FormModal-txt3">
                            {formdata.reciver_pincode &&
                              formdata.reciver_pincode}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div className="PickupScreen-FormModal-txt2">
                            Reciver Post
                          </div>
                        </td>
                        <td colSpan={2}>
                          <div className="PickupScreen-FormModal-txt3">
                            {formdata.reciver_post && formdata.reciver_post}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={4}>
                          <hr />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div className="PickupScreen-FormModal-txt2">
                            Pickup Place
                          </div>
                        </td>
                        <td colSpan={2}>
                          <div className="PickupScreen-FormModal-txt3">
                            {formdata.pickup_branch && formdata.pickup_branch}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div className="PickupScreen-FormModal-txt2">
                            Approximate Weight
                          </div>
                        </td>
                        <td colSpan={2}>
                          <div className="PickupScreen-FormModal-txt3">
                            {formdata.approximate_weight &&
                              formdata.approximate_weight}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div className="PickupScreen-FormModal-txt2">
                            Count
                          </div>
                        </td>
                        <td colSpan={2}>
                          <div className="PickupScreen-FormModal-txt3">
                            {formdata.no_of_box && formdata.no_of_box}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div className="PickupScreen-FormModal-txt2">
                            Convenient Date
                          </div>
                        </td>
                        <td colSpan={2}>
                          <div className="PickupScreen-FormModal-txt3">
                            {formdata.convenient_datetime &&
                              moment(formdata.convenient_datetime).format("l")}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div className="PickupScreen-FormModal-txt2">
                            Estimated pickup will be
                          </div>
                        </td>
                        <td colSpan={2}>
                          <div className="PickupScreen-FormModal-txt3">{`${start}  to  ${end}`}</div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </AccordionDetails>
            </Accordion>

            <br />
          </div>

          <Row>
            <Col sm="6" xs="12"></Col>
            <Col sm="3" xs="12">
              <Button onClick={() => props.onClose()} block size="large">
                Cancel
              </Button>
            </Col>
            <Col sm="3" xs="12">
              <Button
                type="primary"
                size="large"
                block
                htmlType="submit"
                loading={loading}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

export default FormModal;

import { Table, Pagination, Button, Popconfirm } from "antd";
import moment from "moment";
function DataTable(props) {
  const columns = [
    { title: "#", dataIndex: "id", key: "id" },
    { title: "Pincode", dataIndex: "pincode", key: "pincode" },
    { title: "Office", dataIndex: "office", key: "office" },
    { title: "District", dataIndex: "district", key: "district" },
    {
      title: "Country",
      dataIndex: "country_id",
      key: "country_id",
      render: (text, record) => (
        <div>{record.countries && record.countries.name}</div>
      ),
    },
    {
      title: "",
      render: (item) => (
        <div className="tableAction">
          <Popconfirm
            placement="bottomRight"
            title={"Are you sure to delete?"}
            okText="Yes"
            cancelText="No"
            onConfirm={() => props.delete(item)}
          >
            <Button size="small">Delete</Button>
          </Popconfirm>
          <Button
            size="small"
            onClick={() => props.update(item)}
            type="primary"
          >
            Edit
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={props.data}
        pagination={false}
        size="small"
      />
      <div align="right">
        <Pagination
          defaultCurrent={props.meta && props.meta.page}
          total={props.meta && props.meta.itemCount}
          pageSize={props.meta && props.meta.take ? props.meta.take : 0}
          onChange={props.PageOnchange}
          pageSizeOptions={[10, 20, 50]}
        />
      </div>
    </div>
  );
}

export default DataTable;

import { Button, Input, Modal, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { API, Headers } from "../../../Config";
import { Col, Row, Table } from "react-bootstrap";
import moment from "moment";
import { useSelector } from "react-redux";
import { IoIosClose } from "react-icons/io";

function UnAssignedModal(props) {
  const Auth = useSelector((state) => state.Auth.user);
  const [loading2, setLoading2] = useState(true);
  const [trip, setTrip] = useState([]);
  useEffect(() => {}, [trip]);

  const onSearchBox = (val) => {
    try {
      setLoading2(true);
      axios
        .get(API.BASR_URL + API.BOXES_VIA_SEARCH + val, {
          headers: Headers(),
        })
        .then(function (response) {
          if (response.status === 200) {
            let data = response.data;
            if (data.length <= 0) {
              message.error(`Boxss ${val} Not Found`);
            }
            setTrip(data);
            setLoading2(false);
          }
        })
        .catch(function (error) {
          message.error(`Boxss ${val} Not Found`);
          console.log(error);
          setLoading2(false);
        });
    } catch (err) {
      console.log(err);
      message.error("Some thing went wrong");
      setLoading2(false);
    }
  };
  const UnAssignTrip = () => {
    try {
      if (trip[0]?.trip_no) {
        setLoading2(true);
        const payload = {
          boxNumber: trip[0]?.box_number,
          userId: Number(Auth?.id),
          branchId: Auth?.branch_id,
          boxesId: trip[0]?.box_number,
        };
        const apiUrl = `${API.BASR_URL + API.TRIP_BOX_REMOVE}`;
        axios
          .post(apiUrl, payload, {
            headers: Headers(),
          })
          .then(function (response) {
            if (response.status === 201) {
              message.success("Boxes unassigned successfully");
              setTrip([]);
              setLoading2(false);
            }
          })
          .catch(function (error) {
            message.error("Failed to unassign boxes");
            console.log(error);
            setLoading2(false);
          })
          .finally(() => props.onClose());
      } else {
        message.error("This box is not assign to trip");
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
      setLoading2(false);
    }
  };

  return (
    <Modal
      open={props.visible}
      onOk={() => props.onClose()}
      onCancel={() => props.onClose()}
      width={700}
      title={"Remove Trip"}
      footer={false}
      closeIcon={
        <div>
          <IoIosClose color="#fff" size={30} />
        </div>
      }
    >
      <Input
        placeholder="Search Box"
        onChange={(val) => onSearchBox(val.target.value)}
        prefix={<AiOutlineSearch color="#999" />}
        style={{ borderRadius: 6 }}
      />
      {trip?.length ? (
        <>
          <Table className="formTable mt-3" borderless>
            <thead>
              <tr>
                <th
                  style={{ backgroundColor: "#1e88e5", color: "#fff" }}
                  colSpan={2}
                  className="box-details-txt1"
                >
                  BOXES DETAILS
                </th>
              </tr>
            </thead>
            <tbody className="box-details-txt2">
              {trip.map((item) => {
                return (
                  <>
                    <tr>
                      <td>Trip No</td>
                      <th>{item.trip_no}</th>
                    </tr>
                    <tr>
                      <td>Box Number</td>
                      <th>{item.box_number}</th>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
          <Row>
            <Col md={6} />
            <Col md={3}>
              <Button style={{ width: "100%" }} onClick={props.onClose}>
                Cancel
              </Button>
            </Col>
            <Col md={3}>
              <Button
                type="primary"
                style={{ width: "100%" }}
                onClick={UnAssignTrip}
                loading={loading2}
              >
                Confirm
              </Button>
            </Col>
          </Row>
        </>
      ) : null}
    </Modal>
  );
}

export default UnAssignedModal;

import React, { useState, useEffect } from "react";
import { Modal, Popconfirm, Input, Button, message } from "antd";
import { Row, Col, Container } from "react-bootstrap";
import axios from "axios";
import { GrFormAdd } from "react-icons/gr";
import { MdEdit, MdDelete } from "react-icons/md";
import { IoIosClose } from "react-icons/io";
import { API, Headers } from "../../../../Config";
import DataTable from "./DataTable";
import FormAddConsigneeModal from "./FormAddConsigneeModal";
const { Search } = Input;
function FormConsigneeModal(props) {
  const [loading, setLoading] = useState(true);
  const [indicator, setIndicator] = useState(false);
  const [data, setData] = useState([]);
  const [mode, setMode] = useState("post");
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [meta, setMeta] = useState({});
  const [selected, setSelected] = useState([]);

  const [addConsigneeModal, setAddConsigneeModal] = useState(false);

  useEffect(() => {
    loaddata(page, take);
  }, []);

  const loaddata = (p, t) => {
    axios
      .get(
        API.BASR_URL + API.RECIVERS_LIST + `?order=ASC&page=${p}&take=${t}`,
        {
          headers: Headers(),
        }
      )
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setData(data.data);
          setMeta(data.meta);
          message.success("Success");
        } else {
          message.error("Oops.Something went wrong");
        }
        setLoading(false);
      })
      .catch(function (error) {
        message.error("Oops.Something went wrong");
        setIndicator(false);
        setLoading(false);
      });
  };

  const AddNew = () => {
    setSelected([]);
    setAddConsigneeModal(true);
    setMode("post");
  };

  const UpdateItem = () => {
    setMode("put");
    setAddConsigneeModal(true);
  };

  const reload = () => {
    setIndicator(true);
    loaddata(page, take);
    setAddConsigneeModal(false);
  };

  const selectCell = (row) => {
    setSelected(row);
  };

  const deleteCell = () => {
    const RowID = selected && selected[0].id;
    axios
      .delete(API.BASR_URL + API.RECIVERS_ACTION + RowID, {
        headers: Headers(),
      })
      .then(function (response) {
        message.success("Deleted Successfully");
        reload();
      })
      .catch(function (error) {
        message.error("Oops.Something went wrong");
        console.log(error);
      });
  };

  const pagination = (page, take) => {
    window.scrollTo(0, 0);
    setPage(page);
    setTake(take);
    loaddata(page, take);
  };

  const onSearch = (value) => {
    axios
      .get(API.BASR_URL + API.RECIVERS_ACTION + "like/" + value, {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setData(data);
          console.log("search data ==>", data);
        } else {
          message.error("Oops.Something went");
        }
      });
  };

  const Submit = () => {
    props.onChange(selected);
    props.onClose();
  };

  return (
    <Modal
      visible={props.visible}
      onCancel={props.onClose}
      footer={false}
      width={800}
    >
      <h5 className="PrintLabelModal-txt1">CONTACT FINDER</h5>
      <Container style={{ display: "flex" }}>
        <Search
          placeholder=""
          allowClear
          style={{ flex: 0.4 }}
          onSearch={onSearch}
        />
        <div
          className="find-customer-modal-close-button"
          onClick={() => loaddata(page, take)}
          style={{ marginRight: 3 }}
        >
          <IoIosClose size={30} />
        </div>

        <Button type="primary" icon={<GrFormAdd />} onClick={() => AddNew()}>
          Add
        </Button>

        <Button
          icon={<MdEdit />}
          disabled={selected.length ? false : true}
          onClick={() => UpdateItem()}
        >
          Edit
        </Button>
        <Popconfirm
          placement="bottomRight"
          title={"Are you sure to delete?"}
          cancelText="No"
          onConfirm={deleteCell}
        >
          <Button icon={<MdDelete />} disabled={selected.length ? false : true}>
            Remove
          </Button>
        </Popconfirm>
      </Container>
      <br />
      <DataTable
        data={data}
        loading={loading}
        selected={selected}
        onSelect={(data) => selectCell(data)}
        pageOnChange={(page, take) => pagination(page, take)}
      />
      <br />
      <Row>
        <Col sm="6"></Col>
        <Col sm="3">
          <Button type="primary" block onClick={() => Submit()}>
            Select
          </Button>
        </Col>
        <Col sm="3">
          <Button onClick={props.onClose} block>
            Cancel
          </Button>
        </Col>
      </Row>
      {addConsigneeModal ? (
        <FormAddConsigneeModal
          selected={selected}
          mode={mode}
          visible={addConsigneeModal}
          reload={reload}
          onClose={() => setAddConsigneeModal(false)}
        />
      ) : null}
    </Modal>
  );
}

export default FormConsigneeModal;

import { Table, Pagination, Button, Popconfirm, Tag } from "antd";

function DataTable(props) {
  const columns = [
    {
      title: "#",
      key: "index",
      width: "20px",
      render: (text, record, index) =>
        (props.meta.page - 1) * props.meta.take + index + 1,
    },
    { title: "Name", dataIndex: "name", key: "name" },
    {
      title: "Branch",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <div className="data-table-items">
          {record.branch && record.branch.name && record.branch?.name}
        </div>
      ),
    },
    {
      title: "Account",
      dataIndex: "account_no",
      key: "account_no",
      render: (text, record) => (
        <div className="data-table-items">
          {
            <Tag
              color={record.account_no ? "success" : "processing"}
              onClick={() => props.accountHandleClick(record.id)}
              size="small"
            >
              {record.account_no ? record.account_no : "Link Account"}
            </Tag>
          }
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text, record) => <div className="data-table-items">{text}</div>,
    },

    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      render: (active) => (
        <Tag color={active ? "green" : "red"}>
          {active ? "Active" : "Inactive"}
        </Tag>
      ),
    },
    {
      title: "Action",
      render: (item) => (
        <div className="tableAction">
          <Popconfirm
            placement="bottomRight"
            title={"Are you sure to delete?"}
            okText="Yes"
            cancelText="No"
            onConfirm={() => props.delete(item)}
          >
            <Button size="small">Delete</Button>
          </Popconfirm>
          <Button
            size="small"
            onClick={() => props.update(item)}
            type="primary"
          >
            Edit
          </Button>
        </div>
      ),
    },
  ];
  return (
    <div style={{ minHeight: "60vh" }}>
      <Table
        columns={columns}
        dataSource={props.data}
        pagination={false}
        size="small"
      />
      <br />
      <div align="right">
        <Pagination
          responsive
          defaultCurrent={props.meta && props.meta.page}
          total={props.meta && props.meta.itemCount}
          pageSize={props.meta && props.meta.take ? props.meta.take : 0}
          onChange={props.PageOnchange}
          pageSizeOptions={[10, 20, 50]}
        />
      </div>
    </div>
  );
}
export default DataTable;

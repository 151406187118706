import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PageHeader from "../../Routes/PageHeader";
import FormModal from "./FormModal";
import DataTable from "./DataTable";

import axios from "axios";
import { API, Headers } from "../../Config";
import LoadingBox from "../../Components/LoadingBox";

import { message, Tabs } from "antd";
import MenuBar from "./MenuBar";
import FormAddExpense from "./FormAddExpense";
import PrintTaxInvoice from "./PrintTaxInvoice";
import PrintTripSheet from "./PrintTripSheet";
import DataTable2 from "./DataTable2";
import DataTable3 from "./DataTable3";

function TripScreen() {
  const Auth = useSelector((state) => state.Auth.user);
  const [loading, setLoading] = useState(true);
  const [indicator, setIndicator] = useState(false);
  const [form, setform] = useState(false);
  const [mode, setMode] = useState("post");
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(20);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({});
  const [item, setItem] = useState({});
  const [branches, setbranches] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [selected, setSelected] = useState([]);
  const [addexpense, setAddExpense] = useState(false);
  const [printtax, setPrintTax] = useState(false);
  const [printripsheet, setPrintripsheet] = useState(false);

  const [outbound, setOutbound] = useState([]);
  const [inbound, setInbound] = useState([]);
  const [pending, setPending] = useState([]);

  const { TabPane } = Tabs;

  useEffect(() => {
    loaddata(page, take);
    BranchesDropdown();
    DriverDropdown();
  }, []);

  const loaddata = (p, t) => {
    axios
      .get(API.BASR_URL + API.TRIP_LIST + `?order=DESC&page=${p}&take=${t}`, {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setData(data.data);
          filterTrip(data.data);
          setMeta(data.meta);
          setLoading(false);
          setIndicator(false);
          message.success("Success");
        }
      })
      .catch(function (error) {
        console.log(error);
        message.error("Oops.Something went wrong");
        setLoading(false);
        setIndicator(false);
      });
  };

  const filterTrip = (data) => {
    let arr = data;
    let arr2 = data;
    const newArr = arr.filter(
      (data) => Number(data.loggin_branch_id) === Number(Auth.branch_id)
    );
    const newArr1 = arr.filter(
      (data) => Number(data.to_branch_id) === Number(Auth.branch_id)
    );
    const PendingTrip = arr2.filter((data) => data.trip_status === "start");

    setOutbound(newArr);
    setInbound(newArr1);
    setPending(PendingTrip);

    console.log("New Array===>> ", newArr);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const BranchesDropdown = () => {
    axios
      .get(API.BASR_URL + API.BRANCHES_LIST + `/list`, {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) setbranches(res.data);
      })
      .catch(function (error) {
        message.error("Contries dropdown faild");
      });
  };
  const DriverDropdown = () => {
    axios
      .get(API.BASR_URL + API.DRIVERS_LIST + `/list`, {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) setDrivers(res.data);
      })
      .catch(function (error) {
        message.error("Contries dropdown faild");
      });
  };

  const pagination = (page, take) => {
    window.scrollTo(0, 0);
    setIndicator(true);
    setPage(page);
    setTake(take);
    loaddata(page, take);
  };

  const relaod = () => {
    loaddata(page, take);
    setMode("post");
    setItem({});
    setform(false);
  };

  const AddItem = () => {
    setMode("post");
    setSelected([]);
    setform(true);
  };

  const UpdateItem = (item) => {
    setMode("put");
    setItem(item);
    setform(true);
  };

  const DeleteItem = (item) => {
    setLoading(true);

    const TripID = selected && selected[0].id;
    axios
      .delete(API.BASR_URL + API.TRIP_ACTIONS + TripID, {
        headers: Headers(),
      })
      .then(function (response) {
        message.success("Deleted Successfully");
        setLoading(false);
        relaod();
      })
      .catch(function (error) {
        message.error("Oops.Something went wrong");
        console.log(error);
      });
  };

  const selectCell = (row) => {
    setSelected(row);
    console.log("selectCell ===>", row);
  };

  const onSearch = (val) => {
    console.log("onSearch ===>", val);
  };

  const refresh = () => {
    setIndicator(true);
    loaddata(page, take);
  };

  return (
    <div className="tripDiv">
      <PageHeader
        PageName={"Trip"}
        breadScrum={"Admin / Trip"}
        loader={indicator}
      />
      <MenuBar
        singleSelect={selected.length ? false : true}
        onSearch={onSearch}
        onOpen={() => UpdateItem(selected)}
        onDelete={() => DeleteItem()}
        onRefresh={() => refresh()}
        addNew={() => AddItem()}
        addExpense={() => setAddExpense(true)}
        printtax={() => setPrintTax(true)}
        printripsheet={() => setPrintripsheet(true)}
      />
      <br />
      {loading ? (
        <LoadingBox />
      ) : (
        <div>
          <Tabs>
            <TabPane tab={"Inbound Trip"} key="1">
              <DataTable
                data={inbound}
                meta={meta}
                onSelect={(data) => selectCell(data)}
                update={(item) => UpdateItem(item)}
                delete={(item) => DeleteItem(item)}
                PageOnchange={(page, take) => pagination(page, take)}
              />
            </TabPane>
            <TabPane tab={"Outbound trip"} key="2">
              <DataTable2
                data={outbound}
                meta={meta}
                onSelect={(data) => selectCell(data)}
                update={(item) => UpdateItem(item)}
                delete={(item) => DeleteItem(item)}
                PageOnchange={(page, take) => pagination(page, take)}
              />
            </TabPane>{" "}
            <TabPane tab={"Pending trip"} key="3">
              <DataTable3
                data={pending}
                meta={meta}
                onSelect={(data) => selectCell(data)}
                update={(item) => UpdateItem(item)}
                delete={(item) => DeleteItem(item)}
                PageOnchange={(page, take) => pagination(page, take)}
              />
            </TabPane>
          </Tabs>

          {form ? (
            <FormModal
              item={item}
              selected={selected[0]}
              mode={mode}
              branches={branches}
              drivers={drivers}
              visible={form}
              onchange={() => relaod()}
              onClose={() => setform(false)}
            />
          ) : null}
          {addexpense ? (
            <FormAddExpense
              visible={addexpense}
              onCancel={() => setAddExpense(false)}
            />
          ) : null}
          {printtax ? (
            <PrintTaxInvoice
              visible={printtax}
              selected={selected[0]}
              onCancel={() => setPrintTax(false)}
            />
          ) : null}
          {printripsheet ? (
            <PrintTripSheet
              selected={selected[0]}
              visible={printripsheet}
              onCancel={() => setPrintripsheet(false)}
            />
          ) : null}
        </div>
      )}
    </div>
  );
}

export default TripScreen;

import React from "react";
import "./styles.css";
import { Tabs, DatePicker } from "antd";

import datetime from "../../Assets/Images/calenderp.png";
import Left1 from "../../Assets/Images/Left (1).svg";
import Left2 from "../../Assets/Images/Left (2).svg";
import Right1 from "../../Assets/Images/Right (1).svg";
import Right2 from "../../Assets/Images/Right (2).svg";

import Collection from "./Collection";
import Process from "./Process";
import Delivery from "./Delivery";
import DelhiveryTracking from "./DelhiveryTracking";

const { TabPane } = Tabs;
function HomeScreen() {
  return (
    <div>
      <div className="page-header">
        <div className="txt4 gray-bold">Overview</div>
        <div>
          <DatePicker
            className="dashboard-date"
            suffixIcon={<img src={datetime} width="80%" />}
            prevIcon={<img src={Left1} width="80%" />}
            superPrevIcon={<img src={Left2} width="80%" />}
            nextIcon={<img src={Right1} width="80%" />}
            superNextIcon={<img src={Right2} width="80%" />}
            style={{
              borderRadius: "8px",
              background: "#F5F5F5",
              borderColor: "#f5f5f5",
            }}
          />
        </div>
      </div>
      <br />
      <div>
        <Tabs defaultActiveKey="1">
          <TabPane
            tab={
              <span>
                <div className="tab-div">
                  <div className="collection-icon"></div>
                  Collection
                </div>
              </span>
            }
            key="1"
          >
            <br />
            <Collection />
            <br />
          </TabPane>
          <TabPane
            tab={
              <span>
                {/* <IoCarSharp /> Process */}
                <div className="tab-div">
                  <div className="process-icon"></div>
                  Process
                </div>
              </span>
            }
            key="2"
          >
            <br />
            <Process />
            <br />
          </TabPane>
          <TabPane
            tab={
              <span>
                <div className="tab-div">
                  <div className="delivery-icon"></div>
                  Delivery
                </div>
              </span>
            }
            key="3"
          >
            <br />
            <Delivery />
            <br />
          </TabPane>
          <TabPane
            tab={
              <span>
                <div className="tab-div">
                  <div className="delivery-icon"></div>
                  Tracking
                </div>
              </span>
            }
            key="94"
          >
            <br />
            <DelhiveryTracking />
            <br />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}
export default HomeScreen;

import { withRouter, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Menu from "./Menu.json";
function DrawerItem(props) {
  const location = useLocation();
  const Auth = useSelector((state) => state.Auth.user);
  const [visible, setVisible] = useState([]);
  let visibility = [];
  useEffect(() => {
    visibility = props?.visible;
    setVisible(visibility);
  }, []);

  const navigte = (screen) => {
    props.history.push(screen);
  };

  // Menu.map((val, index) => {
  //   console.log(val, index);
  //   let yes = val.visible.includes(
  //     Auth.user_roles && Number(Auth.user_roles[0].roleId)
  //   );
  //   debugger;
  // });
  return (
    <div
      className={
        location.pathname === props.Route
          ? "route-sidebar-item active"
          : "route-sidebar-item"
      }
      onClick={() => navigte(props.Route)}
      // style={
      //   visible?.includes(1) ? {} : { visibility: "hidden", display: "none" }
      // }
      style={
        String(props.show) === "true"
          ? {}
          : { visibility: "hidden", display: "none" }
      }
    >
      <div>{props.Icon}</div>
      <div className="route-sidebar-item-txt">
        {props.name} {}
      </div>
    </div>
  );
}
export default withRouter(DrawerItem);

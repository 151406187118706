import axios from "axios";
import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Barcode from "react-barcode";
import moment from "moment";
import { BlobProvider, PDFDownloadLink } from "@react-pdf/renderer";
import { API, Headers } from "../../Config";
import LoadingBox from "../../Components/LoadingBox";
import PdfTaxInvoice from "./PdfTaxInvoice";
import GenQrCode from "../Boxes/GenQrCode";

import "./styles.css";
import logo from "../../Assets/Images/logoNew.jpg";
import Companyseal from "../../../src/Assets/Images/seal.JPG";

import { Button, Collapse } from "antd";
const { Panel } = Collapse;

function Bills() {
  const [data, setData] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const location = useLocation();

  var windows = location.pathname;
  var id = windows.substring(windows.lastIndexOf("/") + 1);
  useEffect(() => {
    loadData(id);
  }, []);

  const loadData = (id) => {
    setIsLoading(true);
    axios
      .get(API.BASR_URL + API.PICKUPCOLLECTIONS_LIST + `/${id}`, {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setData(data);
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  const download = (url) => {
    window.open(url);
  };

  return (
    <div>
      <PDFDownloadLink
        document={<PdfTaxInvoice data={data} />}
        fileName={`SahariCargoInvoice${id}.pdf`}
      >
        {({ blob, url, loading, error }) => {
          return loading || isloading ? (
            <div>
              <LoadingBox />
            </div>
          ) : (
            <div className="Bills-box1">
              {/* <div style={{ fontSize: "9px", marginBottom: "5px" }}>
                downloading.........
              </div> */}
              <Button
                type="primary"
                // onClick={() => download(url)}
              >
                Download
              </Button>
            </div>
          );
        }}
      </PDFDownloadLink>
    </div>
  );
}

export default Bills;

import React, { useEffect, useState } from "react";
import { Button, Modal, message } from "antd";
import { Container } from "react-bootstrap";
import Excel from "../../../Assets/Images/excel-icons-animation.gif";
import { API, Headers } from "../../../Config";
import axios from "axios";
import { AiOutlineCloseCircle } from "react-icons/ai";
import * as XLSX from "xlsx";

function ExcelDeliveryList(props) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    loadBoxesViaAwb();
  }, []);

  const loadBoxesViaAwb = () => {
    let AWBID = props.selected && props.selected.id;
    let URL = API.BASR_URL + API.BOXES_VIA_AWB + `byColln/${AWBID}`;
    axios
      .get(URL, {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          Generatedata(data);
        } else {
          setIsLoading(false);
          message.error("Oops.Something went");
        }
      });
  };

  const Generatedata = async (data) => {
    let newArr = data;
    let sorted_arr = newArr.slice().sort();
    let results = [];
    let Newresults = [];

    for (let i = 0; i < sorted_arr.length; i++) {
      if (sorted_arr[i + 1]?.collection_id == sorted_arr[i]?.collection_id) {
        results.push(sorted_arr[i]);
      } else {
        Newresults.push(sorted_arr[i]);
      }
    }

    try {
      var newar = [];
      Newresults.sort((a, b) => a.box_number - b.box_number).map(
        //added sort
        async (item) => {
          let desc = await CreateDiscription(
            item.collection.boxes,
            item.airway_bill_no
          );
          let obj = {
            "BOX NO": desc[0],
            REFERENCE: desc[2],
            Pcs: desc[3],
            WEIGHT: desc[1],
            "DIMENSIONS (L X B X H)": desc[4],
            "SHIPPER ADDRESS": `${item.collection.sender_name?.toUpperCase()} , PHONE:${
              item.collection.sender_phone
            }   
              ${
                item.collection.sender_address
                  ? item.collection.sender_address.toUpperCase()
                  : ""
              }
              `,

            "CONSIGNEE ADDRESS": `${item.collection.reciver_name?.toUpperCase()},  ${item.collection.reciver_address?.toUpperCase()}  ${item.collection.reciver_distrcit?.toUpperCase()} ${item.collection.reciver_state?.toUpperCase()}  PIN :  ${
              item.collection.reciver_pincode
                ? item.collection?.reciver_pincode
                : ""
            }  ${
              item.collection.reciver_post
                ? item.collection?.reciver_post.toUpperCase()
                : ""
            }  PHONE : ${item.collection?.reciver_phone} , ${
              item.collection.reciver_altphone
                ? item.collection?.reciver_altphone
                : ""
            } ${
              item.collection.recivercountries &&
              item.collection.recivercountries.name?.toUpperCase()
            } `,
            STATE: item.collection.reciver_state?.toUpperCase(),
            DIST: item.collection.reciver_distrcit?.toUpperCase(),
          };
          newar.push(obj);
          let da = await newar;
          setData(da);
          setIsLoading(false);
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const CreateDiscription = async (item, val) => {
    try {
      let desc = " ";
      let totalWeight = 0;
      let ref = " ";
      let pieces = 0;
      let dimensions = "";
      item.map(async (ite) => {
        if (ite.airway_bill_no === val) {
          desc += ite?.box_number + ",";
          totalWeight += ite?.chargable_weight;
          ref += ite?.box_ref_no
            ? ite?.box_ref_no + ","
            : ite?.referance_details
            ? ite?.referance_details + ","
            : "";
          pieces++;
          dimensions += `${ite?.length} x ${ite?.breadth} x ${ite?.height},\n`;
        }
      });
      return [desc, totalWeight, ref, pieces, dimensions];
    } catch (err) {
      console.log(err);
    }
  };

  const downloadExcel = (data) => {
    const headerTitle = `${
      props.selected && props.selected.orijin_airport_name
    }     - ${props.selected && props.selected.destination_airport_name} - (${
      props.selected.airline
    } - ${props.selected.awb_no} )  (${props.selected.psc} PCS)`;
    const sheet = XLSX.utils.json_to_sheet([{}], {
      header: [headerTitle],
    });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.sheet_add_json(sheet, data, { origin: "A3" });
    XLSX.utils.book_append_sheet(workbook, sheet);
    XLSX.writeFile(
      workbook,
      `${props.selected.airline} - ${props.selected.awb_no} deliverylist.xls`
    );
  };

  return (
    <div>
      <Modal
        visible={props.visible}
        onCancel={props.onClose}
        width={300}
        footer={false}
      >
        <div>
          <Container>
            <br />
            <div className="print-manifest-box1">
              {isLoading ? (
                <>
                  <img src={Excel} className="print-manifest-img" />
                  <div>Fetching data....</div>
                  <div>Downloading ....</div>
                </>
              ) : (
                <>
                  {data && data.length ? (
                    <>
                      <img src={Excel} className="print-manifest-img" />
                      <div>File is ready For download</div>
                      <br />
                      {/* <CSVLink data={data}>
                        <Button
                          icon={<GrDocumentCsv style={styles.buttonsIcon} />}
                        >
                          Download
                        </Button>
                      </CSVLink> */}
                      <Button onClick={() => downloadExcel(data)}>
                        Download As Excel
                      </Button>
                    </>
                  ) : (
                    <>
                      <AiOutlineCloseCircle size={50} color={"red"} />
                      <div style={{ margin: 20 }} />
                      <div>No data found.</div>
                    </>
                  )}
                </>
              )}
              <br /> <br />
              <Button type="primary" onClick={props.onClose}>
                Close
              </Button>
            </div>
          </Container>
        </div>
      </Modal>
    </div>
  );
}

export default ExcelDeliveryList;

// import React, { useEffect, useState } from "react";
// import { Button, Modal, message } from "antd";
// import styles from "../styles";
// import { Container } from "react-bootstrap";
// import { CSVLink } from "react-csv";
// import Excel from "../../../Assets/Images/excel-icons-animation.gif";
// import { API, Headers } from "../../../Config";
// import axios from "axios";
// import { GrDocumentCsv } from "react-icons/gr";
// import { AiOutlineCloseCircle } from "react-icons/ai";
// import * as XLSX from "xlsx";

// function ExcelDeliveryList(props) {
//   const [data, setData] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   useEffect(() => {
//     loadBoxesViaAwb();
//   }, []);

//   const loadBoxesViaAwb = () => {
//     const AWBID = props.selected && props.selected.id;
//     let URL = API.BASR_URL + API.BOXES_VIA_AWB + `byColln/${AWBID}`;
//     axios
//       .get(URL, {
//         headers: Headers(),
//       })
//       .then(function (response) {
//         if (response.status === 200) {
//           let data = response.data;
//           Generatedata(data);
//         } else {
//           setIsLoading(false);
//           message.error("Oops.Something went");
//         }
//       });
//   };

//   const Generatedata = async (data) => {
//     let newArr = data;
//     let sorted_arr = newArr.slice().sort();
//     let results = [];
//     let Newresults = [];

//     for (let i = 0; i < sorted_arr.length; i++) {
//       if (sorted_arr[i + 1]?.collection_id == sorted_arr[i]?.collection_id) {
//         results.push(sorted_arr[i]);
//       } else {
//         Newresults.push(sorted_arr[i]);
//       }
//     }

//     try {
//       var newar = [];
//       Newresults.sort((a, b) => a.box_number - b.box_number).map(
//         //added sort
//         async (item) => {
//           let desc = await CreateDiscription(
//             item.collection.boxes,
//             item.airway_bill_no
//           );
//           let obj = {
//             "BOX NO": desc[0],
//             REFERENCE: desc[2],
//             Pcs: desc[3],
//             WEIGHT: desc[1],
//             "SHIPPER ADDRESS": `${item.collection.sender_name?.toUpperCase()} , PHONE:${
//               item.collection.sender_phone
//             }
//               ${
//                 item.collection.sender_address
//                   ? item.collection.sender_address.toUpperCase()
//                   : ""
//               }
//               `,

//             "CONSIGNEE ADDRESS": `${item.collection.reciver_name?.toUpperCase()},  ${item.collection.reciver_address?.toUpperCase()}  ${item.collection.reciver_distrcit?.toUpperCase()} ${item.collection.reciver_state?.toUpperCase()}  PIN :  ${
//               item.collection.reciver_pincode
//                 ? item.collection?.reciver_pincode
//                 : ""
//             }  ${
//               item.collection.reciver_post
//                 ? item.collection?.reciver_post.toUpperCase()
//                 : ""
//             }  PHONE : ${item.collection?.reciver_phone} , ${
//               item.collection.reciver_altphone
//                 ? item.collection?.reciver_altphone
//                 : ""
//             } ${
//               item.collection.recivercountries &&
//               item.collection.recivercountries.name?.toUpperCase()
//             } `,
//             STATE: item.collection.reciver_state?.toUpperCase(),
//             DIST: item.collection.reciver_distrcit?.toUpperCase(),
//           };
//           newar.push(obj);
//           let da = await newar;
//           setData(da);
//           setIsLoading(false);
//         }
//       );
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   const CreateDiscription = async (item, val) => {
//     try {
//       let desc = " ";
//       let totalWeight = 0;
//       let ref = " ";
//       let pieces = 0;
//       item.map(async (ite) => {
//         if (ite.airway_bill_no === val) {
//           desc += ite?.box_number + ",";
//           totalWeight += ite?.chargable_weight;
//           ref += ite?.box_ref_no
//             ? ite?.box_ref_no + ","
//             : ite?.referance_details
//             ? ite?.referance_details + ","
//             : "";
//           pieces++;
//         }
//       });
//       return [desc, totalWeight, ref, pieces];
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   const downloadExcel = (data) => {
//     const headerTitle = `${
//       props.selected && props.selected.orijin_airport_name
//     }     - ${props.selected && props.selected.destination_airport_name} - (${
//       props.selected.airline
//     } - ${props.selected.awb_no} )  (${props.selected.psc} PCS)`;
//     const sheet = XLSX.utils.json_to_sheet([{}], {
//       header: [headerTitle],
//     });
//     const workbook = XLSX.utils.book_new();
//     XLSX.utils.sheet_add_json(sheet, data, { origin: "A3" });
//     XLSX.utils.book_append_sheet(workbook, sheet);
//     XLSX.writeFile(
//       workbook,
//       `${props.selected.airline} - ${props.selected.awb_no} deliverylist.xls`
//     );
//   };

//   return (
//     <div>
//       <Modal
//         visible={props.visible}
//         onCancel={props.onClose}
//         width={300}
//         footer={false}
//       >
//         <div>
//           <Container>
//             <br />
//             <div className="print-manifest-box1">
//               {isLoading ? (
//                 <>
//                   <img src={Excel} className="print-manifest-img" />
//                   <div>Fetching data....</div>
//                   <div>Downloading ....</div>
//                 </>
//               ) : (
//                 <>
//                   {data && data.length ? (
//                     <>
//                       <img src={Excel} className="print-manifest-img" />
//                       <div>File is ready For download</div>
//                       <br />
//                       {/* <CSVLink data={data}>
//                         <Button
//                           icon={<GrDocumentCsv style={styles.buttonsIcon} />}
//                         >
//                           Download
//                         </Button>
//                       </CSVLink> */}
//                       <Button onClick={() => downloadExcel(data)}>
//                         Download As Excel
//                       </Button>
//                     </>
//                   ) : (
//                     <>
//                       <AiOutlineCloseCircle size={50} color={"red"} />
//                       <div style={{ margin: 20 }} />
//                       <div>No data found.</div>
//                     </>
//                   )}
//                 </>
//               )}
//               <br /> <br />
//               <Button type="primary" onClick={props.onClose}>
//                 Close
//               </Button>
//             </div>
//           </Container>
//         </div>
//       </Modal>
//     </div>
//   );
// }

// export default ExcelDeliveryList;

import React from "react";
import { Form, Modal, Input, Button } from "antd";
import { Row, Col } from "react-bootstrap";

function FormAddExpense(props) {
  const onFinish = (val) => {
    console.log("onFinish==>", val);
  };

  return (
    <div>
      <Modal visible={props.visible} onCancel={props.onCancel} footer={false}>
        <div>TRIP EXPENSES</div>
        <br />
        <div>
          <Form onFinish={onFinish} labelCol={{ span: 8 }}>
            <Form.Item name="voucher_no" label="VOUCHER NO">
              <Input />
            </Form.Item>
            <Form.Item name="date" label="DATE">
              <Input />
            </Form.Item>
            <Form.Item name="time" label="TIME">
              <Input />
            </Form.Item>
            <Form.Item name="logged_id" label="LOGGED ID">
              <Input />
            </Form.Item>
          </Form>
          <Row>
            <Col sm="6" xs="12"></Col>
            <Col sm="3" xs="3">
              <Button onClick={() => props.onClose()} block>
                Cancel
              </Button>
            </Col>
            <Col sm="3" xs="3">
              <Button type="primary" htmlType="submit" block>
                Submit
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
}

export default FormAddExpense;

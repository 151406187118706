import { Row, Col } from "react-bootstrap";
import { IconName } from "react-icons/io5";
function Delivery() {
  return (
    <div>
      <Row>
        <Col sm="3" xs="12">
          <div className="home-card">
            <div className="home-card-row">
              <div className="txt2 new-request-text">New Requests</div>
              <div className="new-request-icon"></div>
            </div>
            <br />
            <div className="txt5 new-request-count">1000</div>
          </div>
          <br />
        </Col>
        <Col sm="3" xs="12">
          <div className="home-card">
            <div className="home-card-row">
              <div className="txt2 new-request-text">New Requests</div>
              <div className="new-request-icon"></div>
            </div>
            <br />
            <div className="txt5 new-request-count">1000</div>
          </div>
        </Col>
        <Col sm="3" xs="12">
          <div className="home-card">
            <div className="home-card-row">
              <div className="txt2 new-request-text">New Requests</div>
              <div className="new-request-icon"></div>
            </div>
            <br />
            <div className="txt5 new-request-count">1000</div>
          </div>
        </Col>
        <Col sm="3" xs="12">
          <div className="home-card">
            <div className="home-card-row">
              <div className="txt2 new-request-text">New Requests</div>
              <div className="new-request-icon"></div>
            </div>
            <br />
            <div className="txt5 new-request-count">1000</div>
          </div>
        </Col>
        <Col sm="3" xs="12">
          <div className="home-card">
            <div className="home-card-row">
              <div className="txt2 new-request-text">New Requests</div>
              <div className="new-request-icon"></div>
            </div>
            <br />
            <div className="txt5 new-request-count">1000</div>
          </div>
        </Col>
        <Col sm="3" xs="12">
          <div className="home-card">
            <div className="home-card-row">
              <div className="txt2 new-request-text">New Requests</div>
              <div className="new-request-icon"></div>
            </div>
            <br />
            <div className="txt5 new-request-count">1000</div>
          </div>
        </Col>
        <Col sm="3" xs="12">
          <div className="home-card">
            <div className="home-card-row">
              <div className="txt2 new-request-text">New Requests</div>
              <div className="new-request-icon"></div>
            </div>
            <br />
            <div className="txt5 new-request-count">1000</div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
export default Delivery;

import React, { useState } from "react";
import {
  Drawer,
  Form,
  Button,
  Switch,
  message,
  InputNumber,
  Select,
} from "antd";
import { Container, Row, Col } from "react-bootstrap";
import { API, Headers } from "../../Config";
import axios from "axios";
var width = window.innerWidth;
var innerwidth = window.innerWidth <= 760;
function FormModal(props) {
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    let obj = {
      userid: Number(values.userid),
      active: values.active,
    };
    let URL =
      props.mode === "post"
        ? API.CUSTOMERS_ACTION
        : API.CUSTOMERS_ACTION + props.item.id;
    axios({
      method: props.mode,
      url: API.BASR_URL + URL,
      headers: Headers(),
      data: obj,
    })
      .then(function (response) {
        setLoading(false);
        if ([200, 201].includes(response.status)) {
          message.success("Success");
          props.onchange();
        }
      })
      .catch(function (error) {
        setLoading(false);
        message.error("Oops.Something went wrong");
        console.log(error);
      });
  };

  return (
    <Drawer
      placement={props.mode === "post" ? "right" : "left"}
      title={
        <span className="txt4">
          {props.mode === "post" ? "New" : "Update"} Agent
        </span>
      }
      width={innerwidth ? width : width / 2.7}
      onClose={() => props.onClose()}
      visible={props.visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form
        layout="vertical"
        hideRequiredMark
        onFinish={onFinish}
        initialValues={{
          userid:
            props.item && props.item.userid && props.item.userid.toString(),
         active: props.item && props.item.active ? props.item.active : false,
        }}
      >
        <Container>
          <Row>
            <Col sm="6" xs="12">
              <Form.Item
                name="userid"
                label="Name"
                rules={[{ required: true, message: "required" }]}
              >
                <Select placeholder="Select User">
                  {props.users.map((option) => (
                    <Select.Option key={option.id}>{option.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Active"
                name="active"
                rules={[{ required: true, message: "required" }]}
                valuePropName={props.item.active ? "checked" : "uncheked"}
              >
                <Switch />
              </Form.Item>
            </Col>

          </Row>
          <Row>
            {props.mode === "post" ? null : <Col sm="6" xs="12"></Col>}
            <Col sm="6" xs="12">
              <Row>
                <Col sm="6" xs="12">
                  <Button onClick={() => props.onClose()} block>
                    Cancel
                  </Button>
                </Col>
                <Col sm="6" xs="12">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    block
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Form>
    </Drawer>
  );
}
export default FormModal;

import React, { useState, useEffect } from "react";
import PageHeader from "../../Routes/PageHeader";
import FormModal from "./FormModal";
import DataTable from "./DataTable";
import axios from "axios";
import { message, Modal, Form, Select, notification } from "antd";
import { API, Headers } from "../../Config";
import LoadingBox from "../../Components/LoadingBox";
import { Input } from "antd";

function UsersScreen() {
  const [loading, setLoading] = useState(true);
  const [indicator, setIndicator] = useState(false);
  const [form, setform] = useState(false);
  const [mode, setMode] = useState("post");
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({});
  const [item, setItem] = useState({});
  const [countries, setcountries] = useState([]);
  const [branches, setbranches] = useState([]);
  const [role, setRole] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [userID, setUserID] = useState(null);
  const { Search } = Input;

  useEffect(() => {
    loaddata(page, take);
    ContriesDropdown();
    BranchesDropdown();
    RoleDropdown();
    AccountsDropdown();
  }, []);

  const loaddata = (p, t) => {
    axios
      .get(API.BASR_URL + API.USERS_LIST + `?order=DESC&page=${p}&take=${t}`, {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setData(data.data);
          setMeta(data.meta);
          message.success("Success");
        } else {
          message.error("Oops.Something went wrong");
        }
        setLoading(false);
        setIndicator(false);
      })
      .catch(function (error) {
        message.error("Oops.Something went wrong");
        setIndicator(false);
        setLoading(false);
      });
  };
  const AccountsDropdown = () => {
    axios
      .get(API.BASR_URL + API.CHART_OF_ACCOUNTS_LIST + `/child_accounts`, {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) setAccounts(res.data);
      })
      .catch(function (error) {
        message.error("Customers dropdown faild");
      });
  };
  const BranchesDropdown = () => {
    axios
      .get(API.BASR_URL + API.BRANCHES_LIST + `/list`, {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) setbranches(res.data);
      })
      .catch(function (error) {
        message.error("Contries dropdown faild");
      });
  };

  const RoleDropdown = () => {
    axios
      .get(API.BASR_URL + API.ROLE_LIST + `/list`, {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setRole(data);
        }
      });
  };

  const pagination = (page, take) => {
    window.scrollTo(0, 0);
    setIndicator(true);
    setPage(page);
    setTake(take);
    loaddata(page, take);
  };

  const relaod = () => {
    loaddata(page, take);
    setMode("post");
    setItem({});
    setform(false);
  };

  const AddItem = () => {
    setMode("post");
    setItem({});
    setform(true);
  };

  const UpdateItem = (item) => {
    setMode("put");
    setItem(item);
    setform(true);
  };

  const accountHandleClick = (val) => {
    setIsModalVisible(true);
    setUserID(val);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    let obj = {
      id: userID,
      account_no: selectedAccount,
    };

    axios({
      method: "post",
      url: API.BASR_URL + API.USERS_ACTIONS + `updateAccount`,
      headers: Headers(),
      data: obj,
    })
      .then(function (response) {
        setLoading(false);
        if ([200, 201].includes(response.status)) {
          message.success("Success");
          setIsModalVisible(false);
          relaod();
        }
      })
      .catch(function (error) {
        setLoading(false);
        message.error("Oops.Something went wrong");
        console.log(error);
        error.response.data.message &&
          error?.response?.data?.message.map((item) => {
            notification.open({
              message: "ERROR",
              description: `${item}`,
            });
          });
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const ContriesDropdown = () => {
    axios
      .get(API.BASR_URL + API.COUNTRIES_LIST + `/list`, {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) {
          setcountries(res.data);
        }
      })
      .catch(function (error) {
        message.error("Contries dropdown faild");
      });
  };
  const DeleteItem = (item) => {
    setIndicator(true);
    axios({
      url: API.BASR_URL + API.USERS_ACTIONS + item.id,
      method: "put",
      headers: Headers(),
      data: {
        active: false,
      },
    })
      .then(function (response) {
        setIndicator(false);
        message.success("Deleted Successfully");
        relaod();
      })
      .catch(function (error) {
        setIndicator(false);
        message.error("Oops.Something went wrong");
        console.log(error);
      });
  };
  const modalContent = (
    <div>
      <Form>
        <Form.Item name="account_no">
          <Select
            size="small"
            placeholder="Select account"
            showSearch
            allowClear
            style={{ borderRadius: "0", width: "300px" }}
            onChange={(selectedValue) => {
              // Check if selectedValue is defined and not null
              if (selectedValue !== undefined && selectedValue !== null) {
                console.log(selectedValue); // Log the selected value
                setSelectedAccount(selectedValue); // Set the selected value in your component's state
              }
            }}
            filterOption={(input, option) => {
              const accountNo = option.value.toLowerCase();
              const accountName = option.children.toString().toLowerCase();
              return (
                accountNo.includes(input.toLowerCase()) ||
                accountName.includes(input.toLowerCase())
              );
            }}
          >
            {accounts.map((item, index) => (
              <Select.Option key={item.account_no} value={item.account_no}>
                {item.account_no} - {item.account_name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {/* Add your form inputs or other content here */}
      </Form>
    </div>
  );

  // const handleSearch = (value) => {
  //   console.log(value);
  // };

  const handleSearch = (value) => {
    setLoading(true);
    axios
      .post(
        API.BASR_URL + `users/search`,
        { query: value },
        {
          headers: Headers(),
        }
      )
      .then(function (response) {
        if (response) {
          setData(response?.data?.data);
          // message.success("Search successful");
        } else {
          message.error("Not fount");
        }
        setLoading(false);
        setIndicator(false);
      })
      .catch(function (error) {
        message.error("Oops. Something went wrong");
        setIndicator(false);
        setLoading(false);
      });
  };

  return (
    <div>
      <PageHeader
        PageName={"Users"}
        breadScrum={"Admin / Users"}
        RightButton={"New Users "}
        RightClcik={() => AddItem()}
        loader={indicator}
      />

      <div style={{ justifyContent: "flex-end", display: "flex" }}>
        <Search
          style={{ width: "300px" }}
          placeholder="Input search text"
          size="medium"
          allowClear
          onSearch={handleSearch}
        />
      </div>
      <br />
      <br />

      {loading ? (
        <LoadingBox />
      ) : (
        <div>
          <DataTable
            accountHandleClick={(val) => accountHandleClick(val)}
            meta={meta}
            data={data}
            update={(item) => UpdateItem(item)}
            delete={(item) => DeleteItem(item)}
            PageOnchange={(page, take) => pagination(page, take)}
          />
          {form ? (
            <FormModal
              item={item}
              mode={mode}
              visible={form}
              countries={countries}
              branches={branches}
              role={role}
              onchange={() => relaod()}
              onClose={() => setform(false)}
            />
          ) : null}
        </div>
      )}
      {isModalVisible && (
        <Modal
          title="Example Modal"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          {modalContent}
        </Modal>
      )}
    </div>
  );
}
export default UsersScreen;

import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import moment from "moment";
import logo from "../../Assets/Images/logoNew.jpg";
import MontserratMedium from "../../Assets/Fonts/Montserrat-Medium.ttf";
import MontserratBold from "../../Assets/Fonts/Montserrat-Bold.ttf";
import MontserratRegular from "../../Assets/Fonts/Montserrat-Regular.ttf";

var QRCodes = require("qrcode");
var JsBarcode = require("jsbarcode");

function PdfTaxInvoice(props) {
  return (
    <Document>
      {props.data &&
        props.data.map((item, index) => {
          let data = item && item.box_number;
          let canvas;
          canvas = document.createElement("canvas");
          QRCodes.toCanvas(canvas, data);
          const qrcode = canvas.toDataURL();

          canvas = document.createElement("canvas");
          JsBarcode(canvas, data, {
            width: 10,
            height: 300,
          });
          const barcode = canvas.toDataURL();
          return (
            <Page size="A4" wrap style={styles.Page}>
              <View style={styles.Table}>
                <View style={styles.Row}>
                  <View style={styles.Col}>
                    <View style={styles.LogoBox}>
                      <Image src={logo} style={styles.Logo} />
                    </View>
                  </View>
                  <View style={styles.Col2}>
                    <Text style={styles.Text1}>
                      {" "}
                      HO:{" "}
                      {item.created_user && item.created_user.branch
                        ? `${
                            (item.created_user.branch?.name,
                            item.created_user.branch?.office_address)
                          }`
                        : null}{" "}
                    </Text>
                    <Text style={styles.Text1}>
                      {" "}
                      PH:
                      {item.created_user && item.created_user.branch
                        ? `${item.created_user.branch?.mobile}`
                        : null}
                    </Text>
                    <Text style={styles.Text1}> VAT NO: 300602378400003</Text>
                  </View>
                </View>
                <View style={styles.Row}>
                  <View style={styles.Col3}>
                    <Text style={styles.Text2}>TAX INVOICE</Text>
                  </View>
                </View>
                <View style={styles.Row}>
                  <View style={styles.Col}>
                    <View>
                      <Text style={styles.Text3}> SENDER INFO</Text>
                      <Text style={styles.Text4}>
                        {item.collection && item.collection.sender_name}
                      </Text>
                      <Text style={styles.Text4}>
                        {item.collection && item.collection.sender_address}
                      </Text>
                      <Text style={styles.Text4}>
                        {item.collection && item.collection.sender_phone}
                      </Text>
                    </View>
                    <View>
                      <Text style={styles.Text3}> RECIEVER INFO</Text>
                      <Text style={styles.Text4}>
                        {item.collection && item.collection.reciver_name}
                      </Text>
                      <Text style={styles.Text4}>
                        {item.collection && item.collection.reciver_address}
                      </Text>
                      <Text style={styles.Text4}>
                        {item.collection && item.collection.reciver_phone},
                        {item.collection && item.collection.reciver_altphone}
                      </Text>
                      <Text style={styles.Text4}>
                        {item.collection && item.collection.reciver_pincode},
                        {""}
                        {item.collection && item.collection.reciver_city}
                      </Text>
                      <Text style={styles.Text4}>
                        {item.collection && item.collection.reciver_distrcit},
                        {""}
                        {item.collection && item.collection.reciver_state}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.Col}>
                    <View style={styles.QrBOX}>
                      <Image src={qrcode} style={styles.QR} />
                    </View>
                  </View>
                  <View style={styles.Col2}>
                    <Text style={styles.Text5}>
                      <Text style={styles.Text7}> INVOICE NO:</Text> {"  "}
                      INV{moment().unix()}
                    </Text>
                    <Text style={styles.Text5}>
                      <Text style={styles.Text7}> INVOICE DATE:</Text>{" "}
                      {item && moment().format("DD-MM-YYYY")}
                    </Text>
                    <Text style={styles.Text5}>
                      <Text style={styles.Text7}> BOOKED STAFF: </Text>{" "}
                      {item.created_user && item.created_user.name}
                    </Text>
                    <Text style={styles.Text5}>
                      <Text style={styles.Text7}> BOOKED BRANCH: </Text>{" "}
                      {item.created_user &&
                        item.created_user.branch &&
                        item.created_user.branch.name}
                    </Text>
                    <Text style={styles.Text5}>
                      <Text style={styles.Text7}> BOOKED DATE: </Text>{" "}
                      {item && moment(item.created_at).format("DD-MM-YYYY")}
                    </Text>
                    <Text style={styles.Text5}>
                      <Text style={styles.Text7}> REFRENCE: </Text>{" "}
                      {item.collection.referance_details &&
                        item.collection.referance_details}
                    </Text>
                    <Text style={styles.Text5}>
                      <Text style={styles.Text7}> BOX SIZE: </Text> {"  "}
                      {item.boxType && item.boxType.name}{" "}
                      {item &&
                        `${item.length} x ${item.breadth} x ${item.height}`}
                    </Text>
                    <Text style={styles.Text5}>
                      <Text style={styles.Text7}> CHARGABLE WEIGHT: </Text>{" "}
                      {"  "}
                      {item && item.chargable_weight}
                    </Text>
                    <Text style={styles.Text5}>
                      <Text style={styles.Text7}> RATE PER KILO: </Text>{" "}
                      {item && item.rate}
                    </Text>
                    <Text style={styles.Text5}>
                      <Text style={styles.Text7}> PACKING CHARGE: </Text>{" "}
                      {item && item.packing_charge}
                    </Text>
                    <Text style={styles.Text5}>
                      <Text style={styles.Text7}> DUTY: </Text>{" "}
                      {item && item.duty}
                    </Text>
                    <Text style={styles.Text5}>
                      <Text style={styles.Text7}> OTHER: </Text>{" "}
                      {item && item.other_charges}
                    </Text>
                    <Text style={styles.Text5}>
                      <Text style={styles.Text7}> AMOUNT: </Text>{" "}
                      {item && item.total_charge}
                    </Text>
                    <Text style={styles.Text5}>
                      <Text style={styles.Text7}> VAT: </Text> {"  "}0
                    </Text>
                    <Text style={styles.Text5}>
                      <Text style={styles.Text7}> TOTAL:</Text> {"  "}
                      {item && item.total_charge}
                    </Text>
                  </View>
                </View>
                <View style={styles.HR}></View>
                <View style={styles.Row}>
                  <View style={styles.Col}>
                    <View>
                      <Image src={barcode} />
                    </View>
                  </View>
                  <View style={styles.Col}></View>
                  <View style={styles.Col}>
                    <View>
                      <Text style={styles.Text6}>
                        {item.service && item.service.name}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </Page>
          );
        })}
    </Document>
  );
}

export default PdfTaxInvoice;

const styles = StyleSheet.create({
  Page: {
    padding: 20,
  },
  Table: {
    display: "table",
    tableLayout: "fixed",
    width: "100%",
  },
  Row: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  Col: {
    flex: 1,
  },
  Col2: {
    flex: 1,
    // textAlign: "right",
  },
  Col3: {
    flex: 1,
    textAlign: "center",
  },
  LogoBox: {
    width: "100%",
    height: "90px",
    objectFit: "contain",
  },
  Logo: {
    width: "190px",
    height: "50px",
  },
  Text1: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    textTransform: "uppercase",
  },
  Text2: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  Text3: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    textTransform: "uppercase",
    textAlign: "left",
    marginBottom: 3,
  },
  Text4: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: 200,
    textTransform: "uppercase",
    textAlign: "left",
    marginBottom: 2,
  },
  Text5: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    textTransform: "uppercase",
  },
  Text6: {
    fontFamily: "Montserrat",
    fontSize: "40px",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  Text7: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    textTransform: "uppercase",
    fontWeight: 200,
    textAlign: "justify",
  },
  HR: {
    marginTop: "10px",
    borderBottom: "1px solid black",
    marginBottom: "25px",
  },
  QrBOX: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    alignSelf: "center",
    textAlign: "center",
  },
  QR: {
    width: "100px",
    height: "100px",
  },
});

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratRegular,
      fontWeight: 200,
    },
    {
      src: MontserratMedium,
    },
    { src: MontserratBold, fontWeight: "bold" },
  ],
});

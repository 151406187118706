import React, { useEffect, useState } from "react";
import { Button, Modal, message } from "antd";
import { Container } from "react-bootstrap";
import axios from "axios";
import { AiOutlineCloseCircle } from "react-icons/ai";
import * as XLSX from "xlsx";
import { API, Headers } from "../../../Config";
import Excel from "../../../Assets/Images/excel-icons-animation.gif";
import moment from "moment";

function ExcelTaxInvoice(props) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadBoxesViaAwb();
  }, []);

  const loadBoxesViaAwb = () => {
    const AWBID = props.selected && props.selected.id;
    let URL = API.BASR_URL + API.BOXES_VIA_AWB + AWBID;
    axios
      .get(URL, {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          Generatedata(data);
        } else {
          setIsLoading(false);
          message.error("Oops.Something went");
        }
      });
  };

  const Generatedata = async (data) => {
    try {
      var newar = [];
      props.formdata.rate &&
        data.map(async (item, index) => {
          let Rate = await props.formdata.rate;
          let Charge = (Number(item.chargable_weight) * Number(Rate)).toFixed(
            2
          );
          let GST = (Number(Charge) * 0.18).toFixed(2);
          let halfGST = Number(GST / 2);
          let TotalCharge = (Number(Charge) + Number(GST)).toFixed(2);
          let RoundOff = (Number(TotalCharge) % 1).toFixed(2);
          let TOTAL = TotalCharge - RoundOff;

          let obj = {
            "Sl.No": index + 1,
            "Customer Name": item?.iqama_name?.toUpperCase(),
            "In.No": item?.box_number && item?.box_number,
            Date: moment(props.formdata.date).format("l"),
            "Rate of Tax": "18%",
            "Non Taxable value": "0",
            "Taxable Value": (
              Number(item.chargable_weight) *
              Number(props.formdata.rate && props.formdata.rate)
            ).toFixed(2),
            CGST: (
              (Number(item.chargable_weight) *
                Number(props.formdata.rate) *
                0.18) /
              2
            ).toFixed(2),
            SGST: (
              (Number(item.chargable_weight) *
                Number(props.formdata.rate) *
                0.18) /
              2
            ).toFixed(2),
            KFC: 0,
            "Round Off": (
              (Number(item.chargable_weight) * Number(props.formdata.rate) +
                Number(item.chargable_weight) *
                  Number(props.formdata.rate) *
                  0.18) %
              1
            ).toFixed(2),
            "Net Taxable": TOTAL,
          };
          newar.push(obj);
        });
      let da = await newar;
      setData(da);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };
  const downloadExcel = (data) => {
    let TotaltaxableValue = data.reduce(function (tot, arr) {
      return Number(tot) + Number(arr["Taxable Value"]);
    }, 0);

    let TotalCGST = data.reduce(function (tot, arr) {
      return Number(tot) + Number(arr.CGST);
    }, 0);

    let TotalRoundOff = data.reduce(function (tot, arr) {
      return Number(tot) + Number(arr["Round Off"]);
    }, 0);

    let TotalNetTaxable = data.reduce(function (tot, arr) {
      return Number(tot) + Number(arr["Net Taxable"]);
    }, 0);

    // Add a new row to the data with the sum of the ages
    data.push({
      "Sl.No": "",
      "Customer Name": "",
      "In.No": "",
      "Rate of Tax": "",
      "Non Taxable value": "0",
      "Taxable Value": TotaltaxableValue,
      CGST: TotalCGST,
      SGST: TotalCGST,
      KFC: "0",
      "Round Off": TotalRoundOff,
      "Net Taxable": TotalNetTaxable,
    });

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, "GST BILL");
    XLSX.writeFile(
      workbook,
      `${props.selected.airline} - ${props.selected.awb_no} ${moment(
        props.formdata.date
      ).format("MMM")}.xls`
    );
  };

  return (
    <div>
      <Modal
        visible={props.visible}
        onCancel={props.onCancel}
        width={300}
        footer={false}
      >
        <div>
          <Container>
            <br />
            <div className="print-manifest-box1">
              {isLoading ? (
                <>
                  <img src={Excel} className="print-manifest-img" />
                  <div>Fetching data....</div>
                  <div>Downloading ....</div>
                </>
              ) : (
                <>
                  {data && data.length ? (
                    <>
                      <img src={Excel} className="print-manifest-img" />
                      <div>File is ready For download</div>
                      <br />
                      <Button onClick={() => downloadExcel(data)}>
                        Download As Excel
                      </Button>
                    </>
                  ) : (
                    <>
                      <AiOutlineCloseCircle size={50} color={"red"} />
                      <div style={{ margin: 20 }} />
                      <div>No data found.</div>
                    </>
                  )}
                </>
              )}
              <br /> <br />
              <Button type="primary" onClick={props.onCancel}>
                Close
              </Button>
            </div>
          </Container>
        </div>
      </Modal>
    </div>
  );
}

export default ExcelTaxInvoice;

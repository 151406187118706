import React, { useState } from "react";
import { Input, message } from "antd";
import axios from "axios";
import { API, Headers } from "../../Config";
import PageHeader from "../../Routes/PageHeader";
import "./styles.css";
import LoadingBox from "../../Components/LoadingBox";
import TrackItem from "./trackItem";
import Accordion from "react-bootstrap/Accordion";
import TrackBoxDetails from "./trackBoxDetails";
import { IoSearchOutline } from "react-icons/io5";
import { FiCopy } from "react-icons/fi";
import { FaCheckCircle } from "react-icons/fa";

const { Search } = Input;

function DetailedTracking() {
  const [boxdata, setBoxdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);

  const onSearch = (txt) => {
    if (txt) {
      loadData(txt);
      setLoading(true);
    } else {
      setBoxdata([]);
    }
  };

  const loadData = (id) => {
    try {
      axios
        .get(API.BASR_URL + API.BOXES_LIST + `/box_status/` + id, {
          headers: Headers(),
        })
        .then(function (response) {
          if (response?.status === 200) {
            let data = response?.data;
            setBoxdata(data);
            setLoading(false);
          }
        })
        .catch(function (error) {
          setBoxdata([]);
          console.log(error);
        });
    } catch (err) {
      setBoxdata([]);
      console.log(err);
    }
  };
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    message.success("Box Number Copied");
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };
  return (
    <div>
      <PageHeader PageName={"Tracking"} breadScrum={"Admin / Tracking"} />
      <div
        className="detailed-tracking-saerch-box"
        style={{
          marginTop: boxdata?.length ? 0 : 200,
        }}
      >
        <Search
          autoFocus={true}
          style={{ width: "500px" }}
          placeholder="Search Box number"
          allowClear
          enterButton="Search"
          size="large"
          onSearch={(txt) => onSearch(txt)}
        />
      </div>
      <br /> <br />
      <br />
      {loading ? (
        <LoadingBox />
      ) : (
        <div>
          {boxdata?.length ? (
            <Accordion>
              {boxdata?.map((data, i) => {
                return (
                  <Accordion.Item eventKey={data?.box_number}>
                    <Accordion.Header>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          width: 250,
                        }}
                      >
                        <span
                          className="copy-icon"
                          onClick={() => handleCopy(data?.box_number)}
                        >
                          {Number(copied) === Number(data?.box_number) ? (
                            <FaCheckCircle size={20} color="#1e88e5" />
                          ) : (
                            <FiCopy size={20} color="#1e88e5" />
                          )}
                        </span>
                        <div className="head-txt">
                          {i + 1}. {data?.box_number}
                        </div>

                        <div className="head-txt">-</div>

                        <div className="head-txt">
                          {data?.service?.name?.toUpperCase()}
                        </div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <TrackBoxDetails data={data} />
                      <br />
                      <div>
                        <b>- Box History</b>
                        <br />
                      </div>
                      <TrackItem data={data} />
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          ) : (
            <div className="no-data">
              <IoSearchOutline color="#c2c2c2" size={50} />
              <div
                style={{
                  color: "#a3a3a3",
                  fontSize: "16px",
                  marginTop: "10px",
                }}
              >
                Search Box
              </div>
            </div>
          )}
        </div>
      )}
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}

export default DetailedTracking;

import React, { useState } from "react";
import {
  Drawer,
  Form,
  Button,
  Input,
  Switch,
  message,
  Select,
  Upload,
} from "antd";
import { Container, Row, Col } from "react-bootstrap";
import { API, Headers } from "../../Config";
import S3con from "../../Config/S3con";
import axios from "axios";
import styles from "./style";
import { uploadFile, deleteFile } from "react-s3";
import moment from "moment";
import "./styles.css";
var width = window.innerWidth;
var innerwidth = window.innerWidth <= 760;

const config = {
  bucketName: S3con.bucketName,
  region: S3con.region,
  accessKeyId: S3con.accessKeyId,
  secretAccessKey: S3con.secretAccessKey,
};

function FormModal(props) {
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState();
  const [logourl, setLogoUrl] = useState();

  const onFinish = (values) => {
    setLoading(true);
    console.log(values);
    let obj = {
      name: values.name,
      code: values.code,
      note: "note",
      email: values.name + "@gmail.com",
      airline_no: values.airline_no,
      county_name: values.county_name,
      airline_address: values.airline_address,
      airline_logo: logourl,
      active: values.active,
    };
    let URL =
      props.mode === "post"
        ? API.AIRLINE_ACTIONS
        : API.AIRLINE_ACTIONS + props.item.airline_no;
    axios({
      method: props.mode,
      url: API.BASR_URL + URL,
      headers: Headers(),
      data: obj,
    })
      .then(function (response) {
        setLoading(false);
        if ([200, 201].includes(response.status)) {
          message.success("Success");
          props.onchange();
        }
      })
      .catch(function (error) {
        setLoading(false);
        message.error("Oops.Something went wrong");
        console.log(error);
      });
  };
  const uploadLogo = (e) => {
    setLogo(e.file);
    handleUpload(e.target.files[0]);
  };

  const handleUpload = async (file) => {
    let File = file;
    console.log(File);
    console.log("uploading...");
    uploadFile(File, config)
      .then((data) => {
        setLogoUrl(data.location);
        console.log(data);
        message.success("Logo Uploaded Successfully...");
      })
      .catch((err) => console.error(err));
  };
  return (
    <Drawer
      placement={props.mode === "post" ? "right" : "left"}
      title={
        <span className="txt4">
          {props.mode === "post" ? "New" : "Update"} Airline
        </span>
      }
      width={innerwidth ? width : width / 2.7}
      onClose={() => props.onClose()}
      visible={props.visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      {props.mode === "put" ? (
        <div className="airline-form-airline-logo-container">
          <img src={props.item && props.item.airline_logo} width="50%" />
        </div>
      ) : null}

      <Form
        layout="vertical"
        hideRequiredMark
        onFinish={onFinish}
        initialValues={{
          name: props.item && props.item.name,
          code: props.item && props.item.code,
          note: props.item && props.item.note,
          email: props.item && props.item.email,
          airline_no: props.item && props.item.airline_no,
          county_name: props.item && props.item.county_name,
          airline_address: props.item && props.item.airline_address,
          airline_logo: props.item && props.item.airline_logo,
          active: props.item && props.item.active ? props.item.active : false,
        }}
      >
        <Container>
          <Row>
            <Col sm="6" xs="12">
              <Form.Item
                name="code"
                label={<div className="form-item-label">Airline Code</div>}
                rules={[{ required: true, message: "required" }]}
              >
                <Input placeholder="Enter Code" style={styles.FormItemInput} />
              </Form.Item>
            </Col>
            <Col sm="6" xs="12">
              <Form.Item
                name="name"
                label={<div className="form-item-label">Airline Name</div>}
                rules={[{ required: true, message: "required" }]}
              >
                <Input placeholder="Enter Name" style={styles.FormItemInput} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                name="airline_no"
                label={<div className="form-item-label">Airline Number</div>}
                rules={[{ required: true, message: "required" }]}
              >
                <Input
                  placeholder="Enter Airline Number"
                  style={styles.FormItemInput}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Form.Item
              name="county_name"
              label={<div className="form-item-label">Country name</div>}
              rules={[{ required: false, message: "required" }]}
            >
              <Select
                placeholder="Select Country"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {props.countries.map((option) => (
                  <Select.Option key={option.code} value={option.name}>
                    {option.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Row>
          <Row>
            <Form.Item
              name="airline_address"
              label={<div className="form-item-label">Airline Address</div>}
            >
              <Input.TextArea
                placeholder="Enter Airline Address"
                style={styles.FormItemInput}
              />
            </Form.Item>
          </Row>
          <Row>
            <Col sm="6" xs="12">
              <Form.Item
                name=""
                label={
                  <div className="form-item-label">Upload Airline Logo</div>
                }
                style={{ width: "100%" }}
              >
                <input type="file" accept="image/*" onChange={uploadLogo} />
              </Form.Item>
            </Col>
            <Col sm="6" xs="12">
              <Form.Item
                label={<div className="form-item-label">Active</div>}
                name="active"
                rules={[{ required: true, message: "required" }]}
                valuePropName={props.item.active ? "checked" : "uncheked"}
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            {props.mode === "post" ? null : <Col sm="6" xs="12"></Col>}
            <Col sm="6" xs="12">
              <Row>
                <Col sm="6" xs="12">
                  <Button onClick={() => props.onClose()} block>
                    Cancel
                  </Button>
                </Col>
                <Col sm="6" xs="12">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    block
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Form>
    </Drawer>
  );
}
export default FormModal;

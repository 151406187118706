import { Table, Pagination, Button, Popconfirm, message } from "antd";
import { MdOutlineDelete, MdContentCopy } from "react-icons/md";
function DataTable(props) {
  const columns = [
    {
      title: "#",
      key: "index",
      width: "20px",
      //  fixed: 'left',
      render: (text, record, index) =>
        (props.meta.page - 1) * props.meta.take + index + 1,
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (id) => (
        <Button type="dashed" shape="circle" size="small">
          {id}
        </Button>
      ),
    },
    {
      title: "Series No",
      dataIndex: "series_no",
      key: "series_no",
      render: (series_no) => (
        <div>
          <Button
            type="link"
            size="small"
            onClick={() => {
              navigator.clipboard.writeText(series_no);
              message.success("copied");
            }}
          >
            <MdContentCopy size={17} />
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          {series_no}
        </div>
      ),
    },
    {
      title: "",
      render: (item) => (
        <div className="tableAction">
          <Popconfirm
            placement="bottomRight"
            title={"Are you sure to delete?"}
            okText="Yes"
            cancelText="No"
            onConfirm={() => props.delete(item)}
          >
            <Button size="small" type="link">
              <MdOutlineDelete size={25} />
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];
  return (
    <div style={{ minHeight: "60vh" }}>
      <Table
        columns={columns}
        dataSource={props.data}
        pagination={false}
        size="small"
      />
      <br />
      <div align="right">
        <Pagination
          responsive
          defaultCurrent={props.meta && props.meta.page}
          total={props.meta && props.meta.itemCount}
          pageSize={props.meta && props.meta.take ? props.meta.take : 0}
          onChange={props.PageOnchange}
          pageSizeOptions={[10, 20, 50]}
        />
      </div>
    </div>
  );
}
export default DataTable;

import React, { useEffect } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
var JsBarcode = require("jsbarcode");
function PdfAirwayBill() {
  return (
    <Document>
      <Page size="A4" wrap>
        <View>
          <View style={styles.Table}>
            <View>
              <View style={styles.TableRow}>
                <View style={styles.Col}>
                  {/* <View style={TableRow2}></View> */}
                </View>
                <View style={styles.Col}>x</View>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}

export default PdfAirwayBill;
const styles = StyleSheet.create({
  Table: {
    margin: 10,
    borderColor: "#000",
    borderWidth: 0.5,
  },
  TableRow: {
    display: "flex",
    flexDirection: "row",
    borderBottomWidth: 0.3,
  },
  Col: {
    flex: 1,
    borderRightWidth: 0.3,
  },
});

const styles = {
  FormItem: {
    margin: -3,
    marginTop: -7,
    marginBottom: -7,
    padding: 0,
    marginLeft: -7,
    marginRight: -7,
  },
  FormInput: {
    borderRdius: 0,
    width: "100%",
  },
  label: {
    textAlign: "right",
    fontWeight: "bold",
  },
};
export default styles;

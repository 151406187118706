import React, { useState, useEffect } from "react";
import PageHeader from "../../Routes/PageHeader";
import DataTable from "./DataTable";
import axios from "axios";
import { message, Input, Select } from "antd";
import { API, Headers } from "../../Config";
import LoadingBox from "../../Components/LoadingBox";
import { useSelector } from "react-redux";
import { AiOutlineSearch } from "react-icons/ai";
import { FaFilter } from "react-icons/fa";
import "./style.css";

function Stock() {
  const [loading, setLoading] = useState(true);
  const [indicator, setIndicator] = useState(true);
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({});
  const [filter, setFilter] = useState(false);
  const [serviceList, setServiceList] = useState([]);
  const Auth = useSelector((state) => state.Auth.user);
  const { Option } = Select;

  useEffect(() => {
    loaddata(page, take);
    loadSerivetype();
  }, []);

  const loaddata = (p, t) => {
    axios
      .post(
        API.BASR_URL +
          API.BOXES_VIA_BRANCH_ID +
          `?branch_id=${Auth?.branch_id}&order=DESC&page=${p}&take=${t}`,
        {
          headers: Headers(),
        }
      )
      .then(function (response) {
        if (response.status === 201) {
          let data = response.data.data;
          let meta = response.data.meta;
          setData(data);
          setMeta(meta);
          message.success("Success");
        } else {
          message.error("Oops.Something went wrong");
        }
        setLoading(false);
        setIndicator(false);
      })
      .catch(function (error) {
        message.error("Oops.Something went wrong");
      });
  };

  const pagination = (page, take) => {
    window.scrollTo(0, 0);
    setIndicator(true);
    setPage(page);
    setTake(take);
    loaddata(page, take);
  };
  const onSearch = (val) => {
    let reqObj = {
      box_number: val,
    };
    axios
      .post(
        API.BASR_URL +
          API.BOXES_VIA_BRANCH_ID +
          `?branch_id=${Auth?.branch_id}&order=DESC&page=${page}&take=${take}`,
        reqObj,
        {
          headers: Headers(),
        }
      )
      .then((response) => {
        if (response.status === 201) {
          setIndicator(false);
          setData(response.data.data);
          message.success("success");
        }
      })
      .catch((err) => {
        message.error(err);
        console.log(err);
      });
  };
  const loadSerivetype = () => {
    let url = API.BASR_URL + API.SERVICE_TYPE_LIST + `/list`;
    axios
      .get(url)
      .then((response) => {
        setIndicator(false);
        setServiceList(response?.data);
      })
      .catch((err) => {
        message.error(err);
        console.log(err);
      });
  };

  const handleServiceTypeChange = (value) => {
    let reqObj = {
      service_type: value,
    };
    axios
      .post(
        API.BASR_URL +
          API.BOXES_VIA_BRANCH_ID +
          `?branch_id=${Auth?.branch_id}&order=DESC&page=${page}&take=${take}`,
        reqObj,
        { headers: Headers() }
      )
      .then((response) => {
        setLoading(false);
        setIndicator(false);
        setData(response?.data?.data);
        message.success("success");
      })
      .catch((err) => {
        message.error(err);
        console.log(err);
      });
  };
  //   let url = API.BASR_URL + API.BOXES_VIA_BRANCH_ID + `?branch_id=${Auth?.branch_id}&order=DESC&page=${page}&take=${take}`,

  //   axios.post(url, reqObj).then((response) => {
  //     console.log("----response--->>>", response);
  //   });
  //   console.log("--url", url);
  // };

  return (
    <div>
      <PageHeader
        PageName={"STOCK"}
        breadScrum={"Admin / Stock"}
        loader={indicator}
      />
      <div>Total {meta.itemCount} Stocks</div>

      <div className="stock-Searchmain">
        {filter ? (
          <>
            <Select
              showSearch
              placeholder="Select Service Type"
              style={{ width: 300, borderRadius: 6, marginRight: 10 }}
              filterOption={(input, option) =>
                (option.label ?? "").toLowerCase().includes(input.toLowerCase())
              }
              onChange={(val) => handleServiceTypeChange(val)}
            >
              {serviceList.map((item) => (
                <Select.Option
                  key={item?.id}
                  value={item?.id}
                  label={item?.name}
                >
                  {item?.name}
                </Select.Option>
              ))}
            </Select>
          </>
        ) : (
          ""
        )}
        <div className="stock-searchinput">
          <Input
            allowClear
            placeholder="Search Box"
            // onPressEnter={(val) => onSearchBox(val.target.value)}
            onPressEnter={(val) => onSearch(val.target.value)}
            prefix={<AiOutlineSearch color="#999" />}
            style={{ borderRadius: 6, width: 300 }}
          />
          <div style={{ margin: 5 }} />
          <div className="stock-filter" onClick={() => setFilter(!filter)}>
            <FaFilter />
          </div>
        </div>
      </div>

      {loading ? (
        <LoadingBox />
      ) : (
        <div>
          <DataTable
            reload={() => {
              loaddata(page, take);
            }}
            meta={meta}
            data={data}
            PageOnchange={(page, take) => pagination(page, take)}
          />
        </div>
      )}
    </div>
  );
}
export default Stock;

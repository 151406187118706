import React, { useState } from "react";

import { Table, Button, Popconfirm, Input, Tag } from "antd";

// import { SearchOutlined } from "@ant-design/icons";

const ExpandableTable = (props) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [searchText, setSearchText] = useState("");
  const handleRowClick = (record) => {
    console.log("isExpanded", record);
    const rowKey = record.account_no;
    const isExpanded = expandedRowKeys.includes(rowKey);
    const newExpandedRowKeys = isExpanded
      ? expandedRowKeys.filter((account_no) => account_no !== rowKey)
      : [...expandedRowKeys, rowKey];
    setExpandedRowKeys(newExpandedRowKeys);
    console.log("newExpandedRowKeys", newExpandedRowKeys);
  };
  const filterData = (data, searchText) => {
    const filteredData = [];
    data.forEach((item) => {
      const match = item.account_name
        .toLowerCase()
        .includes(searchText.toLowerCase());

      const children = filterData(item.children || [], searchText);
      if (match || children.length > 0) {
        filteredData.push({
          ...item,
          children,
        });
      }
    });
    return filteredData;
  };
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const filteredData = filterData(props.treeData, searchText);
  const columns = [
    {
      title: "Account No",
      dataIndex: "account_no",
      key: "account_no",
      // width: "150px",
      render: (text, record) => (
        <div>
          {record.account_no} - {record.account_name}
        </div>
      ),
    },
    {
      title: "Parent Account",
      dataIndex: "parent_account",
      key: "parent_account",
      width: "200px",
      render: (text, record) => (
        <div>{record.parent_account && record.parent_account.account_name}</div>
      ),
    },
    {
      title: "Opening Balance",
      dataIndex: "opening_balance",
      key: "opening_balance",
      width: "100px",
    },
    {
      title: "Active",
      dataIndex: "enabled",
      key: "enabled",
      width: "80px",
      render: (enabled) => (
        <Tag color={enabled ? "green" : "red"}>
          {enabled ? "Active" : "Inactive"}
        </Tag>
      ),
    },
    {
      align: "center",
      title: "Action",
      width: "150px",
      render: (item) => (
        <div>
          <Popconfirm
            placement="bottomRight"
            title={"Are you sure to delete?"}
            okText="Yes"
            cancelText="No"
            onConfirm={() => props.delete(item)}
          >
            <Button size="small">Delete</Button>
          </Popconfirm>
          <Button
            size="small"
            onClick={() => {
              console.log("item", item);
              props.update(item);
            }}
            type="primary"
          >
            Edit
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Input
        placeholder="Search"
        // prefix={<SearchOutlined />}
        value={searchText}
        onChange={handleSearch}
        style={{ marginBottom: 16, width: "300px" }}
      />
      <Table
        dataSource={filteredData}
        columns={columns}
        rowKey={(record) => record.account_no}
        expandedRowKeys={expandedRowKeys}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
        // expandable={{ defaultExpandAllRows: false }}
      />
    </div>
  );
};
export default ExpandableTable;

import React, { useState } from "react";
import styles from "./styles";
import { Container, Row, Col } from "react-bootstrap";
import { Button, Dropdown, Menu, Input, Popconfirm } from "antd";
import { FiRefreshCw } from "react-icons/fi";
import { AiOutlineCopy, AiFillDelete } from "react-icons/ai";
import { MdOpenInBrowser } from "react-icons/md";
import { BsChevronDoubleDown } from "react-icons/bs";
import { BiChevronDown } from "react-icons/bi";
const { Search } = Input;

function MenuBar(props) {
  const menu = (
    <Menu
      items={[
        {
          key: "all",
          label: "all",
        },
        {
          key: "two weeks old",
          label: "two weeks old",
        },
        {
          key: "two months old",
          label: "two months old",
        },
        {
          key: "one year old",
          label: "one year old",
        },
      ]}
      onClick={(val) => props.onDatePicker(val)}
    />
  );
  return (
    <Container fluid>
      <Row>
        <Col>
          <Search
            placeholder="Search"
            allowClear
            onSearch={(e) => props.onSearch(e)}
            style={{
              width: 200,
            }}
          />
        </Col>
        <Col>
          <Dropdown overlay={menu}>
            <Button
              style={styles.buttons}
              block
              icon={<BiChevronDown style={styles.buttonsIcon} />}
            >
              Date
            </Button>
          </Dropdown>
        </Col>
        <Col>
          <Button
            style={styles.buttons}
            block
            icon={<MdOpenInBrowser style={styles.buttonsIcon} />}
            disabled={props.singleSelect}
            onClick={() => props.onOpen()}
          >
            Open
          </Button>
        </Col>
        <Col>
          <Popconfirm
            placement="bottomRight"
            title={"Are you sure to delete?"}
            okText="Yes"
            cancelText="No"
            onConfirm={() => props.onDelete()}
          >
            <Button
              style={styles.buttons}
              block
              icon={<AiFillDelete style={styles.buttonsIcon} />}
              disabled={props.singleSelect}
            >
              Delete
            </Button>
          </Popconfirm>
        </Col>
        <Col>
          <Button
            style={styles.buttons}
            block
            icon={<AiOutlineCopy style={styles.buttonsIcon} />}
            disabled={props.singleSelect}
            onClick={() => props.onCopy()}
          >
            Copy
          </Button>
        </Col>
        <Col>
          <Dropdown
            disabled={props.singleSelect}
            overlay={
              <Menu>
                <Menu.Item onClick={props.printlabel}>Print Label</Menu.Item>
                <Menu.Item onClick={props.printAwb}>Print Awb Bill</Menu.Item>
                <Menu.Item onClick={props.printmanifest}>
                  Print Manifest - A
                </Menu.Item>
                <Menu.Item onClick={props.printmanifest2}>
                  Print Manifest - B
                </Menu.Item>
                <Menu.Item onClick={props.printmanifestC}>
                  Print Manifest - C
                </Menu.Item>
                <Menu.Item onClick={props.printmanifestD}>
                  Print Manifest - D
                </Menu.Item>
                <Menu.Item onClick={props.taxScreen}>Tax Invoice</Menu.Item>
                <Menu.Item onClick={props.printdocs}>Print KYC</Menu.Item>
                <Menu.Item onClick={props.printdeliverylist}>
                  Print Delivery List
                </Menu.Item>
                <Menu.Item onClick={props.printcustoms}>
                  Print Customs
                </Menu.Item>
                <Menu.Item onClick={props.printcustomsB}>
                  Print Customs B
                </Menu.Item>
                <Menu.Item onClick={props.printPdf}>Print PDF</Menu.Item>
                <Menu.Item onClick={props.paymentprocessform}>
                  Payment Process
                </Menu.Item>
                <Menu.Item onClick={props.informBranch}>
                  Notify branch
                </Menu.Item>
                <Menu.Item onClick={props.delivery1}>
                  Third Party Excel
                </Menu.Item>
                <Menu.Item onClick={props.delivery2}>Delivery Excel</Menu.Item>
              </Menu>
            }
          >
            <Button
              style={styles.buttons}
              block
              icon={<BsChevronDoubleDown style={styles.buttonsIcon} />}
            >
              Actions
            </Button>
          </Dropdown>
        </Col>
        <Col>
          <Button
            onClick={props.onRefresh}
            style={styles.buttons}
            icon={<FiRefreshCw style={styles.buttonsIcon} />}
          >
            Refresh
          </Button>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
}
export default MenuBar;

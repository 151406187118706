import React, { useState, useRef } from "react";
import { Button, DatePicker, Form, Input, Select } from "antd";
import { Row, Col, Container } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import moment from "moment";

const { RangePicker } = DatePicker;
const { Option } = Select;

function MenuBar(props) {
  const onUserChange = (val) => {
    let item = props.user.filter((i) => i.id === val);
    if (item && item.length) {
      ref.current?.setFieldsValue({
        branch: item[0].branch.id,
      });
    }
  };

  const onFinish = (val) => {
    let requestObj = {
      date_from: moment(val.date[0]).toISOString(),
      date_to: moment(val.date[1]).toISOString(),
      user_id: Number(val.user),
      agent_id: Number(val.agent),
      port: Number(val.service_type),
    };
    props.onFinish(requestObj);
  };

  const ref = useRef();

  return (
    <Container fluid>
      <Form ref={ref} onFinish={onFinish}>
        <Row>
          <Col>
            <Form.Item name="type">
              <Select
                className="borderedSelect"
                bordered={false}
                allowClear
                options={[
                  {
                    value: "Collections",
                    label: "Collections",
                  },
                  {
                    value: "Boxes",
                    label: "Boxes",
                  },
                  {
                    value: "Payment",
                    label: "Payment",
                  },
                  {
                    value: "transactions",
                    label: "transactions",
                  },
                  {
                    value: "Status",
                    label: "Status",
                  },
                  {
                    value: "Packing Report",
                    label: "Packing Report",
                  },
                  {
                    value: "3rd Party Report",
                    label: "3rd Party Report",
                  },
                  {
                    value: "Sender Report",
                    label: "Sender Report",
                  },
                  {
                    value: "Cash in hand",
                    label: "Cash in hand",
                  },
                ]}
                style={{ width: "100%" }}
                showSearch
                placeholder="Select a Report Type"
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="date"
              rules={[
                {
                  required: true,
                  message: <div>Please choose a Date</div>,
                },
              ]}
            >
              <RangePicker
                defaultValue={[moment(new Date()), moment(new Date())]}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="user">
              <Select
                allowClear
                bordered={false}
                className="borderedSelect"
                style={{ width: "100%" }}
                showSearch
                placeholder="Select a Driver"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={(val) => onUserChange(val)}
              >
                {props.user &&
                  props.user.map((option) => (
                    <Option key={option.id}>{option.name}</Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="branch">
              <Select
                allowClear
                bordered={false}
                className="borderedSelect"
                style={{ width: "100%" }}
                showSearch
                placeholder="Select a Branch"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {props.branches &&
                  props.branches.map((option) => (
                    <Option key={option.id}>{option.name}</Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="agent">
              <Select
                allowClear
                bordered={false}
                className="borderedSelect"
                style={{ width: "100%" }}
                showSearch
                placeholder="Select a Agent"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {props.agents &&
                  props.agents.map((option) => (
                    <Option key={option.id}>
                      {option.user && option.user.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="service_type">
              <Select
                allowClear
                bordered={false}
                className="borderedSelect"
                style={{ width: "100%" }}
                showSearch
                placeholder="Select a Service Type"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {props.service &&
                  props.service.map((option) => (
                    <Option key={option.id}>{option.name}</Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Button type="primary" style={{ width: "100%" }} htmlType="submit">
              <div className="Reports-MenuBar-btn">
                Filter <BsSearch />
              </div>
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

export default MenuBar;

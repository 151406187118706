import React from "react";
import Cropper from "react-cropper";
import axios from "axios";
import "cropperjs/dist/cropper.css";
import { Button, Upload, Tooltip } from "antd";
import { Row, Col } from "react-bootstrap";
import { GrRotateLeft, GrRotateRight } from "react-icons/gr";
import { BiRotateLeft, BiRotateRight } from "react-icons/bi";
import { FiUpload } from "react-icons/fi";
import avatar from "../../Assets/Images/avatar.jpg";
import styles from "./styles";

const { Dragger } = Upload;

export default class ImgRotateAndCrop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageSrc: "",
      uploadedImage: "",
    };
    this.fileInput = React.createRef();
    this.handleFileRead = this.handleFileRead.bind(this);
    this.fileReader = new FileReader();
    this.cropper = React.createRef();
  }
  componentDidMount() {
    if (this.props.fileType == "adhar") {
      this.setState({ imageSrc: this.props.box.adhar });
    } else if (this.props.fileType == "adhar_back") {
      this.setState({ imageSrc: this.props.box.adhar_back });
    } else if (this.props.fileType == "iqama") {
      this.setState({ imageSrc: this.props.box.iqama });
    } else if (this.props.fileType == "signature") {
      this.setState({ imageSrc: this.props.box.signature });
    }
  }

  handleFileRead(e) {
    // console.log("The reading is over");
    const binaryData = this.fileReader.result;
    const base64Data = window.btoa(binaryData);
    this.setState({ base64: base64Data });
    // debugger;
  }

  handleChange(event) {
    // console.log("handleChange======>", event.fileList[0]);
    // const file = this.fileInput.current.files[0];
    const file = this.fileInput.current.fileList[0].originFileObj;

    const { name, size, type } = file;

    // const imageSrc = URL.createObjectURL(event.target.files[0]);
    const imageSrc = URL.createObjectURL(event.fileList[0].originFileObj);

    this.setState({
      name,
      size,
      type,
      imageSrc,
      croppedImgSrc: null,
    });
    // console.log(file.name);

    if (event.file.status === "error") {
      this.fileReader.onloadend = this.handleFileRead;
      console.log("status ========>", event.file.status);
      this.fileReader.readAsBinaryString(file);
      console.log(imageSrc);
    }
  }

  handleCropChange() {
    // console.log("## cropped !");
    const croppedImgData = this.cropper.current.cropper
      .getCroppedCanvas()
      .toDataURL();

    this.setState({ croppedImgSrc: croppedImgData });
    this.props.onCropAccept(croppedImgData);
  }

  handleRotate(lOrR) {
    if (lOrR == "l") {
      this.cropper.current.cropper.rotate(-1);
    } else if (lOrR == "r") {
      this.cropper.current.cropper.rotate(1);
    } else if (lOrR == "l9") {
      this.cropper.current.cropper.rotate(-90);
    } else if (lOrR == "r9") {
      this.cropper.current.cropper.rotate(90);
    }

    this.handleCropChange();
  }

  render() {
    const { name, type, size, imageSrc, base64, croppedImgSrc } = this.state;

    return (
      <div className="App">
        {/* {imageSrc && (<img src={imageSrc} style={{ width: "400px", height: "400px" }}></img>)} */}

        <div>
          <div className="ImgRotateAndCrop-box1">
            {imageSrc ? (
              <Cropper
                style={{ maxWidth: "500px", maxHeight: "500px" }}
                ref={this.cropper}
                src={imageSrc}
                cropend={() => this.handleCropChange()}
              />
            ) : (
              <div className="ImgRotateAndCrop-box2">
                <Dragger
                  onChange={(val) => this.handleChange(val)}
                  ref={this.fileInput}
                  style={{ border: 0 }}
                >
                  <div className="ImgRotateAndCrop-box3">
                    <FiUpload color="#1e88e5" size={80} />
                  </div>
                  <div className="ImgRotateAndCrop-box4">
                    <div className="ImgRotateAndCrop-txt1">
                      Drag and Drop Files to Upload
                    </div>
                    <div className="ImgRotateAndCrop-txt2">OR</div>
                    <div>
                      <Button type="primary" style={{ borderRadius: "10px" }}>
                        Browse
                      </Button>
                    </div>
                  </div>
                </Dragger>
              </div>
            )}

            {croppedImgSrc ? (
              <div>
                <div
                  style={{
                    margin: "10px",
                    height: "400px",
                    width: "400px",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundImage: `url(${croppedImgSrc})`,
                  }}
                ></div>
                <Row>
                  <Col xs="3" sm="3">
                    <Tooltip title={` Rotate Left 90 \u00b0`}>
                      <Button
                        block
                        type="primary"
                        className="ImgRotateAndCrop-button1"
                        onClick={() => this.handleRotate("l9")}
                      >
                        <BiRotateLeft style={{ marginRight: 5 }} size="20" />
                      </Button>
                    </Tooltip>
                  </Col>
                  <Col xs="3" sm="3">
                    <Tooltip title={` Rotate Left`}>
                      <Button
                        block
                        type="primary"
                        className="ImgRotateAndCrop-button1"
                        onClick={() => this.handleRotate("l")}
                      >
                        <GrRotateLeft style={{ marginRight: 5 }} />
                      </Button>
                    </Tooltip>
                  </Col>
                  <Col xs="3" sm="3">
                    <Tooltip title={` Rotate Right`}>
                      <Button
                        block
                        type="primary"
                        className="ImgRotateAndCrop-button2"
                        onClick={() => this.handleRotate("r")}
                      >
                        <GrRotateRight style={{ marginRight: 5 }} />
                      </Button>
                    </Tooltip>
                  </Col>
                  <Col xs="3" sm="3">
                    <Tooltip title={` Rotate Right 90 \u00b0`}>
                      <Button
                        block
                        type="primary"
                        className="ImgRotateAndCrop-button2"
                        onClick={() => this.handleRotate("r9")}
                      >
                        <BiRotateRight style={{ marginRight: 5 }} size="20" />
                      </Button>
                    </Tooltip>
                  </Col>
                </Row>
              </div>
            ) : (
              <div className="ImgRotateAndCrop-box5">
                <img src={avatar} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

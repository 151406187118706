import React, { useState, useEffect } from "react";
import { Modal, Input, Popconfirm, Button, message } from "antd";
import { Row, Col, Container } from "react-bootstrap";
import { GrFormAdd } from "react-icons/gr";
import { MdEdit, MdDelete } from "react-icons/md";
import axios from "axios";
import { API, Headers } from "../../../../Config";
import DataTable from "./DataTable";
import FormAddAgentModal from "./FormModal";
const { Search } = Input;
function FormAgentModal(props) {
  const [loading, setLoading] = useState(true);
  const [indicator, setIndicator] = useState(false);
  const [addAgentModal, setAddAgentModal] = useState(false);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);

  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [meta, setMeta] = useState({});
  const [mode, setMode] = useState("post");

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  useEffect(() => {
    loaddata(page, take);
  }, []);

  const loaddata = (p, t) => {
    axios
      .get(
        API.BASR_URL +
          API.ISSUING_CARRY_AGENT_LIST +
          `?order=ASC&page=${p}&take=${t}`,
        {
          headers: Headers(),
        }
      )
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setData(data.data);

          setMeta(data.meta);
          message.success("Success");
        } else {
          message.error("Oops.Something went wrong");
        }
        setLoading(false);
      })
      .catch(function (error) {
        message.error("Oops.Something went wrong");
        setLoading(false);
        console.log(error);
      });
  };

  const reload = () => {
    setLoading(true);
    setIndicator(true);
    setAddAgentModal(false);
    loaddata(page, take);
  };

  const AddNew = () => {
    setSelected([]);
    setAddAgentModal(true);
    setMode("post");
  };

  const UpdateItem = () => {
    setMode("put");
    setAddAgentModal(true);
  };

  const selectCell = (row) => {
    setSelected(row);
  };

  const deleteCell = () => {
    const RowID = selected && selected[0].id;
    axios
      .delete(API.BASR_URL + API.ISSUING_CARRY_AGENT_ACTIONS + RowID, {
        headers: Headers(),
      })
      .then(function (response) {
        message.success("Deletde Successfully");
        reload();
      })
      .catch(function (error) {
        message.error("Oops.Something went wrong");
        console.log(error);
      });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const pagination = (page, take) => {
    window.scrollTo(0, 0);
    setPage(page);
    setTake(take);
    loaddata(page, take);
  };

  const Submit = () => {
    props.onChange(selected);
    props.onClose();
  };

  return (
    <Modal
      visible={props.visible}
      onCancel={props.onClose}
      footer={false}
      width={800}
    >
      <h5 className="PrintLabelModal-txt1">CONTACT FINDER</h5>
      <Container style={{ display: "flex" }}>
        <Search placeholder="" allowClear style={{ flex: 0.4 }} />

        <Button type="primary" icon={<GrFormAdd />} onClick={() => AddNew()}>
          Add
        </Button>

        <Button
          icon={<MdEdit />}
          disabled={selected.length ? false : true}
          onClick={() => UpdateItem()}
        >
          Edit
        </Button>

        <Popconfirm
          placement="bottomRight"
          title={"Are you sure to delete?"}
          okText="Yes"
          cancelText="No"
          onConfirm={deleteCell}
        >
          <Button icon={<MdDelete />} disabled={selected.length ? false : true}>
            Remove
          </Button>
        </Popconfirm>
      </Container>
      <br />
      <DataTable
        data={data}
        loading={loading}
        selected={selected}
        onSelect={(data) => selectCell(data)}
        onSearch={(selectedKeys, confirm, dataIndex) =>
          handleSearch(selectedKeys, confirm, dataIndex)
        }
        onSearchReset={(clearFilters) => handleReset(clearFilters)}
        pageOnChange={(page, take) => pagination(page, take)}
      />
      <br />
      <Row>
        <Col sm="6"></Col>
        <Col sm="3">
          <Button type="primary" block onClick={() => Submit()}>
            Select
          </Button>
        </Col>
        <Col sm="3">
          <Button onClick={props.onClose} block>
            Cancel
          </Button>
        </Col>
      </Row>
      {addAgentModal ? (
        <FormAddAgentModal
          data={data}
          selected={selected}
          mode={mode}
          visible={addAgentModal}
          reload={reload}
          onClose={() => setAddAgentModal(false)}
        />
      ) : null}
    </Modal>
  );
}

export default FormAgentModal;

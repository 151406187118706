import { Table, Pagination, Button, Popconfirm, Tag } from "antd";
import { MdOutlineRadioButtonChecked } from "react-icons/md";
function DataTable(props) {
  const columns = [
    {
      title: "Color",
      dataIndex: "status_color",
      key: "status_color",
      render: (item) => (
        <div>
          <MdOutlineRadioButtonChecked size={25} color={item} />
        </div>
      ),
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      render: (active) => (
        <div className="data-table-items">
          <Tag color={active ? "green" : "red"}>
            {active ? "Active" : "Inactive"}
          </Tag>
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "status_head",
      key: "status_head",
      render: (text) => <div className="data-table-items">{text}</div>,
    },
    {
      title: "Label",
      dataIndex: "status_label",
      key: "status_label",
      render: (text) => <div className="data-table-items">{text}</div>,
    },
    {
      title: "Order",
      dataIndex: "status_order",
      key: "status_order",
      render: (text) => <div className="data-table-items">{text}</div>,
    },

    {
      title: "Action",
      render: (item) => (
        <div className="tableAction">
          <Popconfirm
            placement="bottomRight"
            title={"Are you sure to delete?"}
            okText="Yes"
            cancelText="No"
            onConfirm={() => props.delete(item)}
          >
            <Button size="small">Delete</Button>
          </Popconfirm>
          <Button
            size="small"
            onClick={() => props.update(item)}
            type="primary"
          >
            Edit
          </Button>
        </div>
      ),
    },
  ];
  return (
    <div style={{ minHeight: "60vh" }}>
      <Table
        columns={columns}
        dataSource={props.data}
        pagination={false}
        size="small"
      />
      <br />
      <div align="right">
        <Pagination
          responsive
          defaultCurrent={props.meta && props.meta.page}
          total={props.meta && props.meta.itemCount}
          pageSize={props.meta && props.meta.take ? props.meta.take : 0}
          onChange={props.PageOnchange}
          pageSizeOptions={[10, 20, 50]}
        />
      </div>
    </div>
  );
}
export default DataTable;

import React, { useState } from "react";
import { Drawer, Form, Button, Input, message } from "antd";
import { Container, Row, Col } from "react-bootstrap";
import { API, Headers } from "../../Config";
import axios from "axios";

var width = window.innerWidth;
function FormModal(props) {
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    let obj = values;
    let URL =
      props.mode === "post"
        ? API.THIRD_PARTY_SENDERS
        : API.THIRD_PARTY_ACTIONS + props.item.id;
    axios({
      method: props.mode,
      url: API.BASR_URL + URL,
      headers: Headers(),
      data: obj,
    })
      .then(function (response) {
        setLoading(false);
        if ([200, 201].includes(response.status)) {
          message.success("Success");
          props.onchange();
        }
      })
      .catch(function (error) {
        setLoading(false);
        message.error("Oops.Something went wrong");
        console.log(error);
      });
  };

  return (
    <div>
      <Drawer
        placement={props.mode === "post" ? "right" : "left"}
        title={
          <span className="txt4">
            {props.mode === "post" ? "New" : "Update"} Third Party Reciever
          </span>
        }
        width={width / 2.7}
        onClose={() => props.onClose()}
        visible={props.visible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form
          layout="vertical"
          hideRequiredMark
          onFinish={onFinish}
          initialValues={{
            name: props.item.name,
          }}
        >
          <Container>
            <Row>
              <Col sm="12" xs="12">
                <Form.Item
                  name="name"
                  label={<div className="form-item-label">Name</div>}
                  rules={[{ required: true, message: "required" }]}
                >
                  <Input
                    placeholder=""
                    style={{ textTransform: "uppercase" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm="6" xs="12"></Col>
              <Col sm="3" xs="12">
                <Button onClick={() => props.onClose()} block>
                  Cancel
                </Button>
              </Col>
              <Col sm="3" xs="12">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  block
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Container>
        </Form>
      </Drawer>
    </div>
  );
}

export default FormModal;

const styles = {
  NextButton: {
    borderRadius: "6px",
    color: "#fff",
  },
  BackButton: {
    background: "#B5B5B5",
    borderColor: "#B5B5B5",
    borderRadius: "6px",
    color: "#fff",
  },
  TripFormTxt1: {
    fontFamily: "Montserrat-Regular",
  },
  buttons: {
    borderRadius: 2,
    fontSize: 14,
    fontWeight: "900",
    marginBottom: 10,
    width: "100%",
  },
  buttonsIcon: {
    fontSize: 20,
    marginRight: 10,
  },
};
export default styles;

import React, { useEffect } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
var JsBarcode = require("jsbarcode");

function PdfCargoLabel(props) {
  let canvas;
  canvas = document.createElement("canvas");
  JsBarcode(canvas, props.selected.awb_no);
  const barcode = canvas.toDataURL();
  return (
    <Document>
      <Page size="A4" wrap>
        <View>
          <View style={styles.Table}>
            <View style={styles.TableRow}>
              <View>
                <Text style={styles.Txt2}>Cargo Pouch</Text>
              </View>
            </View>
            <View style={styles.TableRow}>
              <View>
                <Text style={styles.Txt1}>Airline Name/Insignia</Text>
                <Text style={styles.Txt2}>{props.formdata.airline}</Text>
              </View>
            </View>
            <View>
              <Image src={barcode} style={styles.Barcode} />
            </View>
            <View style={styles.TableRow}>
              <View>
                <Text style={styles.Txt1}>Air Waybill Number</Text>
                <Text style={styles.Txt2}>{props.selected.awb_no}</Text>
              </View>
            </View>

            <View style={styles.TableRow}>
              <View>
                <Text style={styles.Txt1}>Destination</Text>
                <Text style={styles.Txt2}>
                  {props.selected.destination &&
                    props.selected.destination.name}
                </Text>
              </View>
            </View>
            <View style={styles.TableRow}>
              <View>
                <Text style={styles.Txt1}>Flight Identification</Text>
                <Text style={styles.Txt2}>
                  {props.formdata.flight_identification}
                </Text>
              </View>
            </View>
            <View style={styles.TableRow}>
              <View>
                <Text style={styles.Txt1}>Consignee Name</Text>
                <Text style={styles.Txt2}>{props.formdata.cnee_name}</Text>
              </View>
            </View>
            <View style={styles.TableRow}>
              <View>
                <Text style={styles.Txt1}> Consignee number</Text>
                <Text style={styles.Txt2}>{props.formdata.cnee_num}</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}

export default PdfCargoLabel;

const styles = StyleSheet.create({
  Table: {
    margin: 10,
    borderColor: "#000",
    borderWidth: 0.5,
    fontSize: "10px",
    overflow: "hidden",
    width: "127mm",
    padding: 5,
  },
  TableRow: {
    borderBottomColor: "#000",
    borderWidth: 0.5,
    display: "flex",
    flexDirection: "row",
  },
  Col: {
    flex: 1,
  },
  Txt1: {
    margin: 5,
  },
  Txt2: {
    margin: 10,
    fontSize: "30px",
    textAlign: "center",
    fontWeight: 800,
  },
  Barcode: {
    height: "100px",
  },
});

import axios from "axios";
import React, { useEffect, useState } from "react";
import { API, Headers } from "../../Config";
import PageHeader from "../../Routes/PageHeader";
import LoadingBox from "../../Components/LoadingBox";
import Datatable from "./Datatable";
import MenuBar from "./MenuBar";

function Reports() {
  const [data, setData] = useState([]);
  const [user, setUser] = useState([]);
  const [branch, setBranch] = useState([]);
  const [agents, setAgents] = useState([]);
  const [service, setService] = useState([]);
  const [obj, setObj] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadUsers();
    loadBranches();
    loadAgent();
    loadService();
  }, []);

  const loadUsers = () => {
    axios
      .get(API.BASR_URL + API.USERS_LIST + `/list`, {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setUser(data);
        }
      });
  };

  const loadBranches = () => {
    axios
      .get(API.BASR_URL + API.BRANCHES_LIST + `/list`, {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setBranch(data);
          console.log(branch);
        }
      });
  };

  const loadAgent = () => {
    axios
      .get(API.BASR_URL + API.AGENTS_LIST + `/list`, {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setAgents(data);
          console.log(agents);
        }
      });
  };

  const loadService = () => {
    axios
      .get(API.BASR_URL + API.SERVICE_TYPE_LIST + `/list`, {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setService(data);
          console.log(service);
        }
      });
  };

  const onFinish = (val) => {
    setLoading(true);
    let requestObj = val;
    console.log(requestObj);
    setObj(requestObj);
    axios({
      method: "post",
      data: requestObj,
      url: API.BASR_URL + API.BOXES_ACTIONS + `report`,
      headers: Headers(),
    })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setData(data);
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <div>
      <PageHeader PageName={"Report"} breadScrum={"Admin / Report"} />
      <MenuBar
        user={user}
        branches={branch}
        agents={agents}
        service={service}
        onFinish={(val) => onFinish(val)}
      />
      {loading ? <LoadingBox /> : <Datatable data={data} obj={obj} />}
    </div>
  );
}

export default Reports;

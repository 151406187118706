import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Modal,
  Form,
  Input,
  Button,
  message,
  DatePicker,
  Popconfirm,
  notification,
} from "antd";
import { Container, Row, Col } from "react-bootstrap";
import { API, Headers } from "../../../Config";
import axios from "axios";
import moment from "moment";
import { MdOutlinePermContactCalendar } from "react-icons/md";
import { IoIosSend } from "react-icons/io";
import styles from "../styles";

import RateTable from "../DataTables/RateTable";
import OtherTable from "../DataTables/OtherTable";

import FormRateModal from "./FormRateModal";
import FormOtherChargeModal from "./FormOtherChargeModal";
import FormShipperModal from "./FormShipperModal";
import FormConsigneeModal from "./FormConsigneeModal";
import FormAgentModal from "./FormAgentModal";
import FormAwbGenModal from "./FormAwbGenModal";

const { TextArea } = Input;
function FormModal(props) {
  const Auth = useSelector((state) => state.Auth.user);

  const formRef = useRef();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selected, setSelected] = useState({});
  const [prefix, setPrefix] = useState();
  const [selectedType, setSelectedType] = useState(null);

  const [bills, setbills] = useState([]);
  const [billsLoading, setbillsLoading] = useState(true);

  const [ports, setports] = useState([]);
  const [portsLoading, setportsLoading] = useState(true);

  const [shipper, setShipper] = useState([]);
  const [consignee, setConsignee] = useState([]);
  const [issueAgent, setIssueAgent] = useState([]);

  const [RateModal, setRateModal] = useState(false);
  const [otherModal, setOtherModal] = useState(false);
  const [shipperModal, setShipperModal] = useState(false);
  const [consigneeModal, setConsigneeModal] = useState(false);
  const [agentModal, setAgentModal] = useState(false);
  const [awbModal, setAwbModal] = useState(false);

  const [seriesID, SetSeriesID] = useState([]);

  const [otherCharges, setOtherCharges] = useState(
    // props.mode === "put" &&
    props.selected && props.selected.other_charges.otherCharges
      ? props.selected.other_charges.otherCharges
      : []
  );
  const [rateDesc, setRateDesc] = useState(
    // props.mode === "put" &&
    props.selected && props.selected.rate_description.rateDesc
      ? props.selected.rate_description.rateDesc
      : []
  );

  useEffect(() => {
    airbwaybillsDropdown();
    portsDropdown();
    loadSendersList();
    loadRecieversList();
    loadIssuingCarryAgent();
  }, []);

  const airbwaybillsDropdown = () => {
    axios
      .get(API.BASR_URL + API.AIRLINE_LIST + `/list`, {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) setbills(res.data);
      })
      .catch(function (error) {
        message.error("Airway bills dropdown faild");
      });
    setbillsLoading(false);
  };

  const portsDropdown = () => {
    axios
      .get(API.BASR_URL + API.PORTS_LIST + `/list`, {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) setports(res.data);
      })
      .catch(function (error) {
        message.error("porst dropdown faild");
      });
    setportsLoading(false);
  };

  const loadSendersList = () => {
    axios
      .get(API.BASR_URL + API.SENDERS_LIST + `/list`, {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          setShipper(response.data);
        }
      })
      .catch(function (error) {
        message.error("loading senders failed...");
      });
  };

  const loadRecieversList = () => {
    axios
      .get(API.BASR_URL + API.RECIVERS_LIST + `/list`, {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          setConsignee(response.data);
        }
      })
      .catch(function (error) {
        message.error("recievers loading failed...");
      });
  };

  const loadIssuingCarryAgent = () => {
    axios
      .get(API.BASR_URL + API.ISSUING_CARRY_AGENT_LIST + `/list`, {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          setIssueAgent(response.data);
        }
      })
      .catch(function (error) {
        message.error("issuing agent loading failed .......");
      });
  };

  let TotalWeightCharge = 0;
  let OtherCharges = 0;
  let TotalPieces = 0;
  let TotalAgentCharge = 0;
  let TotalCarrierCharge = 0;
  let TotalChargeableWeight = 0;
  //Rate description
  const AddRateDesc = (val) => {
    setSelected([]);
    setRefresh(true);
    let arr = rateDesc;
    let obj = val;
    obj["id"] = new Date().getTime();
    arr.push(val);
    setRateDesc(arr);

    onAddCharge();
    CalcTotalPieces();

    setTimeout(() => {
      setRefresh(false);
    }, 5);
  };
  const DeleteRateDesc = (val) => {
    setRefresh(true);
    let arr = rateDesc;
    let findindex = arr.findIndex((i) => i.id === selected.id);
    arr.splice(findindex, 1);
    setRateDesc(arr);
    setTimeout(() => {
      setRefresh(false);
    }, 5);
  };

  const EditRateDesc = (val) => {
    setRateModal(true);
    let arr = rateDesc;
    let findindex = arr.findIndex((i) => i.id === selected.id);
    arr.splice(findindex, 1);
    setRateDesc(arr);
  };

  //Other Charges
  const AddOtherCahrges = (val) => {
    setSelected([]);
    setRefresh(true);
    let arr = otherCharges;
    let obj = val;
    obj["id"] = new Date().getTime();
    arr.push(val);
    setOtherCharges(arr);

    if (val.entitlement === "C") {
      TotalCarrierCharge = otherCharges.reduce(function (tot, arr) {
        return arr.entitlement === "C"
          ? Number(tot) + Number(arr.amount)
          : TotalCarrierCharge;
      }, 0);
      formRef.current?.setFieldsValue({
        other_charges_due_carrier: TotalCarrierCharge,
      });
    } else {
      TotalAgentCharge = otherCharges.reduce(function (tot, arr) {
        return arr.entitlement === "A"
          ? Number(tot) + Number(arr.amount)
          : null;
      }, 0);

      formRef.current?.setFieldsValue({
        other_charges_due_agent: TotalAgentCharge,
      });
    }

    onAddCharge();
    setTimeout(() => {
      setRefresh(false);
    }, 5);
  };
  const DeleteOtherCahrges = (val) => {
    setRefresh(true);
    let arr = otherCharges;
    let findindex = arr.findIndex((i) => i.id === selected.id);

    arr.splice(findindex, 1);
    setOtherCharges(arr);
    setTimeout(() => {
      setRefresh(false);
    }, 5);
  };

  const EditOtherCharge = (val) => {
    setOtherModal(true);
    let arr = otherCharges;
    let findIndex = arr.findIndex((i) => i.id === selected.id);
    arr.splice(findIndex, 1);
    setOtherCharges(arr);
  };

  const CalcTotalPieces = () => {
    TotalPieces = rateDesc.reduce(function (tot, arr) {
      return Number(tot) + Number(arr.pieces);
    }, 0);
    TotalChargeableWeight = rateDesc.reduce(function (tot, arr) {
      return Number(tot) + Number(arr.chargeable_weight);
    }, 0);
    formRef.current?.setFieldsValue({
      psc: TotalPieces,
      chargable_weight: TotalChargeableWeight,
    });
  };

  const onAddCharge = () => {
    TotalWeightCharge = rateDesc.reduce(function (tot, arr) {
      return Number(tot) + Number(arr.total);
    }, 0);

    formRef.current?.setFieldsValue({
      weight_charge: TotalWeightCharge,
    });

    OtherCharges = otherCharges.reduce(function (tot, arr) {
      return Number(tot) + Number(arr.amount);
    }, 0);

    formRef.current?.setFieldsValue({
      total_charges: Number(OtherCharges) + Number(TotalWeightCharge),
    });
  };

  const onSelect = (value, form) => {
    setSelected(value[0]);
    setSelectedType(form);
  };

  const onFinish = (values) => {
    setLoading(true);
    let checkDigit = values.checked_digit
      ? values.checked_digit.toString()
      : "0";

    let requestObj = {
      userid: parseInt(Auth.id),
      orijin_airport_name: values.orijin_airport_name,
      destination_airport_name: values.destination_airport_name,
      airline: values.airline,
      awb_no: values.awb_no + checkDigit,
      airport_departure_code: values.airport_departure_code,
      psc: values.psc?.toString(),
      actual_weight: 0,
      dimension: 0,
      volume_weight: 0,
      chargable_weight: parseInt(values.chargable_weight),
      rate: 0,
      other_charge: 0,
      awb_charge: 0,
      packing_charge: 0,
      insurance_charge: 0,
      additional_charge: 0,

      incharge_of_generate: "string",
      reference: "string",
      document_date: moment().format(),
      is_print: true,
      cnee: values.cnee,
      shipper: values.shipper,
      agent_name_on_awb: values.agent_name_on_awb,

      route_to: values.route_to,
      by_first_carrier: values.by_first_carrier,
      route_to2: values.route_to2,
      by_carrier2: values.by_carrier2,
      route_to3: values.route_to3,
      by_carrier3: values.by_carrier3,

      accounting_info_details: values.accounting_info_details,
      accounting_info_details: values.accounting_info_details,
      shipment_ref_no: values.accounting_info_details,
      shipment_ref_info: values.shipment_ref_info,
      value_for_customs: values.value_for_customs,
      value_for_carriage: values.value_for_carriage,
      currency: values.currency,
      issuer: values.issuer,
      requirments: values.requirments,
      sci: values.sci,

      carrier_execution_date: moment(values.carrier_execution_date),
      carrier_execution_place: values.carrier_execution_place,
      carrier_execution_sign: values.carrier_execution_sign,
      shipper_signature: values.shipper_signature,

      currency_conv_rate: parseInt(values.currency_conv_rate),
      cc_charges_in_dest: parseInt(values.cc_charges_in_dest),
      charges_at_dest: parseInt(values.charges_at_dest),
      total_collect: parseInt(values.total_collect),

      weight_charge: values.weight_charge,
      valuation_charge: Number(values.valuation_charge),
      tax: Number(values.tax),
      other_charges_due_agent: parseInt(values.other_charges_due_agent),
      other_charges_due_carrier: parseInt(values.other_charges_due_carrier),
      total_charges: parseInt(values.total_charges),
      rate_description: { rateDesc },
      other_charges: { otherCharges },
      clearing_agent_destination: 1,
      delivery_agent_destination: 1,
      awb_status: "start",
    };
    console.log(requestObj);

    let checkAWB = values.checked_digit
      ? values.awb_no + values.checked_digit
      : values.awb_no;

    axios
      .get(
        API.BASR_URL + API.AIRWAY_BILLS_GEN_ACTION + `if_exist/${checkAWB}`,

        { headers: Headers() }
      )
      .then(function (response) {
        //check if awb already exists
        if (response.status === 200) {
          let data = response.data;
          if (data && props.mode !== "put") {
            message.error("Awb number already exists");
            setLoading(false);
          } else {
            let URL =
              props.mode === "put"
                ? API.AIRWAY_BILLS_GEN_ACTION + props.selected.id
                : API.AIRWAY_BILLS_GEN_ACTION;
            axios({
              method: props.mode === "put" ? "put" : "post",
              url: API.BASR_URL + URL,
              headers: Headers(),
              data: requestObj,
            })
              .then(function (response) {
                setLoading(false);
                if ([200, 201].includes(response.status)) {
                  message.success("Success");
                  props.onRefresh();
                }
              })
              .catch(function (error) {
                setLoading(false);
                console.log(error);
                console.log(error.response.data.message);
                message.error("Oops.Something went wrong");
                notification.open({
                  message: "ERROR",
                  description: `${error.response.data.message}`,
                });
              });
            if (seriesID && seriesID.length) {
              let SID = seriesID[0].id && seriesID[0].id;
              let active = {
                active: false,
              };
              axios({
                method: "put",
                url: API.BASR_URL + API.AIRWAY_BILLS_ACTION + SID,
                headers: Headers(),
                data: active,
              })
                .then(function (response) {
                  if (response.status === 200) {
                    console.log(response.data);
                  }
                })
                .catch(function (error) {
                  console.log(error);
                });
            }
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const chooseAWB = (item) => {
    if (item && item.length) {
      formRef.current?.setFieldsValue({
        airline: item[0].airline_id,
        awb_no: item[0].series_no.slice(3, item[0].series_no.length - 1),
        checked_digit: item[0].series_no.slice(-1),
        issuer: item[0].airline_address,
      });
      SetSeriesID(item);
    }
  };

  const onTypenewAwb = (item) => {
    const value = item.target.value;
    let itm = bills.filter((i) => i.airline_no === value);
    if (itm && itm.length) {
      formRef.current?.setFieldsValue({
        issuer: itm[0].airline_address,
      });
    }
  };

  const [first_checkdit, setfirstcheckdit] = useState(0);
  const [last_checkdit, setlast_checkdit] = useState(0);

  const onTypeBillnum = (item) => {
    const value = item.target.value;
    let count = 1;
    let first_checkdit = Number(value);
    if (first_checkdit.toString().length === 7) {
      setfirstcheckdit(first_checkdit % 7);
    }
    if (count && value) {
      let last_checkdigit = Number(Number(count) + Number(value) - 1);
      setlast_checkdit(last_checkdigit % 7);
      formRef.current?.setFieldsValue({
        checked_digit: last_checkdigit % 7,
      });
    }
  };

  const chooseShipper = (item) => {
    if (item && item.length) {
      formRef.current?.setFieldsValue({
        shipper_no: item[0].account_number,
        shipper: item[0].name + "  " + item[0].address,
      });
    }
  };

  const onTypeShipper = (item) => {
    item.preventDefault();
    const value = item.target.value;
    let itm = shipper.filter((i) => i.account_number === value);
    if (itm && itm.length) {
      formRef.current?.setFieldsValue({
        shipper_no: itm[0].account_number,
        shipper: itm[0].name + "  " + itm[0].address,
      });
    }
  };

  const chooseConsignee = (item) => {
    if (item && item.length) {
      formRef.current?.setFieldsValue({
        cnee_no: item[0].account_number,
        cnee: item[0].name + "  " + item[0].address,
      });
    }
  };

  const onTypeConsignee = (item) => {
    item.preventDefault();
    const value = item.target.value;

    let itm = consignee.filter((i) => i.account_number === value);
    if (itm && itm.length) {
      formRef.current?.setFieldsValue({
        cnee_no: itm[0].account_number,
        cnee: itm[0].name + "  " + itm[0].address,
      });
    }
  };

  const chooseIssuAgent = (item) => {
    if (item && item.length) {
      formRef.current?.setFieldsValue({
        agent_name_on_awb_no: item[0].account_number,
        agent_name_on_awb: item[0].name,
        iata_code: item[0].code,
      });
    }
  };

  const onTypeIssueAgent = (item) => {
    item.preventDefault();
    const value = parseInt(item.target.value);
    let itm = issueAgent.filter((i) => i.account_number === value);
    if (itm && itm.length) {
      formRef.current?.setFieldsValue({
        agent_name_on_awb_no: itm[0].account_number,
        agent_name_on_awb: itm[0].name,
        iata_code: itm[0].code,
      });
    }
  };

  const tabindex = useRef(null);
  const tabindex2 = useRef(null);

  const onPressTab = (val) => {
    if (val.code && val.code === "Tab") {
      tabindex.current.focus();
    }
  };

  const onPressTab2 = (val) => {
    if (val.code && val.code === "Tab") {
      tabindex2.current.focus();
    }
  };

  return (
    <Modal
      visible={props.visible}
      onCancel={props.onClose}
      onClose={props.onClose}
      header={false}
      footer={false}
      width={1220}
      maskClosable={false}
    >
      <Form
        ref={formRef}
        name="basic"
        onFinish={onFinish}
        scrollToFirstError
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{
          orijin_airport_name:
            props.selected && props.selected.orijin_airport_name,
          destination_airport_name:
            props.selected && props.selected.destination_airport_name,

          route_to: props.selected && props.selected.route_to,
          by_first_carrier: props.selected && props.selected.by_first_carrier,
          route_to2: props.selected && props.selected.route_to2,
          by_carrier2: props.selected && props.selected.by_carrier2,
          route_to3: props.selected && props.selected.route_to3,
          by_carrier3: props.selected && props.selected.by_carrier3,

          airline:
            props.selected && props.mode === "put"
              ? props.selected.airline
              : "",
          awb_no:
            props.selected && props.mode === "put"
              ? props.selected.awb_no.slice(0, 7)
              : "",
          checked_digit:
            props.selected && props.mode === "put"
              ? props.selected.awb_no.slice(7, 8)
              : "",
          airport_departure_code:
            props.selected && props.mode === "put"
              ? props.selected.airport_departure_code
              : "",
          issuer:
            props.selected && props.mode === "put" ? props.selected.issuer : "",
          cnee: props.selected && props.selected.cnee,
          shipper: props.selected && props.selected.shipper,
          agent_name_on_awb: props.selected && props.selected.agent_name_on_awb,

          accounting_info_details:
            props.selected && props.selected.accounting_info_details,
          weight_charge: props.selected && props.selected.weight_charge,
          valuation_charge: props.selected && props.selected.valuation_charge,
          tax: props.selected && props.selected.tax,
          other_charges_due_agent:
            props.selected && props.selected.other_charges_due_agent,
          other_charges_due_carrier:
            props.selected && props.selected.other_charges_due_carrier,
          total_charges: props.selected && props.selected.total_charges,
          shipment_ref_info: props.selected && props.selected.shipment_ref_info,
          currency: props.selected && props.selected.currency,
          value_for_customs: props.selected && props.selected.value_for_customs,
          value_for_carriage:
            props.selected && props.selected.value_for_carriage,
          requirments: props.selected && props.selected.requirments,
          sci: props.selected && props.selected.sci,
          carrier_execution_date:
            props.selected && moment(props.selected.carrier_execution_date),
          carrier_execution_place:
            props.selected && props.selected.carrier_execution_place,
          carrier_execution_sign:
            props.selected && props.selected.carrier_execution_sign,
          charges_at_dest: props.selected && props.selected.charges_at_dest,
          cc_charges_in_dest:
            props.selected && props.selected.cc_charges_in_dest,
          currency_conv_rate:
            props.selected && props.selected.currency_conv_rate,
          total_collect: props.selected && props.selected.total_collect,
          shipper_signature: props.selected && props.selected.shipper_signature,

          psc: props.selected && props.selected.psc,
          chargable_weight: props.selected && props.selected.chargable_weight,
          rate_description: props.selected && props.selected.rate_description,
          other_charges: props.selected && props.selected.other_charges,
        }}
      >
        <Row>
          <Col sm="3" xs="12">
            <h5 className="PrintLabelModal-txt1">
              {props.mode === "put" ? "Edit" : props.mode} Airway Bill
            </h5>
          </Col>
        </Row>
        <Container>
          <Row className="awb-formodal-form-row">
            <Col sm="6" xs="12">
              <div className="awb-form-box" tabIndex={1}>
                <div className="awb-form-box-legend">
                  <div className="form-item-label">Shipper</div>
                </div>
                <br />
                <Form.Item
                  name="shipper_no"
                  label={<div className="form-item-label">Account number</div>}
                  style={{ margin: 4 }}
                >
                  <Input.Group compact>
                    <Input
                      style={{ width: "85%", textTransform: "uppercase" }}
                      onPressEnter={(e) => onTypeShipper(e)}
                    />
                    <Button onClick={() => setShipperModal(true)}>
                      <MdOutlinePermContactCalendar size={20} />
                    </Button>
                  </Input.Group>
                </Form.Item>
                <Form.Item
                  name="shipper"
                  label={
                    <div className="form-item-label">Name and address</div>
                  }
                  style={{ margin: 4 }}
                >
                  <Input.TextArea
                    rows={4}
                    placeholder=""
                    style={styles.FormInput}
                    ref="shipper"
                  />
                </Form.Item>
              </div>
            </Col>
            <Col sm="6" xs="12">
              <div className="awb-form-box" tabIndex={1}>
                <div className="awb-form-box-legend">
                  <div className="form-item-label"> AWB consigment details</div>
                </div>
                <br />
                <table>
                  <tr>
                    <td colSpan={2}>
                      <Form.Item
                        name={"airline"}
                        label={<div className="form-item-label">AWB no</div>}
                      >
                        <Input
                          style={{ width: "calc(100% - 50px)" }}
                          onChange={(e) => onTypenewAwb(e)}
                          maxLength={3}
                          tabIndex={1}
                          ref={tabindex}
                        />
                      </Form.Item>
                    </td>
                    <td colSpan={2}>
                      <Form.Item name={"awb_no"}>
                        <Input
                          showCount
                          style={{
                            width: "calc(100% + 50px)",
                            minWidth: "150px",
                          }}
                          maxLength={7}
                          onChange={(e) => onTypeBillnum(e)}
                          tabIndex={1}
                          rules={[
                            {
                              max: 7,
                              message: "Value should be less than 7 character",
                            },
                          ]}
                        />
                      </Form.Item>
                    </td>
                    <td colSpan={1}>
                      <Input.Group
                        compact
                        style={{ marginBottom: 0, display: "flex" }}
                      >
                        <Form.Item name={"checked_digit"}>
                          <Input maxLength={1} tabIndex={1} readOnly />
                        </Form.Item>
                        <Button onClick={() => setAwbModal(true)} tabIndex={1}>
                          <IoIosSend size={20} />
                        </Button>
                      </Input.Group>
                    </td>
                  </tr>
                </table>
                <Form.Item
                  name="airport_departure_code"
                  label={
                    <div className="form-item-label">Airport of departure</div>
                  }
                  style={{ margin: 4 }}
                >
                  <Input placeholder="" style={styles.FormInput} tabIndex={1} />
                </Form.Item>
              </div>
            </Col>
          </Row>

          <Row className="awb-formodal-form-row">
            <Col xs="6">
              <div className="awb-form-box">
                <div className="awb-form-box-legend">
                  <div className="form-item-label"> Consignee</div>
                </div>
                <br />
                <Form.Item
                  name="cnee_no"
                  label={<div className="form-item-label">Account number</div>}
                  style={{ margin: 4 }}
                >
                  <Input.Group compact>
                    <Input
                      style={{ width: "85%" }}
                      onPressEnter={(e) => onTypeConsignee(e)}
                    />
                    <Button onClick={() => setConsigneeModal(true)}>
                      <MdOutlinePermContactCalendar size={20} />
                    </Button>
                  </Input.Group>
                </Form.Item>
                <Form.Item
                  name="cnee"
                  label={
                    <div className="form-item-label">Name and address</div>
                  }
                  style={{ margin: 4 }}
                >
                  <TextArea
                    placeholder=""
                    style={styles.FormInput}
                    ref="cnee"
                    rows={4}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col xs="6">
              <div className="awb-form-box">
                <div className="awb-form-box-legend">
                  <div className="form-item-label"> Issuer</div>
                </div>
                <br />
                <Form.Item
                  name="issuer"
                  label={<div className="form-item-label">Issued by</div>}
                  style={{ margin: 4 }}
                >
                  <TextArea
                    placeholder=""
                    style={styles.FormInput}
                    tabIndex={1}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>

          <Row className="awb-formodal-form-row">
            <Col xs="6">
              <div className="awb-form-box">
                <div className="awb-form-box-legend">
                  <div className="form-item-label">Issuing carrier's agent</div>
                </div>
                <br />
                <Form.Item
                  name="agent_name_on_awb_no"
                  label={<div className="form-item-label"> Account no.</div>}
                  style={{ margin: 4 }}
                >
                  <Input.Group compact>
                    <Input
                      style={{ width: "85%" }}
                      onPressEnter={(e) => onTypeIssueAgent(e)}
                    />
                    <Button onClick={() => setAgentModal(true)}>
                      <MdOutlinePermContactCalendar size={20} />
                    </Button>
                  </Input.Group>
                </Form.Item>

                <Form.Item
                  name="agent_name_on_awb"
                  label={<div className="form-item-label"> Name and city</div>}
                  style={{ margin: 4 }}
                >
                  <Input placeholder="" style={styles.FormInput} />
                </Form.Item>

                <Form.Item
                  name="iata_code"
                  label={<div className="form-item-label"> IATA Code</div>}
                  style={{ margin: 4 }}
                >
                  <Input placeholder="" />
                </Form.Item>
              </div>
            </Col>
            <Col xs="6">
              <div className="awb-form-box2">
                <div className="awb-form-box-legend">
                  <div className="form-item-label"> Accounting information</div>
                </div>
                <br />
                <Form.Item
                  name="accounting_info_details"
                  label={<div className="form-item-label"> Details</div>}
                  style={{ margin: 4 }}
                >
                  <TextArea
                    placeholder=""
                    style={styles.FormInput}
                    tabIndex={1}
                  />
                </Form.Item>
              </div>
              <br />
              <div className="awb-form-box2">
                <div className="awb-form-box-legend">
                  <div className="form-item-label">
                    Shipment referente information
                  </div>
                </div>
                <br />
                <Form.Item
                  name="shipment_ref_no"
                  label={
                    <div className="form-item-label"> Reference number</div>
                  }
                  style={{ margin: 4 }}
                >
                  <Input placeholder="" tabIndex={1} />
                </Form.Item>

                <Form.Item
                  name="shipment_ref_info"
                  label={<div className="form-item-label"> Information</div>}
                  style={{ margin: 4 }}
                >
                  <Input placeholder="" tabIndex={1} />
                </Form.Item>
              </div>
            </Col>
          </Row>

          <Row className="awb-formodal-form-row">
            <Col xs="6">
              <div className="awb-form-box">
                <div className="awb-form-box-legend">
                  <div className="form-item-label">
                    Routing and flight bookings
                  </div>
                </div>
                <br />
                <Form.Item
                  name="orijin_airport_name"
                  label={<div className="form-item-label"> Departure</div>}
                  style={{ margin: 4 }}
                >
                  <Input placeholder="" style={styles.FormInput} />
                </Form.Item>

                <Row>
                  <Col sm="5">
                    <Form.Item
                      name="route_to"
                      label={<div className="form-item-label"> Route To</div>}
                      style={{ margin: 4 }}
                    >
                      <Input placeholder="" style={styles.FormInput} />
                    </Form.Item>
                  </Col>
                  <Col sm="7">
                    <Form.Item
                      name="by_first_carrier"
                      label={
                        <div className="form-item-label">By first carrier</div>
                      }
                      style={{ margin: 4 }}
                    >
                      <Input placeholder="" style={styles.FormInput} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col sm="3">
                    <Form.Item
                      name="route_to2"
                      label={<div className="form-item-label">To</div>}
                      style={{ margin: 4 }}
                    >
                      <Input placeholder="" />
                    </Form.Item>
                  </Col>
                  <Col sm="3">
                    <Form.Item
                      name="by_carrier2"
                      label={<div className="form-item-label">By</div>}
                      style={{ margin: 4 }}
                    >
                      <Input placeholder="" />
                    </Form.Item>
                  </Col>
                  <Col sm="3">
                    <Form.Item
                      name="route_to3"
                      label={<div className="form-item-label">To</div>}
                      style={{ margin: 4 }}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col sm="3">
                    <Form.Item
                      name="by_carrier3"
                      label={<div className="form-item-label">By</div>}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item
                  name="destination_airport_name"
                  label={<div className="form-item-label">Destination</div>}
                  style={{ margin: 4 }}
                >
                  <Input placeholder="" style={styles.FormInput} />
                </Form.Item>

                <Form.Item
                  name="flight_date"
                  label={<div className="form-item-label">Flight/Date</div>}
                  style={{ margin: 4 }}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    onKeyDown={(val) => onPressTab(val)}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col xs="6">
              <div className="awb-form-box">
                <div className="awb-form-box-legend">
                  <div className="form-item-label">Charges declaration</div>
                </div>
                <br />
                <Form.Item
                  name="currency"
                  label={<div className="form-item-label">Currency</div>}
                  style={{ margin: 4 }}
                >
                  <Input placeholder="" style={styles.FormInput} tabIndex={1} />
                </Form.Item>

                <Form.Item
                  name="value_for_carriage"
                  label={
                    <div className="form-item-label">Value for carriage</div>
                  }
                  style={{ margin: 4 }}
                >
                  <Input placeholder="" style={styles.FormInput} tabIndex={1} />
                </Form.Item>

                <Form.Item
                  name="value_for_customs"
                  label={
                    <div className="form-item-label">Value for customs</div>
                  }
                  style={{ margin: 4 }}
                >
                  <Input
                    placeholder=""
                    style={styles.FormInput}
                    tabIndex={1}
                    onKeyDown={(val) => onPressTab2(val)}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>

          <Row className="awb-formodal-form-row">
            <Col xs="12">
              <div className="awb-form-box">
                <div className="awb-form-box-legend">
                  <div className="form-item-label"> Handling information</div>
                </div>
                <br />
                <Row>
                  <Col xs="8">
                    <Form.Item
                      name="requirments"
                      label={
                        <div className="form-item-label">Requirements</div>
                      }
                      style={{ margin: 4 }}
                    >
                      <TextArea
                        placeholder=""
                        style={styles.FormInput}
                        ref={tabindex2}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs="2">
                    <Form.Item
                      name="sci"
                      label={<div className="form-item-label">SCI</div>}
                      style={{ margin: 4 }}
                    >
                      <Input placeholder="" style={styles.FormInput} />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row className="awb-formodal-form-row">
            <Col xs="12">
              <div className="awb-form-box">
                <div className="awb-form-box-legend">
                  <div className="form-item-label"> Rate Description</div>
                </div>
                <br />
                <Button type="primary" onClick={() => setRateModal(true)}>
                  Add +
                </Button>
                <Button
                  onClick={() => EditRateDesc()}
                  disabled={selectedType === "rate" ? false : true}
                >
                  Edit
                </Button>
                <Button
                  onClick={() => DeleteRateDesc()}
                  disabled={selectedType === "rate" ? false : true}
                >
                  Remove
                </Button>
                <div style={{ margin: 5 }} />
                {refresh ? null : (
                  <RateTable
                    initialdata={props.selected}
                    data={rateDesc}
                    selected={selected}
                    onSelect={(data) => onSelect(data, "rate")}
                  />
                )}
              </div>
            </Col>
          </Row>

          <Row className="awb-formodal-form-row">
            <Col xs="6">
              <div className="awb-form-box">
                <div className="awb-form-box-legend">
                  <div className="form-item-label">Charges summary</div>
                </div>
                <br />
                <table className="awb-form-table">
                  <tr>
                    <th></th>
                    <th>Prepaid </th>
                    <th>Collect</th>
                  </tr>
                  <tr>
                    <td style={{ fontSize: 13 }}>Weight charge</td>
                    <td>
                      <Form.Item name="weight_charge" style={{ margin: 0 }}>
                        <Input placeholder="" disabled />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item name="" style={{ margin: 0 }}>
                        <Input placeholder="" disabled block />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontSize: 13 }}>
                      <div className="form-item-label">Valuation charge</div>
                    </td>
                    <td>
                      <Form.Item name="valuation_charge" style={{ margin: 0 }}>
                        <Input placeholder="" />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item name="" style={{ margin: 0 }}>
                        <Input placeholder="" />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontSize: 13 }}>
                      <div className="form-item-label">Tax</div>
                    </td>
                    <td>
                      <Form.Item name="tax" style={{ margin: 0 }}>
                        <Input placeholder="" />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item name="" style={{ margin: 0 }}>
                        <Input placeholder="" />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontSize: 13 }}>
                      <div className="form-item-label">
                        {" "}
                        Other charges due agent
                      </div>
                    </td>
                    <td>
                      <Form.Item
                        name="other_charges_due_agent"
                        style={{ margin: 0 }}
                      >
                        <Input placeholder="" disabled />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item name="" style={{ margin: 0 }}>
                        <Input placeholder="" disabled />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontSize: 13 }}>
                      <div className="form-item-label">
                        Other charges due carrier
                      </div>
                    </td>
                    <td>
                      <Form.Item
                        name="other_charges_due_carrier"
                        style={{ margin: 0 }}
                      >
                        <Input placeholder="" disabled />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item name="" style={{ margin: 0 }}>
                        <Input placeholder="" disabled />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontSize: 13 }}>
                      <div className="form-item-label">Total</div>
                    </td>
                    <td>
                      <Form.Item name="total_charges" style={{ margin: 0 }}>
                        <Input placeholder="" disabled />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item name="" style={{ margin: 0 }}>
                        <Input placeholder="" disabled />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr style={{ display: "none", visibility: "hidden" }}>
                    <td style={{ fontSize: 13 }}>Total Pieces</td>
                    <td>
                      <Form.Item name="psc" style={{ margin: 0 }}>
                        <Input placeholder="" disabled />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item name="chargable_weight" style={{ margin: 0 }}>
                        <Input placeholder="" disabled />
                      </Form.Item>
                    </td>
                  </tr>
                </table>
              </div>
            </Col>
            <Col xs="6">
              <div className="awb-form-box">
                <div className="awb-form-box-legend">
                  <div className="form-item-label">Other charges</div>
                </div>
                <br />
                <Button type="primary" onClick={() => setOtherModal(true)}>
                  Add +
                </Button>
                <Button
                  onClick={() => EditOtherCharge()}
                  disabled={selectedType === "other" ? false : true}
                >
                  Edit
                </Button>
                <Popconfirm
                  title={"Do you want to delete it"}
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => DeleteOtherCahrges()}
                >
                  <Button disabled={selectedType === "other" ? false : true}>
                    Remove
                  </Button>
                </Popconfirm>

                <div style={{ margin: 5 }} />
                {refresh ? null : (
                  <OtherTable
                    initialdata={props.selected}
                    data={otherCharges}
                    selected={selected}
                    onSelect={(data) => onSelect(data, "other")}
                  />
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="6">
              <div className="awb-form-box">
                <div className="awb-form-box-legend">
                  <div className="form-item-label">
                    CC CHARGES IN DESTINATION CURRENCY
                  </div>
                </div>
                <br />
                <Form.Item
                  name="currency_conv_rate"
                  label={
                    <div className="form-item-label">Currency conv. rates</div>
                  }
                  style={{ margin: 4 }}
                >
                  <Input placeholder="" type="number" />
                </Form.Item>
                <Form.Item
                  name="cc_charges_in_dest"
                  label={
                    <div className="form-item-label">CC charges in dest.</div>
                  }
                  style={{ margin: 4 }}
                >
                  <Input placeholder="" type="number" />
                </Form.Item>
                <Form.Item
                  name="charges_at_dest"
                  label={
                    <div className="form-item-label">Charges at dest.</div>
                  }
                  style={{ margin: 4 }}
                >
                  <Input placeholder="" type="number" />
                </Form.Item>
                <Form.Item
                  name="total_collect"
                  label={<div className="form-item-label">Total collect</div>}
                  style={{ margin: 4 }}
                >
                  <Input placeholder="" type="number" />
                </Form.Item>
              </div>
            </Col>
            <Col xs="6">
              <div className="awb-form-box2">
                <div className="awb-form-box-legend">
                  <div className="form-item-label">SHIPPER'S CERTIFICATION</div>
                </div>
                <br />
                <Form.Item
                  name="shipper_certification"
                  label={"    "}
                  style={{ margin: 4 }}
                >
                  <TextArea placeholder="" style={styles.FormInput} />
                </Form.Item>
                <Form.Item
                  name="shipper_signature"
                  label={<div className="form-item-label">Signature</div>}
                  style={{ margin: 4 }}
                >
                  <Input placeholder="" style={styles.FormInput} />
                </Form.Item>
              </div>
              <br />
              <div className="awb-form-box2">
                <div className="awb-form-box-legend">
                  <div className="form-item-label">CARRIER'S EXECUTION</div>
                </div>
                <br />
                <Form.Item
                  name="carriers_execution"
                  label={"    "}
                  style={{ margin: 4 }}
                >
                  <TextArea placeholder="" style={styles.FormInput} />
                </Form.Item>
                <table>
                  <tr>
                    <td>
                      <Form.Item
                        name="carrier_execution_date"
                        label={<div className="form-item-label">Date</div>}
                        style={{ margin: 4 }}
                      >
                        <DatePicker />
                      </Form.Item>
                    </td>

                    <td>
                      <Form.Item
                        name="carrier_execution_place"
                        label={<div className="form-item-label">Place</div>}
                        style={{ margin: 4 }}
                      >
                        <Input placeholder="" style={styles.FormInput} />
                      </Form.Item>
                    </td>
                  </tr>
                </table>
                <Form.Item
                  name="carrier_execution_sign"
                  label={<div className="form-item-label">Signature</div>}
                  style={{ margin: 4 }}
                >
                  <Input placeholder="" style={styles.FormInput} />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm="6" xs="12"></Col>
            <Col sm="6" xs="12">
              <Row>
                <Col sm="6" xs="12">
                  <Button size="large" onClick={() => props.onClose()} block>
                    Cancel
                  </Button>
                </Col>
                <Col sm="6" xs="12">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    block
                    size="large"
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Form>
      {RateModal ? (
        <FormRateModal
          visible={RateModal}
          data={selected}
          onChange={(val) => AddRateDesc(val)}
          onClose={() => setRateModal(false)}
        />
      ) : null}
      {otherModal ? (
        <FormOtherChargeModal
          visible={otherModal}
          onClose={() => setOtherModal(false)}
          data={selected}
          onChange={(val) => AddOtherCahrges(val)}
        />
      ) : null}
      {shipperModal ? (
        <FormShipperModal
          visible={shipperModal}
          onClose={() => setShipperModal(false)}
          onChange={(val) => chooseShipper(val)}
        />
      ) : null}
      {consigneeModal ? (
        <FormConsigneeModal
          visible={consigneeModal}
          onClose={() => setConsigneeModal(false)}
          onChange={(val) => chooseConsignee(val)}
        />
      ) : null}
      {agentModal ? (
        <FormAgentModal
          visible={agentModal}
          onClose={() => setAgentModal(false)}
          onChange={(val) => chooseIssuAgent(val)}
        />
      ) : null}
      {awbModal ? (
        <FormAwbGenModal
          visible={awbModal}
          onClose={() => setAwbModal(false)}
          onChange={(val) => chooseAWB(val)}
        />
      ) : null}
    </Modal>
  );
}

export default FormModal;

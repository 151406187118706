import React, { useMemo, useState, useEffect } from "react";
import LoadingBox from "../../Components/LoadingBox";
import PageHeader from "../../Routes/PageHeader";
import { API } from "../../Config";
import { Form, Button, Select, Spin, notification } from "antd";
import { GET, POST } from "../../Config/ApiRequests";
import "./styles.css";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";

const UserAccess = () => {
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [form] = Form.useForm();

  const [allUsers, setAllUsers] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [selectedUser, setSelectedUser] = useState(null);
  const [modules, setModules] = useState([]);

  const [section, setSection] = useState([]);

  const [sectionAccess, setSectionAccess] = useState([]);
  const [showData, setShowData] = useState(false);
  const [selectedIds, setSelectedIds] = useState("");
  const [Notifications, contextHolder] = notification.useNotification();

  useEffect(() => {
    getAllModules();
    getAllUsers();
  }, []);

  const reload = () => {
    setLoading1(true);
    getAllUsers();
    getAllModules();
  };

  const getAllUsers = async () => {
    try {
      setLoading1(true);
      const api = API.ALL_USERS;
      const users = await GET(api, null);
      if (users?.status) {
        setAllUsers(users?.data);
        setLoading1(false);
      } else {
        setAllUsers([]);
        setLoading1(false);
      }
    } catch (err) {
      console.log(err);
      setLoading1(false);
    }
  };

  const getAllModules = async () => {
    try {
      setLoading1(true);
      let api = API.LIST_MODULES;
      let modules = await POST(api, {});
      if (modules?.status) {
        setModules(modules?.data);
        setSectionAccess(modules?.data);
        setSection(modules?.data);
        setLoading1(false);
      } else {
        setModules([]);
        setLoading1(false);
      }
    } catch (err) {
      setLoading1(false);
      console.log(err);
    }
  };

  const searchStaff = async (qry) => {
    try {
      setLoading2(true);
      let obj = {
        query: qry,
      };
      const api = API.SEARCH_USER;
      const users = await POST(api, obj);
      if (users?.status) {
        setAllUsers(users?.data);
        setLoading2(false);
      } else {
        setAllUsers([]);
        setLoading2(false);
      }
    } catch (err) {
      setAllUsers([]);
      setLoading2(false);
    }
  };

  const getUserDetails = async () => {
    try {
      setShowData(false);
      setLoading3(true);
      let api = API.ONE_USER + selectedUser;
      let details = await GET(api, null);
      if (details?.status) {
        setUserDetails(details?.data);
        setSelectedIds(details?.data?.user_access);
        setLoading3(false);
        setShowData(true);
      } else {
        setUserDetails({});
        setLoading3(false);
        setShowData(false);
      }
    } catch (err) {
      setLoading3(false);
      setShowData(false);
      console.log(err);
    }
  };

  const getIdsSet = (idsString) => new Set(idsString.split("|"));

  const toggleCheckbox = (id) => {
    const idsSet = getIdsSet(selectedIds);
    if (idsSet.has(id)) {
      idsSet.delete(id);
    } else {
      idsSet.add(id);
    }
    setSelectedIds(Array.from(idsSet).join("|"));
  };

  const allSelected = useMemo(() => {
    const idsSet = getIdsSet(selectedIds);
    return modules.every((module) => idsSet.has(module.id));
  }, [selectedIds, modules]);

  const toggleSelectAll = () => {
    if (allSelected) {
      setSelectedIds("");
    } else {
      const allIds = modules.map((module) => module.id).join("|");
      setSelectedIds(allIds);
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading3(true);
      const api = API.UPDATE_ACCESS;
      let obj = {
        user_id: Number(selectedUser),
        access: selectedIds,
      };
      const update = await POST(api, obj);
      if (update?.status) {
        Notifications["success"]({
          message: "Updated",
        });
        reload();
        setLoading3(false);
      } else {
        Notifications["error"]({
          message: "Unable to update",
        });
        setLoading3(false);
      }
    } catch (error) {
      Notifications["error"]({
        message: "Server error",
      });
      setLoading3(false);
      console.log(error);
    }
  };

  return (
    <div>
      {contextHolder}
      <PageHeader PageName={"User Access"} breadScrum={"Admin / UserAccess"} />
      {loading1 ? (
        <LoadingBox />
      ) : (
        <>
          <div className="card1">
            <Form form={form} layout="vertical">
              <br />
              <Container>
                <div className="card-header">Select Staff</div>
                <br />
                <Row>
                  <Col md="4">
                    <Form.Item
                      name="from_date"
                      rules={[{ required: true, message: "Required" }]}
                    >
                      <Select
                        size="large"
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        placeholder="Select User"
                        onChange={(data, val) => {
                          setSelectedUser(data);
                        }}
                        onSearch={(val, data) => {
                          searchStaff(val);
                        }}
                      >
                        {allUsers?.length &&
                          allUsers?.map((user) => {
                            return (
                              <Select.Option value={user?.id}>
                                {user?.name}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col md={1} />

                  <Col md={2}>
                    <Button
                      size="large"
                      style={{ width: "100%" }}
                      loading={loading2}
                      onClick={() => getUserDetails()}
                    >
                      Search
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Form>
          </div>
          <br />

          {showData ? (
            <div className="card1">
              <Container>
                {userDetails?.id && (
                  <>
                    <Row>
                      <Col md="4">
                        <div className="text-item">
                          <div className="text-label">Name</div>
                          <div className="text-value">{userDetails?.name}</div>
                        </div>
                      </Col>

                      <Col md="4">
                        <div className="text-item">
                          <div className="text-label">Email</div>
                          <div className="text-value">{userDetails?.email}</div>
                        </div>
                      </Col>

                      <Col md="4">
                        <div className="text-item">
                          <div className="text-label">Branch</div>
                          <div className="text-value">
                            {userDetails?.branch?.name}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <hr />
                    <br />
                  </>
                )}

                <br />
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <Row>
                    <Col md="10">
                      <div className="txt1">Select Screens</div>
                    </Col>
                    <Col md="2">
                      <div
                        onClick={toggleSelectAll}
                        style={{ cursor: "pointer" }}
                      >
                        <input
                          type="checkbox"
                          checked={allSelected}
                          onChange={toggleSelectAll}
                        />
                        &nbsp;&nbsp; Select All Screens
                      </div>
                    </Col>
                  </Row>
                  <br />

                  <table style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th>Module Name</th>
                        <th>Description</th>
                      </tr>
                    </thead>

                    <tbody>
                      {modules?.map((module) => (
                        <tr
                          key={module.id}
                          onClick={() => toggleCheckbox(module.module_id)}
                        >
                          <td
                            style={{
                              backgroundColor: "#ffff",
                              width: "2%",
                              textAlign: "left",
                            }}
                            className="table-td"
                          >
                            {module.module_id}
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                              width: "3%",
                            }}
                            className="table-td"
                          >
                            <input
                              type="checkbox"
                              checked={getIdsSet(selectedIds).has(
                                module.module_id
                              )}
                              onChange={() => toggleCheckbox(module.module_id)}
                            />
                          </td>
                          <td className="MC-table-Txt table-td">
                            {module.modules_name}
                          </td>
                          <td className="MC-table-Txt table-td">
                            {module.description}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <br />
                  <Row>
                    <Col md="10"></Col>
                    <Col md="2">
                      <button className="submit-btn" type="submit">
                        {loading3 ? <Spin /> : "Save"}
                      </button>
                    </Col>
                  </Row>
                  <br />
                </form>
              </Container>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default UserAccess;

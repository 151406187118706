// import React, { useState } from "react";
// import { useParams } from "react-router-dom";

// const Voucher = () => {
//   const { mode } = useParams();
//   console.log(mode);
//   const [message, setMessage] = useState("");

//   const handleClick = () => {
//     setMessage("Button clicked!");
//   };

//   return (
//     <div>
//       <button onClick={handleClick}>Click Me</button>
//       <p>{message}</p>
//     </div>
//   );
// };

// export default Voucher;

import React, { useState, useEffect } from "react";
import PageHeader from "../../Routes/PageHeader";
import FormModal from "./FormModal";
import DataTable from "./DataTable";
import axios from "axios";
import { message } from "antd";
import { API, Headers } from "../../Config";
import LoadingBox from "../../Components/LoadingBox";
import { useParams } from "react-router-dom";
const Voucher = () => {
  const { page_mode } = useParams();
  console.log(page_mode);
  const [loading, setLoading] = useState(true);
  const [indicator, setIndicator] = useState(false);
  const [form, setform] = useState(false);
  const [mode, setMode] = useState("post");
  const [head, setHead] = useState("");
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({});
  const [item, setItem] = useState({});
  const [accounts, setAccounts] = useState([]);
  const [columnsVisible, setColumnsVisible] = useState({});
  // useEffect(() => {
  //   if (page_mode == "PV") {
  //     setHead("Payment Voucher");
  //   } else if ("RV") {
  //     setHead("Reciept Voucher");
  //   } else {
  //     setHead("Journal Voucher");
  //   }

  // }, []);

  useEffect(() => {
    switch (page_mode) {
      case "RV":
        setColumnsVisible({
          page: "Reciept Voucher",
          short: "RV",
          amount_cr: true,
          amount_dr: false,
          accounts: true,
        });
        break;
      case "PV":
        setColumnsVisible({
          page: "Payment Voucher",
          short: "PV",
          amount_cr: true,
          amount_dr: true,
          accounts: true,
        });
        break;
      case "JV":
        setColumnsVisible({
          page: "Journal Voucher",
          short: "JV",
          amount_cr: true,
          amount_dr: true,
          accounts: false,
        });
        break;
      default:
        // Handle the default case if needed
        break;
    }
    loaddata(page, take);
    AccountsDropdown();
  }, []);

  const loaddata = (p, t) => {
    axios
      .get(
        API.BASR_URL +
          API.VOUCHER_LIST +
          `/${page_mode}?order=DESC&page=${p}&take=${t}`,
        {
          headers: Headers(),
        }
      )
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setData(data.data);
          setMeta(data.meta);
          message.success("Success");
        } else {
          message.error("Oops.Something went wrong");
        }
        setLoading(false);
        setIndicator(false);
      })
      .catch(function (error) {
        message.error("Oops.Something went wrong");
        setIndicator(false);
        setLoading(false);
      });
  };
  const AccountsDropdown = () => {
    axios
      .get(API.BASR_URL + API.CHART_OF_ACCOUNTS_LIST + `/child_accounts`, {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) setAccounts(res.data);
      })
      .catch(function (error) {
        message.error("Customers dropdown faild");
      });
  };
  const pagination = (page, take) => {
    window.scrollTo(0, 0);
    setIndicator(true);
    setPage(page);
    setTake(take);
    loaddata(page, take);
  };

  const relaod = () => {
    loaddata(page, take);
    setMode("post");
    setItem({});
    setform(false);
  };

  const AddItem = () => {
    setMode("post");
    setItem({});
    setform(true);
  };

  const UpdateItem = (item) => {
    setMode("put");
    setItem(item);
    setform(true);
  };

  const DeleteItem = (item) => {
    setIndicator(true);
    axios
      .delete(API.BASR_URL + API.VOUCHER_ACTIONS + item.id, {
        headers: Headers(),
      })
      .then(function (response) {
        setIndicator(false);
        message.success("Deleted Successfully");
        relaod();
      })
      .catch(function (error) {
        setIndicator(false);
        message.error("Oops.Something went wrong");
        console.log(error);
      });
  };

  return (
    <div>
      <PageHeader
        PageName={head}
        breadScrum={`Admin / ${columnsVisible.page}`}
        RightButton={`New / ${columnsVisible.page}`}
        RightClcik={() => AddItem()}
        loader={indicator}
      />
      {loading ? (
        <LoadingBox />
      ) : (
        <div>
          <DataTable
            columnsVisible={columnsVisible}
            meta={meta}
            data={data}
            update={(item) => UpdateItem(item)}
            delete={(item) => DeleteItem(item)}
            PageOnchange={(page, take) => pagination(page, take)}
          />
          {form ? (
            <FormModal
              page_mode={page_mode}
              accounts={accounts}
              head={head}
              item={item}
              mode={mode}
              visible={form}
              columnsVisible={columnsVisible}
              onchange={() => relaod()}
              onClose={() => setform(false)}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};
export default Voucher;

import React, { useMemo, useState, useEffect } from "react";
import LoadingBox from "../../Components/LoadingBox";
import PageHeader from "../../Routes/PageHeader";
import { API } from "../../Config";
import { Form, Button, Select, Spin, notification } from "antd";
import { GET, POST } from "../../Config/ApiRequests";

import { Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";

import { GrDocumentCsv } from "react-icons/gr";
import * as XLSX from "xlsx";

const UserAccess = () => {
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [form] = Form.useForm();
  const [allUsers, setAllUsers] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(20);
  const [meta, setMeta] = useState({});
  const [showData, setShowData] = useState(false);
  const [selectedIds, setSelectedIds] = useState("");
  const [Notifications, contextHolder] = notification.useNotification();
  const [excel, setExcel] = useState(false);

  console.log("userDetails", userDetails);

  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllUsers = async () => {
    try {
      setLoading1(true);
      const api = API.BRANCHES_LIST + `?order=ASC&page=1&take=50`;
      const users = await GET(api, {});

      if (users) {
        setAllUsers(users?.data);
        setLoading1(false);
      } else {
        setAllUsers([]);
        setLoading1(false);
      }
    } catch (err) {
      console.log(err);
      setLoading1(false);
    }
  };

  const searchPhoneNumber = async (qry) => {
    try {
      setLoading2(true);
      let obj = {
        query: qry,
        branch_id: Number(selectedUser),
      };
      const api = API.PICKUPCOLLECTIONS_LIST_SEARCH;
      const users = await POST(api, obj);
      if (users) {
        setAllUsers(users?.data);
        setMeta(users?.meta);
        setLoading2(false);
      } else {
        setAllUsers([]);
        setLoading2(false);
      }
    } catch (err) {
      setAllUsers([]);
      setLoading2(false);
    }
  };

  const getPhoneDetails = async () => {
    try {
      setShowData(false);
      setLoading3(true);
      let api = API.PICKUPCOLLECTIONS_LIST_SEARCH;
      let obj = {
        branch_id: Number(selectedUser),
        page: page,
        take: take,
      };
      let details = await POST(api, obj);
      if (details?.status) {
        setUserDetails(details?.data);
        // setSelectedIds(details?.data?.user_access);
        setLoading3(false);
        setShowData(true);
      } else {
        setUserDetails({});
        setLoading3(false);
        setShowData(false);
      }
    } catch (err) {
      setLoading3(false);
      setShowData(false);
      console.log(err);
    }
  };

  const downloadExcel = (data) => {
    const workbook = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(data);
    sheet["!cols"] = [
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
    ];

    sheet["!margins"] = {
      left: 0.7,
      right: 0.7,
      top: 0.75,
      bottom: 0.75,
      header: 0.3,
      footer: 0.3,
    };
    sheet["!page"] = { paperSize: 9 };

    XLSX.utils.book_append_sheet(workbook, sheet, "Phone Numbers");

    XLSX.writeFile(workbook, "branchlist.xlsx");
  };

  return (
    <div>
      <>
        <div>
          {contextHolder}

          <PageHeader
            PageName={"Phone Numbers "}
            breadScrum={"Admin / PhoneNumbers"}
          />
          {loading1 ? (
            <LoadingBox />
          ) : (
            <div className="card1">
              <Form form={form} layout="vertical">
                <br />
                <Container>
                  <div className="card-header">Select Branches Name</div>
                  <br />
                  <Row>
                    <Col md="4">
                      <Form.Item
                        name="from_date"
                        rules={[{ required: true, message: "Required" }]}
                      >
                        <Select
                          size="large"
                          allowClear
                          showSearch
                          optionFilterProp="children"
                          placeholder="Select Branches"
                          onChange={(data, val) => {
                            setSelectedUser(data);
                            console.log(data);
                            console.log(val);
                          }}
                          onSearch={(val, data) => {
                            searchPhoneNumber(val);
                          }}
                        >
                          {allUsers?.length &&
                            allUsers?.map((user) => {
                              return (
                                <Select.Option value={user?.id}>
                                  {user?.name}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col md={2}>
                      <Button
                        size="large"
                        style={{ width: "100%" }}
                        loading={loading2}
                        onClick={() => getPhoneDetails()}
                      >
                        Search
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Form>
            </div>
          )}
        </div>
        <br />

        {/* {showData ? ( */}
        <div className="card1">
          <Container>
            {userDetails.length > 0 && (
              <>
                <Row>
                  <Col md="4">
                    <div className="text-item">
                      <div className="text-label">sender_name</div>
                      <div className="text-value">
                        {userDetails?.sender_name}
                      </div>
                    </div>
                  </Col>

                  <Col md="4">
                    <div className="text-item">
                      <div className="text-label">sender_phone</div>
                      <div className="text-value">
                        {userDetails?.sender_phone}
                      </div>
                    </div>
                  </Col>

                  <Col md="4">
                    <div className="text-item">
                      <div className="text-label">sender_phone_alt</div>
                      <div className="text-value">
                        {userDetails?.sender_phone_alt}
                      </div>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="text-item">
                      <div className="text-label">reciver_name</div>
                      <div className="text-value">
                        {userDetails?.reciver_name}
                      </div>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="text-item">
                      <div className="text-label">reciver_phone</div>
                      <div className="text-value">
                        {userDetails?.reciver_phone}
                      </div>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="text-item">
                      <div className="text-label">reciver_altphone</div>
                      <div className="text-value">
                        {userDetails?.reciver_altphone}
                      </div>
                    </div>
                  </Col>
                </Row>
                <hr />
                <br />
              </>
            )}

            <br />
            <Button
              icon={<GrDocumentCsv />}
              onClick={() => downloadExcel(userDetails)}
            >
              Download as Excel
            </Button>

            <div className="card1">
              <Container>
                <table className="table">
                  <thead>
                    <tr>
                      <th>sender_name</th>
                      <th>sender_phone_alt</th>
                      <th>sender_phone</th>
                      <th>reciver_name</th>
                      <th>reciver_phone</th>
                      <th>reciver_altphone</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userDetails.map((userDetail, index) => (
                      <React.Fragment key={index}>
                        <tr>
                          <td>{userDetail.sender_name}</td>

                          <td>{userDetail.sender_phone}</td>

                          <td>{userDetail.sender_phone_alt}</td>

                          <td>{userDetail.reciver_name}</td>

                          <td>{userDetail.reciver_phone}</td>

                          <td>{userDetail.reciver_altphone}</td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </Container>
            </div>
          </Container>
        </div>
        {/* ) : null} */}
      </>
      {/* {excel ? (
        <ExcelDeliveryList
          //   selected={props.selected}
          visible={excel}
          onClose={() => setExcel(false)}
        />
      ) : null} */}
    </div>
  );
};

export default UserAccess;

import React, { useEffect } from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
export default function DeliveryList(props) {
  useEffect(() => {}, []);
  return (
    <Document>
      <Page size="A4" wrap>
        <View>
          <Text style={styles.Title}>
            {props.awb && props.awb.orijin_airport_name} -
            {props.awb && props.awb.destination_airport_name} - (
            {props.awb.awb_no})
          </Text>
        </View>
        <View style={styles.Table}>
          <View style={styles.TableHeader}>
            <View style={styles.TableItem}>
              <Text style={styles.TableItemtxt}>Bill No</Text>
            </View>
            <View style={styles.TableItem}>
              <Text style={styles.TableItemtxt}>Pcs</Text>
            </View>
            <View style={styles.TableItem}>
              <Text style={styles.TableItemtxt}>WEIGHT </Text>
            </View>
            <View style={styles.TableItem}>
              <Text style={styles.TableItemtxt}>BOX No</Text>
            </View>
            <View style={styles.TableItem2}>
              <Text style={styles.TableItemtxt}>SHIPPER </Text>
            </View>
            <View style={styles.TableItem2}>
              <Text style={styles.TableItemtxt}>CONSIGNEE </Text>
            </View>
            <View style={styles.TableItem}>
              <Text style={styles.TableItemtxt}>STATE </Text>
            </View>
            <View style={styles.TableItem}>
              <Text style={styles.TableItemtxt}>DIST </Text>
            </View>
          </View>
          {props.data
            ? props.data.map((item) => {
                return (
                  <View style={styles.TableRow}>
                    <View style={styles.TableItem2}>
                      <Text>{item.id} </Text>
                    </View>
                    <View style={styles.TableItem2}>
                      <Text>{item.item_count}</Text>
                    </View>
                    <View style={styles.TableItem2}>
                      <Text>{item.volume_weight}</Text>
                    </View>
                    <View style={styles.TableItem2}>
                      <Text>{item.box_number}</Text>
                    </View>
                    <View style={styles.TableItem2}>
                      <Text>
                        {item.collection.sender_name} , PHONE :
                        {item.collection.sender_address},
                        {item.collection.sender_phone}
                      </Text>
                    </View>
                    <View style={styles.TableItem2}>
                      <Text>
                        {item.collection.reciver_name},
                        {item.collection.reciver_address},
                        {item.collection.reciver_distrcit}, PIN:{" "}
                        {item.collection.reciver_pincode}, PHONE:{" "}
                        {item.collection.reciver_phone},
                        {item.collection.reciver_altphone},
                        {item.collection.reciver_country},
                      </Text>
                    </View>
                    <View style={styles.TableItem2}>
                      <Text> {item.collection.reciver_state}</Text>
                    </View>
                    <View style={styles.TableItem2}>
                      <Text>{item.collection.reciver_distrcit}</Text>
                    </View>
                  </View>
                );
              })
            : null}
        </View>
      </Page>
    </Document>
  );
}

const styles = StyleSheet.create({
  Title: {
    textAlign: "center",
    fontSize: "16px",
    color: "red",
    margin: 10,
  },
  Table: {
    margin: 10,
    borderColor: "red",
    borderWidth: 0.5,
    fontSize: "10px",
  },
  TableHeader: {
    display: "flex",
    flexDirection: "row",
    height: "20px",
  },
  TableRow: {
    display: "flex",
    flexDirection: "row",
  },
  TableItem: {
    borderWidth: 0.5,
    borderColor: "red",
    flex: 1,
    padding: 2,
    alignItems: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  TableItemtxt: {
    fontSize: 10,
    color: "#000",
    flex: 1,
  },
  TableItem2: {
    borderWidth: 0.5,
    borderColor: "red",
    flex: 1,
    padding: 2,
    alignItems: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  TableItemtxt2: {
    fontSize: 7,
    color: "#000",
    flex: 1,
  },
});

import React, { useEffect, useRef, useState } from "react";
import { Button, message, Modal } from "antd";
import axios from "axios";
import moment from "moment";
import Barcode from "react-barcode";
import { BlobProvider } from "@react-pdf/renderer";
import ReactToPrint from "react-to-print";
import { API, Headers } from "../../Config";
import LoadingBox from "../../Components/LoadingBox";
import PdfTripSheet from "./PdfTripSheet";
import logo from "../../Assets/Images/logoNew.jpg";

function PrintTripSheet(props) {
  const componentRef = useRef();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    loadBoxesViaTrip();
  }, []);

  console.log(props);

  const loadBoxesViaTrip = () => {
    const TripID = props.selected && props.selected.id;
    let URL = API.BASR_URL + API.BOXES_VIA_TRIP + TripID;
    axios
      .get(URL, {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setData(data);
          setIsLoading(false);
        } else {
          message.error("Ooops. Something went wrong...!");
          setIsLoading(false);
        }
      });
  };

  let TotalWeight = props.selected.boxes.reduce(function (tot, arr) {
    return Number(Number(tot) + Number(arr.chargable_weight)).toFixed(2);
  }, 0);
  let TotalPieces = props.selected.boxes.length;

  const download = (url) => {
    window.open(url);
  };

  return (
    <div>
      <Modal
        visible={props.visible}
        onCancel={props.onCancel}
        footer={false}
        width={900}
      >
        {isLoading ? (
          <LoadingBox />
        ) : (
          <div>
            <div>
              <ReactToPrint
                trigger={() => <Button type="primary">Print</Button>}
                content={() => componentRef.current}
              />
              <BlobProvider
                document={
                  <PdfTripSheet data={data} selected={props.selected} />
                }
                fileName={"TaxInvoice.pdf"}
              >
                {({ blob, url, loading, error }) => {
                  return loading ? null : (
                    <Button onClick={() => download(url)}>Download</Button>
                  );
                }}
              </BlobProvider>
            </div>
            <div className="PrintTripSheet-page" ref={componentRef}>
              <table className="PrintTripSheet-table">
                <tr>
                  <td colSpan={2}>
                    <div>
                      <img src={logo} width="" className="InvoiceLogo" />
                    </div>
                  </td>
                  <td colSpan={2}></td>
                  <td colSpan={2}>
                    <div>
                      <Barcode
                        value={props.selected.id}
                        height="60"
                        fontSize="12px"
                        font="Montserrat-Regular"
                      />
                    </div>
                    <div className="print-invoice-table-headertxt1">
                      VAT NO: 300602378400003
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <div className="PrintTripSheet-txt1">
                      DATE : {moment(props.selected.createdAt).format("l")}
                    </div>
                  </td>
                  <td colSpan={2}></td>
                  <td colSpan={2}>
                    <div className="PrintTripSheet-txt1">
                      FROM :{" "}
                      {props.selected.loggin_branch.name &&
                        props.selected.loggin_branch.name}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <div className="PrintTripSheet-txt1">
                      TRIP NO :{props.selected.id}
                    </div>
                  </td>
                  <td colSpan={2}></td>
                  <td colSpan={2}>
                    <div className="PrintTripSheet-txt1">
                      TO :{" "}
                      {props.selected.to_branch.name &&
                        props.selected.to_branch.name}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <div className="PrintTripSheet-txt1">
                      VEHICLE NO : {props.selected.truck_no}
                    </div>
                  </td>
                  <td colSpan={2}></td>
                  <td colSpan={2}>
                    <div className="PrintTripSheet-txt1">
                      TOTAL PCS : {TotalPieces}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <div className="PrintTripSheet-txt1">
                      DRIVER NAME : {props.selected.driver_name}
                    </div>
                  </td>
                  <td colSpan={2}></td>
                  <td colSpan={2}>
                    <div className="PrintTripSheet-txt1">
                      TOTAL WEIGHT :{TotalWeight}
                    </div>
                  </td>
                </tr>
                <br />
                <tr>
                  <td colSpan={6}>
                    <table className="PrintTripSheet-table">
                      <thead style={{ background: " rgb(173, 173, 173)" }}>
                        <tr>
                          <td className="PrintTripSheet-table-td">BOX NO</td>
                          <td className="PrintTripSheet-table-td">WEIGHT</td>
                          <td className="PrintTripSheet-table-td">SERVICE</td>
                        </tr>
                      </thead>
                      <tbody>
                        {props.selected.boxes &&
                          props.selected.boxes.map((item, index) => {
                            return (
                              <tr>
                                <td className="PrintTripSheet-table-td">
                                  {item.box_number}
                                </td>
                                <td className="PrintTripSheet-table-td">
                                  {item.chargable_weight}
                                </td>
                                <td className="PrintTripSheet-table-td">
                                  {item.service && item.service.name}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default PrintTripSheet;

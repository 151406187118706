import React, { useState } from "react";
import { Drawer, Form, Button, Input, Select, Radio, Space } from "antd";
import { Container, Row, Col } from "react-bootstrap";
import CircularProgress from "@mui/material/CircularProgress";
import API from "../../Config/API";
const { Option } = Select;
function FormModal(props) {
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  return (
    <Drawer
      placement="right"
      title={<span className="txt4">{props.mode} Staff</span>}
      width={720}
      onClose={() => props.onClose()}
      visible={props.visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form layout="vertical" hideRequiredMark onFinish={onFinish}>
        <Container>
          <Row>
            <Col sm="6" xs="12">
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: "required" }]}
              >
                <Input placeholder="Enter Name" />
              </Form.Item>

              <Form.Item name="contact" label="contact Number">
                <Input placeholder="Enter Number" />
              </Form.Item>
              <Form.Item
                name="type"
                label="Type"
                rules={[{ required: true, message: "required" }]}
              >
                <Select placeholder="select Type">
                  <Option>India</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="branch"
                label="Branch"
                rules={[{ required: true, message: "required" }]}
              >
                <Select placeholder="select Branch">
                  <Option>India</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col sm="6" xs="12">
              <Form.Item name="username" label="Username">
                <Input placeholder="Enter username" />
              </Form.Item>
              <Form.Item name="email" label="Email">
                <Input placeholder="Enter Email" />
              </Form.Item>
              <Form.Item name="password" label="Password">
                <Input.Password placeholder="Enter Password" />
              </Form.Item>
              <Form.Item name="password" label="Confirm Password">
                <Input.Password placeholder="Enter Password" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Radio.Group>
              <Radio value="a">Admin</Radio>
              <Radio value="b">Office Staff</Radio>
              <Radio value="c">Delivery Boy</Radio>
              <Radio value="d">Pickup staff</Radio>
            </Radio.Group>
          </Form.Item>
          <Space>
            <Button onClick={() => props.onClose()}>Cancel</Button>
            <Button type="primary" htmlType="submit">
              {loading ? (
                <CircularProgress size={20} style={{ color: "#fff" }} />
              ) : (
                "Submit"
              )}
            </Button>
          </Space>
        </Container>
      </Form>
    </Drawer>
  );
}
export default FormModal;

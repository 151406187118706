import React, { useState } from "react";
import { Modal, Form, Select, Button, message } from "antd";
import { Row, Col } from "react-bootstrap";
import axios from "axios";
import { API, Headers } from "../../Config";

function AssignDriversModal(props) {
  const { Option } = Select;

  const [loading, setLoading] = useState(false);

  const onFinish = (val) => {
    setLoading(true);
    let obj = {
      assigned_to: Number(val.assigned_to),
    };
    axios({
      method: "put",
      url: API.BASR_URL + API.PICKUP_COLLECTION_REQUEST_ACTIONS + props.item.id,
      headers: Headers(),
      data: obj,
    })
      .then(function (response) {
        if (response.status === 200) {
          message.success("Success");
          setLoading(false);
          props.onCancel();
          props.onChange();
        }
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
        message.error("Oops.Something went wrong");
      });
  };

  return (
    <div>
      <Modal
        visible={props.visible}
        onCancel={props.onCancel}
        title={null}
        footer={null}
        maskClosable={false}
      >
        <br />
        <h6>Assign Drivers</h6>
        <br />
        <div>
          <Form onFinish={onFinish}>
            <Form.Item name="assigned_to" label="Drivers">
              <Select>
                {props.users &&
                  props.users.map((option) => (
                    <Option key={option.id}>{option.name}</Option>
                  ))}
              </Select>
            </Form.Item>
            <Row>
              <Col sm="6" xs="6"></Col>
              <Col sm="3" xs="3">
                <Button block onClick={() => props.onCancel()}>
                  Cancel
                </Button>
              </Col>
              <Col sm="3" xs="3">
                <Button
                  type="primary"
                  block
                  htmlType="submit"
                  loading={loading}
                >
                  Assign
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </div>
  );
}

export default AssignDriversModal;

import React, { useEffect, useState } from "react";
import { Modal, Table, Input, message, Button, Tooltip } from "antd";

function PincodeSearchModel(props) {
  const coloums = [
    {
      key: "Pincode",
      title: "PIN",
      dataIndex: "pincode",
      render: (text) => <div className="collection-table-item">{text}</div>,
    },
    {
      key: "Office",
      title: "POST",
      dataIndex: "office",
      render: (text) => <div className="collection-table-item">{text}</div>,
    },
    {
      key: "District",
      title: "DISTRICT",
      dataIndex: "district",
      render: (text) => <div className="collection-table-item">{text}</div>,
    },
    {
      key: "State",
      title: "STATE",
      dataIndex: "state",
      render: (text) => <div className="collection-table-item">{text}</div>,
    },
    {
      key: "country_id",
      title: "COUNTRY",
      dataIndex: "country_id",
      render: (text, record) => (
        <div className="collection-table-item">
          {record.countries && record.countries.name}
        </div>
      ),
    },

    {
      title: "ACTION",
      dataIndex: "",
      key: "",
      render: (item) => (
        <div>
          <Button onClick={() => props.select(item)} size="small">
            Fetch
          </Button>
        </div>
      ),
    },
  ];

  return (
    <Modal
      visible={props.visible}
      onCancel={props.onClose}
      footer={false}
      width={900}
    >
      <br />
      <div>
        <Table columns={coloums} dataSource={props.data} size="small" />
      </div>
    </Modal>
  );
}
export default PincodeSearchModel;

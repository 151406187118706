import React, { useState, useEffect } from "react";
import PageHeader from "../../Routes/PageHeader";
import TabHeader from "../../Routes/TabHeader";
import FormModal from "./FormModal";
import DataTable from "./DataTable";
import axios from "axios";
import { message } from "antd";
import { API, Headers } from "../../Config";
import LoadingBox from "../../Components/LoadingBox";

import DetailsModal from "./DetailsModal";
import UploadKYCModal from "./UploadKYCModal";
import PrintBarcodeModal from "./PrintBarcodeModal";
import KYCupdate from "./KYCupdateModal";
import CashRcptModal from "./CashRcptModal";
import PaymentRcptModal from "./PaymentRcptModal";

import PrintInvoiceModal from "./PrintInvoiceModal";
import PrintCustomerInvoiceModal from "./PrintCustomerInvoiceModal";
import PrintCustomerInvoiceModal2 from "./PrintCustomerInvoiceModal2";
import PrintTaxInvoiceModal from "./PrintTaxInvoiceModal";
import PrintCustomesModal from "./PrintCustomesModal";
import PrintCustomesModalB from "./PrintCustomesModal-B";
import PrintBoxNumModal from "./PrintBoxNumModal";
import PrintKYCModal from "./PrintKYCModal";
import DownloadTiffModal from "./DownloadTiffModal";

function BoxesScreen() {
  const [loading, setLoading] = useState(true);
  const [indicator, setIndicator] = useState(false);
  const [form, setform] = useState(false);
  const [mode, setMode] = useState("post");
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({});
  const [item, setItem] = useState({});

  const [details, setdetails] = useState(false);

  const [uploadKYC, setUploadKYC] = useState(false);
  const [record, setRecord] = useState(null);
  const [fileType, setFileType] = useState("");
  const [uploading, setuploading] = useState(false);
  const [currentId, setCurrentId] = useState();

  const [updateKYC, setUpdateKYC] = useState(false);
  const [cashRcpt, setCashRcpt] = useState(false);
  const [payRcpt, setPayRcpt] = useState(false);
  const [print, setPrint] = useState(false);
  const [invoice, setInvoice] = useState(false);
  const [custinvoice, setCustInvoice] = useState(false);
  const [custinvoice2, setCustInvoice2] = useState(false);
  const [taxinvoice, setTaxInvoice] = useState(false);

  const [customs, setCustoms] = useState(false);
  const [customsB, setCustomsB] = useState(false);
  const [billp, setBillp] = useState(false);
  const [printkyc, setPrintKYC] = useState(false);
  const [downoladtiff, setDownoladtiff] = useState(false);

  const [collectiond, setCollectiond] = useState([]);

  const [search, setSearch] = useState();

  useEffect(() => {
    loaddata(page, take);
    CollectionDetails(page, take);
  }, []);

  const loaddata = (p, t) => {
    axios
      .get(API.BASR_URL + API.BOXES_LIST + `?order=DESC&page=${p}&take=${t}`, {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setData(data.data);
          setMeta(data.meta);
          message.success("Success");
        } else {
          message.error("Oops.Something went wrong");
        }
        setLoading(false);
        setIndicator(false);
      })
      .catch(function (error) {
        message.error("Oops.Something went wrong");
        setIndicator(false);
        setLoading(false);
      });
  };

  const CollectionDetails = (p, t) => {
    axios
      .get(
        API.BASR_URL +
          API.PICKUPCOLLECTIONS_LIST +
          `?order=DESC&page=${p}&take=${t}`,
        {
          headers: Headers(),
        }
      )
      .then(function (res) {
        if (res.status === 200) setCollectiond(res.data);
      })
      .catch(function (error) {
        // message.error("fetching collection details failed");
      });
  };

  const pagination = (page, take) => {
    window.scrollTo(0, 0);
    setIndicator(true);
    setPage(page);
    setTake(take);
    loaddata(page, take);
  };

  const relaod = () => {
    setIndicator(true);
    loaddata(page, take);
    setMode("post");
    setItem({});
    setform(false);
  };

  // const AddItem = () => {
  //   setMode("post");
  //   setItem({});
  //   setform(true);
  // };

  const UpdateItem = (item) => {
    setMode("put");
    setItem(item);
    setform(true);
  };

  const DeleteItem = (item) => {
    setIndicator(true);
    axios
      .delete(API.BASR_URL + API.BOXES_ACTIONS + `delete/` + item.box_number, {
        headers: Headers(),
      })
      .then(function (response) {
        setIndicator(false);
        message.success("Deleted Successfully");
        relaod();
      })
      .catch(function (error) {
        setIndicator(false);
        message.error("Oops.Something went wrong");
        console.log(error);
      });
  };
  const showDetails = (item) => {
    setItem(item);
    setdetails(!details);
  };
  const updateKYCData = (item) => {
    setUpdateKYC(item);
    setItem(item);
    setUpdateKYC(!updateKYC);
  };

  const reload = () => {
    setIndicator(true);
    setUpdateKYC(false);
    loaddata(page, take);
  };

  const updateCashRcpt = (item) => {
    setCashRcpt(item);
    setItem(item);
  };
  const updatePayRcpt = (item) => {
    setPayRcpt(item);
    setItem(item);
  };
  const updatePrint = (item) => {
    setPrint(true);
    setItem(item);
  };
  const updateInvoice = (item) => {
    setInvoice(item);
    setItem(item);
  };
  const updateCustInvoice = (item) => {
    setCustInvoice(item);
    setItem(item);
  };
  const updateCustInvoice2 = (item) => {
    setCustInvoice2(item);
    setItem(item);
  };
  const updateTaxInvoice = (item) => {
    setTaxInvoice(item);
    setItem(item);
  };
  const updateCustoms = (item) => {
    setCustoms(item);
    setItem(item);
  };
  const updateCustomsB = (item) => {
    setCustomsB(item);
    setItem(item);
  };
  const updatedownoladtiff = (item) => {
    setDownoladtiff(item);
    setItem(item);
  };
  const updateBillP = (item) => {
    setBillp(item);
    setItem(item);
  };

  const PrintKYC = (item) => {
    setItem(item);
    setPrintKYC(true);
  };

  const setUplaodingStatus = (status) => {
    setuploading(status);
  };

  const showUploadKYCModal = (record, fileType) => {
    setRecord(record);
    setFileType(fileType);
    setCurrentId(record.id);
    setUploadKYC(true);
  };

  const onSearch = (val) => {
    setSearch(val);
    setIndicator(true);
    axios
      .get(API.BASR_URL + API.BOXES_ACTIONS + `search_all/Number/${val}`, {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setData(data);
          setIndicator(false);
          message.success("success");
        }
      })
      .catch(function (error) {
        message.error(`Boxss ${val} Not Found`);
        console.log(error);
        setIndicator(false);
      });
  };

  const loadBoxByStatus = (status) => {
    setIndicator(true);
    axios
      .get(
        API.BASR_URL +
          API.BOXES_ACTIONS +
          `status/${status}?order=DESC&page=${page}&take=${take}`,
        {
          headers: Headers(),
        }
      )
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setData(data.data);
          setIndicator(false);
          message.success("success");
        }
      })
      .catch(function (error) {
        message.error("Ooops Something Went Wrong..");
        console.log(error);
        setIndicator(false);
      });
  };

  const onSort = (type, val) => {
    console.log(type, val);
    if (type === "Latest") {
      relaod();
    } else if (type === "Updated") {
      setIndicator(true);
      axios
        .get(
          API.BASR_URL +
            API.BOXES_ACTIONS +
            `lastupdated?order=ASC&page=${page}&take=${take}`,
          {
            headers: Headers(),
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            let data = response.data;
            setData(data.data);
            setIndicator(false);
            message.success("success");
          }
        })
        .catch(function (error) {
          message.error("Ooops Something Went Wrong..");
          console.log(error);
          setIndicator(false);
        });
    } else if (type === "Packed") {
      loadBoxByStatus(1);
    } else {
      loadBoxByStatus(35);
    }
  };

  return (
    <div>
      <PageHeader
        PageName={"Boxes"}
        breadScrum={"Admin / Boxes"}
        SearchBar={(val) => onSearch(val)}
        FilterButton={(type, val) => onSort(type, val)}
        loader={indicator}
      />
      <TabHeader />
      {loading ? (
        <LoadingBox />
      ) : (
        <div>
          <DataTable
            reload={() => {
              loaddata(page, take);
            }}
            meta={meta}
            data={data}
            item={item}
            fileType={fileType}
            uploading={uploading}
            currentId={currentId}
            update={(item) => UpdateItem(item)}
            delete={(item) => DeleteItem(item)}
            details={(item) => showDetails(item)}
            uploadKYC={(record, fileType) =>
              showUploadKYCModal(record, fileType)
            }
            updateKYC={(item) => updateKYCData(item)}
            cashRcpt={(item) => updateCashRcpt(item)}
            payRcpt={(item) => updatePayRcpt(item)}
            print={(item) => updatePrint(item)}
            invoice={(item) => updateInvoice(item)}
            custinvoice={(item) => updateCustInvoice(item)}
            custinvoice2={(item) => updateCustInvoice2(item)}
            taxinvoice={(item) => updateTaxInvoice(item)}
            customs={(item) => updateCustoms(item)}
            customsB={(item) => updateCustomsB(item)}
            downoladtiff={(item) => updatedownoladtiff(item)}
            billp={(item) => updateBillP(item)}
            printKyc={(item) => PrintKYC(item)}
            PageOnchange={(page, take) => pagination(page, take)}
          />
          {form ? (
            <FormModal
              item={item}
              mode={mode}
              visible={form}
              onchange={() => relaod()}
              onClose={() => setform(false)}
            />
          ) : null}
          {details ? (
            <DetailsModal
              item={item}
              visible={details}
              onClose={() => showDetails({})}
            />
          ) : null}
          {uploadKYC ? (
            <UploadKYCModal
              record={record}
              fileType={fileType}
              visible={uploadKYC}
              setStatus={(status) => setUplaodingStatus(status)}
              search={search}
              searchData={() => onSearch(search)}
              onClose={() => setUploadKYC(false)}
              onChange={() => reload()}
            />
          ) : null}

          {updateKYC ? (
            <KYCupdate
              item={item}
              data={data}
              visible={updateKYC}
              search={search}
              searchData={() => onSearch(search)}
              onClose={() => setUpdateKYC(false)}
              onChange={() => reload()}
            />
          ) : null}
          {cashRcpt ? (
            <CashRcptModal
              item={item}
              visible={cashRcpt}
              onClose={() => setCashRcpt(false)}
            />
          ) : null}
          {payRcpt ? (
            <PaymentRcptModal
              item={item}
              visible={payRcpt}
              onClose={() => setPayRcpt(false)}
            />
          ) : null}
          {print ? (
            <PrintBarcodeModal
              item={item}
              collectiond={collectiond}
              visible={print}
              reload={() => {
                loaddata(page, take);
              }}
              onClose={() => setPrint(false)}
            />
          ) : null}
          {invoice ? (
            <PrintInvoiceModal
              item={item}
              visible={invoice}
              reload={() => {
                loaddata(page, take);
              }}
              onClose={() => setInvoice(false)}
            />
          ) : null}
          {downoladtiff ? (
            <DownloadTiffModal
              item={item}
              visible={downoladtiff}
              reload={() => {
                loaddata(page, take);
              }}
              onClose={() => setDownoladtiff(false)}
            />
          ) : null}
          {custinvoice ? (
            <PrintCustomerInvoiceModal
              item={item}
              visible={custinvoice}
              reload={() => {
                loaddata(page, take);
              }}
              onClose={() => setCustInvoice(false)}
            />
          ) : null}

          {custinvoice2 ? (
            <PrintCustomerInvoiceModal2
              item={item}
              visible={custinvoice2}
              reload={() => {
                loaddata(page, take);
              }}
              onClose={() => setCustInvoice2(false)}
            />
          ) : null}

          {taxinvoice ? (
            <PrintTaxInvoiceModal
              item={item}
              visible={taxinvoice}
              reload={() => {
                loaddata(page, take);
              }}
              onClose={() => setTaxInvoice(false)}
            />
          ) : null}

          {customs ? (
            <PrintCustomesModal
              item={item}
              visible={customs}
              reload={() => {
                loaddata(page, take);
              }}
              onClose={() => setCustoms(false)}
            />
          ) : null}
          {customsB ? (
            <PrintCustomesModalB
              item={item}
              visible={customsB}
              reload={() => {
                loaddata(page, take);
              }}
              onClose={() => setCustomsB(false)}
            />
          ) : null}

          {billp ? (
            <PrintBoxNumModal
              item={item}
              data={data}
              visible={billp}
              reload={() => {
                loaddata(page, take);
              }}
              onClose={() => setBillp(false)}
            />
          ) : null}
          {printkyc ? (
            <PrintKYCModal
              item={item}
              visible={printkyc}
              onCancel={() => setPrintKYC(false)}
            />
          ) : null}
        </div>
      )}
    </div>
  );
}
export default BoxesScreen;

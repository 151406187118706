import { useState } from "react";
import { Table, Pagination, Button, Input } from "antd";
import moment from "moment";
import { AiOutlineFilter, AiOutlineIdcard } from "react-icons/ai";
import { AiOutlineMobile } from "react-icons/ai";
import { FaInternetExplorer } from "react-icons/fa";
window.Buffer = window.Buffer || require("buffer").Buffer;

function DataTable(props) {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  let getPercentage = (obj) => {
    let BoxObj = {
      iqama_name: obj.iqama_name,
      iqama_number: obj.iqama_number,
      address: obj.address,
      sender_mobile: obj.sender_mobile,
      reciver_mobile1: obj.reciver_mobile1,
      adhar_no: obj.adhar_no,
      adhar_name: obj.adhar_name,
      adhar_address: obj.adhar_address,
      pin: obj.pin,
      state: obj.state,
      district: obj.district,
      country: obj.country,
      adhar: obj.adhar,
      adhar_back: obj.adhar_back,
      iqama: obj.iqama,
    };
    let Keys = Object.keys(BoxObj);
    let valuesArr = Keys.map((key) => {
      return BoxObj[key];
    });
    let lengthArr = valuesArr.length;
    let nonFilledCount = 0;
    valuesArr.forEach((value) => {
      if (value == null || value == "NULL") nonFilledCount++;
    });
    let Totalpercentage = 100 - (nonFilledCount / lengthArr) * 100;
    return Math.round(Totalpercentage);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          size="middle"
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
        <div style={{ margin: 5 }} />
        <Button onClick={() => handleReset(clearFilters)} size="small">
          Reset
        </Button>
        <Button
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          type="primary"
          size="small"
        >
          Search
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <div style={{ backgroundColor: "transparent" }}>
        <AiOutlineFilter />
      </div>
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) =>
        (props.meta.page - 1) * props.meta.take + index + 1,
    },
    {
      title: "",
      dataIndex: "created_from",
      render: (text, record, index) => (
        <div>
          {record.collection && record.collection.created_from === "web" ? (
            <FaInternetExplorer />
          ) : (
            <AiOutlineMobile />
          )}{" "}
        </div>
      ),
    },
    {
      title: "DATE",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => <div>{moment(text).format("l")}</div>,
    },
    {
      title: <div style={{ fontSize: "11.8px" }}>Box Number</div>,
      dataIndex: "box_number",
      key: "box_number",
      width: "10%",
      sortDirections: ["descend", "ascend"],
    },

    {
      title: "User",
      dataIndex: "user",
      key: "user",
      render: (text, record, index) => (
        <div className="boxes-table-item">
          {record.created_user && record.created_user.name.toUpperCase()}
        </div>
      ),
    },

    {
      title: "Service",
      dataIndex: "service_type",
      key: "service_type",
      sortDirections: ["descend", "ascend"],
      render: (text, record, index) => (
        <div className="boxes-table-item">
          {record.service && record.service.name}
        </div>
      ),
    },
    {
      title: "Weight",
      dataIndex: "chargable_weight",
      key: "chargable_weight",
      render: (text, record, index) => <div>{text}</div>,
    },
    {
      title: "Amount",
      dataIndex: "total_charge",
      key: "total_charge",
      render: (text, record, index) => <div>{text}</div>,
    },
  ];
  return (
    <div style={{ minHeight: "60vh" }}>
      <Table
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        columns={columns}
        dataSource={props.data}
        pagination={false}
        size="small"
      />
      <br />
      <div align="right">
        <Pagination
          defaultCurrent={props.meta && props.meta.page}
          total={props.meta && props.meta.itemCount}
          pageSize={props.meta && props.meta.take ? props.meta.take : 0}
          onChange={props.PageOnchange}
          pageSizeOptions={[10, 20, 50]}
        />
      </div>
    </div>
  );
}
export default DataTable;

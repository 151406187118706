import React from "react";
import { Table, Pagination } from "antd";

function DataTable(props) {
  const columns = [
    { title: "Box Number", dataIndex: "boxNo", key: "boxNo" },
    { title: "Pieces", dataIndex: "pcs", key: "pcs" },
    { title: "WGT", dataIndex: "wgt", key: "wgt" },
    { title: "R WGT", dataIndex: "rwgt", key: "rwgt" },
    { title: "State", dataIndex: "state", key: "state" },
  ];

  return (
    <div style={{ minHeight: "60vh" }}>
      <Table
        columns={columns}
        dataSource={props.data}
        pagination={false}
        size="small"
        rowKey="box_number"
      />
      <br />
      <div align="right">
        <Pagination
          current={props.meta.page}
          total={props.meta.itemCount}
          pageSize={props.meta.take}
          onChange={(page, pageSize) => props.PageOnchange(page, pageSize)}
          showSizeChanger
          pageSizeOptions={["10", "20", "50"]}
        />
      </div>
    </div>
  );
}
export default DataTable;

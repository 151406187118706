import React, { useRef, useState } from "react";
import {
  Drawer,
  Form,
  Button,
  Input,
  message,
  Select,
  Checkbox,
  notification,
} from "antd";
import { Table, Row, Col } from "react-bootstrap";
import axios from "axios";
import { API, Headers } from "../../Config";
import moment from "moment";
import PdfRefNumModal from "./PdfRefNumModal";
import { POST } from "../../Config/ApiRequests";

const { Option } = Select;
function FormModal(props) {
  const formRef = useRef();

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [userloading, setUserloading] = useState(false);
  const [count, setCount] = useState(0);
  const [download, setDownload] = useState(false);

  const [data, setData] = useState([]);
  const [value, setValue] = useState([]);
  const [list, setList] = useState([]);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [userId, setUserId] = useState(0);

  const onFinish = (val) => {
    let uuid = `${value.userid}${moment().unix()}`;
    let UUID = uuid.slice(0, 4);
    setLoading(true);
    if (list && list.length) {
      let numberss = [];
      // list.map((item) => {
      //   // numberss = numberss + item.referance;
      //   numberss.push(item.referance.toString());
      // });
      // let series_no = numberss.slice(numberss.indexOf(",") + 1).trim();
      let requestObj = {
        referance: numberss,
        user_id: Number(value.userid),
        series_no: Number(UUID),
        from_no: from,
        to_no: to,
      };

      axios({
        method: "post",
        url: API.BASR_URL + API.REFERENCE_NUM_LIST,
        headers: Headers(),
        data: requestObj,
      })
        .then(function (response) {
          if ((response.status === 200, 201)) {
            message.success("Success");
            props.reload();
            setLoading(false);
          }
        })
        .catch(function (error) {
          message.error("Something went wrong");
          console.log(error);
          notification.open({
            message: "ERROR",
            description: `${error.response.data.message}`,
          });
          setLoading(false);
        });
    }
  };

  const onValuesChange = (_, val) => {
    console.log("onValuesChange====>", val);
    setValue(val);
  };

  const selectUser = (val) => {
    console.log(val);
    setUserloading(true);
    axios
      .get(API.BASR_URL + API.REFERENCE_NUM_LIST + `/get_user/${val}`, {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setData(data);
          console.log(data);
          let length = Number(data.length) - 1;
          // let index = data.lastIndex();
          if (data && data.length) {
            formRef.current?.setFieldsValue({
              from: data[length].to_no && data[length].to_no,
            });
          }
          setUserloading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setUserloading(false);
      });
  };

  const createSeries = async () => {
    try {
      setLoading2(true);
      let api = "referance/create";
      let obj = {
        series_no: 1,
        user_id: Number(userId),
        from_no: 1,
        to_no: 2,
        numbers: list,
        referance: list,
      };

      const create = await POST(api, obj);
      if (create?.status) {
        setLoading2(false);
        message.success("Created Successfully");
        props.reload();
        props.onClose();
      } else {
        setLoading2(false);
        message.success("Failed to create");
      }
    } catch (err) {
      setLoading2(false);
      console.log(err);
    }
  };

  const generate = async (val) => {
    try {
      let arr = [];
      // let _num = await generateDateTimeBasedNumber();
      let _num = moment().unix();
      let num = `${userId}${_num}`;
      for (let i = 0; i < count; i++) {
        let _id = Number(num) + Number(i);
        arr.push(_id);
      }
      setList(arr);
    } catch (error) {
      console.log(error);
    }
  };

  async function generateDateTimeBasedNumber() {
    const now = new Date();
    // Extract time components
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const milliseconds = now.getMilliseconds();
    // Combine and hash into an 8-digit number
    const hash =
      (hours * 3600000 + minutes * 60000 + seconds * 1000 + milliseconds) %
      100000000;
    // Ensure it's exactly 8 digits
    const uniqueNumber = hash.toString().padStart(8, "0");
    return uniqueNumber;
  }

  const Clear = () => {
    formRef.current.setFieldsValue({
      from: "",
      to: "",
      count: "",
    });
    setList([]);
  };

  const printSave = () => {
    // setDownload(true);
    setTimeout(() => onFinish(), 1000);
  };

  return (
    <div>
      <Drawer
        placement="right"
        title={<span className="txt4">Generate Reference Number</span>}
        width={720}
        visible={props.visible}
        onClose={props.onClose}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Row>
          <Col md="9">
            <div>Select User</div>
            <Select
              size="large"
              showSearch
              style={{ width: "100%" }}
              onChange={(val) => {
                setUserId(val);
                // selectUser(val);
              }}
            >
              {props.users &&
                props.users.map((options) => (
                  <Option key={options?.id}>
                    {options?.id} - {options?.name} - {options?.email}
                  </Option>
                ))}
            </Select>
          </Col>

          <Col md="3">
            <div>Count</div>
            <Input
              type="number"
              onChange={(e) => setCount(Number(e.target.value))}
              size="large"
            />
          </Col>
        </Row>

        <br />

        <Row>
          <Col md="7"></Col>

          <Col md="5">
            <Button
              type="primary"
              onClick={() => generate()}
              style={{ width: "100%" }}
              size="large"
            >
              Generate
            </Button>
          </Col>
        </Row>
        <br />
        <hr />
        <br />

        {list?.length ? (
          <div>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Reference Number</th>
                </tr>
              </thead>
              <tbody>
                {list.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : null}

        {/* <Form ref={formRef} onFinish={onFinish} onValuesChange={onValuesChange}>
          <div>
            <Form.Item name="userid" label="Select User">
              <Select onChange={(val) => selectUser(val)}>
                {props.users &&
                  props.users.map((options) => (
                    <Option key={options.id}> {options.name}</Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
          <Table bordered>
            <thead style={{ backgroundColor: "#1e88e5", color: "#fff" }}>
              <tr>
                <th></th>
                <th>From</th>
                <th>To</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td>
                  <Form.Item
                    name={"from"}
                    style={{ padding: 0, margin: 0 }}
                    rules={[{ required: true, message: "required" }]}
                    hasFeedback={userloading}
                    validateStatus="validating"
                  >
                    <Input disabled={data && data.length} />
                  </Form.Item>
                </td>
                <td>
                  <Form.Item name={"to"} style={{ padding: 0, margin: 0 }}>
                    <Input disabled={!count} />
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  Enter Total Count{" "}
                  <Checkbox onChange={() => setCount(!count)}></Checkbox>
                </td>
                <td>
                  <Form.Item name={"count"} style={{ padding: 0, margin: 0 }}>
                    <Input disabled={count} />
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>
                  <div style={{ display: "flex" }}>
                    <Button onClick={() => Clear()}>Clear</Button>
                    <div style={{ margin: 5 }}></div>
                    <Button type="primary" onClick={(val) => generate(val)}>
                      Generate
                    </Button>
                  </div>
                </td>
              </tr>
              {list && list.length ? (
                <>
                  <tr>
                    <td colSpan={3}>Numbers</td>
                  </tr>
                  {list.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td colSpan={2}>{item}</td>
                      </tr>
                    );
                  })}
                </>
              ) : null}
              {list && list.length ? (
                <tr>
                  <td colSpan={2}></td>
                  <td>
                    <div style={{ display: "flex" }}>
                      <Button size="large" block onClick={() => printSave()}>
                        Print & Save
                      </Button>
                      <div style={{ margin: 3 }} />
                      <Button
                        size="large"
                        type="primary"
                        block
                        htmlType="submit"
                        loading={loading}
                      >
                        Save all
                      </Button>
                    </div>
                  </td>
                </tr>
              ) : null}
            </tbody>
          </Table>
        </Form> */}

        {list && list.length ? (
          <tr>
            <td colSpan={2}></td>
            <td>
              <div style={{ display: "flex" }}>
                <Button
                  size="large"
                  style={{ width: "200px" }}
                  block
                  onClick={() => createSeries()}
                >
                  Save
                </Button>
                <div style={{ margin: 3 }} />
                {/* <Button
                  size="large"
                  type="primary"
                  block
                  htmlType="submit"
                  loading={loading}
                >
                  Save all
                </Button> */}
              </div>
            </td>
          </tr>
        ) : null}
      </Drawer>
      {download && list.length ? (
        <PdfRefNumModal
          selected={list}
          visible={download}
          onCancel={() => setDownload(false)}
        />
      ) : null}
    </div>
  );
}

export default FormModal;

import React, { useState, useRef, useEffect } from "react";
import styles from "../styles";
import {
  Modal,
  Form,
  Radio,
  Space,
  Button,
  Select,
  Checkbox,
  Input,
} from "antd";
import { Container, Row, Col } from "react-bootstrap";
import { AiFillEye, AiFillPrinter } from "react-icons/ai";
import ReactToPrint from "react-to-print";
import { BlobProvider, PDFDownloadLink } from "@react-pdf/renderer";
import { AiOutlineFilePdf } from "react-icons/ai";
import Barcode from "react-barcode";
import PdfLabel from "../PrintDesign/PdfLabel";
import PdfCargoLabel from "../PrintDesign/PdfCargoLabel";

var JsBarcode = require("jsbarcode");

function PrintLabelModal(props) {
  const componentRef = useRef();
  const [preview, setPrevieww] = useState(true);
  const [formdata, setFormData] = useState({});

  const [standardlabel, setStandardLabel] = useState(true);
  const [identify, setIdentify] = useState(false);

  const onFinish = (values) => {
    setPrevieww(!preview);
    const FormData = values;
    let requestObj = {
      agent_name_on_awb: values.agent_name_on_awb,
      airline: values.airline,
      awb_no: values.awb_no,
      consignee_paper_size: values.consignee_paper_size,
      destination_airport_name: values.destination_airport_name,
      orijin_airport_name: values.orijin_airport_name,
      route_to: values.route_to,

      inc_shipper: values.inc_shipper ? values.inc_shipper : "a",
      inc_consignee: values.inc_consignee ? values.inc_consignee : "a",

      iflogo: values.iflogo,
      inc_agent: values.inc_agent,
      inc_hawb: values.inc_hawb,
      inc_mawbtotal_pcs: values.inc_mawbtotal_pcs,
      inc_mawbtotal_weight: values.inc_mawbtotal_weight,
      inc_origin: values.inc_origin,

      inc_total_pkg: values.inc_total_pkg,
      inc_total_weight: values.inc_total_weight,
      inc_transit: values.inc_transit,
      label_type: values.label_type,
      manual_entry: values.manual_entry,

      other_info: values.other_info,
      pkgweight_paper_size: values.pkgweight_paper_size,
      pkgnum: values.pkgnum,
      shipper_paper_size: values.shipper_paper_size,
      transit: values.transit,
    };
    setFormData(requestObj);
  };

  let barcodedata = formdata && formdata.awb_no;

  let canvas;
  canvas = document.createElement("canvas");
  JsBarcode(canvas, barcodedata);
  const barcode = canvas.toDataURL();

  const numOfBox = props.selected && parseInt(props.selected.psc);

  const onChange = (e) => {
    setStandardLabel(!standardlabel);
  };

  const download = (url) => {
    window.open(url);
  };

  let cnee = props.selected.cnee && props.selected.cnee;
  let CneeName;
  if (cnee.split("  ").length > 1) {
    CneeName = cnee.split("  ")[0];
  } else {
    CneeName = cnee.split("\n")[0];
  }


  let shipper = props.selected.shipper && props.selected.shipper;
  let shipperName
    if (shipper.split("  ").length > 1) {
    shipperName = shipper.split("  ")[0];
  } else {
    shipperName = shipper.split("\n")[0];
  }




  return (
    <Modal
      visible={props.visible}
      onCancel={props.onClose}
      header={false}
      footer={false}
      width={460}
    >
      <div>
        <br />
        <Container>
          <h5 className="PrintLabelModal-txt1">Print Label</h5>

          <Form
            onFinish={onFinish}
            initialValues={{
              airline: props.selected && props.selected.airlines.name,
              awb_no:
                props.selected &&
                props.selected.airline + "-" + props.selected.awb_no,
              destination_airport_name:
                props.selected.destination_airport_name &&
                props.selected.destination_airport_name,
              orijin_airport_name:
                props.selected.orijin_airport_name &&
                props.selected.orijin_airport_name.split(",")[0],
              agent_name_on_awb:
                props.selected && props.selected.agent_name_on_awb,
              route_to: props.selected && props.selected.route_to,
            }}
          >
            <Button
              style={styles.buttons}
              icon={<AiFillEye style={styles.buttonsIcon} />}
              htmlType="submit"
              type="primary"
            >
              {preview ? "Print Preview" : "Config"}
            </Button>
            <ReactToPrint
              trigger={() => (
                <Button
                  style={styles.buttons}
                  icon={<AiFillPrinter style={styles.buttonsIcon} />}
                >
                  Print
                </Button>
              )}
              content={() => componentRef.current}
            />

            <BlobProvider
              document={
                standardlabel ? (
                  <PdfLabel
                    formdata={formdata}
                    selected={props.selected}
                    barcode={barcode}
                  />
                ) : (
                  <PdfCargoLabel
                    formdata={formdata}
                    selected={props.selected}
                  />
                )
              }
              fileName={
                standardlabel
                  ? `${props.selected.awb_no}label.pdf`
                  : `${props.selected.awb_no}cargolabel.pdf`
              }
            >
              {({ blob, url, loading, error }) => {
                return loading ? null : (
                  <Button
                    style={styles.buttons}
                    icon={<AiOutlineFilePdf style={styles.buttonsIcon} />}
                    onClick={() => download(url)}
                  >
                    Download PDF
                  </Button>
                );
              }}
            </BlobProvider>

            {preview ? (
              <>
                <div style={{ margin: 30 }} />
                <div className="PrintLabelModal-txt2">Label type</div>
                <hr />
                <Form.Item name={"label_type"}>
                  <Radio.Group defaultValue={1} onChange={onChange}>
                    <Space direction="vertical">
                      <Radio value={1}>
                        <div className="PrintLabelModal-txt3">
                          Standard barcode label
                        </div>
                      </Radio>
                      <Radio value={2}>
                        <div className="PrintLabelModal-txt3">
                          Cargo pouch label
                        </div>
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
                <br />
                {standardlabel ? (
                  <>
                    <div className="PrintLabelModal-txt2">
                      Basic information
                    </div>
                    <hr />
                    <Row>
                      <Col sm="12">
                        <Form.Item name={"airline"} label={"Airline"}>
                          <Input style={{ width: "100%" }} />
                        </Form.Item>
                        <Form.Item name={"iflogo"} valuePropName="checked">
                          <Checkbox>
                            <div className="PrintLabelModal-txt3">
                              use logo if available
                            </div>
                          </Checkbox>
                        </Form.Item>
                        <Form.Item name={"awb_no"} label={"MAWB"}>
                          <Input style={{ width: "100%" }} />
                        </Form.Item>
                        <Form.Item
                          name={"destination_airport_name"}
                          label={"Destination"}
                        >
                          <Input
                            style={{
                              width: "100%",
                              textTransform: "uppercase",
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <div className="PrintLabelModal-txt2">
                      Package numbering
                    </div>
                    <hr />
                    <Row>
                      <Col sm="12">
                        <Form.Item name={"pkgnum"}>
                          <Radio.Group
                            defaultValue={1}
                            onChange={() => setIdentify(!identify)}
                          >
                            <Space direction="vertical">
                              <Radio value={1}>
                                <div className="PrintLabelModal-txt3">
                                  Don't identify packages <br />
                                  Labels can be used on any package
                                </div>
                              </Radio>
                              <Radio value={2}>
                                <div className="PrintLabelModal-txt3">
                                  Identify packages
                                  <br />
                                  Each barcode number identifies each package
                                </div>
                              </Radio>
                            </Space>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>

                    <div className="PrintLabelModal-txt2">
                      Additional information
                    </div>
                    <hr />
                    <Row>
                      <Col sm="6">
                        <Form.Item name={"inc_origin"} valuePropName="checked">
                          <Checkbox>
                            <div className="PrintLabelModal-txt3">
                              Include origin
                            </div>
                          </Checkbox>
                        </Form.Item>
                        <Form.Item name={"inc_transit"} valuePropName="checked">
                          <Checkbox>
                            <div className="PrintLabelModal-txt3">
                              Include transit
                            </div>
                          </Checkbox>
                        </Form.Item>
                        <Form.Item name={"inc_agent"} valuePropName="checked">
                          <Checkbox>
                            <div className="PrintLabelModal-txt3">
                              Include agent
                            </div>
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col sm="6">
                        <Form.Item name={"orijin_airport_name"}>
                          <Input style={{ textTransform: "uppercase" }} />
                        </Form.Item>
                        <Form.Item name={"route_to"}>
                          <Input style={{ textTransform: "uppercase" }} />
                        </Form.Item>
                        <Form.Item name={"agent_name_on_awb"}>
                          <Input style={{ textTransform: "uppercase" }} />
                        </Form.Item>
                      </Col>
                      <Col sm="8">
                        <Form.Item name="inc_shipper" label="Include  shipper">
                          <Select defaultValue="a" style={{ width: "100%" }}>
                            <Select.Option value="a">No</Select.Option>
                            <Select.Option value="b">Only Name</Select.Option>
                            <Select.Option value="c">
                              All contact information
                            </Select.Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="inc_consignee"
                          label="Include  consignee"
                        >
                          <Select defaultValue="a" style={{ width: "100%" }}>
                            <Select.Option value="a">No</Select.Option>
                            <Select.Option value="b">Only Name</Select.Option>
                            <Select.Option value="c">
                              All contact information
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col sm="8">
                        <Form.Item
                          name={"inc_mawbtotal_pcs"}
                          valuePropName="checked"
                        >
                          <Checkbox disabled={identify}>
                            Include total HAWB packages count
                          </Checkbox>
                        </Form.Item>
                        <Form.Item
                          name={"inc_mawbtotal_weight"}
                          valuePropName="checked"
                        >
                          <Checkbox>
                            <div className="PrintLabelModal-txt3">
                              Include total HAWB weight
                            </div>
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>

                    <div className="PrintLabelModal-txt2">
                      Additional house information
                    </div>
                    <hr />
                    <Row>
                      <Col sm="8">
                        <Form.Item name={"inc_hawb"} valuePropName="checked">
                          <Checkbox disabled>
                            <div className="PrintLabelModal-txt3">
                              Include HAWB number
                            </div>
                          </Checkbox>
                        </Form.Item>
                        <Form.Item
                          name={"inc_total_pkg"}
                          valuePropName="checked"
                        >
                          <Checkbox disabled>
                            <div className="PrintLabelModal-txt3">
                              Include total HAWB packages count
                            </div>
                          </Checkbox>
                        </Form.Item>
                        <Form.Item
                          name={"inc_total_weight"}
                          valuePropName="checked"
                        >
                          <Checkbox disabled>
                            <div className="PrintLabelModal-txt3">
                              Include total HAWB weight
                            </div>
                          </Checkbox>
                        </Form.Item>
                        <Form.Item
                          name="shipper_paper_size"
                          label="Include house shipper"
                        >
                          <Select defaultValue="a" style={{ width: "100%" }}>
                            <Select.Option value="a">No</Select.Option>
                            <Select.Option value="b">Only Name</Select.Option>
                            <Select.Option value="c">
                              All contact information
                            </Select.Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="consignee_paper_size"
                          label="Include house consignee"
                        >
                          <Select defaultValue="a" style={{ width: "100%" }}>
                            <Select.Option value="a">No</Select.Option>
                            <Select.Option value="b">Only Name</Select.Option>
                            <Select.Option value="c">
                              All contact information
                            </Select.Option>
                          </Select>
                        </Form.Item>
                        <div className="PrintLabelModal-txt3">
                          (loads houses data from linked houses)
                        </div>
                        <br />
                      </Col>
                    </Row>

                    <div className="PrintLabelModal-txt2">Packages weight</div>
                    <hr />
                    <Row>
                      <Col sm="8">
                        <Form.Item
                          name="pkgweight_paper_size"
                          label="Include packages weight"
                        >
                          <Select defaultValue="a" style={{ width: "100%" }}>
                            <Select.Option value="a">No</Select.Option>
                            <Select.Option value="b">Manual</Select.Option>
                          </Select>
                        </Form.Item>
                        <Form.Item name="manual_entry" label="Manual entry">
                          <Input.TextArea style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                    </Row>

                    <div className="PrintLabelModal-txt2">
                      Optional information
                    </div>
                    <hr />
                    <Form.Item name="other_info">
                      <Input.TextArea />
                    </Form.Item>
                  </>
                ) : (
                  <div>
                    <div className="PrintLabelModal-txt2">
                      Basic information
                    </div>
                    <hr />
                    <Row>
                      <Col sm="12">
                        <Form.Item name={"airline"} label={"Airline"}>
                          <Input style={{ width: "100%" }} />
                        </Form.Item>
                        <Form.Item name={"iflogo"} valuePropName="checked">
                          <Checkbox>
                            <div className="PrintLabelModal-txt3">
                              use logo if available
                            </div>
                          </Checkbox>
                        </Form.Item>
                        <Form.Item name={"awb_no"} label={"MAWB"}>
                          <Input style={{ width: "100%" }} />
                        </Form.Item>
                        <Form.Item
                          name={"flight_identification"}
                          label={"Flight"}
                        >
                          <Input style={{ width: "100%" }} />
                        </Form.Item>
                        <Form.Item name={"cnee_name"} label={"Consignee name"}>
                          <Input style={{ width: "100%" }} />
                        </Form.Item>
                        <Form.Item
                          name={"cnee_num"}
                          label={"Consignee  number"}
                        >
                          <Input style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                )}
              </>
            ) : (
              <>
                {standardlabel ? (
                  <div>
                    <div className="print-awblabel-page">
                      <table className="print-awblabelpreview-table">
                        <tr>
                          {formdata.iflogo ? (
                            <td colSpan="4">
                              <div className="print-awblabel-logo-container">
                                <img
                                  src={props.selected.airlines.airline_logo}
                                  alt="airline_logo"
                                  className="print-awblabel-logo"
                                />
                              </div>
                            </td>
                          ) : (
                            <td
                              colSpan="4"
                              className="print-awblabelpreview-table-td"
                            >
                              <div className="print-awblabel-txt1">Airline</div>
                              <div className="print-awblabel-txt2">
                                {formdata.airline}
                              </div>
                            </td>
                          )}
                        </tr>
                        <tr>
                          <td colSpan={4}>
                            <div className="print-label-barcode">
                              <Barcode
                                value={formdata.awb_no}
                                width="3"
                                font="Montserrat-Regular"
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan={4}
                            className="print-awblabelpreview-table-td"
                          >
                            <div className="print-awblabel-txt1">
                              Air Way Bill Number
                            </div>
                            <div
                              className="print-awblabel-txt2"
                              style={{ fontSize: "25px" }}
                            >
                              {formdata.awb_no}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan="2"
                            className="print-awblabelpreview-table-td"
                          >
                            <div className="print-awblabel-txt1 ">
                              Destination
                            </div>
                            <div className="print-awblabel-txt2">
                              {formdata.destination_airport_name}
                            </div>
                          </td>
                          <td
                            colSpan="2"
                            className="print-awblabelpreview-table-td"
                          >
                            {formdata.inc_origin ? (
                              <div className="">
                                <div className="print-awblabel-txt1">
                                  Departure
                                </div>
                                <div className="print-awblabel-txt2">
                                  {formdata.orijin_airport_name}
                                </div>
                              </div>
                            ) : null}
                          </td>
                        </tr>
                        <tr>
                          {formdata.inc_transit ? (
                            <td
                              colSpan={formdata.inc_mawbtotal_pcs ? 2 : 4}
                              className="print-awblabelpreview-table-td"
                            >
                              <div className="print-awblabel-txt1 ">
                                Transit
                              </div>
                              <div className="print-awblabel-txt2">
                                {formdata.route_to}
                              </div>
                            </td>
                          ) : null}
                          {formdata.inc_mawbtotal_pcs ? (
                            <td
                              colSpan="2"
                              className="print-awblabelpreview-table-td"
                            >
                              <div className="print-awblabel-txt1 ">
                                Total No. of pieces
                              </div>
                              <div className="print-awblabel-txt2">
                                {props.selected.psc}
                              </div>
                            </td>
                          ) : null}
                        </tr>
                        {formdata.inc_agent ? (
                          <tr>
                            <td
                              colSpan={4}
                              className="print-awblabelpreview-table-td"
                            >
                              <div className="print-awblabel-txt1">Agent</div>
                              <div className="print-awblabel-txt2">
                                {formdata.agent_name_on_awb}
                              </div>
                            </td>
                          </tr>
                        ) : null}
                        {formdata.inc_mawbtotal_weight ? (
                          <tr>
                            <td
                              colSpan={2}
                              className="print-awblabelpreview-table-td"
                            >
                              <div className="print-awblabel-txt1">
                                Total Weight of this Shipment
                              </div>
                              <div className="print-awblabel-txt2">
                                {props.selected.chargable_weight}
                              </div>
                            </td>
                            <td
                              className="print-awblabelpreview-table-td"
                              colSpan={2}
                            ></td>
                          </tr>
                        ) : null}
                        {formdata.other_info ||
                        formdata.inc_shipper != "a" ||
                        formdata.inc_consignee != "a" ? (
                          <tr>
                            <td
                              colSpan={4}
                              className="print-awblabelpreview-table-td"
                            >
                              <div className="print-awblabel-txt1">
                                Other Information
                              </div>
                              {formdata.inc_shipper === "a" ? null : (
                                <>
                                  {formdata.inc_shipper === "b" ? (
                                    <div className="print-awblabel-txt3">
                                      Shipper:
                                      {shipperName}
                                    </div>
                                  ) : (
                                    <div className="print-awblabel-txt3">
                                      Shipper:
                                      {shipper}
                                    </div>
                                  )}
                                </>
                              )}
                              {formdata.inc_consignee === "a" ? null : (
                                <>
                                  {formdata.inc_consignee === "b" ? (
                                    <div className="print-awblabel-txt3">
                                      Consignee : {CneeName}
                                    </div>
                                  ) : (
                                    <div className="print-awblabel-txt3">
                                      Consignee : {cnee}
                                    </div>
                                  )}
                                </>
                              )}
                              <div className="print-awblabel-txt3">
                                {formdata.other_info}
                              </div>
                            </td>
                          </tr>
                        ) : null}
                      </table>
                    </div>
                    <div style={{ diplay: "none", visibility: "hidden" }}>
                      <div ref={componentRef}>
                        {[...Array(numOfBox)].map((e, i) => {
                          return (
                            <div style={{ padding: 10 }}>
                              <div className="print-awblabel-page">
                                <table className="print-awblabelpreview-table">
                                  <tr>
                                    {formdata.iflogo ? (
                                      <td colSpan="4">
                                        <div className="print-awblabel-logo-container">
                                          <img
                                            src={
                                              props.selected.airlines
                                                .airline_logo
                                            }
                                            alt="airline_logo"
                                            className="print-awblabel-logo"
                                          />
                                        </div>
                                      </td>
                                    ) : (
                                      <td
                                        colSpan="4"
                                        className="print-awblabelpreview-table-td"
                                      >
                                        <div className="print-awblabel-txt1">
                                          Airline
                                        </div>
                                        <div className="print-awblabel-txt2">
                                          {formdata.airline}
                                        </div>
                                      </td>
                                    )}
                                  </tr>
                                  <tr>
                                    <td colSpan={4}>
                                      <div className="print-label-barcode">
                                        <Barcode
                                          value={formdata.awb_no}
                                          width="3"
                                          font="Montserrat-Regular"
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      colSpan={4}
                                      className="print-awblabelpreview-table-td"
                                    >
                                      <div className="print-awblabel-txt1">
                                        Air Way Bill Number
                                      </div>
                                      <div className="print-awblabel-txt2">
                                        {formdata.awb_no}
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      colSpan="2"
                                      className="print-awblabelpreview-table-td"
                                    >
                                      <div className="print-awblabel-txt1 ">
                                        Destination
                                      </div>
                                      <div className="print-awblabel-txt2">
                                        {formdata.destination_airport_name}
                                      </div>
                                    </td>
                                    <td
                                      colSpan="2"
                                      className="print-awblabelpreview-table-td"
                                    >
                                      {formdata.inc_origin ? (
                                        <div className="">
                                          <div className="print-awblabel-txt1">
                                            Departure
                                          </div>
                                          <div className="print-awblabel-txt2">
                                            {formdata.orijin_airport_name}
                                          </div>
                                        </div>
                                      ) : null}
                                    </td>
                                  </tr>
                                  <tr>
                                    {formdata.inc_transit ? (
                                      <td
                                        colSpan={
                                          formdata.inc_mawbtotal_pcs ? 2 : 4
                                        }
                                        className="print-awblabelpreview-table-td"
                                      >
                                        <div className="print-awblabel-txt1 ">
                                          Transit
                                        </div>
                                        <div className="print-awblabel-txt2">
                                          {formdata.route_to}
                                        </div>
                                      </td>
                                    ) : null}
                                    {formdata.inc_mawbtotal_pcs ? (
                                      <td
                                        colSpan="2"
                                        className="print-awblabelpreview-table-td"
                                      >
                                        <div className="print-awblabel-txt1 ">
                                          Total No. of pieces
                                        </div>
                                        <div className="print-awblabel-txt2">
                                          {props.selected.psc}
                                        </div>
                                      </td>
                                    ) : null}
                                  </tr>
                                  {formdata.inc_agent ? (
                                    <tr>
                                      <td
                                        colSpan={4}
                                        className="print-awblabelpreview-table-td"
                                      >
                                        <div className="print-awblabel-txt1">
                                          Agent
                                        </div>
                                        <div className="print-awblabel-txt2">
                                          {formdata.agent_name_on_awb}
                                        </div>
                                      </td>
                                    </tr>
                                  ) : null}
                                  {formdata.inc_mawbtotal_weight ? (
                                    <tr>
                                      <td
                                        colSpan={2}
                                        className="print-awblabelpreview-table-td"
                                      >
                                        <div className="print-awblabel-txt1">
                                          Total Weight of this Shipment
                                        </div>
                                        <div className="print-awblabel-txt2">
                                          {props.selected.chargable_weight}
                                        </div>
                                      </td>
                                      <td
                                        className="print-awblabelpreview-table-td"
                                        colSpan={2}
                                      ></td>
                                    </tr>
                                  ) : null}
                                  {formdata.other_info ||
                                  formdata.inc_shipper != "a" ||
                                  formdata.inc_consignee != "a" ? (
                                    <tr>
                                      <td
                                        colSpan={4}
                                        className="print-awblabelpreview-table-td"
                                      >
                                        <div className="print-awblabel-txt1">
                                          Other Information
                                        </div>
                                        {formdata.inc_shipper === "a" ? null : (
                                          <>
                                            {formdata.inc_shipper === "b" ? (
                                              <div className="print-awblabel-txt3">
                                                Shipper:
                                                {shipperName}
                                              </div>
                                            ) : (
                                              <div className="print-awblabel-txt3">
                                                Shipper:
                                                {shipper}
                                              </div>
                                            )}
                                          </>
                                        )}
                                        {formdata.inc_consignee ===
                                        "a" ? null : (
                                          <>
                                            {formdata.inc_consignee === "b" ? (
                                              <div className="print-awblabel-txt3">
                                                Consignee : {CneeName}
                                              </div>
                                            ) : (
                                              <div className="print-awblabel-txt3">
                                                Consignee : {cnee}
                                              </div>
                                            )}
                                          </>
                                        )}
                                        <div className="print-awblabel-txt3">
                                          {formdata.other_info}
                                        </div>
                                      </td>
                                    </tr>
                                  ) : null}
                                </table>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div>
                      <div ref={componentRef}>
                        {[...Array(numOfBox)].map((e, i) => {
                          return (
                            <div className="print-awblabel-page">
                              <table className="print-awblabelpreview-table">
                                <tr>
                                  <td
                                    colSpan={4}
                                    className="print-awblabelpreview-table-td"
                                  >
                                    <div className="print-awblabel-txt2">
                                      Cargo Pouch
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colSpan={4}
                                    className="print-awblabelpreview-table-td"
                                  >
                                    <div className="print-awblabel-txt1">
                                      Airline Name/Insignia
                                    </div>
                                    <div className="print-awblabel-txt2">
                                      {formdata.airline}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colSpan={4}
                                    className="print-awblabelpreview-table-td"
                                  >
                                    <div className="print-label-barcode">
                                      <Barcode
                                        value={props.selected.awb_no}
                                        width="3"
                                        font="Montserrat-Regular"
                                      />
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colSpan={4}
                                    className="print-awblabelpreview-table-td"
                                  >
                                    <div className="print-awblabel-txt1">
                                      Air Way Bill Number
                                    </div>
                                    <div className="print-awblabel-txt2">
                                      {formdata.awb_no}
                                    </div>
                                  </td>
                                </tr>
                                {props.selected.destination_airport_name ? (
                                  <tr>
                                    <td
                                      colSpan={4}
                                      className="print-awblabelpreview-table-td"
                                    >
                                      <div className="print-awblabel-txt1">
                                        Destination
                                      </div>
                                      <div className="print-awblabel-txt2">
                                        {props.selected
                                          .destination_airport_name &&
                                          props.selected.destination_airport_name
                                            .toString()
                                            .slice(0, 3)}
                                      </div>
                                    </td>
                                  </tr>
                                ) : null}
                                {formdata.flight_identification ? (
                                  <tr>
                                    <td
                                      colSpan={4}
                                      className="print-awblabelpreview-table-td"
                                    >
                                      <div className="print-awblabel-txt1">
                                        Flight Identification
                                      </div>
                                      <div className="print-awblabel-txt2">
                                        {formdata.flight_identification}
                                      </div>
                                    </td>
                                  </tr>
                                ) : null}
                                {formdata.cnee_name ? (
                                  <tr>
                                    <td
                                      colSpan={4}
                                      className="print-awblabelpreview-table-td"
                                    >
                                      <div className="print-awblabel-txt1">
                                        Consignee Name
                                      </div>
                                      <div className="print-awblabel-txt2">
                                        {formdata.cnee_name}
                                      </div>
                                    </td>
                                  </tr>
                                ) : null}
                                {formdata.cnee_num ? (
                                  <tr>
                                    <td
                                      colSpan={4}
                                      className="print-awblabelpreview-table-td"
                                    >
                                      <div className="print-awblabel-txt1">
                                        Consignee number
                                      </div>
                                      <div className="print-awblabel-txt2">
                                        {formdata.cnee_num}
                                      </div>
                                    </td>
                                  </tr>
                                ) : null}
                              </table>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </Form>
        </Container>
      </div>
    </Modal>
  );
}

export default PrintLabelModal;

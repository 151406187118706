import React, { useState } from "react";
import styles from "./styles";
import {
  Drawer,
  Form,
  Button,
  Input,
  InputNumber,
  Select,
  message,
  Switch,
  DatePicker,
  Modal,
} from "antd";
import { Container, Row, Col, Table } from "react-bootstrap";
import { API, Headers } from "../../Config";
import axios from "axios";
import moment from "moment";
function FormModal(props) {
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    console.log(values);
    let requestObj = {
      service: values.service,
      // orijin_airport: values.orijin_airport,
      orijin_airport: 1,
      destination_airport: parseInt(values.destination_airport),
      airline: parseInt(values.airline),
      awb_no: values.awb_no,
      comodity: values.comodity,
      awb_supplier: parseInt(values.awb_supplier),
      forwarding_agent: values.forwarding_agent.toString(),
      // export_broker: parseInt(values.forwarding_agent),
      export_broker: 3,
      agent_name_on_awb: values.agent_name_on_awb,
      clearing_agent_destination: parseInt(values.clearing_agent_destination),
      delivery_agent_destination: parseInt(values.delivery_agent_destination),
      shipper: values.shipper,
      cnee: values.cnee,
      psc: values.psc,
      actual_weight: parseInt(values.actual_weight),
      dimension: parseInt(values.dimension),
      volume_weight: parseInt(values.volume_weight),
      chargable_weight: parseInt(values.chargable_weight),
      rate: parseInt(values.rate),
      other_charge: parseInt(values.other_charge),
      awb_charge: parseInt(values.awb_charge),
      packing_charge: parseInt(values.packing_charge),
      insurance_charge: parseInt(values.insurance_charge),
      additional_charge: parseInt(values.additional_charge),
      place_of_generate: values.place_of_generate,
      incharge_of_generate: values.incharge_of_generate,
      reference: "string",
      document_date: "2022-09-21T13:25:00.859Z",
      is_print: false,
    };
    console.log(requestObj);
    let URL =
      props.mode === "post"
        ? API.AIRWAY_BILLS_GEN_ACTION
        : API.AIRWAY_BILLS_GEN_ACTION + props.item.id;
    axios({
      method: props.mode,
      url: API.BASR_URL + URL,
      headers: Headers(),
      data: requestObj,
    })
      .then(function (response) {
        setLoading(false);
        if ([200, 201].includes(response.status)) {
          message.success("Success");
          props.onchange();
        }
      })
      .catch(function (error) {
        setLoading(false);
        message.error("Oops.Something went wrong");
        console.log(error);
      });
  };

  return (
    <Modal
      title={
        <span className="txt4">
          {props.mode === "post" ? "Generate" : "Update"} Airway Bill
        </span>
      }
      onClose={() => props.onClose()}
      visible={props.visible}
      footer={false}
      width={800}
      onCancel={() => props.onClose()}
    >
      <Form
        layout="vertical"
        hideRequiredMark
        onFinish={onFinish}
        initialValues={{
          service: props.item && props.item.service,
          orijin_airport: props.item && props.item.orijin_airport,
          destination_airport: props.item && props.item.destination_airport,
          airline: props.item && props.item.airline,
          awb_no: props.item && props.item.awb_no,
          comodity: props.item && props.item.comodity,
          awb_supplier: props.item && props.item.awb_supplier,
          forwarding_agent: props.item && props.item.forwarding_agent,
          export_broker: props.item && props.item.export_broker,

          agent_name_on_awb: props.item && props.item.agent_name_on_awb,
          clearing_agent_destination:
            props.item && props.item.clearing_agent_destination,
          delivery_agent_destination:
            props.item && props.item.delivery_agent_destination,
          shipper: props.item && props.item.shipper,
          cnee: props.item && props.item.cnee,
          psc: props.item && props.item.psc,
          actual_weight: props.item && props.item.actual_weight,
          dimension: props.item && props.item.dimension,
          volume_weight: props.item && props.item.volume_weight,
          chargable_weight: props.item && props.item.chargable_weight,
          rate: props.item && props.item.rate,
          other_charge: props.item && props.item.other_charge,
          awb_charge: props.item && props.item.awb_charge,
          packing_charge: props.item && props.item.packing_charge,
          insurance_charge: props.item && props.item.insurance_charge,
          additional_charge: props.item && props.item.additional_charge,
          place_of_generate: props.item && props.item.place_of_generate,
          incharge_of_generate: props.item && props.item.incharge_of_generate,
        }}
      >
        <Container>
          <Table bordered className="formTable">
            <tbody>
              <tr>
                <td style={styles.label}>SERVICE : </td>
                <td>
                  <Form.Item name={"service"} style={styles.FormItem}>
                    <Input placeholder="service" style={styles.FormInput} />
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <td style={styles.label}>ORJIN AIRPORT : </td>
                <td>
                  <Form.Item name={"orijin_airport"} style={styles.FormItem}>
                    <Select placeholder="Select Port">
                      {props.ports &&
                        props.ports.map((option) => (
                          <Select.Option key={option.id} value={option.id}>
                            {option.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <td style={styles.label}>DESTINATION AIRPORT : </td>
                <td>
                  <Form.Item
                    name={"destination_airport"}
                    style={styles.FormItem}
                  >
                    <Select placeholder="Select Port">
                      {props.ports &&
                        props.ports.map((option) => (
                          <Select.Option key={option.id} value={option.id}>
                            {option.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <td style={styles.label}>AIRLINE : </td>
                <td>
                  <Form.Item name={"airline"} style={styles.FormItem}>
                    <Select placeholder="Select Airline">
                      {props.airline &&
                        props.airline.map((option) => (
                          <Select.Option key={option.id} value={option.id}>
                            {option.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <td style={styles.label}>AWB NO : </td>
                <td>
                  <Form.Item name={"awb_no"} style={styles.FormItem}>
                    <Select placeholder="Choose Bill">
                      {props.bills &&
                        props.bills.map((option) => (
                          <Select.Option
                            key={option.id}
                            value={"" + option.series_no}
                          >
                            {option.series_no}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <td style={styles.label}>COMIDITY : </td>
                <td>
                  <Form.Item name={"comodity"} style={styles.FormItem}>
                    <Input placeholder="service" style={styles.FormInput} />
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <td style={styles.label}>AWB SUPLIER : </td>
                <td>
                  <Form.Item name={"awb_supplier"} style={styles.FormItem}>
                    <Select placeholder="Choose Supplier">
                      {props.awbsuppliers &&
                        props.awbsuppliers.map((option) => (
                          <Select.Option key={option.id} value={option.id}>
                            {option.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <td style={styles.label}>FORWARDING AGENT (X RAY) : </td>
                <td>
                  <Form.Item name={"forwarding_agent"} style={styles.FormItem}>
                    <Select placeholder="Choose Agent">
                      {props.agents &&
                        props.agents.map((option) => (
                          <Select.Option key={option.id} value={option.rate}>
                            {option.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <td style={styles.label}>EXPORT BROKER : </td>
                <td>
                  <Form.Item name={"export_broker"} style={styles.FormItem}>
                    <Select placeholder="Choose Broker">
                      {props.exportbroker &&
                        props.exportbroker.map((option) => (
                          <Select.Option key={option.id} value={option.id}>
                            {option.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <td style={styles.label}>AGENT NAME ON AWB (PRINT) : </td>
                <td>
                  <Form.Item name={"agent_name_on_awb"} style={styles.FormItem}>
                    <Input placeholder="service" style={styles.FormInput} />
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <td style={styles.label}>CLEARING AGENT AT DESTINATION : </td>
                <td>
                  <Form.Item
                    name={"clearing_agent_destination"}
                    style={styles.FormItem}
                  >
                    <Select placeholder="Choose agent">
                      {props.clearingagents &&
                        props.clearingagents.map((option) => (
                          <Select.Option key={option.id} value={option.id}>
                            {option.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <td style={styles.label}>DELIVERY AGENT AT DESTINATION : </td>
                <td>
                  <Form.Item
                    name={"delivery_agent_destination"}
                    style={styles.FormItem}
                  >
                    <Select placeholder="Choose agent">
                      {props.deliveryagents &&
                        props.deliveryagents.map((option) => (
                          <Select.Option key={option.id} value={option.id}>
                            {option.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <td style={styles.label}>SHIPPER : </td>
                <td>
                  <Form.Item name={"shipper"} style={styles.FormItem}>
                    <Input placeholder="Enter Name" style={styles.FormInput} />
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <td style={styles.label}>CNEE : </td>
                <td>
                  <Form.Item name={"cnee"} style={styles.FormItem}>
                    <Input placeholder="Enter CNEE" style={styles.FormInput} />
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <td style={styles.label}>PSC : </td>
                <td>
                  <Form.Item name={"psc"} style={styles.FormItem}>
                    <Input placeholder="Enter Psc" style={styles.FormInput} />
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <td style={styles.label}>ACTUAL WEIGHT : </td>
                <td>
                  <Form.Item name={"actual_weight"} style={styles.FormItem}>
                    <Input
                      placeholder="Enter Weight"
                      style={styles.FormInput}
                    />
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <td style={styles.label}>DIMENSION : </td>
                <td>
                  <Form.Item name={"dimension"} style={styles.FormItem}>
                    <Input
                      placeholder="Enter Dimension"
                      style={styles.FormInput}
                    />
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <td style={styles.label}>VOLUME WEIGHT : </td>
                <td>
                  <Form.Item name={"volume_weight"} style={styles.FormItem}>
                    <Input
                      placeholder="Enter Weight"
                      style={styles.FormInput}
                    />
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <td style={styles.label}>CHARGABLE WEIGHT : </td>
                <td>
                  <Form.Item name={"chargable_weight"} style={styles.FormItem}>
                    <Input
                      placeholder="Enter Weight"
                      style={styles.FormInput}
                    />
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <td style={styles.label}>RATE : </td>
                <td>
                  <Form.Item name={"rate"} style={styles.FormItem}>
                    <Input placeholder="Enter Rate" style={styles.FormInput} />
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <td style={styles.label}>OTHER CHARGE : </td>
                <td>
                  <Form.Item name={"other_charge"} style={styles.FormItem}>
                    <Input
                      placeholder="Enter Charge"
                      style={styles.FormInput}
                    />
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <td style={styles.label}>AWB CHARGE : </td>
                <td>
                  <Form.Item name={"awb_charge"} style={styles.FormItem}>
                    <Input
                      placeholder="Enter Charge"
                      style={styles.FormInput}
                    />
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <td style={styles.label}>PACKING CHARGE : </td>
                <td>
                  <Form.Item name={"packing_charge"} style={styles.FormItem}>
                    <Input
                      placeholder="Enter Charge"
                      style={styles.FormInput}
                    />
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <td style={styles.label}>INSURANCE CHARGE : </td>
                <td>
                  <Form.Item name={"insurance_charge"} style={styles.FormItem}>
                    <Input
                      placeholder="Enter Charge"
                      style={styles.FormInput}
                    />
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <td style={styles.label}>ADDITIONAL CHARGE : </td>
                <td>
                  <Form.Item name={"additional_charge"} style={styles.FormItem}>
                    <Input
                      placeholder="Enter Charge"
                      style={styles.FormInput}
                    />
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <td style={styles.label}>PLACE OF GENERATE : </td>
                <td>
                  <Form.Item name={"place_of_generate"} style={styles.FormItem}>
                    <Input placeholder="Enter Place" style={styles.FormInput} />
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <td style={styles.label}>INCHARGE OF GENERATE : </td>
                <td>
                  <Form.Item
                    name={"incharge_of_generate"}
                    style={styles.FormItem}
                  >
                    <Input placeholder="Enter Name" style={styles.FormInput} />
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <Button onClick={() => props.onClose()} block size="large">
                    Cancel
                  </Button>
                </td>
                <td>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    block
                    size="large"
                  >
                    Submit
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </Container>
      </Form>
    </Modal>
  );
}
export default FormModal;

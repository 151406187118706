import React, { useState, useEffect } from "react";
import axios from "axios";
import { message } from "antd";
import { API, Headers } from "../../Config";
import LoadingBox from "../../Components/LoadingBox";
import PageHeader from "../../Routes/PageHeader";
import DataTable from "./DataTable";
import PrintModal from "./PrintModal";
import FormModel from "./FormModel";

import { useSelector } from "react-redux";
import { create } from "@mui/material/styles/createTransitions";
function ThirdPartyTransactions() {
  const Auth = useSelector((state) => state.Auth.user);
  const [loading, setLoading] = useState(false);
  const [indicator, setIndicator] = useState(false);
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [data, setData] = useState([]);
  const [unAssigned, setUnAssigned] = useState([]);
  const [party, setParty] = useState([]);
  const [meta, setMeta] = useState({});
  const [item, setItem] = useState({});
  const [print, setPrint] = useState(false);
  const [form, setform] = useState(false);
  const [mode, setMode] = useState("post");
  useEffect(() => {
    loaddata(page, take);
    listUnAssigned();
    List3Party();
  }, []);
  const listUnAssigned = () => {
    setLoading(true);
    //localhost:8082/boxesNew/ThirdPartyAssignList/1
   

    http: axios
      .get(
        API.BASR_URL +
          API.BOXES_LIST +
          `/ThirdPartyAssignList/${Auth.branch_id }`,
        {
          headers: Headers(),
        }
      )
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          const formattedData = data.map((item) => ({
            key: item.box_number,
            title: item.box_number,
            description: item.state,
            weight: item.chargable_weight,
          }));
         
          setUnAssigned(formattedData);
          // setBoxes2(data);
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const List3Party = () => {
    setLoading(true);
    axios
      .get(API.BASR_URL + API.THIRD_PARTY_SENDERS + "/list", {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;

          setParty(data);

          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error); 
      });
  };
  const loaddata = (p, t) => {
    setLoading(true);

    axios
      .get(API.BASR_URL + `ThirdPartytrans?order=DESC&page=${p}&take=${t}`, {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setData(data.data);
          setMeta(data.meta);

          message.success("Success");
        } else {
          message.error("Oops.Something went wrong");
        }
        setLoading(false);
        setIndicator(false);
      })
      .catch(function (error) {
        message.error("Oops.Something went wrong");
        setIndicator(false);
        setLoading(false);
      });
  };

  const pagination = (page, take) => {
    window.scrollTo(0, 0);
    
    setIndicator(true);
    setPage(page);
    setTake(take);
    loaddata(page, take);
  };
  const printItem = (items) => {
    setItem(items);
    setPrint(true);
  };
  const AddItem = () => {
    setMode("post");
    setItem({});
    setform(true);
  };

  const relaod = () => {
    loaddata(page, take);
    setMode("post");
    setItem({});
    setform(false);
  };

  const UpdateItem = (item) => {
    console.log("222222222222222222", item);
    setMode("put");
    setItem(item);
    setform(true);
  };
  return (
    <div>
      <PageHeader
        PageName={"Third Party Trans"}
        breadScrum={"Admin /Third Party Transaction"}
        loader={indicator}
        RightButton={"Add Third Party Trans "}
        RightClcik={() => AddItem()}
      />
      {loading ? (
        <LoadingBox />
      ) : (
        <DataTable
          meta={meta}
          data={data}
          update={(item) => UpdateItem(item)}
          // delete={(item) => DeleteItem(item)}
          printItem={(items) => printItem(items)}
          PageOnchange={(page, take) => pagination(page, take)}
        />
      )}

      {print ? (
        <PrintModal
          item={item}
          visible={print}
          onCancel={() => setPrint(false)}
        />
      ) : null}
      {form ? (
        <FormModel
          item={item}
          party={party}
          mode={mode}
          visible={form}
          unAssigned={unAssigned}
          onchange={() => relaod()}
          onClose={() => setform(false)}
        />
      ) : null}
    </div>
  );
}
export default ThirdPartyTransactions;

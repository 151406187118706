import React, { useState, useEffect } from "react";
import PageHeader from "../../Routes/PageHeader";
// import FormModal from "./FormModal";
import DataTable from "./DataTable";
import axios from "axios";
import { message } from "antd";
import { API, Headers } from "../../Config";
import LoadingBox from "../../Components/LoadingBox";
import Item from "antd/lib/list/Item";
function BoxItem() {
  const [loading, setLoading] = useState(true);
  const [indicator, setIndicator] = useState(false);
  const [form, setform] = useState(false);
  const [mode, setMode] = useState("post");
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({});
  const [columns, setColumns] = useState({});
  const [item, setItem] = useState({});

  useEffect(() => {
    loaddata(page, take);
  }, []);

  const loaddata = (p, t) => {
    axios
      .get(API.BASR_URL + `item?order=ASC&page=${p}&take=${t}`, {
        headers: Headers(),
      })
      .then(function (response) {
        console.log("response==============>", response.data.data);
        if (response.status === 200) {
          let data = response;
          setData(data.data.data);
          setMeta(data.meta.data.data);
          setLoading(false);
          setIndicator(false);
          message.success("Success");
        }
      })
      .catch(function (error) {
        message.error("Oops.Something went wrong");
        setIndicator(false);
        setLoading(false);
      });
  };

  const pagination = (page, take) => {
    window.scrollTo(0, 0);
    setIndicator(true);
    setPage(page);
    setTake(take);
    loaddata(page, take);
  };

  const relaod = () => {
    loaddata(page, take);
    setMode("post");
    setItem({});
    setform(false);
  };

  const AddItem = () => {
    setMode("post");
    setItem({});
    setform(true);
  };

  const UpdateItem = (item) => {
    setMode("put");
    setItem(item);
    setform(true);
  };

  const DeleteItem = (item) => {
    setIndicator(true);
    axios
      .delete(API.BASR_URL + `item/` + item.id, {
        headers: Headers(),
      })

      .then(function (response) {
        setIndicator(false);
        message.success("Deleted Successfully");
        relaod();
      })
      .catch(function (error) {
        setIndicator(false);
        message.error("Oops.Something went wrong");
        console.log(error);
      });
  };

  return (
    <div>
      <PageHeader
        PageName={"BoxItem"}
        breadScrum={"Admin / Items"}
        loader={indicator}
      />
      {loading ? (
        <LoadingBox />
      ) : (
        <div>
          <DataTable
            data={data}
            meta={meta}
            update={(item) => UpdateItem(item)}
            delete={(item) => DeleteItem(item)}
            PageOnchange={(page, take) => pagination(page, take)}
          />
        </div>
      )}
    </div>
  );
}
export default BoxItem;

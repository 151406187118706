import React from "react";
import { useState } from "react";
import { Table, Pagination, Button, Popconfirm, Tooltip, Input } from "antd";
import { AiOutlineDelete } from "react-icons/ai";
import { AiOutlineFilter, AiOutlinePrinter } from "react-icons/ai";
import moment from "moment";

function Datatable(props) {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          size="middle"
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
        <div style={{ margin: 5 }} />
        <Button onClick={() => handleReset(clearFilters)} size="small">
          Reset
        </Button>
        <Button
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          type="primary"
          size="small"
        >
          Search
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <div style={{ backgroundColor: "transparent" }}>
        <AiOutlineFilter />
      </div>
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const onSelectChange = (newSelectedRowKeys, rowDetails) => {
    props.onSelect(rowDetails);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const calculateIndex = (pageIndex, pageSize, index) => {
    return pageIndex * pageSize + index + 1;
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) =>
        calculateIndex(props.meta.page - 1, props.meta.take, index),
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      ...getColumnSearchProps("user"),

      render: (text, record, index) => (
        <Tooltip title={record.name && record.name}>
          <div
            className=""
            onClick={() => onSelectChange([record.id], [record])}
          >
            <div className="">
              {record.user && record.user.name && record.user.name}
            </div>
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Agent Name",
      dataIndex: "awb_xray_agent",
      key: "awb_xray_agent",
      render: (text, record) => (
        <div>{record.awb_xray_agent && record.awb_xray_agent?.name}</div>
      ),
    },

    {
      title: "Date",
      dataIndex: "date",
      render: (text) => <div>{moment(text).format("DD-MM-YYYY")}</div>,
      key: "date",
    },
    {
      title: "Time",
      dataIndex: "date",
      render: (text) => <div>{moment(text).format("h:mm A")}</div>,
      key: "time",
    },
    {
      title: "AWB No",
      render: (text, record) =>
        record.airwaybill.map((value, index) => (
          <div>
            {`${value.airline && value?.airline} - ${
              value.awb_no && value?.awb_no
            }`}
          </div>
        )),
    },
    {
      title: "Pieces",
      // dataIndex: "total_pcs",
      key: "total_pcs",
      render: (item, record) =>
        record.airwaybill.reduce((acc, curr) => {
          return Number(acc) + Number(curr.psc);
        }, 0),
    },
    {
      title: "Weight",
      dataIndex: "total_weight",
      key: "total_weight",
    },
    {
      title: "Destination Airport",
      render: (item, record) => (
        <div>{record.airwaybill[0]?.destination_airport_name}</div>
      ),
    },
    {
      title: "Action",
      width: 50,
      render: (item) => (
        <div className="tableAction">
          <div>
            <Button
              size="small"
              type="primary"
              style={{ borderRadius: 8, fontSize: 12 }}
              onClick={() => props.printItem(item)}
            >
              Print
            </Button>
          </div>
          <Popconfirm
            placement="bottomRight"
            title={"Are you sure to delete?"}
            okText="Yes"
            cancelText="No"
            onConfirm={() => props.delete(item)}
          >
            <AiOutlineDelete size={20} />
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div style={{ minHeight: "60vh" }}>
      <Table
        columns={columns}
        dataSource={props.data}
        pagination={false}
        size="small"
      />
      <br />
      <div align="right">
        <Pagination
          responsive
          defaultCurrent={props.meta && props.meta.page}
          total={props.meta && props.meta.itemCount}
          pageSize={props.meta && props.meta.take ? props.meta.take : 0}
          onChange={props.PageOnchange}
          pageSizeOptions={[10, 20, 50]}
        />
      </div>
    </div>
  );
}

export default Datatable;

import React, { useEffect, useState, useRef } from "react";
import styles from "../styles";
import { Modal, message, Button } from "antd";
import { Row, Col } from "react-bootstrap";
import { API, Headers } from "../../../Config";
import axios from "axios";
import Barcode from "react-barcode";
import ReactToPrint from "react-to-print";
import { AiFillPrinter, AiOutlineCloseCircle } from "react-icons/ai";
import documentloader from "../../../Assets/Images/documentloader.gif";

function PrintDocumentModal(props) {
  const componentRef = useRef();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadBoxesViaAwb();
  }, []);
  const loadBoxesViaAwb = () => {
    const AWBID = props.selected && props.selected[0].id;
    let URL = API.BASR_URL + API.BOXES_VIA_AWB + AWBID;
    axios
      .get(URL, {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setData(data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          message.error("Oops.Something went");
        }
      });
  };

  return (
    <Modal
      visible={props.visible}
      onCancel={props.onClose}
      width={data && data.length ? 850 : 300}
      footer={false}
    >
      {isLoading || (data && data.length === 0) ? (
        <>
          {isLoading ? (
            <div className="print-manifest-box1">
              <img src={documentloader} className="print-document-loader" />
              <div>Downloading Documents...</div>
            </div>
          ) : data && data.length === 0 ? (
            <div className="print-manifest-box1">
              <br />
              <AiOutlineCloseCircle size={50} color={"red"} />
              <div style={{ margin: 20 }} />
              <div>Sorry No document Find.</div>
              <div style={{ margin: 30 }} />
              <Button type="primary" onClick={props.onClose}>
                Close
              </Button>
            </div>
          ) : null}
        </>
      ) : (
        <>
          <h5 className="PrintLabelModal-txt1">Print Document</h5>
          <ReactToPrint
            trigger={() => (
              <Button
                style={styles.buttons}
                type="primary"
                icon={<AiFillPrinter style={styles.buttonsIcon} />}
              >
                Print
              </Button>
            )}
            content={() => componentRef.current}
          />

          <br />
          <div ref={componentRef}>
            {data
              .sort((a, b) => a.box_number - b.box_number)
              .map((item) => {
                return (
                  <div className="PrintDocumentModal-page">
                    <div className="PrintDocumentModal-pageHeader">
                      <div>
                        <h6>Box Number : {item.box_number}</h6>
                      </div>
                      <div>
                        <Barcode value={item.box_number} height="30" />
                      </div>
                    </div>
                    <hr />
                    <div className="PrintDocumentModal-Box">
                      <div>
                        <img
                          key={item.id}
                          src={item.adhar}
                          className="print-document-image"
                          alt="adhar-front"
                        />
                      </div>
                      <br />
                      <div>
                        <img
                          key={item.id}
                          src={item.adhar_back}
                          className="print-document-image"
                          alt="adhar-back"
                        />
                      </div>
                      <div>
                        <img
                          key={item.id}
                          src={item.iqama}
                          className="print-document-image"
                          alt="iqama-front"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <br />
          <br />
          <Row>
            <Col sm="8" xs="12"></Col>
            <Col sm="4" xs="12">
              <Row>
                <Col sm="6" xs="12">
                  <Button onClick={() => props.onClose()} block size="large">
                    Cancel
                  </Button>
                </Col>
                <Col sm="6" xs="12">
                  <ReactToPrint
                    trigger={() => (
                      <Button type="primary" size="large" block>
                        Print
                      </Button>
                    )}
                    content={() => componentRef.current}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </Modal>
  );
}

export default PrintDocumentModal;

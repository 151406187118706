import React, { useState, useRef } from "react";
import styles from "./styles";
import { Form, Button, Input, Select, message, Tooltip, Search } from "antd";
import { Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";

import LoadingBox from "../../Components/LoadingBox";
import SearchModal from "./SearchModel";
import { API, Headers } from "../../Config";
import axios from "axios";
import PincodeSearchModel from "./PincodeSearchModel";
const { Option } = Select;
function CustomerForm(props) {
  const [data, setData] = useState([]);

  const [searchModal, setSearchModal] = useState(false);
  const [searchPincodeModal, setSearchPincodeModal] = useState(false);

  const [prefix, setPrefix] = useState("PH");
  const [search, setSearch] = useState("");
  const [pincode, setPincode] = useState("");
  const [pincodeData, setPincodeData] = useState([]);
  const [searchData, setsearchData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [indicator, setIndicator] = useState(true);
  const formRef = useRef();

  const onFinish = (values) => {
    let next = Number(props.count) + 1;
    setData(values);
    props.next(next, values);
    message.info("Customer info added");
    props.onTabchange("3");
  };
  const OnBack = () => {
    let back = Number(props.count) - 1;
    props.next(back, data);
    props.onTabchange("1");
  };

  const handleChanges = (action, values) => {
    setData(values);
    props.onchange(props.count, values);
  };

  const chooseSenderAndReciverData = (item) => {
    setSearchModal(false);

    if (item) {
      formRef.current?.setFieldsValue({
        sender_name: item.sender_name,
        sender_address: item.sender_address,
        sender_phone: item.sender_phone,
        sender_phone_alt: item.sender_phone_alt,
        reciver_name: item.reciver_name,
        reciver_address: item.reciver_address,
        reciver_pincode: item.reciver_pincode,
        reciver_post: item.reciver_pincode,
        reciver_distrcit: item.reciver_distrcit,
        reciver_state: item.reciver_state,
        reciver_country: item.reciver_country,
        reciver_phone: item.reciver_phone,
        reciver_altphone: item.reciver_altphone,
      });
    }
  };
  const choosePincodeData = (item) => {
    setSearchPincodeModal(false);

    if (item) {
      formRef.current?.setFieldsValue({
        reciver_pincode: item.pincode,
        reciver_post: item.office,
        reciver_distrcit: item.district,
        reciver_state: item.state,
        reciver_country: item.country_id,
      });
    }
  };

  const loaddata = () => {
    setIndicator(true);
    setLoading(true);
    message.loading("Loading....");

    let URL =
      prefix === "REF"
        ? API.BASR_URL + API.PICKUP_COLLECTION_REQUEST_LIST + `/list/${search}`
        : API.BASR_URL +
          API.PICKUPCOLLECTIONS_LIST +
          `/details/search/${search}/${prefix}`;

    console.log("URL===>", URL);
    if (search) {
      axios
        .get(URL, {
          headers: Headers(),
        })
        .then(function (response) {
          if (response.status === 200) {
            let data = response.data;
            setsearchData(data);

            message.success("Success");
          } else {
            message.error("Oops.Something went wrongs");
          }

          setLoading(false);
          setIndicator(false);
        })
        .catch(function (error) {
          message.error("Oops.Something went wrong....123");
          setIndicator(false);
          setLoading(false);
        });

      if (prefix === "REF") {
        console.log("setsearchData ===>", searchData);
        console.log("setsearchData ===>", searchData[0].id);
        let ReqID = searchData && searchData[0].id;
        let obj = {
          active: false,
        };
        let URL = API.BASR_URL + API.PICKUP_COLLECTION_REQUEST_ACTIONS + ReqID;
        axios({
          method: "put",
          headers: Headers(),
          url: URL,
          data: obj,
        })
          .then(function (response) {
            if (response.status === 200) {
              console.log(response.data);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  };
  const loadPincodeData = () => {
    setIndicator(true);
    setLoading(true);
    message.loading("Loading....");
    let url = API.BASR_URL + API.PINCODE_SEARCH + `${pincode}`;

    if (pincode) {
      axios
        .get(url, {
          headers: Headers(),
        })
        .then(function (response) {
          if (response.status === 200) {
            let data = response.data;
            setPincodeData(data);
            message.success("Success");
            setLoading(false);
            setIndicator(false);
          } else {
            console.log("1234567");
            message.error("Oops.Something went wrongs");
          }
        })
        .catch(function (error) {
          message.error("Oops.Something went wrong....987" + error);
          setIndicator(false);
          setLoading(false);
        });
    }
  };

  const searchPincodeButton = (val) => {
    val.preventDefault();
    setSearchPincodeModal(true);
    loadPincodeData(pincode);
  };
  const handlePincodeChange = (event) => {
    setPincode(event.target.value);
  };

  const handleDataChange = (event) => {
    event.preventDefault();
    setSearch(event.target.value);
    console.log("handleDataChange====>", event.target.value);
  };
  const selectChange = (e) => {
    setPrefix(e);
    console.log("prefixSelector===>", e);
  };

  const onSearchPickup = (event, val) => {
    event.preventDefault();
    setSearchModal(true);
    loaddata(prefix, search);
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{
          width: 150,
        }}
        value={prefix}
        onChange={selectChange}
        defaultValue={"PH"}
      >
        <Option value="PH">Phone</Option>
        <Option value="BOX">Box Number</Option>
        <Option value="REF">Pickup Request</Option>
      </Select>
    </Form.Item>
  );

  return (
    <div>
      <Form
        layout="vertical"
        hideRequiredMark
        onFinish={onFinish}
        onValuesChange={handleChanges}
        ref={formRef}
        initialValues={
          props.mode === "put"
            ? {
                cargo_type:
                  props.data && props.data.cargo_type
                    ? props.data.cargo_type
                    : "air",
                sender_name: props.item && props.item.sender_name,
                sender_address: props.item && props.item.sender_address,
                sender_phone: props.item && props.item.sender_phone,
                sender_phone_alt: props.item && props.item.sender_phone_alt,
                reciver_name: props.item && props.item.reciver_name,
                reciver_address: props.item && props.item.reciver_address,
                reciver_pincode: props.item && props.item.reciver_pincode,
                reciver_post: props.item && props.item.reciver_post,
                reciver_distrcit: props.item && props.item.reciver_distrcit,
                reciver_state: props.item && props.item.reciver_state,
                reciver_country: props.item && props.item.reciver_country,
                reciver_phone: props.item && props.item.reciver_phone,
                reciver_altphone: props.item && props.item.reciver_altphone,
              }
            : {
                cargo_type:
                  props.data && props.data.cargo_type
                    ? props.data.cargo_type
                    : "air",
                sender_name: props.data && props.data.sender_name,
                sender_address: props.data && props.data.sender_address,
                sender_phone: props.data && props.data.sender_phone,
                sender_phone_alt: props.data && props.data.sender_phone_alt,
                reciver_name: props.data && props.data.reciver_name,
                reciver_address: props.data && props.data.reciver_address,
                reciver_pincode: props.data && props.data.reciver_pincode,
                reciver_post: props.data && props.data.reciver_post,
                reciver_distrcit: props.data && props.data.reciver_distrcit,
                reciver_state: props.data && props.data.reciver_state,
                reciver_country: props.data && props.data.reciver_country,
                reciver_phone: props.data && props.data.reciver_phone,
                reciver_altphone: props.data && props.data.reciver_altphone,
              }
        }
      >
        <div align="center">
          <Input.Group compact style={styles.InputGroups}>
            <Input.Search
              addonBefore={prefixSelector}
              style={{
                width: "calc(100% - 600px)",
              }}
              placeholder="Search By Phone Number Or Box Number"
              onChange={(val) => handleDataChange(val)}
              value={search}
              onSearch={(e, val) => onSearchPickup(val)}
              enterButton
              allowClear
            />
          </Input.Group>
        </div>
        <div className="collection-customerform-box">
          <div className="collection-customerform-txt1">Sender Info</div>
        </div>

        <Row>
          <Col sm="6" xs="12">
            <Form.Item
              name="sender_name"
              label={
                <div className="collection-customerform-formlabel">
                  Sender Name
                </div>
              }
              rules={[{ required: false, message: "required" }]}
            >
              <Input placeholder="Sender Name" style={styles.FormItemInput} />
            </Form.Item>
          </Col>
          <Col sm="6" xs="12">
            <Form.Item
              name="sender_address"
              label={
                <div className="collection-customerform-formlabel">Address</div>
              }
              rules={[{ required: false, message: "required" }]}
            >
              <Input.TextArea
                placeholder="Enter Address"
                style={styles.FormItemInputAdress}
                rows={4}
              />
            </Form.Item>
          </Col>
          <Col sm="6" xs="12" style={{ marginTop: "-85px" }}>
            <Row>
              <Col sm="6" xs="12">
                <Form.Item
                  name="sender_phone"
                  label={
                    <div className="collection-customerform-formlabel">
                      Phone Number
                    </div>
                  }
                  rules={[{ required: false, message: "required" }]}
                  style={{ order: 2 }}
                >
                  <Input
                    placeholder="Enter phone number"
                    style={styles.FormItemInput}
                    tabIndex={0}
                  />
                </Form.Item>
              </Col>
              <Col sm="6" xs="12">
                <Form.Item
                  name="sender_phone_alt"
                  label={
                    <div className="collection-customerform-formlabel">
                      Alt. Phone Number
                    </div>
                  }
                  rules={[{ required: false, message: "required" }]}
                >
                  <Input
                    placeholder="Enter phone number"
                    style={styles.FormItemInput}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="collection-customerform-box">
          <div className="collection-customerform-txt1">Reciever Info</div>
          <div>
            <Input.Group compact style={styles.InputGroups}>
              <Input
                placeholder="Search Pincode"
                style={{
                  width: "calc(100% - 50px)",
                }}
                onChange={handlePincodeChange}
                onPressEnter={(val) => searchPincodeButton(val)}
                allowClear
              />
              <Button
                type="primary"
                onClick={(val) => searchPincodeButton(val)}
                icon={<AiOutlineSearch />}
              />
            </Input.Group>
          </div>
        </div>
        <Row>
          <Col sm="6" xs="12">
            <Form.Item
              name="reciver_name"
              label={
                <div className="collection-customerform-formlabel">
                  Reciever Name
                </div>
              }
              rules={[{ required: false, message: "required" }]}
            >
              <Input placeholder="Enter Name " style={styles.FormItemInput} />
            </Form.Item>
          </Col>
          <Col sm="6" xs="12">
            <Form.Item
              name="reciver_address"
              label={
                <div className="collection-customerform-formlabel">Address</div>
              }
              rules={[{ required: false, message: "required" }]}
            >
              <Input.TextArea
                placeholder="Enter Address"
                style={styles.FormItemInputAdress}
                rows={4}
              />
            </Form.Item>
          </Col>
          <Col sm="6" xs="12" style={{ marginTop: "-83px" }}>
            <Row>
              <Col sm="6" xs="12">
                <Form.Item
                  name="reciver_country"
                  label={
                    <div className="collection-customerform-formlabel">
                      Country
                    </div>
                  }
                  rules={[{ required: false, message: "required" }]}
                >
                  <Select
                    bordered={false}
                    className="CustomerForm-Select"
                    placeholder="Select Country"
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ margin: 4, textTransform: "uppercase" }}
                  >
                    {props.countries.map((option) => (
                      <Select.Option key={option.code} value={option.id}>
                        {option.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm="6" xs="12">
                <Form.Item
                  name="reciver_pincode"
                  label={
                    <div className="collection-customerform-formlabel">
                      Pincode
                    </div>
                  }
                  rules={[{ required: false, message: "required" }]}
                >
                  <Input
                    placeholder="Enter Pincode"
                    style={styles.FormItemInput}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={styles.RecieverFormRow}>
          <Col sm="2" xs="12" style={styles.RecieverFormRowItem}>
            <Form.Item
              name="reciver_post"
              label={
                <div className="collection-customerform-formlabel">Post</div>
              }
              rules={[{ required: false, message: "required" }]}
            >
              <Input placeholder="Enter Post" style={styles.FormItemInput} />
            </Form.Item>
          </Col>
          <Col sm="2" xs="12" style={styles.RecieverFormRowItem}>
            <Form.Item
              name="reciver_distrcit"
              label={
                <div className="collection-customerform-formlabel">
                  District
                </div>
              }
              rules={[{ required: false, message: "required" }]}
            >
              <Input
                placeholder="Enter District"
                style={styles.FormItemInput}
              />
            </Form.Item>
          </Col>

          <Col sm="2" xs="12" style={styles.RecieverFormRowItem}>
            <Form.Item
              name="reciver_state"
              label={
                <div className="collection-customerform-formlabel">State</div>
              }
              rules={[{ required: false, message: "required" }]}
            >
              <Input placeholder="Enter State" style={styles.FormItemInput} />
            </Form.Item>
          </Col>
          <Col sm={2} xs="12" style={styles.RecieverFormRowItem}>
            <Form.Item
              name="reciver_phone"
              label={
                <div className="collection-customerform-formlabel">Phone</div>
              }
              rules={[{ required: false, message: "required" }]}
            >
              <Input
                placeholder="Enter Contact Number"
                style={styles.FormItemInput}
              />
            </Form.Item>
          </Col>
          <Col sm="2" xs="12" style={styles.RecieverFormRowItem}>
            <Form.Item
              name="reciver_altphone"
              label={
                <div className="collection-customerform-formlabel">
                  Alernative phone
                </div>
              }
              rules={[{ required: false, message: "required" }]}
            >
              <Input
                placeholder="Enter Contact Number"
                style={styles.FormItemInput}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row className="boxformFinalBtn">
          <Col sm="3" xs="6">
            <Button type="primary" htmlType="submit" block size="large">
              Next
            </Button>
          </Col>
          <Col sm="3" xs="6">
            <Button
              onClick={() => OnBack()}
              block
              size="large"
              style={styles.BackButton}
            >
              Back
            </Button>
          </Col>
        </Row>
      </Form>

      {searchModal ? (
        loading ? (
          <LoadingBox></LoadingBox>
        ) : (
          <SearchModal
            visible={searchModal}
            onClose={() => setSearchModal(false)}
            data={searchData}
            select={(val) => {
              chooseSenderAndReciverData(val);
            }}
          />
        )
      ) : null}
      {searchPincodeModal ? (
        <PincodeSearchModel
          visible={searchPincodeModal}
          onClose={() => setSearchPincodeModal(false)}
          data={pincodeData}
          select={(val) => {
            choosePincodeData(val);
          }}
        />
      ) : null}
    </div>
  );
}
export default withRouter(CustomerForm);

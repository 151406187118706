import React, { useState, useEffect, useRef } from "react";
import { Form, DatePicker, Select, Button, Table, message } from "antd";
import moment from "moment";
import { API, Headers } from "../../../Config";
import axios from "axios";
import ReactToPrint from "react-to-print";
import { PrinterOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;
const { Option } = Select;

const PackingReport = () => {
  const [loading, setLoading] = useState(false);
  const componentRef = useRef();
  const [form] = Form.useForm();
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [user, setUsers] = useState([]);
  const [status, setStatus] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [userID, setUserID] = useState("");
  useEffect(() => {
    AccountsDropdown();
    StatusDropdown();
  }, []);
  const handleSearch = () => {
    form.validateFields().then((values) => {
      const { dateRange, user_id, status_id } = values;

      // Make an API call to the backend to fetch filtered data based on date range and selected account
      fetchFilteredData(dateRange, user_id, status_id);
    });
  };
  const fetchFilteredData = async (dateRange, user_id, status_id) => {
    console.log(status_id);
    const [startDate, endDate] = dateRange;
    const formattedStartDate = startDate
      ? startDate.startOf("day").toISOString()
      : null;
    const formattedEndDate = endDate
      ? endDate.endOf("day").toISOString()
      : null;
    setDateRange(dateRange);
    setUserID(user_id);
    setLoading(true);
    let obj = {
      date_from: formattedStartDate,
      date_to: formattedEndDate,
      user_id: Number(user_id),
      status_id: Number(status_id),
    };
    console.log(obj);

    axios({
      method: "post",
      url: API.BASR_URL + "box-status/report",
      headers: Headers(),
      data: obj,
    })
      .then(function (response) {
        setLoading(false);
        if ([200, 201].includes(response.status)) {
          message.success("Success");
          setFilteredDataSource(response.data);
        }
      })
      .catch(function (error) {
        setLoading(false);
        message.error("Oops.Something went wrong");
        console.log(error);
      });
  };
  const AccountsDropdown = () => {
    axios
      .get(API.BASR_URL + `users/list`, {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) setUsers(res.data);
      })
      .catch(function (error) {
        message.error("Customers dropdown faild");
      });
  };
  const StatusDropdown = () => {
    axios
      .get(API.BASR_URL + `status/list`, {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) setStatus(res.data);
      })
      .catch(function (error) {
        message.error("Customers dropdown faild");
      });
  };

  const columns = [
    {
      title: "#",
      key: "index",
      width: "20px",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, record, index) => (
        <span>{moment(record.created_at).format("DD-MM-YYYY")}</span>
      ),
    },
    {
      title: "Box No",
      dataIndex: "box_number",
      key: "box_number",
    },

    {
      title: "Kg",
      dataIndex: "",
      key: "",
      render: (record) => <span>{record.boxes && record.boxes.weight}</span>,
    },
    {
      title: "Service",
      dataIndex: "",
      key: "",
      render: (record) => (
        <span>
          {record.boxes && record.boxes.service && record.boxes.service.name}
        </span>
      ),
    },
    {
      title: "Remarks",
      dataIndex: "",
      key: "",
      render: (record) => <span>{record.boxes && record.boxes.remarks}</span>,
    },
    {
      title: "Status",
      dataIndex: "remarks",
      key: "remarks",
      // render: (record) => <span>{record.boxes && record.boxes.remarks}</span>,
    },
  ];

  const renderSummary = () => {
    const totalWeight = (filteredDataSource?.boxes || []).reduce(
      (acc, item) => {
        const itemWeight = parseFloat(item.weight);
        if (!isNaN(itemWeight)) {
          return acc + itemWeight;
        }
        return acc;
      },
      0
    );

    // const totalTaxAmount = data.reduce((acc, item) => acc + item.taxAmount, 0);
    // const totalDiscount = data.reduce((acc, item) => acc + item.discount, 0);
    return (
      <>
        <Table.Summary.Row className="my-summary-row">
          <Table.Summary.Cell className="cell"></Table.Summary.Cell>
          <Table.Summary.Cell className="cell"></Table.Summary.Cell>

          <Table.Summary.Cell align="center" style={{}} className="cell">
            {" "}
            <b>
              {Math.abs(Number(filteredDataSource.length))} {" Boxes"}{" "}
            </b>
          </Table.Summary.Cell>

          <Table.Summary.Cell className="cell">
            {" "}
            <b style={{ paddingLeft: "9px" }}></b>
          </Table.Summary.Cell>
          <Table.Summary.Cell className="cell">
            {" "}
            <b style={{ paddingLeft: "9px" }}></b>
          </Table.Summary.Cell>
          <Table.Summary.Cell className="cell">
            {" "}
            <b style={{ paddingLeft: "9px" }}></b>
          </Table.Summary.Cell>
          <Table.Summary.Cell className="cell">
            {" "}
            <b style={{ paddingLeft: "9px" }}></b>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  };
  const handlePrint = (record) => {
    // Handle printing logic here
    // You can use a library like react-to-print to facilitate the printing process
    // Example: https://www.npmjs.com/package/react-to-print
    componentRef.current.handlePrint();
  };
  return (
    <div>
      <Form
        form={form}
        layout="inline"
        style={{
          marginBottom: 16,
          display: "flex",
          justifyContent: "center",
          border: "1px solid #ccc",
          padding: "16px",
        }}
      >
        <Form.Item name="dateRange">
          <RangePicker size="small" style={{ width: 240 }} />
        </Form.Item>
        <Form.Item name="status_id">
          <Select
            size="small"
            placeholder="Select status"
            showSearch
            allowClear
            style={{ borderRadius: "0", width: "300px" }}
            filterOption={(input, option) => {
              const accountNo = option.value.toLowerCase();
              const accountName = option.children.toString().toLowerCase();
              return (
                accountNo.includes(input.toLowerCase()) ||
                accountName.includes(input.toLowerCase())
              );
            }}
          >
            {status.map((item, index) => (
              <Select.Option key={item.id} value={item.id}>
                {item.status_head}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="user_id">
          <Select
            size="small"
            placeholder="Select employee"
            showSearch
            allowClear
            style={{ borderRadius: "0", width: "300px" }}
            filterOption={(input, option) => {
              const accountNo = option.value.toLowerCase();
              const accountName = option.children.toString().toLowerCase();
              return (
                accountNo.includes(input.toLowerCase()) ||
                accountName.includes(input.toLowerCase())
              );
            }}
          >
            {user.map((item, index) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button
            size="small"
            type="primary"
            onClick={handleSearch}
            loading={loading}
          >
            Search
          </Button>
        </Form.Item>
        <Form.Item>
          <ReactToPrint
            trigger={() => (
              <Button
                size="small"
                type="primary"
                icon={<PrinterOutlined />}
                style={{ marginLeft: 8 }}
              >
                Print Statement
              </Button>
            )}
            content={() => componentRef.current}
          />
        </Form.Item>
      </Form>
      <div ref={componentRef} style={{ margin: "20px 20px" }}>
        <div style={{ marginBottom: "10px" }}>
          <b>Date Range:</b> {moment(dateRange[0]).format("DD-MM-YYYY")} to{" "}
          {moment(dateRange[1]).format("DD-MM-YYYY")}
        </div>
        <div style={{ marginBottom: "10px" }}>
          <b>Account:</b>{" "}
          {user.find((item) => item.id === userID)?.account_name}
        </div>

        <Table
          size="small"
          columns={columns}
          dataSource={filteredDataSource}
          pagination={false}
          bordered
          summary={renderSummary}
        />
      </div>
    </div>
  );
};

export default PackingReport;

import "./App.less";
import { Switch, Route } from "react-router-dom";
import { message } from "antd";
import LoginScreen from "./Containers/LoginScreen";
import BillsScreen from "./Containers/Bills";
import Routes from "./Routes";
import Tracking from "./Containers/Tracking";
function App() {
  message.config({ top: 100 });
  return (
    <div>
      <Switch>
        <Route path="/" exact component={LoginScreen} />
        <Route path="/auth" component={Routes} />
        <Route path="/bills" component={BillsScreen} />
        <Route path="/tracking" component={Tracking} />
      </Switch>
    </div>
  );
}
export default App;

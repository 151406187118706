import React, { useState, useEffect } from "react";
import { Modal, message } from "antd";
import LoadingBox from "../../Components/LoadingBox";
import { Table } from "react-bootstrap";
import { API, Headers } from "../../Config";
import axios from "axios";
import moment from "moment";
function PaymentRcptModal(props) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [indicator, setIndicator] = useState(false);
  const [take, setTake] = useState(10);
  const [meta, setMeta] = useState({});
  const [page, setPage] = useState(1);

  return (
    <Modal
      title={<span className="newFormModalTitle"> Payment reciept</span>}
      visible={props.visible}
      onOk={() => props.onClose()}
      onCancel={() => props.onClose()}
      width={900}
    >
      {loading ? (
        <LoadingBox />
      ) : (
        <div>
          <Table bordered className="formTable">
            <tbody className="DetailsMoal">
              <tr>
                <td>TRANSACTION_ID</td>
                <td>{props.item.airway_bill_no}</td>
              </tr>
              <tr>
                <td>box_number</td>
                <td>{props.item.box_number}</td>
              </tr>
              <tr>
                <td>time</td>
                <td>time_number</td>
              </tr>
              <tr>
                <td>amount</td>
                <td>amount_number</td>
              </tr>
            </tbody>
          </Table>
        </div>
      )}
    </Modal>
  );
}
export default PaymentRcptModal;

import React, { useState } from "react";
import { Form, Button, Modal, Select, message } from "antd";
import axios from "axios";
import { API, Headers } from "../../../Config";

function FormInfoBranchModal(props) {
  const { Option } = Select;
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    let URL = API.AIRWAY_BILLS_GEN_ACTION + props.selected.id;
    let requestObj = {
      to_branch_id: Number(values.to_branch_id),
    };
    axios({
      method: "put",
      url: API.BASR_URL + URL,
      headers: Headers(),
      data: requestObj,
    })
      .then(function (response) {
        if ([200, 201].includes(response.status)) {
          message.success("Success");
          props.onCancel();
        } else {
          setLoading(false);
          message.error("Oops.Something went wrong");
        }
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        message.error("Oops.Something went wrong");
        console.log(error);
      });
  };

  return (
    <Modal visible={props.visible} footer={false} onCancel={props.onCancel}>
      <br />

      <div>Notify Branch</div>
      <br />
      <Form onFinish={onFinish}>
        <Form.Item name="to_branch_id">
          <Select style={{ width: "100%" }} placeholder="Select a Branch">
            {props.branches.map((branch, index) => (
              <Option key={branch.id}>{branch.name}</Option>
            ))}
          </Select>
        </Form.Item>

        <br />
        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </Form>

      <br />
    </Modal>
  );
}

export default FormInfoBranchModal;

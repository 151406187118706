import moment from "moment";
import React from "react";

import { CSVDownload } from "react-csv";

function ExportReport(props) {
  var arr = [];

  props.data &&
    props.data.map((item, index) => {
      let obj = {
        "Created on": item.createdAt,
        "Truck No ": item.truck_no,
        "From Branch ": item.loggin_branch.name && item.loggin_branch.name,
        "To Branch": item.to_branch.name && item.to_branch.name,
        "Loaded Time": moment(item.loadded_time),
        "Deportedtime ": moment(item.deported_time),
        "Expected vehicle charge": item.expected_vehicle_charge,
      };
      arr.push(obj);
    });

  return (
    <div>
      <CSVDownload data={arr} />
    </div>
  );
}

export default ExportReport;

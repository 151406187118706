import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { MdFileDownload } from "react-icons/md";
import { Upload, message, Button, Input } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import * as XLSX from "xlsx";
import DataTable from "./DataTable";
import File from "./excel/SAHARI_CARGO_THIRD_PARTY_FILE.xlsx";
import { API, Headers } from "../../Config";

const Excel1 = () => {
  const [fileData, setFileData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginatedData, setPaginatedData] = useState([]);
  const [meta, setMeta] = useState({ page: 1, take: 10, itemCount: 0 });

  useEffect(() => {
    const { page, take } = meta;
    const start = (page - 1) * take;
    const end = start + take;
    setPaginatedData(fileData.slice(start, end));
  }, [fileData, meta]);

  const beforeUpload = (file) => {
    const isExcel =
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.ms-excel";
    if (!isExcel) {
      message.error("You can only upload Excel file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("File must be smaller than 2MB!");
    }
    return isExcel && isLt2M;
  };

  const transformKeys = (data) => {
    return data.map((item) => {
      return {
        boxNo: item["BOX NO"],
        pcs: item["PCS"],
        wgt: item["WGT"],
        rwgt: item["R WGT"],
        state: item["STATE"],
        remark: item["REMARK"],
      };
    });
  };

  const handleExcelUpload = (info) => {
    try {
      if (info.file.status === "uploading") {
        setLoading(true);
        return;
      }
      if (info.file.status === "done") {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = XLSX.utils.sheet_to_json(worksheet);
          const transformedData = transformKeys(json);
          setFileData(transformedData);
          setMeta({ ...meta, itemCount: json.length });
          setLoading(false);
        };
        reader.readAsArrayBuffer(info.file.originFileObj);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const DownloadButton = () => {
    const handleDownload = () => {
      const link = document.createElement("a");
      link.href = File;
      link.download = "SAHARI_CARGO_THIRD_PARTY_FILE.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    return (
      <Button onClick={handleDownload}>
        <MdFileDownload size={20} color="#1e88e5" /> &nbsp; Sample File
      </Button>
    );
  };

  const handlePageChange = (page, take) => {
    setMeta({ ...meta, page, take });
  };

  const onSubmit = () => {
    console.log("fileData------------->");
    console.log(fileData);
    console.log("fileData------------->");

    setLoading(true);
    try {
      const obj = fileData.map((item) => ({
        box_number: item?.boxNo,
        pcs: item.pcs,
        weight: item.wgt,
        RWGT: item?.rwgt,
        state: item.state,
        remark: item?.remark,
      }));
      axios({
        method: "post",
        url: API.BASR_URL + API.DELIVERY_STATUS_UPDATE,
        headers: Headers(),
        data: obj,
      })
        .then(function (response) {
          setLoading(false);
          setPaginatedData([]);
          if (response?.data?.status) {
            message.success("Success");
          } else {
            message.error("Fail");
          }
        })
        .catch(function (error) {
          setLoading(false);
          message.error("Oops. Something went wrong");
          console.log(error);
        });
    } catch (err) {
      console.log(err);
      message.error("Oops. Something went wrong");
      setLoading(false);
    }
  };

  return (
    <div>
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h4>Upload Excel</h4>
        <div>
          <DownloadButton />
          <div></div>
        </div>
      </div>

      <br />
      <hr />
      <br />

      <Row>
        <Col md="10">
          <Upload
            name="file"
            listType="picture-card"
            className="file-uploader"
            showUploadList={false}
            beforeUpload={beforeUpload}
            customRequest={({ file, onSuccess }) => {
              setTimeout(() => {
                onSuccess("ok");
              }, 0);
            }}
            onChange={handleExcelUpload}
          >
            {uploadButton}
          </Upload>
        </Col>
        <Col md="2">
          <Button
            onClick={() => onSubmit()}
            size="large"
            type="primary"
            style={{ width: "100%" }}
            loading={loading}
          >
            Update
          </Button>
        </Col>
      </Row>
      <br />
      <br />

      <div>
        {paginatedData.length > 0 && (
          <>
            <DataTable
              meta={meta}
              data={paginatedData}
              PageOnchange={handlePageChange}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Excel1;

import React, { useEffect, useState } from "react";
import { Form, Button, Modal, Select, message } from "antd";
import axios from "axios";
import { API, Headers } from "../../../Config";
import Excel from "../../../Assets/Images/excel-icons-animation.gif";
import "../styles.css";
import { MdDownload } from "react-icons/md";
import { GET } from "../../../Config/ApiRequests";
import * as XLSX from "xlsx";

const DeliveryFileModal2 = (props) => {
  const [boxes, setBoxes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getBoxes();
  }, []);

  console.log(props?.selected);

  const getBoxes = async () => {
    try {
      setIsLoading(true);
      const api = `boxesNew/byAwbNo/${props?.selected?.id}`;
      const boxesData = await GET(api, null);
      if (boxesData?.length) {
        setBoxes(boxesData);
        setIsLoading(false);
      } else {
        setBoxes([]);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const exportToExcel = () => {
    const transformedData = boxes?.map((item) => ({
      "BOX NO": item.box_number,
      WEIGHT: item.chargable_weight,
      "AGENT NAME": "",
      "DOKET NO": "",
      "TRACKING URL": "",
    }));
    transformedData.sort((a, b) => (a["BOX NO"] > b["BOX NO"] ? 1 : -1));
    const worksheet = XLSX.utils.json_to_sheet(transformedData);
    const workbook = XLSX.utils.book_new();
    let sheet_name = `${props?.selected?.airline}-${props?.selected?.awb_no}`;
    XLSX.utils.book_append_sheet(workbook, worksheet, sheet_name);
    let file_name = `${props?.selected?.airline}-${props?.selected?.awb_no}-DELIVERY_EXCEL.xlsx`;
    XLSX.writeFile(workbook, file_name);
  };

  return (
    <Modal open={props.open} centered footer={false} onCancel={props.close}>
      <div className="modal-content">
        {isLoading ? (
          <div>Loading...</div>
        ) : boxes?.length ? (
          <div className="modal-content">
            <img src={Excel} className="print-manifest-img" />
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              onClick={exportToExcel}
            >
              <MdDownload color="white" size={20} /> &nbsp;Download
            </Button>
          </div>
        ) : (
          <div>
            <h5>No data!</h5>
          </div>
        )}
      </div>

      <br />
    </Modal>
  );
};

export default DeliveryFileModal2;

import React, { useState } from "react";
import { Table, Pagination, Tooltip, Button, Input } from "antd";
import { AiOutlineFilter } from "react-icons/ai";

function DataTable(props) {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys, rowDetails) => {
    props.onSelect(rowDetails);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          size="middle"
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
        <div style={{ margin: 5 }} />
        <Button onClick={() => handleReset(clearFilters)} size="small">
          Reset
        </Button>
        <Button
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          type="primary"
          size="small"
        >
          Search
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <div>
        <AiOutlineFilter />
      </div>
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => (
        <div onClick={() => onSelectChange([record.id], [record])}>
          <div>{index + 1}</div>
        </div>
      ),
    },
    {
      title: "AIRLINE CODE",
      dataIndex: "airline_id",
      key: "airline_id",
      render: (text, record, index) => (
        <Tooltip title={text}>
          <div onClick={() => onSelectChange([record.id], [record])}>
            <div>{text}</div>
          </div>
        </Tooltip>
      ),
      ...getColumnSearchProps("airline_id"),
      sorter: (a, b) => a.airline_id.length - b.airline_id.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "AWB Number",
      dataIndex: "series_no",
      key: "series_no",
      render: (text, record, index) => (
        <Tooltip title={text}>
          <div onClick={() => onSelectChange([record.id], [record])}>
            <div>{text}</div>
          </div>
        </Tooltip>
      ),
      ...getColumnSearchProps("series_no"),
      sorter: (a, b) => a.series_no.length - b.series_no.length,
      sortDirections: ["descend", "ascend"],
    },
  ];

  return (
    <div>
      <Table
        rowKey="id"
        size="small"
        loading={props.loading}
        rowSelection={{ type: "radio", ...rowSelection }}
        columns={columns}
        dataSource={props.data}
        pagination={false}
      />
      <div align="right">
        <Pagination
          responsive
          onChange={props.PageOnchange}
          defaultCurrent={props.meta && props.meta.page}
          pageSize={props.meta && props.meta.take ? props.meta.take : 0}
          total={props.meta && props.meta.itemCount}
          pageSizeOptions={[10, 20, 50]}
        />
      </div>
    </div>
  );
}

export default DataTable;

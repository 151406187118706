// const S3con = {
//   bucketName: "sahari-bucket",
//   region: "ap-south-1",
//   accessKeyId: "AKIAYCY7LW3I3EYYG4XK",
//   secretAccessKey: "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",
// };
// export default S3con;

// latest //
const S3con = {
  bucketName: "sahari-bucket",
  region: "ap-south-1",
  accessKeyId: "AKIAYCY7LW3I6FPKWM47",
  secretAccessKey: "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",
};
export default S3con;
